import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ShiftNotes from "../ShiftNotes";
import { RotationNote, ShiftNote } from "src/models/NoteItem";
import { ShiftStatus } from 'src/enums/ShiftStatus';
import ShiftService from 'src/services/api/shift.service';
import "./HistoryModal.scss";
import { NoteTab, NoteType } from 'src/enums/NoteTypes';
import RotationService from 'src/services/api/rotations.service';
import RotationNotes from '../RotationNotes';

interface HistoryModalProps {
    show: boolean;
    onHide: any;
    noteType: NoteType,
    roleId?: string;
    scheduleId?: string;
    rotationId?: string;
}

const HistoryModal = ((props: HistoryModalProps) => {
    const rotationService = new RotationService();
    const shiftService = new ShiftService();

    const shiftPropsDefault = { status: "N/A", statusClassName: "", lastUpdatedDateTime: "N/A", userNameText: "N/A", userNameTooltip: "" };

    const [shiftNotes, setShiftNotes] = useState<ShiftNote>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [shiftProperties, setShiftProperties] = useState(shiftPropsDefault);
    const [rotationNotes, setRotationNotes] = useState<RotationNote>();

    useEffect(() => {
        const refreshShiftProperties = () => {
            if (shiftNotes) {
                const lastUpdatedDateTime = shiftNotes.shiftLastUpdatedDateTime !== '' ? new Date(shiftNotes.shiftLastUpdatedDateTime).toLocaleString() : shiftPropsDefault.lastUpdatedDateTime;
                const status = ShiftStatus[shiftNotes.shiftStatus] != '0' ? ShiftStatus[shiftNotes.shiftStatus].toLocaleLowerCase() : shiftPropsDefault.status;

                let statusClassName = shiftPropsDefault.statusClassName;

                switch (shiftNotes.shiftStatus) {
                    case ShiftStatus.Unassigned:
                        statusClassName = 'bi-exclamation-triangle mr-1';
                        break;
                    case ShiftStatus.Assigned:
                        if (shiftNotes.onShiftUserNames?.length > 1)
                            statusClassName = 'bi-people-fill mr-1';
                        else
                            statusClassName = 'bi-person-fill mr-1';
                        break;
                }

                let columnData = shiftPropsDefault.userNameText;
                let columnDataTooltip = shiftPropsDefault.userNameTooltip;

                if (shiftNotes.onShiftUserNames && shiftNotes.onShiftUserNames.length > 0) {
                    columnData = shiftNotes.onShiftUserNames[0];

                    if (shiftNotes.onShiftUserNames.length > 1) {
                        columnData += `, +${shiftNotes.onShiftUserNames?.length - 1}`;
                        columnDataTooltip = shiftNotes.onShiftUserNames?.join('\n');
                    }
                }

                setShiftProperties(prev => ({
                    ...prev,
                    lastUpdatedDateTime: lastUpdatedDateTime,
                    status: status,
                    statusClassName: statusClassName,
                    userNameText: columnData,
                    userNameTooltip: columnDataTooltip
                }));
            }
            else {
                setShiftProperties(shiftPropsDefault);
            }
        }

        refreshShiftProperties();
    }, [shiftNotes]);

    useEffect(() => {
        const getSelectRoleNotes = async () => {
            setLoading(true);
            await shiftService.getHistoryRoleNotes(props.roleId, props.scheduleId).then((data) => {
                setShiftNotes(data);
            }).catch((error) => {
                console.error(error);
            }).finally(() => setLoading(false));
        };

        const getRotationNotes = async () => {
            setLoading(true);

            await rotationService.getRotationNotesByRotationId(props.rotationId).then((data) => {
                setRotationNotes(data);
            }).catch((error) => {
                console.error(error);
            }).finally(() => setLoading(false));
        };

        const refreshModalData = async () => {
            if (props.noteType == NoteType.Shift && props.roleId && props.scheduleId)
                getSelectRoleNotes();
            else if (props.noteType == NoteType.Rotation && props.rotationId)
                getRotationNotes();
        }

        if (props.show)
            refreshModalData();
    }, [props.show]);

    const onHideSelectRoleModal = () => {
        props.onHide();
    }

    return (
        <Modal className='historyModal dialog1'
            show={props.show}
            onHide={() => onHideSelectRoleModal()}
            centered>
            <Modal.Header closeButton className='px-4'>
                <h4 className='m-0'>
                    {
                        props.noteType === NoteType.Shift &&
                        shiftNotes?.roleName + ' - ' + shiftNotes?.jobClassShortName
                    }
                    {
                        props.noteType === NoteType.Rotation &&
                        rotationNotes?.roleName + ' - ' + rotationNotes?.jobClassShortName
                    }
                </h4>
            </Modal.Header>

            <Modal.Body className='d-flex-column flex-grow-1 px-4 pb-0 bg-lightest-gray'>
                <div className="d-flex-column flex-grow-1 modalStyle">
                    {/*MyShiftNotes*/}
                    {props.noteType === NoteType.Shift &&
                        <>
                            <div className="d-flex gap-3 flex-wrap align-items-center border-bottom">
                                <div className='d-flex-column'>
                                    <strong>status</strong>
                                    <label>
                                        <i className={shiftProperties.statusClassName}></i>
                                        {shiftProperties.status}
                                    </label>
                                </div>
                                <div className='d-flex-column'>
                                    <strong>last updated</strong>
                                    <label>
                                        {shiftProperties.lastUpdatedDateTime}
                                    </label>
                                </div>
                                <div className='d-flex-column'>
                                    <strong>on-shift user</strong>
                                    <label title={shiftProperties.userNameTooltip}>
                                        {shiftProperties.userNameText}
                                    </label>
                                </div>
                            </div>

                            <ShiftNotes loading={loading} noteTab={NoteTab.HistoryNotesModal} noteData={shiftNotes} />
                        </>
                    }

                    {/*RotationNotes*/}
                    {props.noteType === NoteType.Rotation &&
                        <RotationNotes noteTab={NoteTab.SelectRoleRotationNotes} rotationData={rotationNotes} loading={loading} />
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
});

export default HistoryModal;