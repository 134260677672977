import { Tag, RoleTag } from 'src/models/Tag';
import { APIService } from './api.service';
import { TagType } from 'src/enums/TagType';

export default class TagService extends APIService {
    private url = '/tags';

    async getTagsByType(tagType: TagType): Promise<Tag[]> {
        const response = await this.api.get(`${this.url}/by-tagType`, {
            params: { tagType }
        });

        return response.data;
    }

    async getTagsByRoleId(roleId: string): Promise<RoleTag[]> {
        const response = await this.api.get(`${this.url}/by-roleId`, {
            params: { roleId }
        });
        return response.data;
    }

    async getTagsByRoleIdNoteId(roleId: string, noteId: string): Promise<RoleTag[]> {
        const response = await this.api.get(`${this.url}/by-roleid-noteid`, {
            params: { roleId, noteId }
        });
        return response.data;
    }

}
