import { ShiftScheduleInfo } from "./NoteItem";
import { ReportConfig } from "./ReportConfig";
import { GroupRole } from "./Role";

export class AppProfile {
    categoryTagSelectionLimit: number;
    sectionTagSelectionLimit: number;
    timeZone: string;
    rootRoleId: string;
    shiftScheduleInfoList: ShiftScheduleInfo[];
    reportConfig: ReportConfig
    roleId: string;
    isShiftEnabled: boolean;
    isRotationEnabled: boolean;
    isScheduleReportEnabled: boolean;
}

export class UserFacility {
    userProfileId: string;
    facilityId: string;
    facilityName: string;
    isSelected: boolean;
}

export class UserProfile {
    id: string;
    oId: string;
    firstName: string;
    lastName: string;
    email: string;
    groupRoles: GroupRole[];
    created: Date;
    isAuthorized: boolean;
    userFacilities: UserFacility[];
}

export class ManageUserAccessPageToast {
    show: boolean;
    content: string;
}

export const defaultUser0 = {
    id: "00000000-0000-0000-0000-000000000000",
    oId: "00000000-0000-0000-0000-000000000000",
    firstName: "Jane",
    lastName: "Doe",
    email: "fake0@chevron.com",
    groupRoles: null,
    created: new Date(),
    isAuthorized: false,
    userFacilities: null,
}

export const defaultUser1 = {
    id: "00000000-0000-0000-0000-000000000001",
    oId: "00000000-0000-0000-0000-000000000001",
    firstName: "John",
    lastName: "Doe",
    email: "fake1@chevron.com",
    groupRoles: null,
    created: new Date(),
    isAuthorized: true,
    userFacilities: null,
}