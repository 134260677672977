import "./Loading.scss";

const Loading = () => {
    return (
        <div className="content align-items-center justify-content-center">
            <h1>authentication in progress...</h1>
        </div>
    );
}

export default Loading;
