import { SilentRequest } from "@azure/msal-browser";
import axios from "axios";
import { msalInstance } from "src/index";
import { Toaster } from "src/services/api/toaster.service";

const acquireUserProfileAccessToken = async () => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();

    const request: SilentRequest = {
        scopes: ["User.Read"],
        account: activeAccount || accounts[0]
    };

    const authResult = await msalInstance.acquireTokenSilent(request);
    return authResult.accessToken
};

const axiosMSGraphInstance = axios.create({
    baseURL: process.env.REACT_APP_MS_GRAPH_URL
});

/* eslint-disable  @typescript-eslint/no-explicit-any */
function getErrorMessage(error: any) {
    let message = error.response?.data?.message;

    if (message == null) {
        message = error.message;
    }

    return message;
}

axiosMSGraphInstance.interceptors.request.use(
    async config => {
        const accessToken = await acquireUserProfileAccessToken();

        if (accessToken) {
            config.headers["Authorization"] = "Bearer " + accessToken;
        }

        return config;
    },
    error => {
        Toaster.showError(getErrorMessage(error));
        console.error(error)
        return Promise.reject(error);
    }
);

axiosMSGraphInstance.interceptors.response.use(
    async (response) => response,
    (error) => {
        return Promise.reject(error);
    });

export default axiosMSGraphInstance;
