export enum TagType {
    Category = 1,
    Role = 2,
    Section = 3,
}

export enum FileType {
    Doc = 1,
    Image = 2,
    Video = 3,
}

export enum NoteMetaDataType {
    Attachment = 1,
    RoleTag = 2,
}

export enum FilterDataType {
    Role = 1,
    CategoryTag = 2,
    RoleTag = 3,
    ShiftSchedulePeriod = 4,
    Location = 5,
    DateRange = 6,
    Text = 7,
}