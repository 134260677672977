import { useState } from "react";
import HistoryModal from "src/components/HistoryModal";
import { NoteType } from "src/enums/NoteTypes";
import { DateTimeHelper } from "src/helpers/DateTimeHelper";
import { useNotificationActionCreators } from "src/hooks/useActions";
import { GracePeriodNotificationFilterConfig } from "src/models/GracePeriodNotificationFilterConfig";
import { Notification } from "src/models/Notification";

interface ShiftGracePeriodNotificationCardProps {
    notification: Notification; 
}
const ShiftGracePeriodNotificationCard = (props: ShiftGracePeriodNotificationCardProps) => {

    const { setReadNotification, getUserNotifications } = useNotificationActionCreators();
    const [historyModalVisibility, setHistoryModalVisibility] = useState(false);

    const filter = (): GracePeriodNotificationFilterConfig =>{
        const filterConfig = JSON.parse(props.notification.filterConfig);
        console.log(filterConfig);
        return filterConfig;
    }
    console.log(filter().roleId);
    console.log(filter().shiftScheduleId);
    const showHistoryModal = () => {
        setHistoryModalVisibility(true);
        setReadNotification(props.notification.id);
    }

    const hideHistoryModal = () => {
        getUserNotifications();
        setHistoryModalVisibility(false);
    }

    return <li className="list-group-item-action list-group-item" onClick={showHistoryModal}>
    <div className="text-start col-auto">
        <p className="bold">{props.notification.message} </p>
        <div className="small mt-2">{DateTimeHelper.toHHMMSSString(DateTimeHelper.fromUTCToLocal(new Date(props.notification.created)))}</div>
    </div>
    <HistoryModal show={historyModalVisibility} onHide={hideHistoryModal} noteType={NoteType.Shift} roleId={filter().roleId} scheduleId={filter().shiftScheduleId} />
</li>;
}

export default ShiftGracePeriodNotificationCard;