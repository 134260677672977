import { FilterProfileHistory } from 'src/models/FilterProfileHistory';
import { APIService } from './api.service';
import { TagFilters } from 'src/models/Tag';

export default class Filters extends APIService {
    private url = '/filterprofile';

    async getAllFilters(pageType: number): Promise<FilterProfileHistory> {
        const response = await this.api.get(`${this.url}`, {
            params: {
                pageType
            }
        });
        return response.data;
    }

    async add(item: number, pageType: number): Promise<boolean> {
        const response = await this.api.post(`${this.url}/shift-schedule-period?shiftSchedulePeriod=` + `${item}&pageType=${pageType}`);
        return response.data;
    }

    async delete(item: number, pageType: number): Promise<boolean> {
        const response = await this.api.delete(`${this.url}/shift-schedule-period`, {
            params: {
                shiftSchedulePeriod: item,
                pageType
            }
        });
        return response.data;
    }

    async updateLocations(pageType: number, locationIds: string[]): Promise<boolean> {
        const response = await this.api.put(`${this.url}/update-selected-locations?pageType=${pageType}`, locationIds);
        return response.data;
    }
    async updateRole(pageType: number, roleIds: string[]): Promise<boolean> {
        const response = await this.api.put(`${this.url}/update-selected-roles?pageType=${pageType}`, roleIds);
        return response.data;
    }

    async updateTags(pageType: number, tags: TagFilters): Promise<boolean> {
        const response = await this.api.put(`${this.url}/update-selected-tags?pageType=${pageType}`, tags);
        return response.data;
    }

    async addFavourites(pageType: number, id: string): Promise<boolean[]> {
        const response = await this.api.post(this.url + `/favorite-role?id=${id}&pageType=${pageType}`);

        return response.data;
    }
    
    async deleteFavourites(pageType: number, id: string): Promise<boolean> {
        const response = await this.api.delete(this.url + `/favorite-role`, {
            params: {
                id,
                pageType
            }
        });

        return response.data;
    }

    async deleteAll(pageType: number) {
        const response = await this.api.delete(`${this.url}`, {
            params: {
                pageType
            }
        });

        return response.data;
    }
}