import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { UserFacility, UserProfile } from "src/models/AppProfile";
import UserService from "src/services/api/user.service";
import './FacilityDropdown.scss'


interface FacilityDropdownProps {
    userProfile: UserProfile;
    isLoading: boolean;
}

const FacilityDropdown = (props : FacilityDropdownProps) => {

    const [facilityOptions, setFacilityOptions] = useState<UserFacility[]> ([]);

    const userService = new UserService();

    const handleFacilityChange = async (e) => {
        await userService.changeCurrentUserFacility(e.value);
        window.location.replace('/');
    }

    useEffect(() =>{
        setFacilityOptions(props.userProfile.userFacilities);
    }, [])


    return <div className="facility-dropdown" >
            <Dropdown className= ""
                optionLabel="facilityName"
                optionValue="facilityId"
                value={props.userProfile.userFacilities.find(facility => facility.isSelected).facilityId}
                options = {facilityOptions}
                onChange={(e) => handleFacilityChange(e)}
                placeholder="select a facility"
                disabled={props.isLoading} 
            />  
    </div>
}

export default FacilityDropdown;