import { SingleNote } from "src/models/NoteItem";
import { NoteTab, NoteType } from "src/enums/NoteTypes";
import TitleCard from "./TitleCard";
import { useEffect, useState } from "react";
import ReportService from "src/services/api/report.service";
import NoteItemCard from "../NoteItemCard";

interface ReportNoteCardProps {
    noteType: NoteType,
    notes: SingleNote[],
    roleJobClassName?: string,
    titleStDef?: string,
}

// eslint-disable-next-line react/display-name
const ReportNoteCard = (props: ReportNoteCardProps) => {
    const reportService: ReportService = new ReportService();

    const noNotesMsg = reportService.noNotesMessage;

    const [notesCount, setNotesCount] = useState(0);

    useEffect(() => {
        setNotesCount(props.notes?.length);
    }, [props.notes]);

    return (
        <div className="d-flex-column gap-3">
            <TitleCard titleStDef={props.titleStDef} content={props.roleJobClassName} />
            <div className="bg-lightest-gray">
                {
                    notesCount > 0 ?
                        <div className="d-flex-column gap-3">
                            {props.notes && props.notes.length > 0 && props.notes.map((note: SingleNote, index: number) => {
                                return <div className="p-3 bg-white" key={'r-nic-' + index}>
                                    <NoteItemCard key={'rnc-' + index} noteTab={NoteTab.SelectRoleNotes} note={note} isPrintMode={true} />
                                </div>
                            })}
                        </div>
                        : <div>{noNotesMsg}</div>

                }
            </div>
        </div>
    );
}

export default ReportNoteCard;
