export enum ActionType {
    GET_CATEGORY_LIST = 'get_category_list',
    GET_CATEGORY_LIST_SUCCESS = 'get_category_list_success',
    GET_CATEGORY_LIST_FAILURE = 'get_category_list_failure',
    GET_SECTION_LIST = 'get_section_list',
    GET_SECTION_LIST_SUCCESS = 'get_section_list_success',
    GET_SECTION_LIST_FAILURE = 'get_section_list_failure',
    GET_ROLE_SETTINGS = 'get_role_settings',
    GET_ROLE_SETTINGS_SUCCESS = 'get_role_settings_success',
    GET_ROLE_SETTINGS_FAILURE = 'get_role_settings_failure',
    GET_CURRENT_SHIFT_DETAILS = 'get_current_shift_details',
    GET_CURRENT_SHIFT_DETAILS_SUCCESS = 'get_current_shift_details_success',
    GET_CURRENT_SHIFT_DETAILS_FAILURE = 'get_current_shift_details_failure',
    GET_CURRENT_SHIFT_NOTES = 'get_current_shift_notes',
    GET_CURRENT_SHIFT_NOTES_SUCCESS = 'get_current_shift_notes_success',
    GET_CURRENT_SHIFT_NOTES_FAILURE = 'get_current_shift_notes_failure',
    CLEAR_CURRENT_SHIFT_NOTES = 'clear_current_shift_notes',
    GET_FOLLOWED_NOTES = 'get_followed_shift_notes',
    GET_FOLLOWED_NOTES_SUCCESS = 'get_followed_shift_notes_success',
    GET_FOLLOWED_NOTES_FAILURE = 'get_followed_shift_notes_failure',
    CLEAR_FOLLOWED_NOTES = 'clear_followed_notes',
    GET_ALL_ROLES_SUMMARY = 'get_all_roles_summary',
    GET_ALL_ROLES_SUMMARY_SUCCESS = 'get_all_roles_summary_success',
    GET_ALL_ROLES_SUMMARY_FAILURE = 'get_all_roles_summary_failure',
    CLEAR_ALL_ROLES_SUMMARY = 'clear_all_notes_summary',
    GET_ALL_HISTORY_NOTES = 'get_all_history_notes',
    GET_ALL_HISTORY_SUCCESS = 'get_all_history_notes_success',
    GET_ALL_HISTORY_FAILURE = 'get_all_history_notes_failure',
    SET_COLLAPSE_ALL_NOTES = 'set_collapse_all_notes',
    SET_SHOW_TAG_ROLE_MODAL = 'set_show_Tag_Role_Modal',
    SET_TAG_ROLE_MODAL_LIST = 'set_tag_role_modal_list',
    SET_MY_NOTES_COLLAPSE_ALL_NOTES = 'set_my_notes_collapse_all_notes',
    SET_MY_ROT_NOTES_COLLAPSE_ALL_NOTES = 'set_my_rot_notes_collapse_all_notes',
    SET_ALL_NOTES_COLLAPSE_ALL_NOTES = 'set_all_notes_collapse_all_notes',
    SELECT_ROLE_MODAL_TOGGLE_VISIBILITY = 'select_role_modal_toggle_visibility',
    GET_ROLE_DATA = 'get_role_data',
    GET_ROLE_DATA_SUCCESS = 'get_role_data_success',
    GET_ROLE_DATA_FAILURE = 'get_role_data_failure',
    SET_ROLE_DATA = 'set_role_data',
    SET_HISTORY_DATE_FILTERS = 'set_history_date_filters',
    SET_HISTORY_TEXT_FILTER = 'set_history_text_filter',
    SET_IS_FAVOURITE_TOGGLE = 'set_is_favourite_toggle',
    SET_IS_FILTER_PANEL_ENABLED = 'set_is_filter_panel_enabled',
    SET_ROLE = 'set_role',
    GET_ROLE_FILTERS = 'get_role_filters',
    GET_ROLE_FILTERS_SUCCESS = 'get_role_filters_success',
    GET_ROLE_FILTERS_FAILURE = 'get_role_filters_failure',
    GET_SELECTED_ROLE_FILTERS = 'get_selected_role_filters',
    GET_SELECTED_ROLE_FILTERS_SUCCESS = 'get_selected_role_filters_success',
    GET_SELECTED_ROLE_FILTERS_FAILURE = 'get_selected_role_filters_failure',
    SET_SELECTED_ROLE_FILTERS = 'set_selected_role_filters',
    SET_SELECTED_CATEGORY_TAG_FILTERS = 'set_selected_category_tag_filters',
    SET_SELECTED_SECTION_TAG_FILTERS = 'set_selected_section_tag_filters',
    SET_SELECTED_ROLE_TAG_FILTERS = 'SET_SELECTED_ROLE_TAG_FILTERS',
    SET_ROTATION_SELECTED_FILTER = 'set_rotation_selected_filters',
    SET_PRIORITY_SELECTED_FILTER = 'set_priority_selected_filters',
    SET_SHIFT_ID = 'set_shift_id',
    SET_SHOW_ADD_SHIFT_TOAST = 'set_show_add_shift_toast',
    SHOW_MANAGE_USER_ACCESS_PAGE_TOAST = 'show_manage_user_access_page_toast',
    HIDE_MANAGE_USER_ACCESS_PAGE_TOAST = 'hide_manage_user_access_page_toast',
    SET_CURRENT_SHIFT = 'set_current_shift',
    SET_IS_GRACE_PERIOD = 'set_is_grace_period',
    SET_TIME_LEFT_FOR_SHIFT_END = 'set_time_left_for_shift_end',
    GET_SHIFT_SCHEDULE = 'get_shift_schedule',
    GET_SHIFT_SCHEDULE_SUCCESS = 'get_shift_schedule_success',
    GET_SHIFT_SCHEDULE_FAILURE = 'get_shift_schedule_failure',
    GET_ALL_FILTERS = 'get_history_filter',
    GET_ALL_FILTERS_SUCCESS = 'get_history_filter_success',
    GET_ALL_FILTERS_FAILURE = 'get_history_filter_failure',
    CLEAR_ALL_FILTERS = 'clear_history_filter',
    SET_SELECTED_SHIFT_SCHEDULE_FILTER = 'set_selected_shift_schedule_history_filter',
    SET_SELECTED_LOCATION_FILTER = 'set_selected_location_history_filter',
    INCREMENT_DRAFT_NOTES_COUNT = 'increment_draft_notes_count',
    DECREMENT_DRAFT_NOTES_COUNT = 'decrement_draft_notes_count',
    SET_NEW_NOTE_IS_DIRTY = 'new_note_is_dirty',
    GET_USER_PROFILE = 'get_user_profile',
    GET_USER_PROFILE_SUCCESS = 'get_user_profile_success',
    GET_USER_PROFILE_FAILURE = 'get_user_profile_failure',
    ADD_PROFILE_IMAGE = "add_profile_image",
    UPDATE_PROFILE_IMAGE = "update_profile_image",
    GET_NOTE_TYPE_SELECTION = 'get_note_type_selection',
    SET_NOTE_TYPE_SELECTION = 'set_note_type_selection',
    SET_ACTIVE_SHIFT_TAB = 'set_active_shift_tab',
    GET_CURRENT_ROTATION_NOTES = 'get_current_rotation_notes',
    GET_CURRENT_ROTATION_NOTES_SUCCESS = 'get_current_rotation_notes_success',
    GET_CURRENT_ROTATION_NOTES_FAILURE = 'get_current_rotation_notes_failure',
    CLEAR_CURRENT_ROTATION_NOTES = 'clear_current_rotation_notes',
    GET_CURRENT_ROTATION_DETAILS = 'get_current_rotation_details',
    GET_CURRENT_ROTATION_DETAILS_SUCCESS = 'get_current_rotation_details_success',
    GET_CURRENT_ROTATION_DETAILS_FAILURE = 'get_current_rotation_details_failure',
    SET_CURRENT_ROTATION = 'set_current_rotation',
    GET_FACILITY_USERS = 'get_facility_users',
    GET_FACILITY_USERS_DETAILS_SUCCESS = 'get_facility_users_details_success',
    GET_FACILITY_USERS_DETAILS_FAILURE = 'get_facility_users_details_failure',
    ADD_HUB_CONNECTION = "add_hub_connection",
    REMOVE_HUB_CONNECTION = "remove_hub_connection",
    SET_ADD_NOTE_DIALOG_VISIBILITY = 'set_add_note_dialog_visibility',
    CLEAR_ADD_NOTE_DIALOG_VISIBILITY = 'clear_add_note_dialog_visibility',
    //Notification
    GET_USER_NOTIFICATIONS = 'get_user_notifications',
    GET_USER_NOTIFICATIONS_SUCCESS = 'get_user_notifications_success',
    GET_USER_NOTIFICATIONS_FAILURE = 'get_user_notifications_failure',
    SET_READED_NOTIFICATION = 'set_readed_notification',
    SET_ALL_NOTES_COLLAPSE_ROTATION = 'set_all_notes_collapse_rotation',
    SET_MY_NOTES_COLLAPSE_ALL_NOTES_ROTATION = 'set_all_notes_collapse_all_notes_rotation',
    GET_PREVIOUS_NOTES_ROTATION = 'get_followed_notes_rotation',
    GET_PREVIOUS_NOTES_ROTATION_SUCCESS = 'get_followed_notes_rotation_success',
    GET_PREVIOUS_NOTES_ROTATION_ERROR = 'get_followed_notes_rotation_error',
    SET_PREVIOUS_NOTES_ROTATION_CLEAR = 'set_followed_notes_rotation_clear',
    //Template
    GET_DEFAULT_TEMPLATE = 'get_default_template',
    GET_DEFAULT_TEMPLATE_SUCCESS = 'get_default_template_success',
    GET_DEFAULT_TEMPLATE_FAILURE = 'get_default_template_failure',
}