import { EditorTools } from '@progress/kendo-react-editor';
import styles from '../../styles/style.module.scss';

const {
  ForeColor,
  BackColor,
} = EditorTools;

const foreColorPaletteSettings = {
  palette: [
    styles.black,
    styles.darkGray,
    styles.darkBlue,
    styles.mediumBlue,
    styles.mediumTeal,
    styles.mediumRed,
  ],
};

const backColorPaletteSettings = {
  palette: [
    styles.highlightBlue,
    styles.highlightYellow,
    styles.highlightPeach,
    styles.highlightNeon,
  ],
};

const EditorCustomForeColor = propss => (
  <ForeColor {...propss} colorPickerProps={{ defaultValue: styles.black, paletteSettings: foreColorPaletteSettings }} />
);

const EditorCustomBackColor = propss => (
  <BackColor {...propss} colorPickerProps={{ paletteSettings: backColorPaletteSettings }} />
);

export { EditorCustomForeColor, EditorCustomBackColor };