import { Dispatch } from "react";
import { ActionType } from "../action-types";
import { NotificationAction } from "../actions/notificationActions";
import NotificationService from "src/services/api/notification.service";
import { Notification } from "src/models/Notification";

const notificationService = new NotificationService();

export const getUserNotifications = () => {
    return async (dispatch: Dispatch<NotificationAction>) => {
        dispatch({
            type: ActionType.GET_USER_NOTIFICATIONS
        });
        try {
            const notifications:Notification[]  = await notificationService.getUserNotifications();
            dispatch({
                type: ActionType.GET_USER_NOTIFICATIONS_SUCCESS,
                payload: notifications
            });
        } catch (err: any) {
            dispatch({
                type: ActionType.GET_USER_NOTIFICATIONS_FAILURE,
                payload: err.message
            });
        }
    }
}

export const setReadNotification = (notificationId: string) => {
    return async (dispatch: Dispatch<NotificationAction>) => {
        try {
            const response = await notificationService.setReadNotification(notificationId);
            dispatch({
                type: ActionType.SET_READED_NOTIFICATION,
                payload: response
            });
        } catch (err: any) {
            console.log(err);
        }
    }
} 