import { forwardRef } from "react";
import { Dropdown } from 'primereact/dropdown';

interface ReportSelectionProps {
    dropdownParentClass: string
    dropdownChildClass: string;
    dropdownClass: string;
}

// eslint-disable-next-line react/display-name
const ReportSelection = forwardRef((props: ReportSelectionProps, ref) => {
    return (
        <div className={`d-flex align-items-center ${props.dropdownParentClass}`}>
            <div className={props.dropdownChildClass}>
                <Dropdown className={props.dropdownClass}
                    placeholder="my reports"
                />
            </div>
        </div>
    );
});

export default ReportSelection;