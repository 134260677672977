import * as dateFns from "date-fns";
import { enUS } from "date-fns/locale";

export class DateTimeHelper {
    static toLocalDateTimeString(utc: Date): string {
        const format = "MM/dd/yyyy hh:mm a";
        const newUTC = Date.UTC(
            utc.getFullYear(),
            utc.getMonth(),
            utc.getDate(),
            utc.getHours(),
            utc.getMinutes(),
            utc.getSeconds(),
            utc.getMilliseconds()
        );

        return dateFns.format(newUTC, format, {
            locale: enUS
        });
    }

    static toTimeString(date: Date): string {
        const format = "hh:mm a";
        return dateFns.format(date, format);
    }

    static fromUTCToLocal(utc: Date): Date {
        const dateParced = new Date(utc); //required to avoid date conversion issues
        const newDate = new Date(dateParced.getTime()+dateParced.getTimezoneOffset()*60*1000);

        const offset = dateParced.getTimezoneOffset() / 60;
        const hours = dateParced.getHours();
    
        newDate.setHours(hours - offset);
    
        return newDate;   
    }

    static toString(utc: Date | string): string {
        if (!utc)
            return '-';
        
        const format = "MM/dd/yyyy";
        return dateFns.format(new Date(utc), format);
    }

    static toHHMMString(utc: Date | string): string {
        if (!utc)
            return '-';

        return new Date(utc).toLocaleTimeString([],
            {
                year: 'numeric', month: 'numeric'
                , day: 'numeric', hour: 'numeric'
                , minute: 'numeric'
            });
    }

    static toHHMMSSString(utc: Date | string): string {
        if (!utc)
            return '-';

        return new Date(utc).toLocaleTimeString([],
            {
                year: 'numeric', month: 'numeric'
                , day: 'numeric', hour: 'numeric'
                , minute: 'numeric', second: 'numeric'
            });
    }

    static isMAXDateTime(utc: Date | string): boolean {
        if (!utc)
            return false;

        return new Date(utc).getFullYear() == 9999;
    }
}