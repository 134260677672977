import './SettingsIcon.scss';
import { IoSettingsOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Permission } from 'src/enums/Permission';
import { PermissionHelper } from "src/helpers/PermissionHelper"
import { UserProfile } from "src/models/AppProfile";

interface SettingsIconProps {
    userProfile: UserProfile;
    isLoading: boolean;
}

const SettingsIcon = (props: SettingsIconProps) => {
    const navigate = useNavigate();
    
    const handleAdminPageNavigation = () => {
        navigate('/AdminUserAccess')
    }
    
    return <div className="settings-icon">
        {PermissionHelper.hasPermission(props.userProfile, Permission.Admin) &&
            <div className='settings float-end'>
                <button className='setting-btn border-0' onClick={handleAdminPageNavigation}>
                    <IoSettingsOutline
                        color='#00708C'
                        size={'3em'}
                    />
                </button>
            </div>
        }
    </div>
}

export default SettingsIcon;