import { FilterActions } from './../actions/FilterActions';
import { Dispatch } from 'redux';
import Filters from 'src/services/api/filter.service';
import { ActionType } from '../action-types';
import { RoleTagFilter, ShiftScheduleFilter, TagFilter } from "src/models/FilterProfileHistory";
import { IdName } from "src/models/IdName";
import { FilterTree } from "../reducers/filterReducer";

const filterService = new Filters();

export const getAllFilters = (pageType: number) => {
    return async (dispatch: Dispatch<FilterActions>) => {
        dispatch({
            type: ActionType.GET_ALL_FILTERS,
        });
        try {
            const filters = await filterService.getAllFilters(pageType);
            dispatch({
                type: ActionType.GET_ALL_FILTERS_SUCCESS,
                payload: filters,
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            dispatch({
                type: ActionType.GET_ALL_FILTERS_FAILURE,
                payload: err.message,
            });
        }
    };
};

export const setSelectedShiftScheduleFilters = (payload: ShiftScheduleFilter[]) => {
    return (dispatch: Dispatch<FilterActions>) => {
        dispatch({
            type: ActionType.SET_SELECTED_SHIFT_SCHEDULE_FILTER,
            payload
        });
    }
}

export const setSelectedLocationFilters = (payload: IdName<string>[]) => {
    return (dispatch: Dispatch<FilterActions>) => {
        dispatch({
            type: ActionType.SET_SELECTED_LOCATION_FILTER,
            payload
        });
    }
}

export const setSelectedRolesFilters = (role: string[]) => {
    return (dispatch: Dispatch<FilterActions>) => {
        dispatch({
            type: ActionType.SET_SELECTED_ROLE_FILTERS,
            payload: role
        });
    }
}

export const setSelectedCategoryTagFilters = (tags: TagFilter[]) => {
    return (dispatch: Dispatch<FilterActions>) => {
        dispatch({
            type: ActionType.SET_SELECTED_CATEGORY_TAG_FILTERS,
            payload: tags
        });
    }
}

export const setSelectedSectionTagFilters = (tags: TagFilter[]) => {
    return (dispatch: Dispatch<FilterActions>) => {
        dispatch({
            type: ActionType.SET_SELECTED_SECTION_TAG_FILTERS,
            payload: tags
        });
    }
}

export const setSelectedRoleTagFilters = (tags: RoleTagFilter[]) => {
    return (dispatch: Dispatch<FilterActions>) => {
        dispatch({
            type: ActionType.SET_SELECTED_ROLE_TAG_FILTERS,
            payload: tags
        });
    }
}

export const setRotationsSelectedFilter = (isRotationsSelected: boolean) => {
    return (dispatch: Dispatch<FilterActions>) => {
        dispatch({
            type: ActionType.SET_ROTATION_SELECTED_FILTER,
            payload: isRotationsSelected
        });
    }
}

export const setCriticalSelectedFilter = (isCriticalSelected: boolean) => {
    return (dispatch: Dispatch<FilterActions>) => {
        dispatch({
            type: ActionType.SET_PRIORITY_SELECTED_FILTER,
            payload: isCriticalSelected
        });
    }
}

export const setRole = (role: FilterTree) => {
    return (dispatch: Dispatch<FilterActions>) => {
        dispatch({
            type: ActionType.SET_ROLE,
            payload: role
        });
    }
}

export const clearAllFilters = () => {
    return (dispatch: Dispatch<FilterActions>) => {
        dispatch({
            type: ActionType.CLEAR_ALL_FILTERS
        });
    }
}

