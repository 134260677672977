import { NoteType } from "src/enums/NoteTypes";
import { RotationNote, SectionTagRotationNote } from "src/models/NoteItem";
import { useTypedSelector } from "src/hooks/useTypedselector";
import { useEffect, useState } from "react";
import ReportService from "src/services/api/report.service";
import { TagType } from "src/enums/TagType";
import NoteCardSkeleton from "../Skeleton/NoteCardSkeleton";
import ReportNoteCardSection from "./ReportNoteCardSection";
import SectionTagConstants from "src/constants/section-tag-constants";

interface ReportRotationNotesProps {
    noteData: RotationNote,
    noteType: NoteType,
    loading?: boolean,
    showHeader?: boolean,
    headerContent?: string,
}

// eslint-disable-next-line react/display-name
const ReportRotationNotes = (props: ReportRotationNotesProps) => {
    const noteType = props.noteType;

    const styleDef = "d-flex justify-content-between align-items-center bold medium gotham-bold color-dark-blue cursor-pointer rounded-top bg-lighter-gray px-3 py-2";
    const errorContStyle = "d-flex-column flex-grow-1 align-items-center justify-content-center";

    const [sectionTagRotationNotes, setSectionTagRotationNotes] = useState<SectionTagRotationNote[]>([]);

    const sectionTagsMasterList = useTypedSelector(state => state.myShiftReducer.sectionList || []);

    const reportService: ReportService = new ReportService();

    const [localNoteData, setLocalNoteData] = useState<RotationNote>();

    const [noteCount, setNoteCount] = useState(0);

    const [errorMessage, setErrorMessage] = useState<string>();

    const filterBySectionTags = (rotationNote: RotationNote, tagName: string) => {
        const _notesForSectionTags = structuredClone(rotationNote);
        reportService.filterRotationNoteBySectionTags(_notesForSectionTags, tagName);

        return _notesForSectionTags;
    }

    useEffect(() => {
        setLocalNoteData(props.noteData);
    }, [props.noteData]);


    useEffect(() => {
        if (!localNoteData || localNoteData === undefined)
            setErrorMessage("No Rotation/Notes found");
        else if (localNoteData.rotationNotes == null || localNoteData.rotationNotes.length === 0)
            setErrorMessage("No Notes found");
        else
            setErrorMessage("");

        if (noteType === NoteType.HistoryRotationNotes) {
            let _cnt = 0;
            localNoteData?.rotationNotes?.map(notelist => _cnt = _cnt + notelist.notes.length);
            setNoteCount(_cnt);
        }

        const allNotes: SectionTagRotationNote[] = [];

        sectionTagsMasterList.forEach(sectionTag => {
            const sTagNameVal = sectionTag.name.toLocaleLowerCase();

            const data: SectionTagRotationNote = {
                isCritical: sTagNameVal == SectionTagConstants.CriticalSectionTagName.toLocaleLowerCase(),
                rotationNote: filterBySectionTags(localNoteData, sTagNameVal),
                tag: {
                    id: sectionTag.id,
                    name: sectionTag.name.toLocaleLowerCase(),
                    tagContent: sectionTag.tagContent,
                    tagType: TagType.Section
                }
            }

            allNotes.push(data);
        });

        setSectionTagRotationNotes(allNotes);

    }, [localNoteData]);

    return (
        <div className="d-flex-column flex-grow-1">
            {
                props.showHeader && <div className={styleDef}>
                    <>
                        <span>{props.headerContent}
                            {noteType === NoteType.HistoryRotationNotes && <span className="pl-1">{`(${noteCount})`}</span>}
                        </span>
                    </>
                </div>
            }

            {/*List of Note Cards*/}
            <div className="d-flex-column flex-grow-1 p-3">
                {
                    (props.loading) ? <div className="p-3"><NoteCardSkeleton /></div> :
                        (sectionTagRotationNotes && sectionTagRotationNotes?.map((sectionData: SectionTagRotationNote, index) => {
                            return (sectionData.rotationNote?.rotationNotes?.length > 0) ?
                                <ReportNoteCardSection key={index} noteType={noteType} sectionTagRotationNote={sectionData} /> :
                                <></>
                        }))
                }

                {
                    !(props.loading) && errorMessage && <div className={errorContStyle}>
                        {errorMessage}
                    </div>
                }
            </div>
        </div>
    );
}

export default ReportRotationNotes;