import { SyntheticEvent, useEffect, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Panel } from 'primereact/panel';
import { Tree, TreeCheckboxSelectionKeys, TreeSelectionParams } from 'primereact/tree';
import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect'
import { ModelType } from 'src/enums/ModelType';
import { RoleFilterItem } from 'src/models/FilterItem';
import TreeHelper from './TreeHelper';
import './FilterPanel.scss';
import { PageType } from 'src/enums/PageType';
import { useSelectRoleActionCreators, useFilterActionCreators } from 'src/hooks/useActions';
import Filters from 'src/services/api/filter.service';
import { useTypedSelector } from 'src/hooks/useTypedselector';
import { ShiftScheduleFilter, TagFilter } from "src/models/FilterProfileHistory";
import { IdName } from 'src/models/IdName';
import { Toaster } from "src/services/api/toaster.service";
import TagModal from "../TagModal";
import NoteItemSection from "../NoteItemSection";
import { BiPurchaseTag } from 'react-icons/bi';
import { FiInfo } from "react-icons/fi";
import { NoteType } from 'src/enums/NoteTypes';
import ReportModal from '../ReportModal';
import { Report as ReportModel } from "src/models/Report";
import { ScheduleType } from 'src/enums/ScheduleType';
import { TagFilters } from 'src/models/Tag';
import FilterPanelSkeleton from '../Skeleton/FilterPanelShkeleton';

interface FilterPanelProps {
    filterFromPage: number;
    noteType: NoteType;
}

// FilterPanel Component
const FilterPanel = (props: FilterPanelProps) => {

    const {
        setIsFavoriteToggle,
        setIsFilterPanelEnabled,
    } = useSelectRoleActionCreators();

    const {
        getAllFilters,
        setSelectedShiftScheduleFilters,
        setSelectedLocationFilters,
        setSelectedRolesFilters,
        setSelectedCategoryTagFilters,
        setSelectedSectionTagFilters,
        setSelectedRoleTagFilters,
        setRotationsSelectedFilter,
        setCriticalSelectedFilter,
        setRole,
        clearAllFilters
    } = useFilterActionCreators();

    const isAllFiltersLoading = useTypedSelector(state => state.filterReducer.allFiltersLoading);
    const isFavoriteToggle = useTypedSelector(state => state.selectRoleReducer.isFavoriteToggle);
    const isRotationsSelected = useTypedSelector(state => state.filterReducer.isRotationsSelected);
    const isCriticalSelected = useTypedSelector(state => state.filterReducer.isCriticalSelected);
    const role = useTypedSelector(state => state.filterReducer.role);
    const filters = useTypedSelector(state => state.filterReducer.allFilters);

    const roleSettings = useTypedSelector(state => state.appProfileReducer.roleSettings.roleSettingsDetail);

    const isScheduleReportEnabled = roleSettings?.isScheduleReportEnabled;
    const isRotationEnabled = roleSettings?.isRotationEnabled;

    const [showManageTagModal, setShowManageTagModal] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectTags, setSelectedTags] = useState<TagFilters>(new TagFilters());
    const [disableGenerateReportButton, setDisableSGenerateReportButton] = useState<boolean>(false)
    const reportTipText = 'Tip: you can turn the search above into a recurring email report';

    useEffect(() => {
        if (isRotationsSelected || isCriticalSelected || role.selectedCount || filters.categoryTags?.length || filters.sectionTags?.length || filters.roleTags?.length
            || filters.shiftSchedulePeriods?.length || filters.selectedLocationIds?.length) {
            setDisableSGenerateReportButton(false)
        }
        else {
            setDisableSGenerateReportButton(true)
        }
    }, [isRotationsSelected, isCriticalSelected, role, filters.categoryTags, filters.sectionTags, filters.roleTags, filters.shiftSchedulePeriods, filters.selectedLocationIds])

    const filterService = new Filters();

    // Toggle Favorites switch
    const onFavoriteToggle = () => {
        setIsFavoriteToggle(!isFavoriteToggle);
    };

    const onRotationsSelected = () => {
        setRotationsSelectedFilter(!isRotationsSelected);
    };

    const onCriticalSelected = () => {
        setCriticalSelectedFilter(!isCriticalSelected);
    };

    const initRole = (roleFilters: RoleFilterItem[], activeIds: string[]) => {
        const items = [...roleFilters];
        const keys = activeIds.length > 0 ? activeIds : TreeHelper.getAllIdsFromLevel(items, 0, 2);
        const selectedKeys = TreeHelper.convertToSelectedKeys(items, keys);
                                                
        setRole({
            filters: TreeHelper.fitleredTreeItemsByPermissions(items),
            selectedCount: activeIds.length,
            selectedNodes: TreeHelper.convertToSelectedKeys(items, activeIds),
            expandedNodes: TreeHelper.convertToExpandedKeys(selectedKeys)
        });
    }

    const getSectionTags = (tagFilters: TagFilters): string[] => {
        return tagFilters.sectionTags;
    }

    const getCategoryTags = (tagFilters: TagFilters): string[] => {
        return tagFilters.categoryTags;
    }

    const getRoleTags = (tagFilters: TagFilters): string[] => {
        return tagFilters.roleTags;
    }

    const initSelectedTags = () => {
        const tagFilters: TagFilters = new TagFilters();
        const _selectedCategories = filters.categoryTags?.filter(i => i.isSelected);
        const _selectedCategoryInitial = _selectedCategories ? _selectedCategories.map(tag => tag.name) : [];

        const _selectedSections = filters.sectionTags?.filter(i => i.isSelected);
        const _selectedSectionInitial = _selectedSections ? _selectedSections.map(tag => tag.name) : [];

        tagFilters.categoryTags = _selectedCategoryInitial;
        tagFilters.sectionTags = _selectedSectionInitial;

        const _selectedRolesInitial = [];
        if (!isRotationsSelected) {
            filters.roleTags?.forEach((rt) => {
                rt.tags.forEach(rtTag => {
                    if (rtTag.isSelected && !_selectedRolesInitial.some(sri => sri === rtTag.name))
                        _selectedRolesInitial.push(rtTag.name);
                });
            });

            tagFilters.roleTags = _selectedRolesInitial;
        }
        else {
            tagFilters.roleTags = [];
            setSelectedTags(tagFilters);
            return;
        }

        setSelectedTags(tagFilters);
    }

    const roleChanged = async (e: TreeSelectionParams) => {
        setLoading(true);
        const { value } = e;
        const selectedItems = TreeHelper.getSelectedItems(value);
        setRole({
            filters: role.filters,
            selectedCount: selectedItems.length,
            selectedNodes: value as TreeCheckboxSelectionKeys,
            expandedNodes: null
        });
        const _selectedIds = selectedItems.map(i => i.id)
        await filterService.updateRole(props.filterFromPage, _selectedIds);
        if (_selectedIds.map(id => id) !== filters.selectedRoleIds.map(id => id)) {
            setSelectedRolesFilters(_selectedIds);
        }
        setLoading(false);
    }

    useEffect(() => {
        const pageType = props.filterFromPage;
        getAllFilters(pageType);
    }, []);


    useEffect(() => {
        initRole(filters.filterProfileRoleModels, filters?.selectedRoleIds);
        initSelectedTags();
    }, [filters]);

    useEffect(() => {
        setCriticalSelectedFilter(false);
    }, [isRotationsSelected]);

    useEffect(() => {
        setRotationsSelectedFilter(false);
    }, [isCriticalSelected]);

    const updateSelectedTags = async (sectionTags: string[], roles: string[], categories: string[]) => {

        if (filters.categoryTags?.length == 0 && filters.roleTags?.length == 0 && filters.sectionTags?.length == 0) {
            return
        }

        const _selectedCategories = [...filters.categoryTags];
        const _selectedRoles = [...filters.roleTags];
        const _selectedSections = [...filters.sectionTags];

        const tagFilters: TagFilters = {
            "categoryTags": categories,
            "roleTags": roles,
            "sectionTags": sectionTags
        }

        await filterService.updateTags(props.filterFromPage, tagFilters).then(() => {

            _selectedCategories.forEach(tag => {
                if (categories.includes(tag.name))
                    tag.isSelected = true;
                else
                    tag.isSelected = false;
            });

            _selectedRoles.forEach(rt => {
                rt.tags.forEach(rtTag => {
                    if (roles.includes(rtTag.name))
                        rtTag.isSelected = true;
                    else
                        rtTag.isSelected = false;
                });
            });

            _selectedSections.forEach(st => {
                if(sectionTags.includes(st.name))
                    st.isSelected = true;
                else
                    st.isSelected = false;
            });

            setSelectedCategoryTagFilters(_selectedCategories);
            setSelectedRoleTagFilters(_selectedRoles);
            setSelectedSectionTagFilters(_selectedSections);

            setSelectedTags(tagFilters);
        })
    }

    const clearAllFiltersFunc = async (e: SyntheticEvent) => {
        e.preventDefault();

        setLoading(true);
        if (await filterService.deleteAll(props.filterFromPage)) {
            const shiftScheduleFilters: ShiftScheduleFilter[] = filters?.shiftSchedulePeriods ? [...filters?.shiftSchedulePeriods] : [];

            shiftScheduleFilters.forEach(async (item) => {
                item.isSelected = false;
            });

            setRotationsSelectedFilter(false);
            setCriticalSelectedFilter(false);

            clearAllFilters();
            setSelectedShiftScheduleFilters(shiftScheduleFilters);

            setSelectedTags(new TagFilters());
            setIsFavoriteToggle(false);
            setIsFilterPanelEnabled(false);

            setLoading(false);
        }
    }
    const onShiftScheduleChange = async (e: { value: ShiftScheduleFilter, checked: boolean }) => {
        setLoading(true);
        const _selectedShiftScheduleFilters: ShiftScheduleFilter[] = [...filters.shiftSchedulePeriods];
        const pageType = props.filterFromPage;

        for (let index = 0; index < filters.shiftSchedulePeriods.length; index++) {
            const item = filters.shiftSchedulePeriods[index];
            if (item.id === e.value.id) {
                item.isSelected = e.checked;

                if (e.checked) {
                    await filterService.add(e.value.id, pageType);
                } else {
                    await filterService.delete(e.value.id, pageType);
                }
            }
        }

        setSelectedShiftScheduleFilters(_selectedShiftScheduleFilters);
        setLoading(false);
    }

    const onLocationFilterChange = async (e: { value: IdName<string>[] }) => {
        setLoading(true);
        const _selectedLocationIds = e.value.map(location => location.id)
        await filterService.updateLocations(props.filterFromPage, _selectedLocationIds).then(() => {
            setSelectedLocationFilters(e.value);
        }).catch((error) => {
            Toaster.showError('Failed to update locations.');
        });
        setLoading(false);
    }

    const getSelectTagsCount = (): number => {
        let totalTags = filters.categoryTags?.filter(t => t.isSelected)?.length;

        if (!isRotationsSelected) {
            totalTags += filters.roleTags?.flatMap(rt => rt.tags.filter(t => t.isSelected))?.length;
        }

        return totalTags;
    }

    const toggleAddTagModal = () => {
        setShowManageTagModal(!showManageTagModal)
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const template = (options: any, modelType: ModelType) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
        let headerName = '';

        switch (modelType) {
            case ModelType.FilterRole:
                headerName = `roles (${role.selectedCount})`;
                break;
            case ModelType.ShiftSchedule:
                headerName = `shift schedule (${filters.shiftSchedulePeriods?.filter(s => s.isSelected)?.length})`;
                break;
            case ModelType.Location:
                headerName = `location (${filters.selectedLocationIds?.length})`;
                break;
            case ModelType.Tags:
                headerName = `tags (${getSelectTagsCount()})`;
                break;
        }

        return (
            <div className={options.className}>
                <p className='filter-component-group-title'>
                    {headerName}
                </p>
                <button 
                    type="button" 
                    className={options.togglerClassName} 
                    onClick={options.onTogglerClick} 
                    data-cy="filterSectionCollapseBtn">
                    <span className={toggleIcon}></span>
                </button>
            </div>
        )
    }

    const manageTagsHeadTemplate = (showModalVal: boolean) => {
        return showModalVal ? 'select tags' : 'manage tags';
    }
    /* eslint-disable */
    const roleTemplate = (options: any) => template(options, ModelType.FilterRole);
    const shiftScheduleTemplate = (options: any) => template(options, ModelType.ShiftSchedule);
    const locationTemplate = (options: any) => template(options, ModelType.Location);
    const tagTemplate = (options: any) => template(options, ModelType.Tags);

    const createReport = (): ReportModel => {
        let report = new ReportModel();
        report.scheduleType = !isRotationsSelected ? ScheduleType.Shift : ScheduleType.Rotation;
        return report;
    };

    /* eslint-enable */
    return <> {isAllFiltersLoading ? <FilterPanelSkeleton pageType={props.filterFromPage} /> : <Col className="d-flex-column" data-cy="filterPanelComponent">
        <div className="filter-component pt-2">
            <Row className='no-gutters'>
                {/*Header*/}
                <div className="filter-component-header align-items-baseline">
                    <strong className="filter-component-header-title">
                        filters
                    </strong>
                    <strong>
                        <button
                            className="btn button-text filter-component-header-sub-title"
                            onClick={clearAllFiltersFunc}
                            type="button"
                            aria-label='clear all filters'
                            disabled={loading}
                            data-cy="clearAllBtn"
                        >
                            clear all
                        </button>
                    </strong>
                </div>

                {/*Body*/}
                <div className="filter-component-favorite">
                    {/*Body-Favorites Toggle Switch*/}
                    {props.filterFromPage === PageType.SelectRolePage &&
                        <>
                            <div id="panelChkToggleFavorites">
                                <Form.Switch
                                    onChange={onFavoriteToggle}
                                    id="chkToggleFavorites"
                                    label="show favorites only"
                                    checked={isFavoriteToggle}
                                    disabled={loading}
                                    data-cy="showFavoritesOnlySwitch"
                                />
                            </div>
                            <hr />
                        </>
                    }
                </div>
                <div>
                    {/*Body-Favorites Toggle Switch*/}
                    {props.filterFromPage === PageType.History &&
                        <>
                            <div className="d-flex-column gap-2">
                                {isRotationEnabled && <Form.Switch
                                    onChange={onRotationsSelected}
                                    id="chkToggleRotations"
                                    label="rotations"
                                    checked={isRotationsSelected}
                                    disabled={loading || isCriticalSelected}
                                    data-cy="showRotationsOnlySwitch"
                                /> }

                                <Form.Switch
                                    onChange={onCriticalSelected}
                                    id="chkCritical"
                                    label="critical"
                                    checked={isCriticalSelected}
                                    disabled={loading || isRotationsSelected}
                                    data-cy="showCriticalOnlySwitch"
                                />
                            </div>
                            <hr />
                        </>
                    }
                </div>
            </Row>

            {/*Filter Sections: Role, Location Tags, Shift*/}
            <Row className="no-gutters" data-cy="roleHeirarchyTree">
                {/*Role*/}
                {(props.filterFromPage === PageType.SelectRolePage || (props.filterFromPage === PageType.History)) && role.filters?.length > 0 &&
                    <Panel headerTemplate={roleTemplate} toggleable>
                        <Tree
                            value={role.filters}
                            selectionMode="checkbox"
                            selectionKeys={role.selectedNodes}
                            expandedKeys={role.expandedNodes}
                            onSelectionChange={roleChanged}
                            disabled={(props.filterFromPage === PageType.SelectRolePage) && isFavoriteToggle && loading}
                        />
                    </Panel>
                }
                {/*Tags*/}
                {props.filterFromPage === PageType.History &&
                    <Panel className="mt-2"
                        headerTemplate={tagTemplate}
                        toggleable
                    >
                        {/*tags filter component*/}
                        <div className="d-flex align-items-center gotham-bold medium manage-tags-head"
                            onClick={() => { toggleAddTagModal() }}
                        >
                            {manageTagsHeadTemplate(showManageTagModal)}
                            <BiPurchaseTag className="icon ml-2" />
                        </div>
                        <div>
                            <NoteItemSection
                                noteId={''}
                                selectedSection={getSectionTags(selectTags)}
                                selectedCategory={getCategoryTags(selectTags)}
                                selectedRoles={isRotationsSelected ? [] : getRoleTags(selectTags)}
                                setSelectedSectionTags={() => {/* Do nothing */ }}
                                setSelectedCategories={() => {/* Do nothing */ }}
                                setSelectedRoles={() => {/* Do nothing */ }}
                                selectedAttachments={[]}
                                setSelectedAttachments={() => {/* Do nothing */ }}
                                selectedReadOnlyRoles={[]}
                                editMode={true}
                                updateSelectedTags={updateSelectedTags}
                                loading={loading}
                            />
                        </div>
                    </Panel>
                }
                {/*Shift*/}
                {props.filterFromPage === PageType.History && !isRotationsSelected && <Panel className="mt-2" headerTemplate={shiftScheduleTemplate} toggleable>
                    {/*shift schedule filtercomponent*/}
                    <div className='row no-gutters pl-4'>
                        {filters && filters.shiftSchedulePeriods?.map((shiftchedule) =>
                            <div className="col-6 field-checkbox d-flex align-items-center" key={shiftchedule.id}>
                                <Checkbox name={shiftchedule.name} value={shiftchedule} onChange={onShiftScheduleChange} checked={shiftchedule.isSelected} disabled={loading} />
                                <label className='label-Text pl-2 mb-0' htmlFor={shiftchedule.name}>{shiftchedule.name}</label>
                            </div>)
                        }
                    </div>
                </Panel>
                }

                {/*location*/}
                {props.filterFromPage === PageType.History && !isRotationsSelected && <Panel className="mt-2" headerTemplate={locationTemplate} toggleable>
                    {/*location filtercomponent*/}
                    {filters && filters.locations &&
                        <div className='row no-gutters'>
                            {filters && filters.locations &&
                                <MultiSelect value={filters.locations.filter(l => filters.selectedLocationIds.includes(l.id))} options={filters.locations} onChange={(e) => onLocationFilterChange(e)}
                                    optionLabel="name" placeholder="Select Locations" filter disabled={loading} />
                            }
                        </div>
                    }
                </Panel>
                }
            </Row>

            {/* Report Section*/}
            <Row className="no-gutters">
                {props.filterFromPage === PageType.History && isScheduleReportEnabled &&
                    <>
                        <div className="mt-3 pb-3">
                            <hr className='report-separator' />
                            {/*shift schedule filtercomponent*/}
                            <div className='row no-gutters '>
                                <p>
                                    <FiInfo /> {reportTipText}
                                </p>
                                <button
                                    className="btn btn-secondary icon mt-3 p-1 filter-toggle-btn"
                                    type="button"
                                    onClick={() => { setShowReportModal(true) }}
                                    aria-label="filter toggle"
                                    data-cy="toggleFilterPanelBtn"
                                    disabled={disableGenerateReportButton || loading}>
                                    create email report
                                </button>
                                <ReportModal
                                    showModal={showReportModal}
                                    toggleShowModal={() => setShowReportModal(!showReportModal)}
                                    report={createReport()}
                                />
                            </div>
                        </div>
                    </>
                }
            </Row>
        </div>
        {showManageTagModal &&
            <TagModal
                roleId={filters?.filterProfileRoleModels[0]?.id}
                selectedSectionTags={getSectionTags(selectTags)}
                selectedCategories={getCategoryTags(selectTags)}
                selectedRoles={getRoleTags(selectTags)}
                showAddTagModal={showManageTagModal}
                toggleAddTagModal={() => toggleAddTagModal()}
                roleTagData={[]}
                setRoleTagData={() => { /* Do nothing */ }}
                isEdit={false}
                title={'Manage tags'}
                updateTags={updateSelectedTags}
                noteType={props.noteType}
            />
        }
    </Col>}
    </>;
}

export default FilterPanel;
