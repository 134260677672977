import SignalRHubInterceptor from "src/interceptors/signalr.hub_interceptor"
import { ActionType } from "../action-types";
import { HubAction } from "../actions/hubActions";

export class HubConnections {
    connections: Map<string, SignalRHubInterceptor> = null;
}

const initialState = {
    connections: new Map<string, SignalRHubInterceptor>()
};


export const hubReducer = (
    state: HubConnections = initialState,
    action: HubAction
): HubConnections => {
    switch (action.type) {
        case ActionType.ADD_HUB_CONNECTION:
            if(state.connections.get(action.payload) === undefined){   
                state.connections.set(action.payload, new SignalRHubInterceptor(action.payload))
            } 

            return { ...state, connections: state.connections };
        case ActionType.REMOVE_HUB_CONNECTION:
            state.connections.delete(action.payload)
            return { ...state, connections: null };
        default:
            return state;
    }
}