import { defaultUser0, defaultUser1, UserProfile } from "./AppProfile";
import { RotationNote } from "./NoteItem";
import { Role } from "./Shift";

export class Rotation {
    id: string;
    startDate: Date;
    endDate: Date;
    assignedUserProfile: UserProfile;
    b2bUserProfile: UserProfile;
    roleId?: string;
    role: Role;
    rotationNotes: RotationNote[];
    lastUpdatedNote: Date;
}

export class RotationSchedule {
    beginDateTime: Date;
    endDateTime: Date;
    shiftEndingInSeconds: number;
    currentDT?: Date;
    durationHours?: number;
    graceMinutes?: number;
    roleId?: string;
    id?: string;
}

export class RotationAddModel {
    roleId: string;
    startDate: Date;
    endDate: Date;
    backToBackEmail: string;
}

export class RotationEditModel {
    id: string;
    startDate: Date;
    endDate: Date;
    backToBackEmail: string;
}

export class NewRotation {
    isStartingRotation: boolean;
    rotation: RotationAddModel;
    isValid: boolean;

    constructor() {
        this.isStartingRotation = false;
        this.rotation = new RotationAddModel();
        this.isValid = false;
    }
}

export class EditRotation {
    rotation: RotationEditModel;
    isValid: boolean;

    constructor() {
        this.rotation = new RotationEditModel();
        this.isValid = false;
    }
}

export class RoNoteHeaderProps {
    rotationStartDate: string;
    rotationEndDate: string;
    lastUpdatedDateTime: string;
    userNamesText: string;
    userNameTooltip: string;
}

export const defaultRotation: Rotation = {
    id: "00000000-0000-0000-0000-000000000000",
    assignedUserProfile: defaultUser0,
    b2bUserProfile: defaultUser1,
    startDate: new Date(),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 28), // 28 days from now
    roleId: "00000000-0000-0000-0000-000000000000",
    role: null,
    rotationNotes: [],
    lastUpdatedNote: new Date(),
}
