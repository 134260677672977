import { Shift, ShiftModelResponse, ShiftSchedule } from 'src/models/Shift';
import { RoleSummary, ShiftNote } from 'src/models/NoteItem';
import { APIService } from './api.service';

export default class ShiftService extends APIService {
    private url = '/shifts';

    async getCurrentShift(): Promise<Shift[]> {
        const response = await this.api.get(`${this.url}/currentShift`);
        return response.data;
    }

    async getCurrentShiftSchedule(): Promise<ShiftSchedule> {
        const response = await this.api.get(`${this.url}/get-shift-schedule`);
        return response.data;
    }

    async getSelectRoleNotes(roleId: string, shiftNumber: number): Promise<ShiftNote> {
        const response = await this.api.get(`${this.url}/selectrole-notes`, {
            params: { roleId, shiftNumber }
        });

        return response.data;
    }

    async getHistoryRoleNotes(roleId: string, scheduleId: string = null): Promise<ShiftNote> {
        const response = await this.api.get(`${this.url}/historyrole-notes`, {
            params: { roleId, scheduleId }
        });

        return response.data;
    }

    async getCurrentShiftNotes(roleId: string, isGracePeriod: boolean): Promise<ShiftNote> {
        const response = await this.api.get(`${this.url}/currentshift-notes`, {
            params: { roleId, isGracePeriod }
        });

        return response.data;
    }

    async getFollowedShiftNotes(roleId: string): Promise<ShiftNote[]> {
        const response = await this.api.get(`${this.url}/followed-notes/${roleId}`);
        return response.data;
    }

    async getAllShiftDetails(): Promise<ShiftNote[]> {
        const response = await this.api.get(`${this.url}/allRolesShiftsNotes`);

        return response.data;
    }

    async getAllRolesSummary(): Promise<RoleSummary[]> {
        const response = await this.api.get(`${this.url}/allRolesSummary`);
        return response.data;
    }

    async getHistoryNotes(searchText: string, from: string, to: string, isCriticalSelected: boolean): Promise<RoleSummary[]> {
        let url = `${this.url}/history-notes?searchText=${searchText}&inclCriticalNotes=${isCriticalSelected}`;
        if (from) {
            url += `&from=${from}`;
        }
        if (to) {
            url += `&to=${to}`;
        }
        const response = await this.api.get(url);
        return response.data;
    }

    async updateShift(request): Promise<ShiftModelResponse> {
        const response = await this.api.put(`${this.url}`, request);
        return response.data;
    }
}
