import { Col, Row } from "react-bootstrap";
import "./ManageUserAccess.scss";
import UserAccessDataTable from "./UserAccessDataTable";
import { MultiSelect } from "primereact/multiselect";
import { useEffect, useState } from "react";
import { useTypedSelector } from "src/hooks/useTypedselector";
import { useLocation, useNavigate } from "react-router-dom";
import UserService from "src/services/api/user.service";
import RolePermissionService from "src/services/api/role.permission.service";
import { AssignRole } from "src/models/Permission";
import { AssignState } from "src/enums/AssignState";
import { ManageUserAccessPageToast } from "src/models/AppProfile";
import { Toaster } from "src/services/api/toaster.service";
import { useAppProfileActionCreators } from "src/hooks/useActions";

// eslint-disable-next-line react/display-name
const ManageUserAccess = (() => {
    const titleClassDef = "normal color-dark-blue";
    const lableCtrlClassDef = "medium color-dark-gray";
    const muaSubTitle = "roles associated with this user";

    //services
    const userService = new UserService();
    const rolePermissionService = new RolePermissionService();

    const roleSettings = useTypedSelector(state => state.appProfileReducer.roleSettings);
    const rootRoleId = roleSettings.roleSettingsDetail?.rootRoleId;
    const navigate = useNavigate();

    //get the information of another page
    const location = useLocation();
    const userProfileId = location.state;
    const currentUserProfile = useTypedSelector(state => state.appProfileReducer.userProfileState.userProfile);
    const isUserProfileSelf = currentUserProfile.id == userProfileId;

    const {getUserProfile} = useAppProfileActionCreators();

    //i need take information about the user
    const accountUser = useTypedSelector(state => state.appProfileReducer.userAccount);

    //roles to add or remove
    const [selectedRoles, setSelectedRoles] = useState([]);

    const [rolePermissions, setRolePermissions] = useState<any[]>([])
    const [facilityAssigned, setFacilityAssigned] = useState<AssignRole[]>([]); //save the information of user

    const { showManageUserAccessPageToast } = useAppProfileActionCreators();

    const cancelAction = () => {
        navigate(`/AdminUserAccess`);
    }

    const addRoleHandler = () => {
        const newAssignedRole: AssignRole[] = [];

        selectedRoles.forEach((id) => {
            const existingAssignedRole = facilityAssigned.find(assignedRole => assignedRole.groupRole.id == id);

            if (existingAssignedRole != undefined) {
                for (let index = 0; index < facilityAssigned.length; index++) {
                    const element = facilityAssigned[index];
                    if (element?.state == AssignState.remove && element?.isPersist == true) {
                        element.state = AssignState.assigned;
                        break;
                    }
                }

                return;
            }

            const roleObj = rolePermissions.find(perm => perm.id == id)
            newAssignedRole.push(new AssignRole(roleObj, AssignState.add, false, new Date().toString()));
        });

        setFacilityAssigned([...facilityAssigned, ...newAssignedRole]);
    }

    // Method to get permissions 
    const getFacilityPermission = async (userId, feciliotyId) => {
        userService.getFacilityPermission(userId, feciliotyId)
            .then(response => {
                if (response) {
                    const assignRoleList: AssignRole[] = response.map((assignedRole: AssignRole) => {
                        return new AssignRole(
                            assignedRole.groupRole,
                            AssignState.assigned,
                            true,
                            new Date().toString()
                        )
                    })
                    setFacilityAssigned(assignRoleList);
                }
            })
            .catch(error => {
                console.log('error', error);
            })
    }

    const showToast = (msg: string) => {
        const muat: ManageUserAccessPageToast = {
            show: true,
            content: msg
        }

        showManageUserAccessPageToast(muat);
    }

    const refreshCurrentUserPermission = async () => {
        if(isUserProfileSelf){
            getUserProfile();
        }
    }

    //funtion to add to save
    const saveUserPermissionHandler = (userprofileId, facilityId) => {
        const requestBody = {
            addGroupRoleIds: [],
            removeGroupRoleIds: []
        };

        const rolesToAdd = new Set();

        facilityAssigned.forEach(role => {
            if (role.isPersist == true && role.state == AssignState.remove) {
                requestBody.removeGroupRoleIds.push(role.groupRole.id);
            }
            if (role.isPersist == false && role.state == AssignState.add) {
                rolesToAdd.add(role.groupRole.id);
            }
        })

        requestBody.addGroupRoleIds = Array.from(rolesToAdd);

        if (requestBody.addGroupRoleIds?.length > 0 || requestBody.removeGroupRoleIds?.length > 0) {
            userService.setFacilityPermission(userprofileId, facilityId, requestBody)
                .then(response => {
                    if (response) {
                        showToast("User permissions updated");

                        getFacilityPermission(userProfileId, rootRoleId);
                        refreshCurrentUserPermission();
                        navigate(`/AdminUserAccess`);
                    }
                })
                .catch(err => {
                    Toaster.showError("User permissions not updated");
                    console.error('error', err);
                })
        }
        else {
            navigate(`/AdminUserAccess`);
        }  
    }

    //method to create dropdown
    const user = accountUser?.items.find(item => item.id == userProfileId)

    const permissionRolesDropdown = rolePermissions.map(item => ({
        label: item.name,
        value: item.id
    }))

    useEffect(() => {
        if (userProfileId == null) {
            navigate('/AdminUserAccess');
            return;
        }

        const fetchRolePermission = async () => {
            rolePermissionService.getRolePermission(rootRoleId)
                .then(response => {
                    setRolePermissions(response);
                })
                .catch(error => {
                    console.log(error);
                })
        }
        fetchRolePermission();
        getFacilityPermission(userProfileId, rootRoleId);
    }, [rootRoleId, userProfileId]);

    return (
        <div className="d-flex-column flex-grow-1 gotham-bold">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <div className="d-flex gap-1 flex-wrap large">
                    <span className="word-break-keepAll">manage access for:</span>
                    <span className="color-dark-blue word-break-breakWord">{user?.firstName + ' ' + user?.lastName}</span>
                    <span>({user?.email})</span>
                </div>

                <div className="d-flex gap-3 flex-wrap">
                    <button type="button" className="btn button-text flex-fill flex-shrink" aria-label=""
                        onClick={cancelAction}
                        data-cy="">
                        cancel
                    </button>
                    <button type="button" className="btn xbtn-primary flex-fill flex-shrink" aria-label=""
                        onClick={() => saveUserPermissionHandler(userProfileId, rootRoleId)}
                        data-cy="">
                        save changes
                    </button>
                </div>
            </div>

            <div className="d-flex-column flex-grow-1 bg-white px-4 pt-3">
                <div className={`${titleClassDef}`}>{muaSubTitle}</div>

                <Row className="mt-2">
                    <Col xs={12} sm={4} md={4} lg={3} xl={2} className="d-flex-column gap-1">
                        <div className={lableCtrlClassDef}>role(s)</div>

                        <MultiSelect
                            value={selectedRoles}
                            onChange={(e) => setSelectedRoles(e.value)}
                            options={permissionRolesDropdown}
                            optionLabel="label"
                            placeholder="select role(s)"
                            maxSelectedLabels={3}
                            filter
                            showClear={true}
                        />
                    </Col>

                    <Col sm={6} md={5} lg={3} xl={2} className="d-flex-column gap-1">
                        <wbr />
                        <button type="button" className="btn btn-secondary" aria-label=""
                            onClick={addRoleHandler}
                            data-cy="">
                            add role(s) to this user
                        </button>
                    </Col>
                </Row>

                <div className="d-flex-column flex-grow-1 mt-3">
                    <UserAccessDataTable facilityAssigned={facilityAssigned} setFacilityAssigned={setFacilityAssigned} isUserProfileSelf={isUserProfileSelf} />
                </div>
            </div>
        </div >
    );
});

export default ManageUserAccess;