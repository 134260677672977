import { ActionType } from '../action-types';
import { MyShiftAction } from '../actions/myShiftActions';
import { Tag } from 'src/models/Tag';
import { NotePayload, ShiftNote, SingleNote } from 'src/models/NoteItem';
import { NoteType } from 'src/enums/NoteTypes';
import { ShiftTab } from 'src/enums/ShiftTab';
import { TagType } from 'src/enums/TagType';

export interface MyShiftState {
    categoryLoading: boolean,
    categoryList: Tag[],
    categoryApiError: string,
    sectionLoading: boolean,
    sectionList: Tag[],
    sectionApiError: string,
    roleApiError: string,
    collapseMyNotes: boolean,
    collapseMyRotationNotes: boolean,
    collapseAllNotes: boolean,
    currentShiftNotesLoading: boolean,
    currentShiftNotes: ShiftNote,
    currentShiftNoteApiErr: string,
    currentShiftNoteLastSynced: string,
    followedNotesLoading: boolean,
    followedNotes: ShiftNote[],
    followedNotesApiErr: string,
    followedNotesLastSynced: string,
    allRolesLoading: boolean,
    allRolesSummary: ShiftNote[],
    allRolesSummaryApiErr: string,
    allRolesSummaryLastSynced: string,
    roleAcceptedinGraceMin: boolean,
    draftNotesCount: number,
    newNoteIsDirty: boolean,
    showTagRoleModal: boolean,
    tagRoleNoteDetails: SingleNote,
    noteTypeSelection: NoteType,
    activeShiftTab: ShiftTab;
    notePayload: NotePayload,
}

const initialState = {
    categoryList: [],
    categoryLoading: false,
    sectionList: [],
    sectionLoading: false,
    roleLoading: false,
    categoryApiError: null,
    sectionApiError: null,
    roleApiError: null,
    seletedAttachments: [],

    collapseMyNotes: false,
    collapseMyRotationNotes: false,
    collapseAllNotes: false,

    currentShiftNotesLoading: false,
    currentShiftNotes: null,
    currentShiftNoteApiErr: '',
    currentShiftNoteLastSynced: '',
    followedNotesLoading: false,
    followedNotes: null,
    followedNotesApiErr: '',
    followedNotesLastSynced: '',
    allRolesLoading: false,
    allRolesSummary: [],
    allRolesSummaryApiErr: '',
    allRolesSummaryLastSynced: '',
    roleAcceptedinGraceMin: false,
    draftNotesCount: 0,
    newNoteIsDirty: false,
    showTagRoleModal: false,
    tagRoleNoteDetails: {
        title: '',
        content: '',
        id: '',
        isCritical: false,
        isPersistent: false,
        persistentBeginDateTime: new Date(),
        persistentEndDateTime: new Date(),
        lastUpdatedDateTime: new Date(),
        locationId: '',
        createdDateTime: new Date(),
        userProfile: {
            id: '',
            oId: '',
            firstName: '',
            lastName: '',
            email: '',
            groupRoles: null,
            created: new Date(),
            isAuthorized: false,
            userFacilities: null,
        },
        tags: [],
        attachments: [],
        canEdit: false,
        canTag: false,
        performedUTC: new Date(),
        performed: new Date(),
        noteType: NoteType.Shift,
        rotationId: ''
    },

    noteTypeSelection: NoteType.Shift,
    activeShiftTab: ShiftTab.MyShiftNotes,
    notePayload: {
        modalVisibility: false,
        noteType: NoteType.Shift,
        tag: {
            id: '',
            name: '',
            tagContent: '',
            tagType: TagType.Section
        }
    },
};

export const myShiftReducer = (
    state: MyShiftState = initialState,
    action: MyShiftAction
): MyShiftState => {
    switch (action.type) {

        case ActionType.GET_CATEGORY_LIST:
            return { ...state, categoryLoading: true };
        case ActionType.GET_CATEGORY_LIST_SUCCESS:
            return { ...state, categoryLoading: false, categoryList: action.payload };
        case ActionType.GET_CATEGORY_LIST_FAILURE:
            return { ...state, categoryLoading: false, categoryApiError: action.payload, categoryList: [] };

        case ActionType.GET_SECTION_LIST:
            return { ...state, sectionLoading: true };
        case ActionType.GET_SECTION_LIST_SUCCESS:
            return { ...state, sectionLoading: false, sectionList: action.payload };
        case ActionType.GET_SECTION_LIST_FAILURE:
            return { ...state, sectionLoading: false, sectionApiError: action.payload, sectionList: [] };

        case ActionType.SET_SHOW_TAG_ROLE_MODAL:
            return { ...state, showTagRoleModal: action.payload };
        case ActionType.SET_TAG_ROLE_MODAL_LIST:
            return { ...state, tagRoleNoteDetails: action.payload };
        case ActionType.GET_CURRENT_SHIFT_NOTES:
            return { ...state, currentShiftNotesLoading: true };
        case ActionType.GET_CURRENT_SHIFT_NOTES_SUCCESS:
            return { ...state, currentShiftNotesLoading: false, currentShiftNoteLastSynced: new Date().toLocaleString(), currentShiftNotes: action.payload };
        case ActionType.CLEAR_CURRENT_SHIFT_NOTES:
            return { ...state, currentShiftNotes: null };
        case ActionType.GET_CURRENT_SHIFT_NOTES_FAILURE:
            return { ...state, currentShiftNotesLoading: false, currentShiftNoteApiErr: action.payload, currentShiftNotes: null };
        case ActionType.GET_FOLLOWED_NOTES:
            return { ...state, followedNotesLoading: true };
        case ActionType.GET_FOLLOWED_NOTES_SUCCESS:
            return { ...state, followedNotesLoading: false, followedNotesLastSynced: new Date().toLocaleString(), followedNotes: action.payload };
        case ActionType.GET_FOLLOWED_NOTES_FAILURE:
            return { ...state, followedNotesLoading: false, followedNotesApiErr: action.payload, followedNotes: null };
        case ActionType.CLEAR_FOLLOWED_NOTES:
            return { ...state, followedNotes: null };
        case ActionType.GET_ALL_ROLES_SUMMARY:
            return { ...state, allRolesLoading: true };
        case ActionType.GET_ALL_ROLES_SUMMARY_SUCCESS:
            return { ...state, allRolesLoading: false, allRolesSummaryLastSynced: new Date().toLocaleString(), allRolesSummary: action.payload };
        case ActionType.GET_ALL_ROLES_SUMMARY_FAILURE:
            return { ...state, allRolesLoading: false, allRolesSummaryApiErr: action.payload, allRolesSummary: [] };
        case ActionType.CLEAR_ALL_ROLES_SUMMARY:
            return { ...state, allRolesSummary: null };
        case ActionType.SET_MY_NOTES_COLLAPSE_ALL_NOTES:
            return { ...state, collapseMyNotes: action.payload }

        case ActionType.SET_MY_ROT_NOTES_COLLAPSE_ALL_NOTES:
            return { ...state, collapseMyRotationNotes: action.payload }

        case ActionType.SET_ALL_NOTES_COLLAPSE_ALL_NOTES:
            return { ...state, collapseAllNotes: action.payload }

        case ActionType.INCREMENT_DRAFT_NOTES_COUNT:
            return { ...state, draftNotesCount: state.draftNotesCount + 1 }
        case ActionType.DECREMENT_DRAFT_NOTES_COUNT:
            return { ...state, draftNotesCount: state.draftNotesCount - 1 }
        case ActionType.SET_NEW_NOTE_IS_DIRTY:
            return { ...state, newNoteIsDirty: action.payload }

        case ActionType.GET_NOTE_TYPE_SELECTION:
            return { ...state, noteTypeSelection: NoteType.Shift }
        case ActionType.SET_NOTE_TYPE_SELECTION:
            return { ...state, noteTypeSelection: action.payload }

        case ActionType.SET_ACTIVE_SHIFT_TAB:
            return { ...state, activeShiftTab: action.payload }

        case ActionType.SET_ADD_NOTE_DIALOG_VISIBILITY:
            return { ...state, notePayload: action.payload }
        case ActionType.CLEAR_ADD_NOTE_DIALOG_VISIBILITY:
            return { ...state, notePayload: null }

        default:
            return state;
    }
}