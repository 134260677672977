import { AccessType } from "src/enums/AccessType";
import "./Unauthorized.scss";

import { RiLock2Line } from "react-icons/ri";

interface UnauthorizedProps {
    authorizationType: AccessType
}

const Unauthorized = (props: UnauthorizedProps) => {
    return <div className = "unauthorized">
        <div className="center-content">            
            <RiLock2Line style={{ fontSize: '2.5em' , marginBottom: '10px'}}/>
        </div>

        <div className = "text-container">
            {props.authorizationType == AccessType.Unauthorized && 
                <p className="bold-text"> You aren&apos;t authorized to access this page. </p>
            }

            {props.authorizationType == AccessType.PendingAuthorization &&
                <p className="bold-text"> You don&apos;t have permission to access this page. </p>
            }
            <p className="description-text">Please reach out to the responsible admin in your facility to request access</p>
        </div>
    </div>
};

export default Unauthorized;