import { Dispatch } from 'redux';
import { ActionType } from '../action-types';
import { AppProfileAction } from '../actions/appProfileActions';
import ConfigService from 'src/services/api/appProfile.service';
import ShiftService from 'src/services/api/shift.service';
import { Shift } from 'src/models/Shift';
import RotationService from 'src/services/api/rotations.service';
import { Rotation } from 'src/models/Rotation';
import UserService from 'src/services/api/user.service';
import AuthTokenHelper from 'src/helpers/AuthTokenHelper';
import { ManageUserAccessPageToast } from 'src/models/AppProfile';

const configService = new ConfigService();
const myShiftService = new ShiftService();
const myRotationService = new RotationService();
const userService = new UserService();

export const getRoleSettings = () => {
    return async (dispatch: Dispatch<AppProfileAction>) => {
        dispatch({
            type: ActionType.GET_ROLE_SETTINGS,
        });
        try {
            const roleSettings = await configService.getRoleSettings();
            dispatch({
                type: ActionType.GET_ROLE_SETTINGS_SUCCESS,
                payload: roleSettings,
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            dispatch({
                type: ActionType.GET_ROLE_SETTINGS_FAILURE,
                payload: err.message,
            });
        }
    };
};

export const getUserProfile = () => {
    return async (dispatch: Dispatch<AppProfileAction>) => {
        dispatch({
            type: ActionType.GET_USER_PROFILE,
        });
        try {
            const authUser = await AuthTokenHelper.getAuthUserProfile();
            const dbUser = await userService.getUserProfile();

            if (dbUser != null) {
                authUser.id = dbUser.id;
                authUser.groupRoles = dbUser.groupRoles;
                authUser.userFacilities = dbUser.userFacilities;
            }

            dispatch({
                type: ActionType.GET_USER_PROFILE_SUCCESS,
                payload: authUser,
            });

        } catch (err: any) {
            dispatch({
                type: ActionType.GET_USER_PROFILE_FAILURE,
                payload: err.message,
            });
        }
    };
};

export const setCurrentShift = (payload: Shift) => {
    return (dispatch: Dispatch<AppProfileAction>) => {
        dispatch({
            type: ActionType.SET_CURRENT_SHIFT,
            payload
        });
    }
}

export const getCurrentShift = () => {
    return async (dispatch: Dispatch<AppProfileAction>) => {
        dispatch({
            type: ActionType.GET_CURRENT_SHIFT_DETAILS,
        });
        try {
            const currentShift = await myShiftService.getCurrentShift();
            dispatch({
                type: ActionType.GET_CURRENT_SHIFT_DETAILS_SUCCESS,
                payload: currentShift,
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            dispatch({
                type: ActionType.GET_CURRENT_SHIFT_DETAILS_FAILURE,
                payload: err.message,
            });
        }
    };
};

export const getShiftSchedule = () => {
    return async (dispatch: Dispatch<AppProfileAction>) => {
        dispatch({
            type: ActionType.GET_SHIFT_SCHEDULE,
        });
        try {
            const shiftschedule = await myShiftService.getCurrentShiftSchedule();
            dispatch({
                type: ActionType.GET_SHIFT_SCHEDULE_SUCCESS,
                payload: shiftschedule,
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            dispatch({
                type: ActionType.GET_SHIFT_SCHEDULE_FAILURE,
                payload: err.message,
            });
        }
    };
};

export const setShiftId = (shiftId: string) => {
    return (dispatch: Dispatch<AppProfileAction>) => {
        dispatch({
            type: ActionType.SET_SHIFT_ID,
            payload: shiftId
        });
    }
}

export const setTimeLeftForShiftEnd = (payload: number) => {
    return (dispatch: Dispatch<AppProfileAction>) => {
        dispatch({
            type: ActionType.SET_TIME_LEFT_FOR_SHIFT_END,
            payload
        });
    }
}

export const setIsGracePeriod = (payload: boolean) => {
    return (dispatch: Dispatch<AppProfileAction>) => {
        dispatch({
            type: ActionType.SET_IS_GRACE_PERIOD,
            payload
        });
    }
}

export const getCurrentRotation = (roleId: string) => {
    return async (dispatch: Dispatch<AppProfileAction>) => {
        dispatch({
            type: ActionType.GET_CURRENT_ROTATION_DETAILS,
        });
        try {
            const currentRotation = await myRotationService.getCurrentRotation(roleId);
            dispatch({
                type: ActionType.GET_CURRENT_ROTATION_DETAILS_SUCCESS,
                payload: currentRotation,
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            dispatch({
                type: ActionType.GET_CURRENT_ROTATION_DETAILS_FAILURE,
                payload: err.message,
            });
        }
    };
};

export const setCurrentRotation = (payload: Rotation) => {
    return (dispatch: Dispatch<AppProfileAction>) => {
        dispatch({
            type: ActionType.SET_CURRENT_ROTATION,
            payload
        });
    }
}

export const getFacilityUsers = (rootRoleId: string, searchCriteria?: string, currentPage?: number, pageSize?: number) => {
    return async (dispatch: Dispatch<AppProfileAction>) => {
        dispatch({
            type: ActionType.GET_FACILITY_USERS,
        });
        try {
            const accountUser = await userService.getFacilityUsers(rootRoleId, searchCriteria, currentPage, pageSize);
            dispatch({
                type: ActionType.GET_FACILITY_USERS_DETAILS_SUCCESS,
                payload: accountUser,
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            dispatch({
                type: ActionType.GET_FACILITY_USERS_DETAILS_FAILURE,
                payload: err.message,
            });
        }
    }
}

export const showManageUserAccessPageToast = (muapt: ManageUserAccessPageToast) => {
    return (dispatch: Dispatch<AppProfileAction>) => {
        dispatch({
            type: ActionType.SHOW_MANAGE_USER_ACCESS_PAGE_TOAST,
            payload: muapt
        });
    }
}

export const hideManageUserAccessPageToast = () => {
    return async (dispatch: Dispatch<AppProfileAction>) => {
        dispatch({
            type: ActionType.HIDE_MANAGE_USER_ACCESS_PAGE_TOAST,
        });
    };
}
