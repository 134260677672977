import { SelectRole } from 'src/models/SelectRole';
import { APIService } from './api.service';

export default class SelectRoleService extends APIService {
    private url = '/roles';

    async getByFilter(isFavorite: boolean, abortSignal: AbortSignal): Promise<SelectRole[]> {
        const response = await this.api.get(`${this.url}/by-filter`, {
            params: { isFavorite },
            signal: abortSignal
        });

        return response.data;
    }
}
