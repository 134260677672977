import { RoleLocation } from 'src/models/Role';
import { APIService } from './api.service';

export default class RoleService extends APIService {
    private url = '/roles';

    async getLocationsByRoleId(roleId: string): Promise<RoleLocation[]> {
        const response = await this.api.get(`${this.url}/by-roleId?roleId=${roleId}`);
        return response.data;
    }

}
