import { Dispatch } from "react";
import NoteTemplateService from "src/services/api/noteTemplate.service";
import { TemplateAction } from "../actions/templateActions";
import { ActionType as ActionTypes } from "../action-types";
import { NoteTemplate, NoteTemplateType } from "src/models/NoteTemplate";
import { TemplateType } from "src/enums/TemplateType";
import { ActionType } from "src/enums/ActionType";

const noteTemplateServie = new NoteTemplateService;

export const getDefaultTemplate = (roleId : string) => {
    return async (dispatch : Dispatch<TemplateAction>) => {
        dispatch({
            type: ActionTypes.GET_DEFAULT_TEMPLATE,
        });
        try{
            const defaultNoteTemplates : NoteTemplate[] = await noteTemplateServie.getDefaultByRoleId(roleId);
            let shiftDefaultTemplate : NoteTemplateType = null;
            let rotationDefaultTemplate : NoteTemplateType = null;

            for( const noteTemplate of defaultNoteTemplates){
                const note = await noteTemplateServie.getTemplate(noteTemplate.noteId);

                if(noteTemplate.templateType === TemplateType.Shift){
                    shiftDefaultTemplate = {note: note, actionType: ActionType.Initialize};
                }

                if(noteTemplate.templateType === TemplateType.Rotation){
                    rotationDefaultTemplate = {note: note, actionType: ActionType.Initialize};
                }
            }
            
            dispatch({
                type: ActionTypes.GET_DEFAULT_TEMPLATE_SUCCESS,
                payload: {shiftDefaultTemplate, rotationDefaultTemplate},
            });
        }catch(error : any){
            dispatch({
                type: ActionTypes.GET_DEFAULT_TEMPLATE_FAILURE,
                payload: error.message,
            })
        }
    }
}
