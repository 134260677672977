import React from "react";
import { SectionTagShiftNote, LocationData, ShiftNote, RotationNote, SectionTagRotationNote, RotationNotesData, NotePayload } from "src/models/NoteItem";
import { NoteType } from "src/enums/NoteTypes";
import ReportNoteCard from "./ReportNoteCard";

interface ReportNoteCardSectionProps {
    noteType: NoteType,
    sectionTagShiftNote?: SectionTagShiftNote,
    sectionTagRotationNote?: SectionTagRotationNote,
    locationName?: string,
    noteData?: ShiftNote | RotationNote,
}

const ReportNoteCardSection: React.FC<ReportNoteCardSectionProps> = (props: ReportNoteCardSectionProps) => {
    const secRotationNote = props.sectionTagRotationNote;

    const IsShiftScope = () => {
        return (props.noteType == NoteType.Shift || props.noteType == NoteType.HistoryShiftNotes);
    }

    const IsCritical = () => {
        return IsShiftScope() ? props.sectionTagShiftNote.isCritical : secRotationNote.isCritical;
    }

    const GetSectionName = () => {
        return IsShiftScope() ? props.sectionTagShiftNote.tag.name : secRotationNote.tag.name;
    }

    const getTitleStDef = () => {
        return "d-flex-column bold medium px-3 pt-2";
    }

    return (<div className={' bg-lightest-gray'}>
        {
            <div className={`d-flex  gap-2 gotham-bold medium p-2 justify-content-center rounded-top px-3 ${IsCritical() ? 'bg-lighter-red' : 'bg-lighter-blue'} `}>
                <span className={`${IsCritical() ? 'color-medium-red' : ''}`}>{GetSectionName()}</span>
            </div>
        }
        {
            <div className="px-3 py-2">
                {
                    IsShiftScope() ?
                        props.sectionTagShiftNote.shiftNote.locationNotesList?.map((locationData: LocationData, index) => {
                            return <ReportNoteCard key={index} notes={locationData.notes} noteType={NoteType.Shift} roleJobClassName={locationData.location.name} titleStDef={getTitleStDef()} />
                        }) :
                        secRotationNote.rotationNote.rotationNotes?.map((rotationNotesData: RotationNotesData, index) => {
                            return <ReportNoteCard key={index} notes={rotationNotesData.notes} noteType={NoteType.Rotation} />
                        })
                }
            </div>
        }
    </div >)
}

export default ReportNoteCardSection;