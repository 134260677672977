import { FilterActions } from './../actions/FilterActions';
import { FilterProfileHistory, ShiftScheduleFilter } from 'src/models/FilterProfileHistory';
import { IdName } from 'src/models/IdName';
import { ActionType } from '../action-types';
import TreeNode from 'primereact/treenode';
import { TreeCheckboxSelectionKeys, TreeExpandedKeysType } from 'primereact/tree';

export interface FilterTree {
    filters: TreeNode[];
    selectedCount: number;
    selectedNodes: TreeCheckboxSelectionKeys;
    expandedNodes: TreeExpandedKeysType;
}

export interface FilterState {
    role: FilterTree,
    allFiltersLoading: boolean,
    allFilters: FilterProfileHistory,
    allFiltersError: string,
    isRotationsSelected: boolean,
    isCriticalSelected: boolean
}

const initialState = {
    role: {
        filters: [],
        selectedCount: 0,
        selectedNodes: null,
        expandedNodes: null
    },
    allFiltersLoading: false,
    allFilters: {
        shiftSchedulePeriods: [],
        locations: [],
        selectedLocationIds: [],
        filterProfileRoleModels: [],
        selectedRoleIds: [],
        categoryTags: [],
        sectionTags: [],
        roleTags: []
    } as FilterProfileHistory,
    allFiltersError: '',
    isRotationsSelected: false,
    isCriticalSelected: false
} as FilterState;

export const filterReducer = (
    state: FilterState = initialState,
    action: FilterActions
): FilterState => {
    switch (action.type) {

        case ActionType.GET_ALL_FILTERS:
            return { ...state, allFiltersLoading: true };
        case ActionType.GET_ALL_FILTERS_SUCCESS:
            return { ...state, allFiltersLoading: false, allFilters: action.payload };
        case ActionType.GET_ALL_FILTERS_FAILURE:
            return { ...state, allFiltersLoading: false, allFiltersError: action.payload }
        case ActionType.SET_SELECTED_SHIFT_SCHEDULE_FILTER:
            return { ...state, allFilters : { ...state.allFilters, shiftSchedulePeriods: action.payload}};
        case ActionType.SET_SELECTED_LOCATION_FILTER:
            return { ...state, allFilters : { ...state.allFilters, selectedLocationIds: action.payload.map(l => l.id)}};
        case ActionType.SET_SELECTED_ROLE_FILTERS:
            return { ...state, allFilters : { ...state.allFilters, selectedRoleIds: action.payload}};
        case ActionType.SET_ROLE:
            return { ...state, role: action.payload }
        case ActionType.SET_SELECTED_CATEGORY_TAG_FILTERS:
            return { ...state, allFilters : { ...state.allFilters, categoryTags: action.payload}  }
        case ActionType.SET_SELECTED_SECTION_TAG_FILTERS:
            return { ...state, allFilters : { ...state.allFilters, sectionTags: action.payload}  }
        case ActionType.SET_SELECTED_ROLE_TAG_FILTERS:
            return { ...state, allFilters : { ...state.allFilters, roleTags: action.payload  } }
        case ActionType.SET_ROTATION_SELECTED_FILTER:
            return { ...state, isRotationsSelected: action.payload }
        case ActionType.SET_PRIORITY_SELECTED_FILTER:
            return { ...state, isCriticalSelected: action.payload }
        case ActionType.CLEAR_ALL_FILTERS:
            return {
                ...state, allFilters: {
                    shiftSchedulePeriods: [],
                    locations: state.allFilters.locations,
                    selectedLocationIds: [],
                    filterProfileRoleModels: state.allFilters.filterProfileRoleModels,
                    selectedRoleIds: [],
                    categoryTags: state.allFilters.categoryTags?.map(t => { return { ...t, isSelected: false } }),
                    sectionTags: state.allFilters.sectionTags?.map(t => { return { ...t, isSelected: false } }),
                    roleTags: state.allFilters.roleTags?.map(t => { return { ...t, selected: false, tags: t.tags?.map(tt => { return {...tt, isSelected: false} }) } })
                } as FilterProfileHistory
            };
        default:
            return state;
    }
}
