import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import RoleService from "src/services/api/role.service";
import { RoleLocation } from "src/models/Role";
import { Dropdown } from 'primereact/dropdown';
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import { useTypedSelector } from "src/hooks/useTypedselector";
import { NoteType } from "src/enums/NoteTypes";

interface LocationSelectionProps {
    roleId: string;
    labelClass: string;
    dropDownClass: string;
    dropdownDivClass: string;
    locationValue: string;
    handleLocationChange: any;
    loading?: boolean;
    isDisable?: boolean;
}

// eslint-disable-next-line react/display-name
const LocationSelection = forwardRef((props: LocationSelectionProps, ref) => {

    const [locationOptions, setLocationOptions] = useState<RoleLocation[]>([]);
    const [loading, setLoading] = useState(false);
    const noteTypeSelection = useTypedSelector(state => state.myShiftReducer.noteTypeSelection);

    function resetLocationSelection(doNotify: boolean) {
        if (locationOptions) {
            if (locationOptions.length == 1)
                props.handleLocationChange({ ...locationOptions[0], value: locationOptions[0].id });
            else
                props.handleLocationChange({ ...locationOptions[0], value: null });
        }
    }

    useImperativeHandle(ref, () => ({
        resetSelection() {
            resetLocationSelection(false);
        }
    }));

    useEffect(() => {
        if (noteTypeSelection == NoteType.Rotation) {
            resetLocationSelection(false);
        }
    }, [noteTypeSelection]);

    const reFetchLocations = () => {
        const service = new RoleService();
        setLoading(true);
        service.getLocationsByRoleId(props.roleId).then((locationData) => {
            setLocationOptions(locationData);
            if (locationData?.length === 1) {
                props.handleLocationChange({ ...locationData[0], value: locationData[0].id });
            }
        }).catch((error) => {
            console.error('get role locations failed', error?.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        if (props.roleId)
            reFetchLocations();
    }, [props.roleId]);

    return (
        <div className="d-flex align-items-center">
            <CustomSpinner loading={loading} />
            {/*Location Dropdown*/}
            <label className={props.labelClass}>
                location
            </label>
            <div className={props.dropdownDivClass}>
                <Dropdown className={props.dropDownClass}
                    optionLabel="name"
                    optionValue="id"
                    value={props.locationValue}
                    options={locationOptions}
                    onChange={(e) => props.handleLocationChange(e)}
                    placeholder="select a location"
                    disabled={loading || props.loading || props.isDisable}
                />
            </div>
        </div>
    );
});

export default LocationSelection;