import { ReactNode } from "react";

interface TitleCardProps {
    titleStDef: string,
    content: ReactNode,
}

// eslint-disable-next-line react/display-name
const TitleCard = (props: TitleCardProps) => {
    return (
        <div className={props.titleStDef}>
            <span>{props.content}</span>
        </div>
    );
}

export default TitleCard;
