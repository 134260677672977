import { combineReducers } from '@reduxjs/toolkit';
import { myShiftReducer } from './myShiftReducer';
import { selectRoleReducer } from './selectRoleReducer';
import { historyReducer } from "./historyReducer";
import { filterReducer } from "./filterReducer";
import { appProfileReducer } from './appProfileReducer';
import { myRotationReducer } from './myRotationReducer';
import { hubReducer } from './hubReducer';
import { notificationReducer } from './notificationReducer';
import { templateReducer } from './templateReducer';

const reducer = combineReducers({
    myShiftReducer,
    selectRoleReducer,
    historyReducer,
    filterReducer,
    appProfileReducer,
    myRotationReducer,
    hubReducer,
    notificationReducer,
    templateReducer
})

export default reducer;

export type RootState = ReturnType<typeof reducer>;