import "./NotificationIcon.scss";
import { UserProfile } from "src/models/AppProfile";
import { IoNotificationsOffSharp, IoNotificationsOutline } from "react-icons/io5";
import { Badge } from 'primereact/badge';
import { useTypedSelector } from "src/hooks/useTypedselector";
import { Notification } from "src/models/Notification";
import ShiftGracePeriodNotificationCard from "../NotificationItemCard/ShiftGracePeriodNotificationCard";

interface NotificationIconProps {
    userProfile: UserProfile;
    isLoading: boolean;
}

const NotificationIcon = (props: NotificationIconProps) => {

    const notifications = useTypedSelector(state => state.notificationReducer);

    return <div className="notification-icon">
        <div className='notification dropdown2 float-end'>
            <button className='dropbtn border-0 '>
                <div className="p-overlay-badge">
                    <IoNotificationsOutline
                        color='#00708C'
                        size={'3em'}
                    />
                    {!notifications.notificationLoading && notifications.notifications?.length > 0 && <Badge value={notifications.notifications?.length} severity="danger"></Badge>}
                </div>

            </button>

            {/*Notification Dropdown Content*/}
            <div className="row no-gutters align-items-center dropdown-content px-2 py-2 extended-dropdown">
                <div className="d-flex justify-content-between align-items-center extended-dropdown_section">
                    <h6 className="mb-0">activity feed</h6>
                    {!notifications.notificationLoading && <Badge value={notifications.notifications?.length} className="badge-secondary badge-pill"></Badge>}
                </div>

                {!notifications.notificationLoading && <div className="extended-dropdown_section_list">
                    <ul className="list-group">
                        {notifications.notifications?.length === 0 && <li className="list-group-item-action list-group-item">
                            <div>
                                <div className="col-auto d-flex align-middle">
                                    {/*left profile picture*/}
                                    <div className="col-auto">
                                        <IoNotificationsOffSharp
                                            color='#00708C'
                                            size={'3em'}
                                        />
                                    </div>
                                    <div className="col-auto text-left gotham-bold align-middle">
                                            no notifications
                                    </div>
                                </div>
                            </div>
                        </li>
                        }

                        {
                            notifications.notifications.map((notification: Notification, index) => {
                                return <ShiftGracePeriodNotificationCard key={index} notification={notification} />
                            })
                        }

                    </ul>
                </div>
                }
            </div>
        </div>
        
    </div>;
}

export default NotificationIcon;