import { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { BiSearch } from "react-icons/bi";
import { NoteTemplate, NoteTemplateType } from 'src/models/NoteTemplate';
import { BsThreeDots } from 'react-icons/bs';
import ConfirmationModal from '../ConfirmationModal';
import NoteTemplateService from "src/services/api/noteTemplate.service";
import { Toaster } from 'src/services/api/toaster.service';
import "./NoteTemplateModal.scss";
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import NoteService from 'src/services/api/note.service';
import { NoteType } from 'src/enums/NoteTypes';
import { ActionType } from 'src/enums/ActionType';
import { UserProfile } from 'src/models/AppProfile';
import { PermissionHelper } from 'src/helpers/PermissionHelper';
import { Permission } from 'src/enums/Permission';
import { TemplateType } from 'src/enums/TemplateType';
import { useTemplateActionCreators } from 'src/hooks/useActions';

interface NoteTemplateModalsProps {
    showModal: boolean;
    toggleShowModal: () => void;
    roleId: string;
    noteTemplateList: string[];
    noteContent: string;
    applyTemplate: any;
    userProfile: UserProfile;
    templateType: TemplateType;
}

const NoteTemplateModal = (props: NoteTemplateModalsProps) => {
    const modalTitle = "select a template";
    const modalSubTitle = "Click on the template you would like to apply to your note";
    const noTemplCreatedMsg = "No note templates created yet";
    const noTemplFoundMsg = "No note templates found";
    const deleteSuccessMsg = "You successfully deleted a note template";
    const deleteErrorMsg = "Failed to delete the note template";
    const setDefaultSuccessMsg = "You successfully updated a note template";
    const setDefaultErrorMsg = "Failed to update the note template";
    const templateIdEmptyMsg = "noteTemplateId cannot be empty";

    const promptModalDeleteTitle = "delete note template?";
    const promptModalDeleteBodyText = "The template will be permanently deleted";

    const promptModalDefaultTitle = "set note template as default?";
    const promptModalDefaultBodyText = "There can only be one Note Template as default, if there is another template as default it will be modified";

    const noteTemplateNameTextboxRef = useRef(null);

    const [templates, setTemplates] = useState<NoteTemplate[]>([]);
    const [searchedTemplates, setSearchedTemplates] = useState<NoteTemplate[]>(templates);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [showDefaultConfirmationModal, setShowDefaultConfirmationModal] = useState(false);
    const [selectedNoteTemplateId, setSelectedNoteTemplateId] = useState('');
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const noteTemplateService = new NoteTemplateService();
    const noteService = new NoteService();

    const { getDefaultTemplate} = useTemplateActionCreators();

    const filterBySearch = () => {
        const query = searchValue.toLowerCase();

        if (searchValue)
            setSearchedTemplates(
                templates.filter(
                    (noteTemplate) => {
                        const templatesText = noteTemplate.label.toLocaleLowerCase();
                        const searchText = query;
                        return templatesText.includes(searchText);
                    }
                ));
        else
            setSearchedTemplates(templates);
    };

    const hideDeleteConfirmationModal = () => {
        setShowDeleteConfirmationModal(false);
    }

    const hideDefaultConfirmationModal = () => {
        setShowDefaultConfirmationModal(false);
    }

    const onDeleteBtnClick = async (noteTemplateId: string) => {
        setShowDeleteConfirmationModal(true);
        setSelectedNoteTemplateId(noteTemplateId);
    }

    const onDefaultBtnClick = async (noteTemplateId: string) => {
        setShowDefaultConfirmationModal(true);
        setSelectedNoteTemplateId(noteTemplateId);
    }

    const setNoteTemplateAsDefault = async (roleId : string, noteTemplateId: string) => {
        
        if (!noteTemplateId) {
            Toaster.showError(templateIdEmptyMsg);
            return;
        }

        setLoading(true);

        noteTemplateService.setTemplateAsDefault(roleId, noteTemplateId).then(async () => {
            getDefaultTemplate(roleId);
            Toaster.showSuccess(setDefaultSuccessMsg);
        }).catch((error) => {
            console.error(error);
            Toaster.showError(setDefaultErrorMsg);
        }).finally(() => setLoading(false));
    }

    const deleteTemplate = async (noteTemplateId: string) => {
        if (!noteTemplateId) {
            Toaster.showError(templateIdEmptyMsg);
            return;
        }

        setLoading(true);

        noteService.deleteNote(noteTemplateId, NoteType.Template).then(async () => {
            // Remove template item by id
            const newTemplatesList = templates.filter((noteTemplate) => noteTemplate.noteId !== noteTemplateId);
            setTemplates(newTemplatesList);

            Toaster.showSuccess(deleteSuccessMsg);
        }).catch((error) => {
            console.error(error);
            Toaster.showError(deleteErrorMsg);
        }).finally(() => setLoading(false));
    }

    const applyTemplate = (noteTemplateId: string) => {
        if (!noteTemplateId) {
            Toaster.showError(templateIdEmptyMsg);
            return;
        }

        noteTemplateService.getTemplate(noteTemplateId)
            .then((tmpl) => {
                const newTemplate : NoteTemplateType = {note: tmpl, actionType: ActionType.Apply}
                props.applyTemplate(newTemplate);
                props.toggleShowModal();
            })
            .catch((error) => {
                console.error('error', error?.message)
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        noteTemplateNameTextboxRef.current?.focus();

        setLoading(true);

        if (props.roleId?.length > 0) {
            noteTemplateService.getTemplateByRoleId(props.roleId)
                .then((templateList) => {
                    setTemplates(templateList);
                })
                .catch((error) => {
                    console.error('error', error?.message)
                })
                .finally(() => setLoading(false));
        }
    }, []);

    useEffect(() => {
        filterBySearch();
    }, [searchValue]);

    useEffect(() => {
        noteTemplateNameTextboxRef.current?.focus();

        filterBySearch();
    }, [templates]);

    return <div>
        <Modal
            className="note-template-modal dialog3"
            show={props.showModal}
            onHide={props.toggleShowModal}
            centered
            keyboard
            scrollable>
            <Modal.Header closeButton closeLabel='Close' className="border-bottom-0">
                <Modal.Title bsPrefix="modal-title large gotham-bold">
                    {modalTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body pt-0 pb-4">
                <label htmlFor="divNoteTempListModal" className="dialog-sub-title">{modalSubTitle}</label>
                <CustomSpinner loading={loading} />

                <div id="divNoteTempListModal" className='d-flex-column my-2'>
                    {!loading && templates?.length == 0 &&
                        <div className='d-flex justify-content-center p-4'>
                            <span className="large gotham-bold">{noTemplCreatedMsg}</span>
                        </div>
                    }

                    {templates?.length > 0 &&
                        <>
                            {/* Search bar Component */}
                            <div className='input-group'>
                                <input
                                    type="text"
                                    id="txtNoteTemplateName"
                                    ref={noteTemplateNameTextboxRef}
                                    className='form-control'
                                    placeholder="I'm looking for..."
                                    readOnly={loading ? true : false}
                                    onChange={(event) => setSearchValue(event.target.value)}
                                />
                                <button
                                    type='button'
                                    className='search-button px-3'
                                    onClick={filterBySearch}>
                                    <BiSearch />
                                </button>
                            </div>

                            {/*Note Template List Component*/}
                            <div className="d-flex-column flex-grow-1 gotham-bold mt-3">
                                {/* No match found */}
                                {searchedTemplates?.length == 0 &&
                                    <div className='d-flex justify-content-center p-4'>
                                        <span className="large">{noTemplFoundMsg}</span>
                                    </div>
                                }

                                {searchedTemplates.filter(noteTemplate => noteTemplate.templateType === props.templateType).map((noteTemplate: NoteTemplate, index) => {
                                    return <div key={index} className='row no-gutters align-items-center'>
                                        <div className='col-10 p-0 dropdown-container'>
                                            {/*Context Menu Dropdown Button*/}
                                            <button
                                                className="btn button-text buttonTextWrap text-left"
                                                onClick={() => applyTemplate(noteTemplate.noteId)}
                                                type="button"
                                                disabled={loading}>
                                                {noteTemplate.label} {noteTemplate.isDefault ? "(default)" : ""}
                                            </button>
                                        </div>
                                        <div className='col p-0 dropdown-container text-end'>
                                            {/*Context Menu Dropdown Button*/}
                                            <button
                                                className="btn-sm btn-secondary border-0 p-0"
                                                disabled={loading}
                                                aria-label="note"
                                                aria-controls="popup_menu"
                                                aria-haspopup>
                                                <BsThreeDots className="normal" />
                                            </button>

                                            {/*Context Menu Options, Dropdown Content*/}
                                            <div className="dropdown-content shadow-sm border">
                                                {/*Context Menu Option: "edit"*/}
                                                <button
                                                    className="btn-sm btn-secondary border-0 w-100 text-left d-block"
                                                    onClick={() => onDeleteBtnClick(noteTemplate.noteId)}
                                                    disabled={loading}
                                                    aria-label="delete noteTemplate">
                                                    delete
                                                </button>

                                                {PermissionHelper.hasPermission(props.userProfile, Permission.Template) &&
                                                    <button
                                                        className="btn-sm btn-secondary border-0 w-100 text-left d-block"
                                                        onClick={() => onDefaultBtnClick(noteTemplate.noteId)}
                                                        disabled={loading || noteTemplate.isDefault}
                                                        aria-label="set as default">
                                                        set as default
                                                    </button>
                                                }
                                                
                                            </div>

                                        </div>
                                    </div>
                                })}

                                <ConfirmationModal
                                    showConfirmationModal={showDeleteConfirmationModal}
                                    toggleConfirmationModal={() => hideDeleteConfirmationModal()}
                                    objIdList={[selectedNoteTemplateId]}
                                    onConfirm={deleteTemplate}
                                    titleText={promptModalDeleteTitle}
                                    titleClass='modal-title large gotham-bold'
                                    bodyText={promptModalDeleteBodyText}
                                    bodyClass='modal-body medium pt-0'
                                    footerClass='modal-footer border-0'
                                    confirmationButtonText='delete' />

                                <ConfirmationModal
                                    showConfirmationModal={showDefaultConfirmationModal}
                                    toggleConfirmationModal={() => hideDefaultConfirmationModal()}
                                    objIdList={[props.roleId, selectedNoteTemplateId]}
                                    onConfirm={setNoteTemplateAsDefault}
                                    titleText={promptModalDefaultTitle}
                                    titleClass='modal-title large gotham-bold'
                                    bodyText={promptModalDefaultBodyText}
                                    bodyClass='modal-body medium pt-0'
                                    footerClass='modal-footer border-0'
                                    confirmationButtonText='set as default' />
                            </div>
                        </>
                    }
                </div>
            </Modal.Body>
        </Modal>
    </div>
};

export default NoteTemplateModal;

