import Header from "src/components/Header";
import "./DefaultLayout.scss";
import { Outlet } from "react-router-dom";
import { CustomNavLink } from "src/models/Header";
import { useTypedSelector } from "src/hooks/useTypedselector";
import Footer from "src/components/Footer";
import { useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineArrowUp } from "react-icons/ai";

const DefaultLayout = () => {
    const scrollref = useRef(null);

    const [showBtn, setShowBtn] = useState(false);

    const roleSettings = useTypedSelector(state => state.appProfileReducer.roleSettings.roleSettingsDetail);

    const shiftEnabled = roleSettings?.isShiftEnabled;
    const rotationEnabled = roleSettings?.isRotationEnabled;
    const shiftScheduleEnabled = roleSettings?.isScheduleReportEnabled;   

    const pageNavTitle = (): string => {
        return shiftEnabled && rotationEnabled
            ? "my shift & rotations"
            : shiftEnabled
                ? "my shift"
                : rotationEnabled
                    ? "my rotation"
                    : "";
    }

    const links: CustomNavLink[] = [{
        name: 'select role',
        bsPrefix: 'nav-link gotham-bold medium',
        eventKey: '/selectrole',
        to: 'selectrole',
        dataCy: 'selectRolePageNavBtn',
        enabled: true
    }, {
        name: pageNavTitle(),
        bsPrefix: 'nav-link gotham-bold medium',
        eventKey: '/myshift',
        to: 'myshift',
        dataCy: 'myShiftPageNavBtn',
        enabled: shiftEnabled || rotationEnabled
    }, {
        name: 'history',
        bsPrefix: 'nav-link gotham-bold medium',
        eventKey: '/history',
        to: 'history',
        dataCy: 'historyPageNavBtn',
        enabled: true
    }, {
        name: 'reports',
        bsPrefix: 'nav-link gotham-bold medium',
        eventKey: '/report',
        to: 'report',
        dataCy: 'reportPageNavBtn',
        enabled: shiftScheduleEnabled
    }];

    const scrollFunction = () => {
        scrollref.current.scrollTop = 0;
    }

    const scrollToTop = (event) => {
        if (event.target.scrollTop > 20) {
            setShowBtn(true)
        } else {
            setShowBtn(false)
        }
    }


    return <>
        <Header title='ops toolbelt' links={links} />

        {/* <span>DefaultLayout</span> */}

        <main className="content" onScroll={(event) => scrollToTop(event)} ref={scrollref}>
            <div className="contentPadding">
                <Outlet />
            </div>
            <Footer />
            {/*Back to top button*/}
            {showBtn &&
                <OverlayTrigger overlay={
                    <Tooltip>
                        scroll to top
                    </Tooltip>
                }>
                    <button
                        onClick={() => scrollFunction()}
                        className="btn jumpToTopBtn border-0 p-3">
                        <AiOutlineArrowUp size={'1.6em'} />
                    </button>
                </OverlayTrigger>
            }
        </main>

    </>
};

export default DefaultLayout;