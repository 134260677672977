import { APIService } from "./api.service";
import { Notification } from "src/models/Notification";

export default class NotificationService extends APIService {
    private url = '/notifications';

    async getUserNotifications(): Promise<Notification[]> {
        const response = await this.api.get(`${this.url}/by-user`);
        return response.data;
    }

    async setReadNotification(notificationId: string): Promise<any> {
        const response = await this.api.put(`${this.url}/read?notificationId=${notificationId}`);

        return response.data;
    }
}