import { Dispatch } from 'redux';
import { ActionType } from '../action-types';
import { MyShiftAction } from '../actions/myShiftActions';
import TagService from 'src/services/api/tag.service';
import ShiftService from 'src/services/api/shift.service';
import { NotePayload, SingleNote } from 'src/models/NoteItem';
import { NoteTab, NoteType } from 'src/enums/NoteTypes';
import { ShiftTab } from 'src/enums/ShiftTab';
import { TagType } from 'src/enums/TagType';

const tagService = new TagService();
const myShiftService = new ShiftService();

export const setShowTagRoleModal = (payload: boolean) => {
    return (dispatch: Dispatch<MyShiftAction>) => {
        dispatch({
            type: ActionType.SET_SHOW_TAG_ROLE_MODAL,
            payload
        });
    }
}

export const setTagRoleModalList = (payload: SingleNote) => {
    return (dispatch: Dispatch<MyShiftAction>) => {
        dispatch({
            type: ActionType.SET_TAG_ROLE_MODAL_LIST,
            payload
        });
    }
}

export const setMyShiftCollapseFlag = (noteTab: NoteTab, payload: boolean) => {
    if (noteTab == NoteTab.MyNotes) {
        return (dispatch: Dispatch<MyShiftAction>) => {
            dispatch({
                type: ActionType.SET_MY_NOTES_COLLAPSE_ALL_NOTES,
                payload
            });
        }
    }
    else if (noteTab == NoteTab.AllNotes) {
        return (dispatch: Dispatch<MyShiftAction>) => {
            dispatch({
                type: ActionType.SET_ALL_NOTES_COLLAPSE_ALL_NOTES,
                payload
            });
        }
    }
    else if (noteTab == NoteTab.MyRotationNotes) {
        return (dispatch: Dispatch<MyShiftAction>) => {
            dispatch({
                type: ActionType.SET_MY_ROT_NOTES_COLLAPSE_ALL_NOTES,
                payload
            });
        }
    }
    else {
        return;
    }
}

export const getCategoryList = () => {
    return async (dispatch: Dispatch<MyShiftAction>) => {
        dispatch({
            type: ActionType.GET_CATEGORY_LIST,
        });
        try {
            const catTags = await tagService.getTagsByType(TagType.Category);
            dispatch({
                type: ActionType.GET_CATEGORY_LIST_SUCCESS,
                payload: catTags,
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            dispatch({
                type: ActionType.GET_CATEGORY_LIST_FAILURE,
                payload: err.message,
            });
        }
    };
};

export const getSectionList = () => {
    return async (dispatch: Dispatch<MyShiftAction>) => {
        dispatch({
            type: ActionType.GET_SECTION_LIST,
        });
        try {
            const tags = await tagService.getTagsByType(TagType.Section);
            
            dispatch({
                type: ActionType.GET_SECTION_LIST_SUCCESS,
                payload: tags,
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            dispatch({
                type: ActionType.GET_SECTION_LIST_FAILURE,
                payload: err.message,
            });
        }
    };
};

export const getCurrentShiftNotes = (roleId: string, isGracePeriod: boolean) => {
    return async (dispatch: Dispatch<MyShiftAction>) => {
        dispatch({
            type: ActionType.GET_CURRENT_SHIFT_NOTES,
        });
        try {
            const currentShiftNotes = await myShiftService.getCurrentShiftNotes(roleId, isGracePeriod);
            dispatch({
                type: ActionType.GET_CURRENT_SHIFT_NOTES_SUCCESS,
                payload: currentShiftNotes,
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            dispatch({
                type: ActionType.GET_CURRENT_SHIFT_NOTES_FAILURE,
                payload: err.message,
            });
        }
    };
};

export const clearCurrentShiftNotes = () => {
    return async (dispatch: Dispatch<MyShiftAction>) => {
        dispatch({
            type: ActionType.CLEAR_CURRENT_SHIFT_NOTES,
        });
    };
};


export const getFollowedNotes = (roleId: string) => {
    return async (dispatch: Dispatch<MyShiftAction>) => {
        dispatch({
            type: ActionType.GET_FOLLOWED_NOTES,
        });
        try {
            const followedShiftNotes = await myShiftService.getFollowedShiftNotes(roleId);
            dispatch({
                type: ActionType.GET_FOLLOWED_NOTES_SUCCESS,
                payload: followedShiftNotes,
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            dispatch({
                type: ActionType.GET_FOLLOWED_NOTES_FAILURE,
                payload: err.message,
            });
        }
    };
};

export const clearFollowedNotes = () => {
    return async (dispatch: Dispatch<MyShiftAction>) => {
        dispatch({
            type: ActionType.CLEAR_FOLLOWED_NOTES,
        });
    };
};

export const getAllRolesSummary = () => {
    return async (dispatch: Dispatch<MyShiftAction>) => {
        dispatch({
            type: ActionType.GET_ALL_ROLES_SUMMARY,
        });
        try {
            const allroles = [];
            await myShiftService.getAllRolesSummary().then((data) => {
                if (data?.length > 0) {
                    data.forEach(x => {
                        allroles.push({
                            jobClassShortName: x.jobClassShortName,
                            roleName: x.fullRoleName,
                            roleId: x.id
                        });
                    });
                }
            });
            dispatch({
                type: ActionType.GET_ALL_ROLES_SUMMARY_SUCCESS,
                payload: allroles,
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            dispatch({
                type: ActionType.GET_ALL_ROLES_SUMMARY_FAILURE,
                payload: err.message,
            });
        }
    };
};

export const clearAllRolesSummary = () => {
    return async (dispatch: Dispatch<MyShiftAction>) => {
        dispatch({
            type: ActionType.CLEAR_ALL_ROLES_SUMMARY,
        });
    };
};

export const incrementDraftNotesCount = () => {
    return (dispatch: Dispatch<MyShiftAction>) => {
        dispatch({
            type: ActionType.INCREMENT_DRAFT_NOTES_COUNT,
        });
    }
}

export const decrementDraftNotesCount = () => {
    return (dispatch: Dispatch<MyShiftAction>) => {
        dispatch({
            type: ActionType.DECREMENT_DRAFT_NOTES_COUNT,
        });
    }
}

export const SetNewNoteIsDirty = (payload: boolean) => {
    return (dispatch: Dispatch<MyShiftAction>) => {
        dispatch({
            type: ActionType.SET_NEW_NOTE_IS_DIRTY, payload
        });
    }
}

export const GetNoteTypeSelection = () => {
    return async (dispatch: Dispatch<MyShiftAction>) => {
        dispatch({
            type: ActionType.GET_NOTE_TYPE_SELECTION,
        });
    };
};

export const SetNoteTypeSelection = (payload: NoteType) => {
    return (dispatch: Dispatch<MyShiftAction>) => {
        dispatch({
            type: ActionType.SET_NOTE_TYPE_SELECTION,
            payload
        });
    }
}

export const setActiveShiftTab = (payload: ShiftTab) => {
    return (dispatch: Dispatch<MyShiftAction>) => {
        dispatch({
            type: ActionType.SET_ACTIVE_SHIFT_TAB,
            payload
        });
    }
}

export const setAddNoteDialogVisibility = (payload: NotePayload) => {
    return (dispatch: Dispatch<MyShiftAction>) => {
        dispatch({
            type: ActionType.SET_ADD_NOTE_DIALOG_VISIBILITY,
            payload
        });
    }
}

export const clearAddNoteDialogVisibility = () => {
    return (dispatch: Dispatch<MyShiftAction>) => {
        dispatch({
            type: ActionType.CLEAR_ADD_NOTE_DIALOG_VISIBILITY
        });
    }
}