import React, { useEffect, useState } from "react";
import "./NoteCardSection.scss";
import { SectionTagShiftNote, LocationData, SectionTagRotationNote, RotationNotesData, NotePayload } from "src/models/NoteItem";
import { NoteTab, NoteType } from "src/enums/NoteTypes";
import NoteCard from "../NoteCard/NoteCard";
import { MdOutlineInfo } from "react-icons/md";
import { useTypedSelector } from "src/hooks/useTypedselector";
import { useMyShiftActionCreators } from "src/hooks/useActions";
import { Tag } from "src/models/Tag";

interface NoteCardSectionProps {
    noteType: NoteTab,
    sectionTagShiftNote?: SectionTagShiftNote,
    sectionTagRotationNote?: SectionTagRotationNote,
    locationName?: string,
    setChecked?: any,
    selectedNoteIds?: string[],
    canSelect?: boolean,
}

const NoteCardSection: React.FC<NoteCardSectionProps> = (props: NoteCardSectionProps) => {
    const noteTab = props.noteType;
    const sectionShiftNoteTag = props.sectionTagShiftNote;
    const sectionRotationNoteTag = props.sectionTagRotationNote;
    const notNoteFound = "No Notes found";

    const contStDef = "d-flex-column gap-3";
    const errorContStyle = "bg-white d-flex-column flex-grow-1 px-4 py-2";

    const addNoteModalVisibility = useTypedSelector(state => state.myShiftReducer.notePayload?.modalVisibility);
    const { setAddNoteDialogVisibility } = useMyShiftActionCreators();

    const collapseStateMyNotes = useTypedSelector(state => state.myShiftReducer.collapseMyNotes);

    const contStyle = () => {
        if (props.noteType == NoteTab.SelectRoleNotes || props.noteType == NoteTab.SelectRoleRotationNotes || props.noteType == NoteTab.SelectRoleAllRotationNotes)
            return "py-2";
        else
            return "px-3 py-2";
    }

    const onHeaderDblClick = () => {
        if (!addNoteModalVisibility) {
            let noteType: NoteType = null;
            let tag: Tag = null;

            if (props.noteType == NoteTab.MyNotes || props.noteType == NoteTab.FollowedNotes) {
                noteType = NoteType.Shift;
                tag = sectionShiftNoteTag.tag;
            }
            else if (props.noteType == NoteTab.MyRotationNotes) {
                noteType = NoteType.Rotation;
                tag = sectionRotationNoteTag.tag;
            }

            if (noteType) {
                const notePayload: NotePayload = {
                    modalVisibility: true,
                    noteType: noteType,
                    tag: tag
                };

                setAddNoteDialogVisibility(notePayload);
            }
        }
    }

    const ContainsShiftNotes = () => {
        return sectionShiftNoteTag?.shiftNote?.locationNotesList?.length > 0;
    }

    const ContainsRotationNotes = () => {
        return sectionRotationNoteTag?.rotationNote?.rotationNotes?.length > 0;
    }

    const IsShiftScope = () => {
        return (noteTab === NoteTab.MyNotes || noteTab === NoteTab.FollowedNotes || noteTab === NoteTab.AllNotes || noteTab === NoteTab.SelectRoleNotes || noteTab === NoteTab.HistoryNotes || noteTab === NoteTab.HistoryNotesModal);
    }

    const IsCritical = () => {
        return IsShiftScope() ? sectionShiftNoteTag.isCritical : sectionRotationNoteTag.isCritical;
    }

    const GetSectionName = () => {
        return IsShiftScope() ? sectionShiftNoteTag.tag.name : sectionRotationNoteTag.tag.name;
    }

    const GetSectionDescription = () => {
        return IsShiftScope() ? sectionShiftNoteTag.tag.tagContent : sectionRotationNoteTag.tag.tagContent;
    }

    const GetLocationName = (locactionData:LocationData) => {
        return locactionData.location.name;
    }

    const GetRoleName = () => {
        return IsShiftScope() ? sectionShiftNoteTag.shiftNote.roleName.concat('-').concat(sectionShiftNoteTag.shiftNote.jobClassShortName) 
                            : sectionRotationNoteTag.rotationNote.roleName.concat('-').concat(sectionRotationNoteTag.rotationNote.jobClassShortName);
    }

    const SetInitialExpandedValue = () => {
        return IsShiftScope() ? ContainsShiftNotes() : ContainsRotationNotes();
    }

    const [isExpanded, setIsExpanded] = useState(false);

    /* handle notes accordian on click of collapse all notes*/
    useEffect(() => {
        if (isExpanded && collapseStateMyNotes && (props.noteType === NoteTab.AllNotes || props.noteType === NoteTab.MyNotes))
            setIsExpanded(false);
    }, [collapseStateMyNotes]);

    useEffect(() => {
        setIsExpanded(SetInitialExpandedValue());
    }, [props.sectionTagShiftNote, props.sectionTagRotationNote]);


    return (<div className={contStyle() + ' bg-lightest-gray note-card-section'} aria-label={!isExpanded ? 'expand panel' : 'collapse panel'} onDoubleClick={() => onHeaderDblClick()}>
        {
            <div className={`d-flex  gap-2 gotham-bold medium p-2 justify-content-between rounded-top px-3 ${IsCritical() ? 'bg-lighter-red' : 'bg-lighter-blue'} `}>
                <div />
                <div className='d-flex flex-wrap gap-2'>
                    {
                        <span className={`${IsCritical() ? 'color-medium-red' : ''}`}>{GetSectionName()}</span>
                    }


                    <div className="btn-sm btn-secondary border-0 p-0 section-tooltip">
                        <button
                            className="btn-sm btn-secondary border-0 p-0"
                            type="button"
                            aria-label="more info about note">
                            <MdOutlineInfo className="small" />
                        </button>
                        <span className="section-tooltiptext gap-2">{GetSectionDescription()}</span>
                    </div>

                 
                    {/* </OverlayTrigger> */}
                </div>
                <div className="shift-icon-plus d-flex align-items-center">
                    <span className={`cursor-pointer ${!isExpanded ? 'pi pi-plus' : 'pi pi-minus'}`} onClick={() => setIsExpanded(!isExpanded)}></span>
                </div>

            </div>
        }
        {isExpanded &&
            <div className={contStDef}>
                {
                    IsShiftScope() ? ContainsShiftNotes() ?
                        sectionShiftNoteTag.shiftNote.locationNotesList?.map((locationData: LocationData, index) => {
                            return <NoteCard key={index} locationName = {GetLocationName(locationData)} roleJobClassName={GetRoleName()} notesToRender={locationData.notes} noteType={props.noteType} selectedNoteIds={props.selectedNoteIds} setChecked={props.setChecked} canSelect={props.canSelect} />;
                        }) : <div className={errorContStyle}>{notNoteFound}</div>
                        :
                        ContainsRotationNotes() ?
                            sectionRotationNoteTag.rotationNote.rotationNotes?.map((rotationNotesData: RotationNotesData, index) => {
                                return <NoteCard key={index} roleJobClassName={GetRoleName()} notesToRender={rotationNotesData.notes} noteType={props.noteType} />
                            }) :
                            <div className={errorContStyle}>{notNoteFound}</div>
                }
            </div>
        }
    </div >)
}

export default NoteCardSection;