import { Rotation } from 'src/models/Rotation';
import { ActionType } from '../action-types';
import { AppProfileAction } from '../actions/appProfileActions';
import { AppProfile, ManageUserAccessPageToast, UserProfile } from './../../models/AppProfile';
import { Shift, ShiftSchedule } from 'src/models/Shift';
import { ProfileImage } from 'src/models/ProfileImage';

export interface AppProfileState {
    // role settings
    roleSettings: {
        isLoading: boolean,
        roleSettingsDetail: AppProfile,
        error: string,
    },

    //userProfile details 
    userProfileState: {
        userProfile: UserProfile,
        profileImages: ProfileImage[]
        isLoading: boolean,
        error: string,
    }

    //userAccount pagination
    userAccount: {
        page: number,
        requestedPageSize: number,
        totalCount: number,
        items: UserProfile[],
        pageSize: number,
        hasPrevious: boolean,
        hasNext: boolean,
        isLoading: boolean,
        error: string
    }

    //shiftProfile details for current shift
    currentUserShift: {
        shift: Shift,
        isLoading: boolean,
        ifExists: boolean,
        error: string,
        gracePeriod: {
            isInGracePeriod: boolean,
            minLeftForShiftEndWithGraceMin: number,
        }
    },

    //rotationProfile details for current shift
    currentUserRotation: {
        rotation: Rotation,
        isLoading: boolean,
        ifExists: boolean,
        error: string
    },

    //shiftProfile details for shift schedule
    shiftSchedule: {
        shiftScheduleDetail: ShiftSchedule,
        isLoading: boolean,
        error: string,
        graceMinutes: number,
    },

    manageUserAccessPageToast: ManageUserAccessPageToast,
}

const initialState = {
    roleSettings: {
        isLoading: false,
        roleSettingsDetail: null,
        error: '',
    },

    userProfileState: {
        userProfile: null,
        profileImages: [],
        isLoading: false,
        error: '',
    },

    userAccount: {
        page: 0,
        requestedPageSize: 0,
        totalCount: 0,
        items: [],
        pageSize: 0,
        hasPrevious: false,
        hasNext: false,
        isLoading: false,
        error: ''
    },

    currentUserShift: {
        shift: null,
        ifExists: false,
        isLoading: false,
        error: '',
        gracePeriod: {
            isInGracePeriod: false,
            minLeftForShiftEndWithGraceMin: 0
        }
    },

    currentUserRotation: {
        rotation: null,
        ifExists: false,
        isLoading: false,
        error: ''
    },

    shiftSchedule: {
        shiftScheduleDetail: {
            beginDateTime: new Date(),
            endDateTime: new Date(),
            shiftEndingInSeconds: 0
        },
        isLoading: false,
        error: '',
        graceMinutes: 0,
    },

    manageUserAccessPageToast: {
        show: false,
        content: ""
    }
};

export const appProfileReducer = (
    state: AppProfileState = initialState,
    action: AppProfileAction
): AppProfileState => {
    switch (action.type) {
        case ActionType.GET_ROLE_SETTINGS:
            return {
                ...state,
                roleSettings: {
                    ...state.roleSettings,
                    isLoading: true
                }
            };

        case ActionType.GET_ROLE_SETTINGS_SUCCESS:
            return {
                ...state,
                roleSettings: {
                    ...state.roleSettings,
                    isLoading: false,
                    roleSettingsDetail: action.payload
                }
            };

        case ActionType.GET_ROLE_SETTINGS_FAILURE:
            return {
                ...state,
                roleSettings: {
                    ...state.roleSettings,
                    isLoading: false,
                    roleSettingsDetail: null,
                    error: action.payload
                }
            };

        case ActionType.GET_USER_PROFILE:
            return {
                ...state,
                userProfileState: {
                    isLoading: true,
                    userProfile: null,
                    profileImages: [],
                    error: ''
                }
            };
        case ActionType.GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                userProfileState: {
                    isLoading: false,
                    userProfile: action.payload,
                    profileImages: [],
                    error: ''
                }
            }
        case ActionType.GET_USER_PROFILE_FAILURE:
            return {
                ...state,
                userProfileState: {
                    isLoading: false,
                    userProfile: null,
                    profileImages: [],
                    error: action.payload
                }

            };

        case ActionType.SET_CURRENT_SHIFT:
            return {
                ...state,
                currentUserShift: {
                    ...state.currentUserShift,
                    ifExists: false,
                    shift: action.payload
                },
            }


        case ActionType.SET_CURRENT_ROTATION:
            return {
                ...state,
                currentUserRotation: {
                    ...state.currentUserRotation,
                    ifExists: false,
                    rotation: action.payload
                },
            }

        case ActionType.GET_CURRENT_SHIFT_DETAILS:
            return {
                ...state,
                currentUserShift: {
                    ...state.currentUserShift,
                    isLoading: true
                },
            };

        case ActionType.GET_CURRENT_ROTATION_DETAILS:
            return {
                ...state,
                currentUserRotation: {
                    ...state.currentUserRotation,
                    isLoading: true
                },
            };

        case ActionType.GET_CURRENT_ROTATION_DETAILS_SUCCESS:
            return {
                ...state,
                currentUserRotation: {
                    ...state.currentUserRotation,
                    isLoading: false,
                    rotation: action.payload,
                    ifExists: true
                },
            };

        case ActionType.GET_CURRENT_ROTATION_DETAILS_FAILURE:
            return {
                ...state,
                currentUserRotation: {
                    ...state.currentUserRotation,
                    isLoading: false,
                    error: action.payload,
                    ifExists: false,
                    rotation: null
                },
            };

        case ActionType.GET_CURRENT_SHIFT_DETAILS_SUCCESS:

            const shiftEndingInSeconds = action.payload[0]?.shiftSchedule.shiftEndingInSeconds;
            let shiftEndInMin = 0;
            let _isGracePeriod = false;
            const _graceMinutes = state.shiftSchedule.graceMinutes;

            if (shiftEndingInSeconds < 0) {
                shiftEndInMin = (Math.abs(shiftEndingInSeconds) / 60) + _graceMinutes; //considering grace minutes from current shift shift-schedule
            }
            if (shiftEndingInSeconds > 0) {
                shiftEndInMin = (_graceMinutes - (Math.abs(shiftEndingInSeconds) / 60)); //considering grace minutes from current shift shift schedule
                _isGracePeriod = true;
            }
            return {
                ...state,
                currentUserShift: {
                    ...state.currentUserShift,
                    isLoading: false,
                    shift: action.payload[0],
                    ifExists: action.payload.length > 0 ? true : false,
                    gracePeriod: {
                        ...state.currentUserShift.gracePeriod,
                        minLeftForShiftEndWithGraceMin: shiftEndInMin,
                        isInGracePeriod: _isGracePeriod,
                    }
                },
            };

        case ActionType.GET_CURRENT_SHIFT_DETAILS_FAILURE:
            return {
                ...state,
                currentUserShift: {
                    ...state.currentUserShift,
                    isLoading: false,
                    error: action.payload,
                    ifExists: false,
                    shift: null
                },
            };

        case ActionType.SET_IS_GRACE_PERIOD:
            return {
                ...state,
                currentUserShift: {
                    ...state.currentUserShift,
                    gracePeriod: {
                        ...state.currentUserShift.gracePeriod,
                        isInGracePeriod: action.payload
                    }
                }
            };

        case ActionType.SET_TIME_LEFT_FOR_SHIFT_END:
            return {
                ...state,
                currentUserShift: {
                    ...state.currentUserShift,
                    gracePeriod: {
                        ...state.currentUserShift.gracePeriod,
                        minLeftForShiftEndWithGraceMin: action.payload
                    }
                }
            };

        case ActionType.GET_SHIFT_SCHEDULE:
            return {
                ...state,
                shiftSchedule: {
                    ...state.shiftSchedule,
                    isLoading: true
                }
            };

        case ActionType.GET_SHIFT_SCHEDULE_SUCCESS:
            return {
                ...state,
                shiftSchedule: {
                    ...state.shiftSchedule,
                    isLoading: false,
                    graceMinutes: action.payload.graceMinutes,
                    shiftScheduleDetail: action.payload
                }
            };

        case ActionType.GET_SHIFT_SCHEDULE_FAILURE:
            return {
                ...state,
                shiftSchedule: {
                    ...state.shiftSchedule,
                    isLoading: false,
                    error: action.payload
                }
            };

        case ActionType.GET_FACILITY_USERS:
            return {
                ...state,
                userAccount: {
                    ...state.userAccount,
                    isLoading: true
                }
            }

        case ActionType.GET_FACILITY_USERS_DETAILS_SUCCESS:
            const { items, page, requestedPageSize, totalCount, pageSize, hasPrevious, hasNext } = action.payload;
            const { userAccount } = state;

            const updatedUserAccount = {
                ...userAccount,
                isLoading: false,
                items,
                page,
                requestedPageSize,
                totalCount,
                pageSize,
                hasPrevious,
                hasNext,
            };

            return {
                ...state,
                userAccount: updatedUserAccount,
            };

        case ActionType.GET_FACILITY_USERS_DETAILS_FAILURE:
            return {
                ...state,
                userAccount: {
                    ...state.userAccount,
                    isLoading: false,
                    error: action.payload
                }
            }

        case ActionType.SHOW_MANAGE_USER_ACCESS_PAGE_TOAST:
            return {
                ...state,
                manageUserAccessPageToast: action.payload
            };

        case ActionType.HIDE_MANAGE_USER_ACCESS_PAGE_TOAST:
            return {
                ...state,
                manageUserAccessPageToast: { show: false, content: "" }
            };

        default:
            return state;
    }
}