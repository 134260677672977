import { memo, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { Galleria } from "primereact/galleria";
import { Attachment } from "src/models/Attachment";
import { FileType } from "src/enums/TagType";
import "./AttachmentPreviewModal.scss";
import { BsDownload } from "react-icons/bs";

interface AttachmentPreviewModalProps {
    activeAttId: number;
    attachments: Attachment[];
    show: any;
    toggleShow: any;
}

const AttachmentPreviewModal = (props: AttachmentPreviewModalProps) => {
    const { show, toggleShow } = props;
    const [images, setImages] = useState([]);
    const emptyAttMsg = "No attachment(s)!";
    const invalidPreviewMsg = "No preview available.";
    const title = "preview attachment";

    const fileInfos = new Map([
        ["rtf", { fileType: FileType.Doc, preview: false }],
        ["txt", { fileType: FileType.Doc, preview: false }],
        ["pdf", { fileType: FileType.Doc, preview: false }],
        ["csv", { fileType: FileType.Doc, preview: false }],
        ["doc", { fileType: FileType.Doc, preview: false }],
        ["docx", { fileType: FileType.Doc, preview: false }],
        ["xls", { fileType: FileType.Doc, preview: false }],
        ["xlsx", { fileType: FileType.Doc, preview: false }],
        ["png", { fileType: FileType.Image, preview: true }],
        ["jpg", { fileType: FileType.Image, preview: true }],
        ["jpeg", { fileType: FileType.Image, preview: true }],
        ["gif", { fileType: FileType.Image, preview: true }],
        ["mp4", { fileType: FileType.Video, preview: true }],
        ["m4v", { fileType: FileType.Video, preview: false }],
        ["mov", { fileType: FileType.Video, preview: false }],
        ["jpgv", { fileType: FileType.Video, preview: false }],
        ["mjpeg", { fileType: FileType.Video, preview: false }],
        ["mpeg", { fileType: FileType.Video, preview: false }],
        ["mpeg4", { fileType: FileType.Video, preview: false }],
        ["mpeg2", { fileType: FileType.Video, preview: false }],
        ["wmv", { fileType: FileType.Video, preview: false }],
        ["avi", { fileType: FileType.Video, preview: false }],
    ]);

    const itemTemplate = (item: any) => {
        if (item.type === FileType.Image) {
            return <img src={`${item.uri}`} alt={item} className="gallery-item" />;
        }
        else if (item.type === FileType.Video) {
            return (
                <video autoPlay={false} muted controls className="gallery-item">
                    <source src={`${item.uri}`} />
                </video>
            );
        }
        else {
            return (
                <div className="d-flex-column align-items-center">
                    <strong>{item.displayFileName}</strong>
                    <div>{invalidPreviewMsg}</div>
                    <a href={item.uri} className="pt-4">
                        <button
                            type="button"
                            className="btn btn-secondary flex-fill">
                            download <BsDownload className="icon" />
                        </button>
                    </a>
                </div >
            );
        }
    };

    const caption = (item: any) => {
        if (item.preview)
            return <p>{item.displayFileName}</p>;
        else
            return <span />;
    };

    useMemo(() => {
        const attImages: any[] = [];

        props.attachments?.forEach((attachment) => {
            let fileExt: string = null;
            let fileInfo: any = null;

            fileExt = attachment.displayFileName.split(".").pop();

            if (!fileExt) fileExt = "doc";

            fileInfo = fileInfos.get(fileExt.toLowerCase());

            const attImage = {
                type: fileInfo.fileType,
                uri: attachment.downloadURI,
                displayFileName: attachment.displayFileName,
                preview: fileInfo.preview
            };

            attImages.push(attImage);
        });

        setImages(attImages);

    }, [props.attachments]);

    return (
        <>
            <div tabIndex={-1}>
                <Modal
                    className="attachmentPreviewModal"
                    show={show}
                    onHide={toggleShow}
                    centered
                >
                    <Modal.Header closeButton>{title}</Modal.Header>
                    <Modal.Body>
                        {(props.attachments && props.attachments.length > 0) &&
                            <Galleria
                                value={images}
                                activeIndex={props.activeAttId}
                                circular
                                showItemNavigators
                                showThumbnails={false}
                                caption={caption}
                                item={itemTemplate}
                            />
                        } :
                        {
                            <h4>{emptyAttMsg}</h4>
                        }
                    </Modal.Body>

                </Modal>
            </div>
        </>
    );
};

export default memo(AttachmentPreviewModal);
