import { APIService } from './api.service';

export default class MS_Graph_Service extends APIService {

    async getUserProfileImage(oId: string): Promise<any> {
        const url = `/v1.0/users/${oId}/photo/$value`;
        
        const response = await this.ms_graph_api.get(url, { responseType: 'blob' });

        return response.data;
    }
}
