import Skeleton from "react-loading-skeleton";

const NoteItemCardSkeleton = () => {
    const SIZE_15 = 15, SIZE_25 = 25, SIZE_40 = 40, SIZE_50 = 50, SIZE_200 = 200;

    const actionItemSkeletons = () => {
        return <div className="d-flex gap-1">
            <Skeleton width={SIZE_15} />
            <Skeleton width={SIZE_15} />
            <Skeleton width={SIZE_25} />
            <Skeleton width={SIZE_25} />
            <Skeleton width={SIZE_40} />
            <Skeleton width={SIZE_40} />
        </div>
    };

    return (
        <div className="d-flex-column">
            <div className="d-flex justify-content-between">
                <div className="d-flex gap-1">
                    {/* Tags */}
                    <Skeleton width={SIZE_25} height={SIZE_15} />
                    <Skeleton width={SIZE_40} height={SIZE_15} />
                    <Skeleton width={SIZE_50} height={SIZE_15} />

                    {/* Author*/}
                    <Skeleton width={SIZE_200} height={SIZE_15} />
                </div>

                {/* action items menu */}
                {actionItemSkeletons()}
            </div>

            <div className="my-2">
                {/* Content */}
                <Skeleton count={3} />
            </div>
        </div>)
}

export default NoteItemCardSkeleton;