import { ShiftSchedule } from './Shift';
import { Tag } from "./Tag";
import { Attachment } from "./Attachment";
import { defaultUser0, UserProfile } from "./AppProfile";
import { NoteType } from "../enums/NoteTypes";
import { TemplateType } from 'src/enums/TemplateType';

export class NoteAddModelBase {
    id?: string;
    shiftId: string;
    content: string;
    tagNames: string[];
    readonly noteType: NoteType;

    constructor(init: NoteAddModelBase) {
        Object.assign(this, init);
    }
}

export class NoteTemplateAddModel extends NoteAddModelBase {
    label: string;
    roleId: string;
    isDefault: boolean;
    templateType: TemplateType;

    constructor(init: NoteTemplateAddModel) {
        super({ ...init, noteType: NoteType.Template })
        this.label = init.label;
        this.roleId = init.roleId;
    }

}

/*Used in Note Update when calling API*/
export class AddShiftNote extends NoteAddModelBase {
    locationId: string;
    copiedFromId: number;
    isCritical: boolean;
    isPersistent: boolean;
    persistentEndDateTime?: Date;
    performed: Date;

    constructor(init: Omit<AddShiftNote, 'noteType'>) {
        super({ ...init, noteType: NoteType.Shift })
        this.locationId = init.locationId;
        this.copiedFromId = init.copiedFromId;
        this.isCritical = init.isCritical;
        this.isPersistent = init.isPersistent;
        this.performed = init.performed;
        this.persistentEndDateTime = init.persistentEndDateTime;
    }
}

export class AddRotationNote extends NoteAddModelBase {
    title: string;
    rotationId: string;
    copiedFromId: number;
    isCritical: boolean;

    constructor(init: Omit<AddRotationNote, 'noteType'>) {
        super({ ...init, noteType: NoteType.Rotation })
        this.title = init.title;
        this.rotationId = init.rotationId;
        this.copiedFromId = init.copiedFromId;
        this.isCritical = init.isCritical;
    }
}

/*Used when receiving note from API*/
export class SingleNote {
    title: string;
    content: string;
    id: string;
    sourceNoteId?: string;
    isCritical: boolean;
    isArchived?: boolean;
    isPersistent: boolean;
    persistentBeginDateTime: Date;
    persistentEndDateTime: Date;
    lastUpdatedDateTime: Date;
    locationId: string;
    createdDateTime: Date;
    userProfile: UserProfile;
    shiftSchedulePeriod?: ShiftSchedulePeriod;
    tags: Tag[];
    attachments: Attachment[];
    canEdit: boolean;
    canTag: boolean;
    shiftSchedule?: ShiftSchedule;
    performed: Date;
    performedUTC: Date;
    noteType: NoteType;
    rotationId: string;
}

const defaultSingleNote: SingleNote = {
    title: "",
    content: "defaultSingleNote",
    id: "00000000-0000-0000-0000-000000000000",
    isCritical: false,
    isPersistent: false,
    persistentBeginDateTime: null,
    persistentEndDateTime: null,
    lastUpdatedDateTime: new Date(),
    locationId: '',
    createdDateTime: new Date(new Date().setHours(new Date().getHours() - 4)), // created 4 hrs ago
    userProfile: defaultUser0,
    tags: [],
    attachments: [],
    canEdit: true,
    canTag: true,
    performed: null,
    performedUTC: null,
    noteType: NoteType.Shift,
    rotationId: ''
}

export enum ShiftSchedulePeriod {
    None = 0,
    Day = 1,
    Night = 2
}

export class NotePayload {
    modalVisibility: boolean;
    noteType: NoteType;
    tag: Tag;
}

export class LocationData {
    location: {
        id: string;
        name: string;
    };
    notes: SingleNote[];
}

export const defaultLocation: LocationData = {
    location: {
        id: "00000000-0000-0000-0000-000000000000",
        name: "RU"
    },
    notes: [defaultSingleNote, defaultSingleNote]
}

export class ShiftNote {
    jobClassShortName: string;
    onShiftUserNames: string[];
    roleName: string;
    locationNotesList: LocationData[];
    shiftLastUpdatedDateTime: string;
    shiftStatus: number;
    shiftScheduleDate: string;
    shiftSchedulePeriod: string;
    beginDateTime?: Date;
    endDateTime?: Date;
    // locationId?: string;
    roleId?: string;
}

export class SectionTagShiftNote {
    isCritical: boolean;
    shiftNote: ShiftNote;
    tag: Tag;
}

export class SectionTagRotationNote {
    isCritical: boolean;
    rotationNote: RotationNote;
    tag: Tag;
}

export class RotationNote {
    roleId?: string;
    roleName: string;
    jobClassShortName: string;
    rotationNotes: RotationNotesData[];
    rotationStartDate: Date;
    rotationEndDate: Date;
}

export class RotationNotesData {
    notes: SingleNote[];
}

export const defaultRotationNote0: RotationNote = {
    jobClassShortName: "POS",
    roleName: "RU",
    rotationNotes: [],
    rotationStartDate: new Date(),
    rotationEndDate: new Date(),
}

export const defaultRotationNote1: RotationNote = {
    jobClassShortName: "FO",
    roleName: "RU",
    rotationNotes: [],
    rotationStartDate: new Date(),
    rotationEndDate: new Date(),
    //the following are not used
}

export class RoleSummary {
    beginDateTime?: Date;
    endDateTime?: Date;
    id: string;
    fullRoleName: string;
    jobClassShortName: string;
    locationNotesList?: LocationData[]
}

export class ShiftScheduleInfo {
    beginTime: string;
    durationHours: number;
    graceMinutes: number;
    order: number;
    period: ShiftSchedulePeriod;
}

export class MyShiftNote {
    followedNotes: ShiftNote[]
}

//sample data for myshift and all notes - interface ShiftNote

export const noteDataSample = {
    //heading: '',//role name 
    jobClassShortName: '',
    onShiftUserNames: [''],
    roleName: '',
    shiftLastUpdatedDateTime: '',
    shiftStatus: 0,
    // heading: string;
    roleNotesList:
        //Facility Name (collapsible headers)
        // list of all location with related notes for the specific role -interface LocationNote[]
        [
            {
                role: {
                    "id": "f020bb33-0894-4b56-8966-957a5c831d43",
                    "name": "Area1"
                },
                notes:  // array of all notes for a specific location -interface SingleNote[]
                    [
                        {
                            "id": "",
                            "content": "",
                            "isCritical": false,
                            "isPersistent": false,
                            "note.persistentBeginDateTime": new Date(),
                            "note.persistentEndDateTime": new Date(),
                            "lastUpdatedDateTime": new Date(),
                            "createdDateTime": new Date(),
                            "userProfile": {
                                "id": "",
                                "firstName": "",
                                "lastName": "",
                                "groupRoles": "",
                                "created": new Date(),
                                "isAuthorized": false
                            },
                            "tags": [
                                {
                                    "id": "120e4f35-a1bf-41ec-9b51-578132d6670c",
                                    "name": "tag1",
                                    "tagType": 0
                                }
                            ]
                        }
                    ]
            }
        ]
}
