import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './SelectRole.scss';
import FilterPanel from 'src/components/FilterPanel';
import RoleDataTable from '../../components/RoleDataTable';
import refreshImg from 'src/assets/images/Refresh.svg';
import { useSelectRoleActionCreators, useHubActionCreators } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypedselector';

import { PageType } from 'src/enums/PageType';
import { FiSliders, FiChevronUp, FiChevronDown } from "react-icons/fi";
import { NoteType } from 'src/enums/NoteTypes';

const SelectRole = () => {
    const AUTO_REFRESH_INTERVAL_MINS = 60 * 15000;
    const { getRoleData, setRoleData, setIsFilterPanelEnabled } = useSelectRoleActionCreators();
    const hasFavoriteToggle = useTypedSelector(state => state.selectRoleReducer.isFavoriteToggle);
    const isFilterPanelHidden = useTypedSelector(state => state.selectRoleReducer.isFilterPanelEnabled);
    const lastSynced = useTypedSelector(state => state.selectRoleReducer.lastSynced);
    const allFilters = useTypedSelector(state => state.filterReducer.allFilters);

    const toggleSelectRoleModalVisibility = useTypedSelector(state => state.selectRoleReducer.showSelectRoleModal);
    const loading = useTypedSelector(state => state.selectRoleReducer.loading);

    const [loadCount, setLoadCount] = useState<number>(0)

    // OnClick handle for FilterPanel Toggle Image
    const onClickFilterToggleImage = () => {
        setIsFilterPanelEnabled(!isFilterPanelHidden);
    };

    const refreshRoleData = (abortSignal: AbortSignal) => {
        getRoleData(hasFavoriteToggle, abortSignal);
    }

    useEffect(() => {
        setLoadCount(loadCount + 1)
    }, [allFilters?.selectedRoleIds, hasFavoriteToggle, toggleSelectRoleModalVisibility]);

    useEffect(() => {
        if (loadCount) {
            const abortController = new AbortController();
            const signal = abortController.signal;

            refreshRoleData(signal);

            const refreshStatusTimerId = setInterval(() => {
                refreshRoleData(signal);
            }, AUTO_REFRESH_INTERVAL_MINS);

            return () => {
                abortController.abort();
                setRoleData([]);
                // Stop the refreshStatuses timer
                clearInterval(refreshStatusTimerId);
            };
        }
    }, [loadCount])

    return (
        <>
            {/*Action Bar: Filter Button, Refresh button*/}
            <Row className="no-gutters">
                <div className="d-flex p-0 min-height-actionbar align-items-center">
                    <div className="me-auto">
                        <button
                            className="btn btn-secondary icon border-0 p-1 filter-toggle-btn"
                            type="button"
                            onClick={onClickFilterToggleImage}
                            aria-label="filter toggle"
                            data-cy="toggleFilterPanelBtn">
                            <FiSliders />
                            {isFilterPanelHidden ? <FiChevronUp /> : <FiChevronDown />}
                        </button>
                    </div>
                    {/*Last Synced*/}
                    <div className="px-2 small color-dark-gray pe-0">
                        last synced: {lastSynced}
                    </div>
                    {/*Refresh Button*/}
                    <div>
                        <button className="btn button-text"
                            onClick={() => setLoadCount(loadCount + 1)}
                            disabled={loading}>
                            refresh
                            <img src={refreshImg} className='ml-1' />
                        </button>
                    </div>
                </div>
            </Row>

            {/*Filter and Role Data table*/}
            <Row className="no-gutters mt-3">
                {/*Filter Panel*/}
                <Col
                    className={isFilterPanelHidden ? 'd-none' : 'mr-3 bg-white shadow-sm'} sm={6} md={4} lg={3}>
                    <FilterPanel filterFromPage={PageType.SelectRolePage} noteType={NoteType.Shift} />
                </Col>
                {/*Data Table*/}
                <Col className=" bg-white shadow-sm">
                    <RoleDataTable />
                </Col>
            </Row>
        </>
    );
}

export default SelectRole;