import { SilentRequest } from "@azure/msal-browser";
import { jwtDecode } from 'jwt-decode';
import { msalInstance } from "src/index";
import { apiScope } from "src/auth-config";
import { Toaster } from "src/services/api/toaster.service";
import { UserProfile } from "src/models/AppProfile";

class AuthTokenHelper {
    
    getAuthUserProfile = async (): Promise<UserProfile> => {
        const activeAccount = msalInstance.getActiveAccount();
        const accounts = msalInstance.getAllAccounts();
    
        const tokenRequest: SilentRequest = {
            scopes: apiScope.scopes,
            account: activeAccount || accounts[0]
        };
    
        return msalInstance.acquireTokenSilent(tokenRequest as SilentRequest)
            .catch((err: any) => {
                Toaster.showError("acquireToken request failed");
                throw err;
            })
            .then(graphToken => jwtDecode(graphToken.accessToken))
            .catch((err: any) => {
                Toaster.showError("jwtDecode failed");
                throw err;
            })
            .then(decodedToken => {
                const userProfile: UserProfile =
                {
                    firstName: decodedToken['given_name'],
                    lastName: decodedToken['family_name'],
                    email: decodedToken['preferred_username'],
                    oId: decodedToken['oid'],
                    id: null,
                    groupRoles: null,
                    created: null,
                    isAuthorized:false,
                    userFacilities: null,
                };
                return userProfile;
            })
            .catch((err: any) => {
                throw err;
            })
    }
}

export default new AuthTokenHelper();