import "./Error.scss";

const Error = () => {  
    return (
        <div className="content align-items-center justify-content-center">
            <h1>An error occurred</h1>
        </div>
    );
}

export default Error;
