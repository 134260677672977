import { ActionType } from '../action-types';
import { HistoryAction } from './../actions/historyActions';

export interface HistoryState {
    historyNotesLoading: boolean,
    historyNotesSummary: any,
    historyNotesSummaryApiErr: string,
    selectedHistoryDates: Date[],
    selectedHistoryText: string
}

const initialState = {
    historyNotesLoading: false,
    historyNotesSummary: [],
    historyNotesSummaryApiErr: '',
    selectedHistoryDates: null,
    selectedHistoryText: ''
};

export const historyReducer = (
    state: HistoryState = initialState,
    action: HistoryAction
): HistoryState => {
    switch (action.type) {
        case ActionType.GET_ALL_HISTORY_NOTES:
            return { ...state, historyNotesLoading: true };
        case ActionType.GET_ALL_HISTORY_SUCCESS:
            return { ...state, historyNotesLoading: false, historyNotesSummary: action.payload };
        case ActionType.GET_ALL_HISTORY_FAILURE:
            return { ...state, historyNotesLoading: false, historyNotesSummaryApiErr: action.payload, historyNotesSummary: [] };
        case ActionType.SET_HISTORY_DATE_FILTERS:
            return { ...state, selectedHistoryDates: action.payload }
        case ActionType.SET_HISTORY_TEXT_FILTER:
            return { ...state, selectedHistoryText: action.payload }
        default:
            return state;
    }
}
