import React, { useState, useRef, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Menu } from 'primereact/menu';
import { Report as ReportModel } from "src/models/Report";
import "./ReportCard.scss";
import { Days } from 'src/enums/Days';
import ReportService from 'src/services/api/report.service';
import { useTypedSelector } from 'src/hooks/useTypedselector';
import ReportModal from '../ReportModal';
import { DateTimeHelper } from 'src/helpers/DateTimeHelper';

interface ReportCardProps {
  report: ReportModel;
  severity: string;
  updateReports: () => void;
}

const ReportCard: React.FC<ReportCardProps> = ({ report, severity, updateReports }) => {
  const roleSettings = useTypedSelector(state => state.appProfileReducer.roleSettings.roleSettingsDetail);
  const menuEdit = 'Edit'
  const menuDuplicate = 'Duplicate'
  const menuDelete = 'Delete'

  const menu = React.useRef(null);
  const [active, setActive] = useState(true);
  const reportService = new ReportService()
  const [showReportModal, setShowReportModal] = useState(false);

  const formatFrequency = (frequency: Days) => {
    const days: string[] = [];

    if (frequency & Days.Sunday) days.push("Sunday");
    if (frequency & Days.Monday) days.push("Monday");
    if (frequency & Days.Tuesday) days.push("Tuesday");
    if (frequency & Days.Wednesday) days.push("Wednesday");
    if (frequency & Days.Thursday) days.push("Thursday");
    if (frequency & Days.Friday) days.push("Friday");
    if (frequency & Days.Saturday) days.push("Saturday");

    return days.join(", ");
  }
  
  const handleEdit = () => { 
    setShowReportModal(true)
  };
  
  const handleDuplicate = async () => {     
    const request = {...report}
  
    if (await reportService.duplicateReport(request)) {
      updateReports()
    }
  };
  const handleDelete = async () => {
    if (await reportService.deleteReport(report)) {
      updateReports()
    }
  };
  const items = [
    { label: menuEdit, icon: 'pi pi-fw pi-pencil', command: () => handleEdit() },
    { label: menuDuplicate, icon: 'pi pi-fw pi-clone', command: () => handleDuplicate() },
    { label: menuDelete, icon: 'pi pi-fw pi-trash', command: () => handleDelete() },
  ];
  const changeReportActive = async (e) => {
    const response = await reportService.updateReportActive(report)
    if (response) {
      setActive(!active)
    }
  };

  useEffect(() => {
    setActive(report.isActive)
  }, [report]);
  
  return (
    <div className="report-card p-card mb-4">
      <div className="d-flex justify-content-between align-items-center m-3 pt-3">
        <h4>
          <b>
            {report.reportName}
          </b>
        </h4>
        <div className='d-flex'>
          <Form.Switch
            id="chkToggleFavorites"
            checked={active} 
            onChange={(e) => changeReportActive(e) }
          />
          
          <i className='pi pi-ellipsis-h ml-3 report-ellipsis' onClick={(event) => menu.current.toggle(event)}></i>
          <Menu model={items} popup ref={menu} id="popup_menu" />

          <ReportModal
            showModal={showReportModal}
            toggleShowModal={() => setShowReportModal(!showReportModal)}
            report={report}
            updateReports={updateReports}
          />
        </div>
      </div>
      <div className="m-3 report-card-info">
        <strong>Roles:</strong> { report?.config?.filters?.roles?.length >0 ? report?.config?.filters?.roles?.map(role => role.name).join(', ') : 'All' } &nbsp;&nbsp;
        <p>
          <strong>Tags:</strong> {  report?.config?.filters?.roleTags?.length > 0 ? report?.config?.filters?.roleTags?.map(tag => tag.name).join(', ') : 'All' } &nbsp;&nbsp;
          <strong>Location:</strong> { report?.config?.filters?.locations?.length > 0 ? report?.config?.filters?.locations?.map(location => location.name).join(', ') : 'All' } &nbsp;&nbsp;
        </p>
      </div>
      
      <div className="m-3 report-card-info">
        <strong>Report data:</strong> { roleSettings?.reportConfig.reportScheduleFrequency[report?.config?.schedule - 1]?.value } &nbsp;&nbsp;
        <strong>Days:</strong> { formatFrequency(report.dailyFrequency) } &nbsp;&nbsp;
        <strong>Execution Time:</strong> { DateTimeHelper.toTimeString(new Date(report.executionTime)) }
      </div>
      <div className="m-3 pb-3 report-card-info">
        <strong>Recipients: </strong>
        {report.reportRecipients.map(s=> s.email).join(', ')}
      </div>
    </div>
  );
};

export default ReportCard;