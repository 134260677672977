import { Calendar, CalendarChangeParams } from "primereact/calendar";
import { forwardRef, useRef } from "react";
import "./HistoryCalendar.scss";
import { Toaster } from "src/services/api/toaster.service";
import { Tooltip as TooltipPR } from 'primereact/tooltip';

interface HistoryCalendarProps {
    selectedDateFilter: Date[] | undefined,
    setSelectedDateFilter: any,
    maxDayRangeSelection: number
}

/* eslint-disable react/display-name */
const HistoryCalendar = forwardRef<Calendar, HistoryCalendarProps>((props, ref) => {
    const maxDate = new Date();
    const historyCalRef = useRef(ref);

    const closeCalendarDatepicker = () => {
        if (historyCalRef != null && historyCalRef.current != null) {
            const calRef: any = historyCalRef.current;
            calRef.current.hide()
        }
    }

    const selectedDateTemplate = (date: any) => {
        const selectedDates = props.selectedDateFilter;

        if (selectedDates != null) {
            for (let index = 0; index < selectedDates.length; index++) {
                const selectedDate: any = selectedDates[index];

                if (selectedDate == null) break;

                if (date.day === selectedDate.getDate() &&
                    date.month === selectedDate.getMonth() &&
                    date.year === selectedDate.getFullYear()) {
                    return (
                        <span className="p-highlight-selected">{date.day}</span>
                    );
                }
            }
        }

        return date.day;
    }

    const validateDate = (date: string) => {
        const dateRegExp = /^(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/]\d\d$/; //regular expresion for mm/dd/yy
        const selectedDate = date.trim();

        if (dateRegExp.test(selectedDate)) {

            const isValidDate = Date.parse(selectedDate);

            if (isValidDate) {
                return new Date(selectedDate);
            }
        }

        return null;
    }

    const maxDateRangeValidation = (selectedDate: Date[] | undefined) => {
        const millisecondsPerDay: number = (1000 * 60 * 60 * 24);
        const startDate: Date = selectedDate[0];
        const endDate: Date = selectedDate[1];

        const utc1 = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        const utc2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

        const rangeDifference: number = Math.floor((utc2 - utc1) / millisecondsPerDay);

        if (rangeDifference > props.maxDayRangeSelection) {
            Toaster.showError(`The specified date range exceeded the maximum range of ${props.maxDayRangeSelection} days. Please select a new date range.`);
            return false;
        }

        return true;
    }

    const validateFutureDate = (selectedDates: Date[] | undefined) => {

        if (selectedDates != null) {
            for (let index = 0; index < selectedDates.length; index++) {
                const selectedDate: any = selectedDates[index];

                if (selectedDate == null) continue;

                if (selectedDate > maxDate) {
                    Toaster.showError(`The specified date(s) should not be greater than today's date.`);
                    return false;
                }
            }
        }

        return true;
    }

    const onInput = (event: any) => {
        const validatedDates: Date[] = [];
        const selectedDates: string = event.target.value;

        if (selectedDates?.length === 19) {
            const dates = selectedDates.split('-');
            let countOfValidDates = 0;

            dates.forEach(d => {
                const date = validateDate(d);

                if (date != null) {
                    countOfValidDates += 1;
                    validatedDates.push(date);
                } else {
                    Toaster.showError(`The specified date range is not valid. Please enter a valid date range respecting the following format mm/dd/yy - mm/dd/yy.`);
                }
            });

            if (countOfValidDates === 2) {
                if (!validateFutureDate(validatedDates)) return;
                if (!maxDateRangeValidation(validatedDates)) return;

                props.setSelectedDateFilter(validatedDates);
                closeCalendarDatepicker();
            }
        }

        if (selectedDates?.length === 8) {
            const date = validateDate(selectedDates);

            if (date != null) {
                validatedDates.push(date);

                if (!validateFutureDate(validatedDates)) return;

                props.setSelectedDateFilter(validatedDates);
                closeCalendarDatepicker();
            } else {
                Toaster.showError(`The specified date is not valid, Please enter a valid date.`);
            }

        }
    }

    const onChange = (event: CalendarChangeParams) => {
        if (event.value != null) {

            if (typeof event.value == 'string') {
                return;
            }

            let selectedDates: Date[] = event.value as Date[];

            if (!validateFutureDate(selectedDates)) return;

            if (selectedDates?.length === 2 && selectedDates.every(x => x != null)) {
                if (!maxDateRangeValidation(selectedDates)) return;

                if (selectedDates[0].getTime() === selectedDates[1].getTime()) {
                    selectedDates = selectedDates.splice(-1)
                    closeCalendarDatepicker();
                }
            }

            props.setSelectedDateFilter(selectedDates);
        } else {
            props.setSelectedDateFilter(null);
            closeCalendarDatepicker();
        }
    }

    {/*Date Range Picker*/ }
    return <div className="date d-flex w-100">
        <div className="d-flex align-items-center">
            <TooltipPR target=".p-date-title" content="shift start-time date range" position="top" />
            <div className="pr-2 p-date-title">date range</div>
        </div>

        <div className="d-flex w-100">
            <Calendar
                className="w-100"
                value={props.selectedDateFilter}
                onChange={onChange}
                onInput={onInput}
                selectionMode='range'
                placeholder='mm/dd/yy - mm/dd/yy'
                keepInvalid={true}
                dateFormat="mm/dd/y"
                maxDate={maxDate}
                showIcon={true}
                inputClassName="py-1"
                yearRange="2021:2030"
                showButtonBar={true}
                dateTemplate={selectedDateTemplate}
                ref={ref}
            />
        </div>

    </div>
});

export default HistoryCalendar;
