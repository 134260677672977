import React from 'react';
import ReactDOMClient from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import "regenerator-runtime/runtime"
import { PublicClientApplication, InteractionType, SilentRequest } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { msalConfig } from "src/auth-config.js";
import App from 'src/pages/App';
import Error from 'src/components/Error';
import Loading from 'src/components/Loading';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'src/styles/NextPage.min.css'
import 'src/index.scss';
import { Provider } from 'react-redux';
import { store } from './store';

export const msalInstance = new PublicClientApplication(msalConfig);

const isIFrame = (): boolean => { return window !== window.parent && !window.opener; }

const authRequest: SilentRequest = {
    scopes: ["openid", "profile"]
};

const interactionType: InteractionType = isIFrame() ? InteractionType.Silent
    : InteractionType.Redirect;

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

root.render(
    <Provider store={store}>
        <React.StrictMode>
            <HashRouter>
                <MsalProvider instance={msalInstance}>
                    <MsalAuthenticationTemplate
                        interactionType={interactionType}
                        authenticationRequest={authRequest}
                        errorComponent={Error}
                        loadingComponent={Loading}>
                        <App />
                    </MsalAuthenticationTemplate>
                </MsalProvider>
            </HashRouter>
        </React.StrictMode>
    </Provider>);
