import { useEffect, useState } from "react";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import "./MyShiftNotes.scss";
import ShiftNotes from "../ShiftNotes";
import { NoteTab } from 'src/enums/NoteTypes';
import { useMyShiftActionCreators } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypedselector';
import { MdOutlineInfo } from "react-icons/md";

interface MyShiftProps {
    roleId: string
}

// eslint-disable-next-line react/display-name
const MyShiftNotes = (props: MyShiftProps) => {

    const collapseAll = useTypedSelector(state => state.myShiftReducer.collapseAllNotes);
    const myPublishedNotes = useTypedSelector(state => state.myShiftReducer.currentShiftNotes);
    const followedNoteData = useTypedSelector(state => state.myShiftReducer.followedNotes);
    const followedNoteDataLoading = useTypedSelector(state => state.myShiftReducer.followedNotesLoading);
    const isGracePeriod = useTypedSelector(state => state.appProfileReducer.currentUserShift.gracePeriod.isInGracePeriod);
    const myPublishedNotesLoading = useTypedSelector(state => state.myShiftReducer.currentShiftNotesLoading);
    const collapsedFromMyNotes = useTypedSelector(state => state.myShiftReducer.collapseMyNotes);

    const { getCurrentShiftNotes, getFollowedNotes, setMyShiftCollapseFlag, clearCurrentShiftNotes, clearFollowedNotes } = useMyShiftActionCreators();
    const [collapsedFollowedNotes, setCollapsedFollowedNotes] = useState(true);

    const styleDef = "d-flex justify-content-between align-items-center bold medium gotham-bold color-dark-blue cursor-pointer rounded-top bg-lighter-gray px-3 py-2";

    useEffect(() => {
        getCurrentShiftNotes(props.roleId, isGracePeriod);
        getFollowedNotes(props.roleId);

        return (() => {
            clearCurrentShiftNotes();
            clearFollowedNotes();
        });
    }, []);

    const handleFollowedNotes = (bool: boolean) => {
        setCollapsedFollowedNotes(bool)// Toggle accordion 
        if (collapseAll)
            setMyShiftCollapseFlag(NoteTab.AllNotes, false);

        if (collapsedFromMyNotes)
            setMyShiftCollapseFlag(NoteTab.MyNotes, false);
    };

    /* handle accordian on click of collapse all*/
    useEffect(() => {
        if (collapsedFromMyNotes && !collapsedFollowedNotes)
            setCollapsedFollowedNotes(true)
    }, [collapsedFromMyNotes])

    return (
        <div className="d-flex-column gap-2">
            {/*Grace Period Info */}
            {isGracePeriod &&
                <div className="bg-highlight-warning-message p-3 mb-1">
                    <MdOutlineInfo className="mr-2" />
                    <strong>Your shift has ended, and you are now in the <i>grace period</i></strong>. During this time, you can add or modify existing notes. After the grace period ends, you will no longer be able to edit notes.
                </div>
            }
            {/* passing collapsed props down to ShiftNote component */}
            {/*My Shift Notes*/}
            <div className="d-flex-column shadow-sm rounded-bottom">
                <ShiftNotes noteTab={NoteTab.MyNotes} noteData={myPublishedNotes} loading={myPublishedNotesLoading} />
            </div>
            {/*Followed Notes*/}
            <div className="d-flex-column shadow-sm rounded-bottom">
                <div className={styleDef} aria-label={collapsedFollowedNotes ? 'collapse panel' : 'expand panel'} onClick={() => handleFollowedNotes(!collapsedFollowedNotes)}>
                    <div>
                        Followed notes
                    </div>

                    <div className="shift-icon-plus d-flex align-items-center">
                        <span className={collapsedFollowedNotes ? 'pi pi-plus' : 'pi pi-minus'}></span>
                    </div>
                </div>

                <div className="bg-lightest-gray">
                    {!collapsedFollowedNotes && followedNoteData && followedNoteData?.length > 0 &&
                        <div className="d-flex-column gap-3 p-4">
                            {
                                followedNoteData.map((followedNote, index) => {
                                    return <ShiftNotes key={index} noteTab={NoteTab.FollowedNotes} noteData={followedNote} loading={followedNoteDataLoading} />
                                })
                            }
                        </div>
                    }
                    {!collapsedFollowedNotes && followedNoteData?.length === 0 &&
                        <div className="normal px-4 py-2">
                            You have no followed notes.
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default MyShiftNotes;
