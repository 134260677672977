import { ca } from 'date-fns/locale';
import { ActionType } from 'src/enums/ActionType';
import { NoteNotificationMessage } from 'src/models/NoteNotificationMessage';
import { Toaster } from 'src/services/api/toaster.service';

export class NotificationHelper {

    static showGracePeriodNotification(message: NoteNotificationMessage) {
        let actionType = "";

        switch(message.actionType){
            case ActionType.Create:
                actionType = "added";
                break;
            case ActionType.Edit:
                actionType = "modified";
                break;
            case ActionType.Delete:
                actionType = "deleted";
                break;
        }

        const messageContent = `${message.userName} has ${actionType} a note on the previous shift during the grace period. Please check the notification belt for more details.`;
        Toaster.showInfo(messageContent);
    }
}