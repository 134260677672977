import { Navigate, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';

import './App.scss';
import SelectRole from 'src/pages/SelectRole';
import MyShift from 'src/pages/MyShift';
import History from 'src/pages/History';
import Missing from 'src/pages/Missing';
import UserService from 'src/services/api/user.service';
import Unauthorized from 'src/pages/Unauthorized';
import { SkeletonTheme } from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";

import { msalInstance } from 'src';
import { AuthenticatedTemplate } from "@azure/msal-react";
import { EventMessage, EventType } from '@azure/msal-browser';
import { AccessType } from 'src/enums/AccessType';
import { useAppProfileActionCreators, useHubActionCreators, useMyShiftActionCreators, useNotificationActionCreators } from 'src/hooks/useActions';

import AdminUserAccess from '../AdminUserAccess';
import ManageUserAccess from 'src/components/ManageUserAccess/ManageUserAccess';
import { useTypedSelector } from 'src/hooks/useTypedselector';
import { PermissionHelper } from 'src/helpers/PermissionHelper';
import Report from '../Report';
import DefaultLayout from '../Layouts/DefaultLayout';
import PrintLayout from '../Layouts/PrintLayout';
import Loading from 'src/components/Loading';
import ViewReport from '../ViewReport';
import SignalRHubInterceptor from 'src/interceptors/signalr.hub_interceptor';
import { NotificationHelper } from 'src/helpers/NotificationHelper';
import GracePeriodHubConstants from 'src/constants/grace-period-hub-constants';
import { Permission } from 'src/enums/Permission';
import { NoteNotificationMessage } from 'src/models/NoteNotificationMessage';

function App() {
    const [loading, setLoading] = useState(true);
    const [unauthorizedUser, setUnauthorizedUser] = useState(false);
    const [authorizationType, setAuthorizationType] = useState(AccessType.Unauthorized);

    const userProfileState = useTypedSelector(state => state.appProfileReducer.userProfileState);
    const roleSettings = useTypedSelector(state => state.appProfileReducer.roleSettings.roleSettingsDetail);
    const hubConnections = useTypedSelector(state => state.hubReducer.connections);
    const {addHubConnection} = useHubActionCreators();
    
    const { getRoleSettings, getUserProfile, getCurrentShift, getShiftSchedule } = useAppProfileActionCreators();
    const { getSectionList } = useMyShiftActionCreators();

    const { getUserNotifications } = useNotificationActionCreators();
    
    const setSignalRNotificationInterceptorHandlers = () => {
        const signalRHubInterceptor : SignalRHubInterceptor = hubConnections.get(GracePeriodHubConstants.gracePeriodHubName);
                    
        if(signalRHubInterceptor === undefined) return;

        signalRHubInterceptor.recieve(GracePeriodHubConstants.gracePeriodHubEvents.gracePeriodNoteNotification, (message: NoteNotificationMessage) => {
            getUserNotifications();
            NotificationHelper.showGracePeriodNotification(message);
        });
    }

    useEffect(() => {
        const msalInstanceCallbackId = msalInstance.addEventCallback((message: EventMessage) => {
            if (message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
            }
        });

        // This method will validate authorized user
        const userSvc = new UserService();
        setLoading(true);
        userSvc.validateAuthorizedUser().then((accessType: AccessType) => {
            setLoading(false);
            switch (accessType) {
                case AccessType.Authorized:
                    getUserProfile();
                    getRoleSettings();
                    getShiftSchedule();
                    getCurrentShift();
                    getSectionList();
                    getUserNotifications();

                    addHubConnection(GracePeriodHubConstants.gracePeriodHubName);
                    setSignalRNotificationInterceptorHandlers();

                    break;
                case AccessType.PendingAuthorization:
                    setUnauthorizedUser(true);
                    setAuthorizationType(accessType);
                    break;
                default:
                    setUnauthorizedUser(true);
                    setAuthorizationType(accessType);
                    break;
            }
        }).catch((error) => {
            setLoading(false);
            setUnauthorizedUser(true);

            return () => {
                if (msalInstanceCallbackId) {
                    msalInstance.removeEventCallback(msalInstanceCallbackId);
                }
            }
        })

    }, []);

    return (
        <div className="app">
            <SkeletonTheme>
                {loading || userProfileState.isLoading &&
                    <Loading />
                }

                {!loading && !userProfileState.isLoading && unauthorizedUser && <AuthenticatedTemplate>
                    <main className="content ">
                        <Unauthorized authorizationType={authorizationType} />
                    </main>
                </AuthenticatedTemplate>
                }

                {!loading && !userProfileState.isLoading && !unauthorizedUser && <AuthenticatedTemplate>

                    <Routes>
                        <Route element={<DefaultLayout />} >
                            <Route path="/myshift" element={<MyShift />} />
                            <Route path="/history" element={<History />} />
                            <Route path="/selectrole" element={<SelectRole />} />
                            <Route path="/report" element={
                                roleSettings?.isScheduleReportEnabled
                                    ? <Report />
                                    : <Unauthorized authorizationType={AccessType.Unauthorized} />
                            } />
                            {/* <Route path="/report/:reportType" element={<Report />} /> */}
                            <Route path="/adminuseraccess" element={
                                PermissionHelper.hasPermission(userProfileState.userProfile, Permission.Admin)
                                    ? <AdminUserAccess />
                                    : <Unauthorized authorizationType={AccessType.Unauthorized} />
                            } />
                            <Route path="/manageuseraccess" element={
                                PermissionHelper.hasPermission(userProfileState.userProfile, Permission.Admin)
                                    ? <ManageUserAccess />
                                    : <Unauthorized authorizationType={AccessType.Unauthorized} />
                            } />
                            <Route path="*" element={<Missing />} />
                            <Route path="/" element={
                                <Navigate replace to="/selectrole" />
                            } />
                        </Route>
                        <Route element={<PrintLayout />} >
                            <Route path="/report/:reportType" element={<Report />} />
                            <Route path="/viewreport/:reportType" element={<ViewReport />} />
                        </Route>
                    </Routes>


                </AuthenticatedTemplate>
                }
            </SkeletonTheme>
        </div>
    );
}

export default App;