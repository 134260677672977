// NOTE: This component is currently used only for display purpose and no interactions (like close/delete button) implemented

interface TagChipProps {
    content: string
}

// eslint-disable-next-line react/display-name
const TagChip = (props: TagChipProps) => {
    return <span className="noteItemFace bg-green gotham-narrow-book color-white rounded p-1">{props.content}</span>
}

export default TagChip;
