import { Dispatch } from 'redux';
import { ActionType } from '../action-types';
import { HistoryAction } from './../actions/historyActions';
import ShiftService from 'src/services/api/shift.service';
import RotationService from 'src/services/api/rotations.service';

const myShiftService = new ShiftService();
const myRotationService = new RotationService


export const getHistoryNotes = (searchText: string, from: string, to: string, getRotationNotes: boolean, isCriticalSelected: boolean) => {
    return async (dispatch: Dispatch<HistoryAction>) => {
        dispatch({
            type: ActionType.GET_ALL_HISTORY_NOTES,
        });
        try {
            const allroles = [];
            if (!getRotationNotes) {
                await myShiftService.getHistoryNotes(searchText, from, to, isCriticalSelected).then((data) => {
                    if (data?.length > 0) {
                        data.forEach(x => {
                            allroles.push({
                                beginDateTime: x.beginDateTime,
                                endDateTime: x.endDateTime,
                                jobClassShortName: x.jobClassShortName,
                                roleName: x.fullRoleName,
                                roleId: x.id,
                                locationNotesList: x.locationNotesList
                            });
                        });
                    }
                });
            }
            else {
                await myRotationService.getHistoryNotes(searchText, from, to).then((data) => {
                    if (data?.length > 0) {
                        data.forEach(x => {
                            allroles.push({
                                rotationStartDate: x.rotationStartDate,
                                rotationEndDate: x.rotationEndDate,
                                jobClassShortName: x.jobClassShortName,
                                roleName: x.roleName,
                                roleId: x.roleId,
                                rotationNotes: x.rotationNotes
                            });
                        });
                    }
                });
            }
            dispatch({
                type: ActionType.GET_ALL_HISTORY_SUCCESS,
                payload: allroles,
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            dispatch({
                type: ActionType.GET_ALL_HISTORY_FAILURE,
                payload: err.message,
            });
        }
    };
};

export const setSelectedHistoryDateFilters = (payload: Date[]) => {
    return (dispatch: Dispatch<HistoryAction>) => {
        dispatch({
            type: ActionType.SET_HISTORY_DATE_FILTERS,
            payload
        });
    }
}

export const setSelectedHistoryTextFilter = (payload: string) => {
    return (dispatch: Dispatch<HistoryAction>) => {
        dispatch({
            type: ActionType.SET_HISTORY_TEXT_FILTER,
            payload
        });
    }
}
