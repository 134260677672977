import "./NoteMetaDataDropDown.scss";
import { BsDownload, BsFillEyeFill, BsPaperclip } from "react-icons/bs";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { NoteTab } from "src/enums/NoteTypes";
import { useEffect, useState } from "react";
import { Attachment } from "src/models/Attachment";
import { NoteMetaDataType } from "src/enums/TagType";
import AttachmentPreviewModal from "../AttachmentPreviewModal";
import { BiPurchaseTag } from "react-icons/bi";
import TagChip from "../TagChip/TagChip";

interface NoteMetaDataDropDownProps {
    noteTab: NoteTab,
    items: any[],
    noteMetaDataType: NoteMetaDataType
}

// eslint-disable-next-line react/display-name
const NoteMetaDataDropDown = (props: NoteMetaDataDropDownProps) => {
    const [attachments, setAttachments] = useState<Attachment[]>([]);

    const IsMetaDaTpeAtt = (props.noteMetaDataType === NoteMetaDataType.Attachment);
    const IsMetaDaTpeRoleTag = (props.noteMetaDataType === NoteMetaDataType.RoleTag);

    const [attPreviewModal, setAttPreviewModal] = useState(false);
    const toggleAttPreviewModal = () => setAttPreviewModal(v => !v);
    const [activeAttId, setActiveAttId] = useState(0);

    const toggleShowAttachmentPreviewModal = (attId) => {
        setActiveAttId(attId);
        setAttPreviewModal(v => !v);
    };

    const toolTipsClassName = () => {
        if (props.noteTab === NoteTab.SelectRoleNotes || props.noteTab === NoteTab.SelectRoleRotationNotes || props.noteTab === NoteTab.SelectRoleAllRotationNotes || props.noteTab === NoteTab.HistoryNotesModal)
            return 'modal-tooltip'
        else
            return "tooltip";
    }

    useEffect(() => {
        const attArr: Attachment[] = [];

        if (IsMetaDaTpeAtt) {
            props.items?.forEach((item) => {
                const attachment = new Attachment();
                attachment.displayFileName = item.displayFileName;
                attachment.storageFileNameReference = item.storageFileNameReference;
                attachment.downloadURI = item.downloadURI;

                attArr.push(attachment);
            });

            setAttachments(attArr);
        }
    }, []);

    return (
        <div className="noteMetaDataDropDown">
            <AttachmentPreviewModal
                attachments={attachments}
                activeAttId={activeAttId}
                show={attPreviewModal}
                toggleShow={toggleAttPreviewModal} />

            {
                props.items.length > 0 &&
                <div className={`col-auto dropdown-container p-0 normal color-blue`}>
                    <div className="d-flex gap-1 align-items-center cursor-pointer">
                        {IsMetaDaTpeAtt &&
                            <><BsPaperclip /><span>({props.items.length})</span></>
                        }

                        {IsMetaDaTpeRoleTag &&
                            <><BiPurchaseTag /><span>({props.items.length})</span></>
                        }
                    </div>

                    {/*Context Menu Options, Dropdown Content*/}
                    <div className="dropdown-content shadow-sm border cursor-default">
                        {
                            props.items.map((it: any, i: number) => {
                                let itemTitle: string;
                                const attachment = new Attachment();

                                if (IsMetaDaTpeAtt) {
                                    itemTitle = it.displayFileName;

                                    attachment.displayFileName = it.displayFileName;
                                    attachment.storageFileNameReference = it.storageFileNameReference;
                                    attachment.downloadURI = it.downloadURI;
                                }
                                else if (IsMetaDaTpeRoleTag) {
                                    itemTitle = it;
                                }

                                return (
                                    <div key={i} className="d-flex justify-content-between align-items-center mx-1">
                                        {IsMetaDaTpeRoleTag &&
                                            <span className="my-1">
                                                <TagChip content={itemTitle} />
                                            </span>
                                        }

                                        {IsMetaDaTpeAtt &&
                                            <>
                                                <span>
                                                    {itemTitle}
                                                </span>

                                                <div>
                                                    <OverlayTrigger
                                                        overlay={
                                                            <Tooltip className={toolTipsClassName()}>
                                                                preview file
                                                            </Tooltip>
                                                        }>
                                                        <button
                                                            className="btn-sm btn-secondary border-0"
                                                            onClick={() => { toggleShowAttachmentPreviewModal(i) }}
                                                            type="button"
                                                            aria-label="preview file">
                                                            <BsFillEyeFill className="normal" />
                                                        </button>
                                                    </OverlayTrigger>

                                                    <OverlayTrigger
                                                        overlay={
                                                            <Tooltip className={toolTipsClassName()}>
                                                                download file
                                                            </Tooltip>
                                                        }>
                                                        <a href={attachment.downloadURI}
                                                            className="btn-sm btn-secondary border-0"
                                                            type="button"
                                                            aria-label="download file">
                                                            <BsDownload className="normal" />
                                                        </a>
                                                    </OverlayTrigger>
                                                </div>
                                            </>
                                        }
                                    </div>
                                );
                            })
                        }

                    </div>
                </div>
            }
        </div>
    )
}

export default NoteMetaDataDropDown;
