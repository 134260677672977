import Skeleton from "react-loading-skeleton";

const RotationHeaderSkeleton = () => {
    const SIZE_20 = 20, SIZE_100 = 100;

    return (
        <>
            <div className="d-flex flex-wrap justify-content-between">
                {/* Rotation BeginDate, EndDate */}
                <div className='mr-auto'>
                    <Skeleton width={SIZE_100} height={SIZE_20} />
                </div>

                {/* Interval Options */}
                <div className="dropdown-select-box">
                    <Skeleton width={SIZE_100} height={SIZE_20} />
                </div>
            </div>

            <div className="d-flex gap-3 flex-wrap pt-3">
                {/* LastUpdated */}
                <div className='d-flex-column'>
                    <Skeleton width={SIZE_100} height={SIZE_20} />
                </div>

                {/* On-Rotation User(s) */}
                <div className='d-flex-column'>
                    <Skeleton width={SIZE_100} height={SIZE_20} />
                </div>
            </div>
        </>
    );
}

export default RotationHeaderSkeleton;
