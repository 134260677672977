import './Footer.scss';

// Footer Component
const Footer = () => {
    return (
        <footer className="footer py-2">
            <ul className=''>
                <li>Last update: {new Date().toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</li>
                <li>&copy; 2023-2024 Chevron Corporation</li>
                <li>Company Confidential</li>
                <li>Facility Equipment & Operations</li>
                <li>Content contact: <a href="mailto:Pulmano, Virgilio">Pulmano, Virgilio</a></li>
                <li>Technical contact: <a href="https://go.chevron.com/appsupport">App Support Form</a></li>
            </ul>
        </footer>
    );
}

export default Footer;