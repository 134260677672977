import { APIService } from './api.service';
import { BlobFile } from 'src/models/BlobFile';

export default class AttachmentService extends APIService {
    private url = '/attachments';


    async add(file: BlobFile): Promise<string> {
        const config: any = { "headers": { "Content-Type": "multipart/form-data" } };

        const bodyFormData = new FormData();
        bodyFormData.append('noteId', file.noteId);
        bodyFormData.append('file', file.file);

        const response = await this.api.post(this.url, bodyFormData, config);

        return response.data;
    }

    async getAttachmentFile(path: string, fileName: string): Promise<any> {
        const response = await this.api.get(`${this.url}?noteId=${path}&fileName=${fileName}`, { responseType: 'blob' });

        return response;
    }

    async removeAttachmentFiles(path: string, fileName: string): Promise<any> {
        const response = await this.api.delete(`${this.url}?noteId=${path}&fileName=${fileName}`);
        return response;
    }

}


