import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { AdminAccessTab } from 'src/enums/AdminAccessTab';
import { useTypedSelector } from 'src/hooks/useTypedselector';
import './AdminUserAccess';
import UserAccount from 'src/components/UserAccount';
import { ManageUserAccessPageToast } from 'src/models/AppProfile';
import { useAppProfileActionCreators } from 'src/hooks/useActions';
import { Toaster } from 'src/services/api/toaster.service';

const AdminUserAccess = () => {

    const [activeTab, setActiveTab] = useState(AdminAccessTab.UserAccounts);

    const roleSettings = useTypedSelector(state => state.appProfileReducer.roleSettings);
    const rootRoleId = roleSettings.roleSettingsDetail?.rootRoleId;

    const showMUAPToast: ManageUserAccessPageToast = useTypedSelector(state => state.appProfileReducer.manageUserAccessPageToast);
    const { hideManageUserAccessPageToast } = useAppProfileActionCreators();

    useEffect(() => {
        if (showMUAPToast.show) {
            Toaster.showSuccess(showMUAPToast.content);

            // This will clear toast flag when reload of the page.
            hideManageUserAccessPageToast();
        }
    }, [showMUAPToast.show])

    return (
        <div className='admin-user-access'>
            <Row className="d-flex-column bg-white shadow-sm">
                <div className="d-flex align-items-baseline tabs">
                    <>
                        <div className={`col-auto p-2 action-bar-page-tab`}>
                            <button onClick={() => { setActiveTab(AdminAccessTab.UserAccounts) }}  >
                                user account
                            </button>
                        </div>
                        {/* <div className={`col-auto p-2 action-bar-page-tab`}>
                            <button onClick={()=> {setActiveTab(AdminAccessTab.RolesAndPermissions)}}>
                                roles & permission
                            </button>
                        </div> */}

                    </>
                </div>

                {/*Horizontal line under the tab buttons*/}
                <hr className="opacity-100" />

                <div className="no-gutters px-3 pb-3">
                    {/*User Account component*/}
                    {activeTab === AdminAccessTab.UserAccounts && rootRoleId &&
                        <>
                            <UserAccount rootRoleId={rootRoleId} />
                        </>
                    }
                    {/*Roles and Permisssios component*/}
                    {activeTab === AdminAccessTab.RolesAndPermissions &&
                        <>
                        </>
                    }
                </div>
            </Row>
        </div>
    )
}

export default AdminUserAccess;