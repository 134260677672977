import { ActionType } from '../action-types';
import { SelectRoleAction } from '../actions/selectRoleActions';
import { ProfileImage } from "../../models/ProfileImage";
import { SelectRole } from '../../models/SelectRole';

export interface SelectRoleState {
    showSelectRoleModal: boolean,
    roleData: SelectRole[],
    loading: boolean,
    error: string,
    isFavoriteToggle: boolean,
    isFilterPanelEnabled: boolean,
    lastSynced: string,
    filtersLoading: boolean,
    filtersError: string,
    selectedRoleFilters: string[],
    selectedRolesFiltersLoading: boolean,
    selectedRoleFiltersError: string,
    showAddShiftToast: boolean,
    profileImages: ProfileImage[]
}

const initialState = {
    showSelectRoleModal: false,
    roleData: [],
    loading: false,
    error: '',
    isFavoriteToggle: false,
    isFilterPanelEnabled: false,
    lastSynced: '',
    selectedRoleFilters: [],
    filtersLoading: false,
    filtersError: '',
    selectedRolesFiltersLoading: false,
    selectedRoleFiltersError: '',
    profileImages: [],
    showAddShiftToast: false
};

export const selectRoleReducer = (
    state: SelectRoleState = initialState,
    action: SelectRoleAction
): SelectRoleState => {
    switch (action.type) {
        case ActionType.SELECT_ROLE_MODAL_TOGGLE_VISIBILITY:
            return { ...state, showSelectRoleModal: !state.showSelectRoleModal }
        case ActionType.GET_ROLE_DATA:
            return { ...state, loading: true };
        case ActionType.GET_ROLE_DATA_SUCCESS:
            return { ...state, loading: false, lastSynced: new Date().toLocaleString(), roleData: action.payload };
        case ActionType.GET_ROLE_DATA_FAILURE:
            return { ...state, loading: false, error: action.payload, roleData: [] };
        case ActionType.SET_ROLE_DATA:
            return { ...state, roleData: action.payload }
        case ActionType.SET_IS_FAVOURITE_TOGGLE:
            return { ...state, isFavoriteToggle: action.payload }
        case ActionType.SET_IS_FILTER_PANEL_ENABLED:
            return { ...state, isFilterPanelEnabled: action.payload }
        case ActionType.SET_SHOW_ADD_SHIFT_TOAST:
            return { ...state, showAddShiftToast: action.payload };
        case ActionType.ADD_PROFILE_IMAGE:
            return {
                ...state,
                profileImages: [...state.profileImages, action.payload],
            };
        case ActionType.UPDATE_PROFILE_IMAGE:
            return {
                ...state,
                profileImages: state.profileImages.map((pi) => {
                    if (pi.oId === action.payload.oId) {
                        return {
                            ...pi,
                            image: action.payload.image,
                        };
                    } else return pi;
                }),
            };
        default:
            return state;
    }
}
