import { Dispatch } from 'redux';
import { ActionType } from '../action-types';
import { MyRotationAction } from '../actions/myRotationActions';
import MyRotationService from 'src/services/api/rotations.service';

const myRotationService = new MyRotationService();

export const getCurrentRotationNotes = (roleId: string, isGracePeriod: boolean) => {
    return async (dispatch: Dispatch<MyRotationAction>) => {
        dispatch({
            type: ActionType.GET_CURRENT_ROTATION_NOTES,
        });
        try {
            const currentRotationNotes = await myRotationService.getCurrentRotationNotes(roleId, isGracePeriod);

            dispatch({
                type: ActionType.GET_CURRENT_ROTATION_NOTES_SUCCESS,
                payload: currentRotationNotes,
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            dispatch({
                type: ActionType.GET_CURRENT_ROTATION_NOTES_FAILURE,
                payload: err.message,
            });
        }
    };
};

export const clearCurrentRotationNotes = () => {
    return async (dispatch: Dispatch<MyRotationAction>) => {
        dispatch({
            type: ActionType.CLEAR_CURRENT_ROTATION_NOTES,
        });
    };
};

export const getPreviousNotesRotation = (roleId) => {
  return async (dispatch: Dispatch<MyRotationAction>) => {
    dispatch({
      type: ActionType.GET_PREVIOUS_NOTES_ROTATION
    });
    try {
      await myRotationService.getRotationNotes(roleId, 28).then((data) => {
        if (data && data.length >= 1){
            dispatch({
              type: ActionType.GET_PREVIOUS_NOTES_ROTATION_SUCCESS,
              payload: data
            })
        }
        else {
          dispatch({
            type: ActionType.GET_PREVIOUS_NOTES_ROTATION_ERROR,
            payload: ''
          })
        }
      }).catch((error) => {
        console.error(error);
      })
    } catch(err: any) {
      dispatch({
        type: ActionType.GET_CURRENT_ROTATION_NOTES_FAILURE,
        payload: err.message
      })
    }
  };
};