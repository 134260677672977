import { useState, useEffect } from 'react';
import './MyShift.scss';
import MyShiftNotes from 'src/components/MyShiftNotes';
import MyRotationNotes from 'src/components/MyRotationNotes';
import AllNotes from 'src/components/AllNotes';
import AddNote from "src/components/AddNote";
import { BiArrowToTop } from "react-icons/bi";
import { FiPrinter, FiRefreshCw } from "react-icons/fi";
import NoShift from 'src/components/NoShift/NoShift';
import { useAppProfileActionCreators, useMyRotationActionCreators, useMyShiftActionCreators, useSelectRoleActionCreators } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypedselector';
import { NoteType, NoteTab } from 'src/enums/NoteTypes';
import { Toaster } from 'src/services/api/toaster.service';
import ReactRouterPrompt from 'react-router-prompt';
import ConfirmationModal from "src/components/ConfirmationModal";
import TagModal from "src/components/TagModal";
import { ShiftTab } from 'src/enums/ShiftTab';
import { MdOutlineEdit } from 'react-icons/md';
import { Tooltip as TooltipPR } from 'primereact/tooltip';
import { EditRotation, Rotation } from 'src/models/Rotation';
import { DateTimeHelper } from 'src/helpers/DateTimeHelper';
import EditRotationDetailsModal from 'src/components/EditRotationDetailsModal';
import RotationService from 'src/services/api/rotations.service';
import { BsThreeDots } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { NotePayload } from 'src/models/NoteItem';

const MyShift = () => {
    let rotationService = null;

    const navigate = useNavigate();

    const currentShift = useTypedSelector(state => state.appProfileReducer.currentUserShift.shift);
    const loading = useTypedSelector(state => state.appProfileReducer.currentUserShift.isLoading);
    const roleId = useTypedSelector(state => state.appProfileReducer.currentUserShift.shift?.role.id);
    const isGracePeriod = useTypedSelector(state => state.appProfileReducer.currentUserShift.gracePeriod.isInGracePeriod);
    const shiftSchedule = useTypedSelector(state => state.appProfileReducer.shiftSchedule.shiftScheduleDetail);
    const graceMinutes = useTypedSelector(state => state.appProfileReducer.shiftSchedule.graceMinutes);
    const shiftId = useTypedSelector(state => state.appProfileReducer.currentUserShift.shift?.id);
    const hasCurrentShift = useTypedSelector(state => state.appProfileReducer.currentUserShift.ifExists);
    const showAddShiftToast = useTypedSelector(state => state.selectRoleReducer.showAddShiftToast);
    const showTagRoleModal = useTypedSelector(state => state.myShiftReducer.showTagRoleModal);
    const [showEditRotationDetailsdModal, setShowEditRotationDetailsdModal] = useState(false)
    const roleSettings = useTypedSelector(state => state.appProfileReducer.roleSettings.roleSettingsDetail);

    const draftNotesCount = useTypedSelector(state => state.myShiftReducer.draftNotesCount);
    const newNoteIsDirty = useTypedSelector(state => state.myShiftReducer.newNoteIsDirty);
    const activeShiftTab = useTypedSelector(state => state.myShiftReducer.activeShiftTab);

    const IsAllNotesTabSelected = (activeShiftTab === ShiftTab.AllNotes);
    const IsMyRotationNotesTabSelected = (activeShiftTab === ShiftTab.MyRotationNotes);
    const IsMyShiftNotesTabSelected = (activeShiftTab === ShiftTab.MyShiftNotes);

    const [isDirty, setIsDirty] = useState(false);
    const [isAllNoteNavigated, setIsAllNoteNavigated] = useState(false);
    const { setShowAddShiftToast } = useSelectRoleActionCreators();
    const { getCurrentShift, setIsGracePeriod, setTimeLeftForShiftEnd, getCurrentRotation } = useAppProfileActionCreators();
    const { setMyShiftCollapseFlag, getAllRolesSummary, getCurrentShiftNotes, getFollowedNotes,
        setShowTagRoleModal, setActiveShiftTab, setAddNoteDialogVisibility, clearAddNoteDialogVisibility } = useMyShiftActionCreators();
    const { getCurrentRotationNotes } = useMyRotationActionCreators();

    const addNoteDialogVisibility = useTypedSelector(state => state.myShiftReducer.notePayload?.modalVisibility);
    const myShiftNotesLastSynced = useTypedSelector(state => state.myShiftReducer.currentShiftNoteLastSynced);
    const allRolesSummaryLastSynced = useTypedSelector(state => state.myShiftReducer.allRolesSummaryLastSynced);
    const myRotationNotesLastSynced = useTypedSelector(state => state.myRotationReducer.currentRotationNotesLastSynced);
    const currentRotation: Rotation = useTypedSelector(state => state.appProfileReducer.currentUserRotation.rotation);
    const [rotationToUpdate, setRotationToUpdate] = useState<EditRotation>(new EditRotation())

    const timeLeftForShiftEnd = useTypedSelector(state => state.appProfileReducer.currentUserShift.gracePeriod.minLeftForShiftEndWithGraceMin);
    const { SetNoteTypeSelection } = useMyShiftActionCreators();

    const [timer, setTimer] = useState(0);
    const [isSavingRotationChanges, setIsSavingRotationChanges] = useState<boolean>(false)

    // Set MyShiftNotes as default active tab on mount
    useEffect(() => {
        setActiveShiftTab(ShiftTab.MyShiftNotes);

        return () => { clearAddNoteDialogVisibility(); };
    }, []);

    useEffect(() => {
        if (IsMyShiftNotesTabSelected)
            SetNoteTypeSelection(NoteType.Shift);
        else if (IsAllNotesTabSelected)
            SetNoteTypeSelection(NoteType.Shift);
        else if (IsMyRotationNotesTabSelected)
            SetNoteTypeSelection(NoteType.Rotation);
    }, [activeShiftTab]);

    /* to set state of timer with minutes left to end the shift calculated from api response*/
    useEffect(() => {
        setTimer(timeLeftForShiftEnd);
    }, [timeLeftForShiftEnd]);

    useEffect(() => {
        if (showAddShiftToast) {
            Toaster.showSuccess('Shift added successfully.');
            // This will clear toast flag when reload of the page.
            setShowAddShiftToast(false);
        }
    }, [showAddShiftToast])

    const refreshNotes = () => {
        const _roleId = currentShift ? roleId : shiftSchedule.roleId

        if (IsMyShiftNotesTabSelected) {
            getCurrentShiftNotes(_roleId, isGracePeriod);
            getFollowedNotes(_roleId);
        } else if (IsAllNotesTabSelected)
            getAllRolesSummary();
        else if (IsMyRotationNotesTabSelected)
            getCurrentRotationNotes(_roleId, isGracePeriod);
    }

    const myShiftRefreshMyNotes = () => {
        const _roleId = currentShift ? roleId : shiftSchedule.roleId

        if (IsMyShiftNotesTabSelected)
            getCurrentShiftNotes(_roleId, isGracePeriod);
        else if (IsMyRotationNotesTabSelected)
            getCurrentRotationNotes(_roleId, isGracePeriod);
    }

    const collapseNotePanes = () => {
        if (IsMyShiftNotesTabSelected)
            setMyShiftCollapseFlag(NoteTab.MyNotes, true);
        else if (IsAllNotesTabSelected)
            setMyShiftCollapseFlag(NoteTab.AllNotes, true);
        else if (IsMyRotationNotesTabSelected)
            setMyShiftCollapseFlag(NoteTab.MyRotationNotes, true);
    }

    const printReport = () => {
        if (IsMyShiftNotesTabSelected)
            navigate(`/viewreport/Shift`);
        else if (IsMyRotationNotesTabSelected)
            navigate(`/viewreport/Rotation`);
    }

    useEffect(() => {
        if (!isGracePeriod) {
            getCurrentShift();
            if (hasCurrentShift) refreshNotes();
        }
    }, [shiftId, isGracePeriod]);

    let interval;

    useEffect(() => {
        let intervalId;
        if (timer > 0) {
            intervalId = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 60 * 1000);
        } else {
            clearInterval(intervalId);
            if (timer === 0) {
                setIsGracePeriod(false);
                setTimeLeftForShiftEnd(timer);
                getCurrentShift();
                if (currentShift) refreshNotes();
            }
        }
        return () => clearInterval(intervalId);
    }, [timer]);

    /* to start a timer for grace period before resetting the UI*/
    useEffect(() => {
        let gracePeriodTimeout;
        if (timer <= graceMinutes && timer > 0) {
            setIsGracePeriod(true);

            gracePeriodTimeout = setTimeout(() => {
                setIsGracePeriod(false);
                setTimeLeftForShiftEnd(timer);
                getCurrentShift();
                if (currentShift) refreshNotes();
            }, timer * 60 * 1000);

            return () => clearTimeout(gracePeriodTimeout);
        }
    }, [timer, graceMinutes]);

    useEffect(() => {
        clearInterval(interval)
    }, [timer < 0]);

    useEffect(() => {
        if (!hasCurrentShift || (draftNotesCount <= 1 && !newNoteIsDirty)) {
            setIsDirty(false);
        }
        else {
            setIsDirty(true);
        }
    }, [currentShift, draftNotesCount, newNoteIsDirty]);

    const onAllNoteTabChange = () => {
        if (!isDirty) {
            setActiveShiftTab(ShiftTab.AllNotes);
            return;
        }

        setIsAllNoteNavigated(!isAllNoteNavigated)
    }

    const onShiftTabChange = (shiftTab: ShiftTab) => {
        switch (shiftTab) {
            case ShiftTab.MyShiftNotes:
                setActiveShiftTab(shiftTab);
                break;

            case ShiftTab.AllNotes:
                onAllNoteTabChange();
                break;

            case ShiftTab.MyRotationNotes:
                setActiveShiftTab(shiftTab);
                break;
        }
    }

    const myShiftLastSynced = () => {
        switch (activeShiftTab) {
            case ShiftTab.MyShiftNotes:
                return myShiftNotesLastSynced;
            case ShiftTab.AllNotes:
                return allRolesSummaryLastSynced;
            case ShiftTab.MyRotationNotes:
                return myRotationNotesLastSynced;
        }
    }

    const onSaveRotationChanges = () => {
        if (rotationToUpdate.isValid) {
            setIsSavingRotationChanges(true)
            if (!rotationService) {
                rotationService = new RotationService()
            }

            const updatedRotation = { ...rotationToUpdate.rotation }
            updatedRotation.startDate = new Date(Date.UTC(updatedRotation.startDate.getFullYear(), updatedRotation.startDate.getMonth(), updatedRotation.startDate.getDate()));
            updatedRotation.endDate = new Date(Date.UTC(updatedRotation.endDate.getFullYear(), updatedRotation.endDate.getMonth(), updatedRotation.endDate.getDate()));

            rotationService.updateRotation(updatedRotation).then(() => {
                setShowEditRotationDetailsdModal(false)
                Toaster.showSuccess('rotation updated successfully')
                getCurrentRotation(roleId)
                getCurrentRotationNotes(roleId, isGracePeriod)
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                setIsSavingRotationChanges(false)
            })
        }
        else {
            setIsSavingRotationChanges(false)
        }
    }

    const lastSyncMarkup = () => {
        return <div className='color-dark-gray word-wrap'>
            last synced: <span>{myShiftLastSynced()}</span>
        </div>;
    }

    const actionToolbarButtonsMarkup = (asLink: boolean) => {
        let btnClassName: string;
        let btnPrintDisplay: string;

        if (asLink)
            btnClassName = "btn button-text";
        else
            btnClassName = "btn-sm btn-secondary border-0 text-left gotham-narrow-bold";

        // Show print button only on MyShiftNotes and MyRotation tabs
        if (!(IsMyShiftNotesTabSelected || IsMyRotationNotesTabSelected))
            btnPrintDisplay = btnClassName.concat(' d-none');
        else
            btnPrintDisplay = btnClassName;

        return <>
            {/*Print Button*/}
            <button
                className={btnPrintDisplay}
                onClick={printReport}
                type="button"
                disabled={loading}
            >
                print
                <FiPrinter className="normal ml-1" />
            </button>

            {/*Refresh Button*/}
            <button
                className={btnClassName}
                onClick={refreshNotes}
                type="button"
                disabled={loading}
            >
                refresh
                <FiRefreshCw className="normal ml-1" />
            </button>

            <button className={btnClassName} onClick={collapseNotePanes}
                type="button" disabled={loading}>
                collapse all
                <BiArrowToTop className="normal ml-1" />
            </button>
        </>
    }

    const rotationToolbarMarkup = () => {
        if ((IsMyRotationNotesTabSelected && currentRotation)) {
            return <div className='d-flex gap-1 flex-wrap align-items-center'>
                <span>From: </span>
                <div className='d-flex gap-1 color-medium-blue font-weight-bold'>
                    <span>{currentRotation.assignedUserProfile.firstName}</span>
                    <span>{currentRotation.assignedUserProfile.lastName}</span>
                </div>
                <span>{DateTimeHelper.toString(currentRotation.startDate)}</span>
                <span>-</span>
                <span>{DateTimeHelper.toString(currentRotation.endDate)}</span>
                <button
                    className="btn button-text mx-2"
                    onClick={() => setShowEditRotationDetailsdModal(true)}
                    type="button"
                    style={{ display: 'inline-block' }}>
                    edit
                    <MdOutlineEdit size={'1.2em'} className="normal ml-1" />
                </button>
            </div>
        }
        else
            return <></>;
    }

    const notePayload: NotePayload = {
        modalVisibility: true,
        noteType: NoteType.Shift,
        tag: null
    };

    const setAddNoteModalVisibility = () => {
        setAddNoteDialogVisibility(notePayload);
    }

    return (
        <div className='myshift'>
            {
                // No Shift message
                !loading && !hasCurrentShift && !isGracePeriod && (
                    <NoShift />
                )
            }

            {/*  Reused ConfirmationModal to show the confirmation message  on navigation*/}
            <ReactRouterPrompt when={isDirty}>
                {({ isActive, onConfirm, onCancel }) => (
                    <ConfirmationModal
                        showConfirmationModal={isActive}
                        toggleConfirmationModal={onCancel}
                        onConfirm={onConfirm}
                        titleText='Changes not Published!'
                        titleClass='modal-title large gotham-bold'
                        bodyText='Are you sure you want to leave the page?'
                        bodyClass='modal-body medium pt-0'
                        footerClass='modal-footer border-0'
                        confirmationButtonText='ok'
                    />
                )}
            </ReactRouterPrompt>

            {
                isAllNoteNavigated &&
                <ConfirmationModal
                    showConfirmationModal={isAllNoteNavigated && isDirty}
                    toggleConfirmationModal={() => { onAllNoteTabChange() }}
                    onConfirm={() => { setActiveShiftTab(ShiftTab.AllNotes); onAllNoteTabChange() }}
                    titleText='Changes not Published!'
                    titleClass='modal-title large gotham-bold'
                    bodyText='Are you sure you want to leave the page?'
                    bodyClass='modal-body medium pt-0'
                    footerClass='modal-footer border-0'
                    confirmationButtonText='ok'
                />
            }

            {
                /* */
                <EditRotationDetailsModal
                    show={showEditRotationDetailsdModal}
                    toggleShowModal={() => setShowEditRotationDetailsdModal(!showEditRotationDetailsdModal)}
                    titleText='edit rotation'
                    titleClass='modal-title large gotham-bold'
                    footerClass='modal-footer border-0'
                    saveRotationDetailsButtonText='save changes'
                    isSavingChanges={isSavingRotationChanges}
                    onSaveChanges={onSaveRotationChanges}
                    setRotation={(rotationEditDetails) => setRotationToUpdate(rotationEditDetails)}
                />
            }

            {/*Body: ActionBar2, NoteType Notes*/}
            {
                roleSettings?.isShiftEnabled && (hasCurrentShift || isGracePeriod) && !loading && (
                    <div className="d-flex-column bg-white shadow-sm">
                        <div className='mb-3 pr-3'>
                            {/* Tabs */}
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-baseline tabs">
                                    {/*ShiftNotes Tab*/}
                                    <div className={`px-4 py-3 action-bar-page-tab ${IsMyShiftNotesTabSelected ? " active-link " : " "}`}
                                        onClick={() => onShiftTabChange(ShiftTab.MyShiftNotes)}>
                                        {"my shift"}
                                    </div>

                                    {/*RotationNotes Tab*/}
                                    <div className={`px-4 py-3 action-bar-page-tab ${IsAllNotesTabSelected ? " active-link " : " "}`}
                                        onClick={() => onShiftTabChange(ShiftTab.AllNotes)}>
                                        {"all shift roles"}
                                    </div>

                                    {/*AllRotationNotes Tab*/}
                                    {roleSettings?.isRotationEnabled &&
                                        <div className={`px-4 py-3 action-bar-page-tab ${IsMyRotationNotesTabSelected ? " active-link " : " "}`}
                                            onClick={() => onShiftTabChange(ShiftTab.MyRotationNotes)}>
                                            {"my rotation"}
                                        </div>
                                    }

                                </div>

                                <div className="d-flex gap-2 justify-content-end align-items-center small">

                                    {/* Rotation Action Toolbar buttons */}
                                    <div className='action-toolbar-rotation-lg'>
                                        {rotationToolbarMarkup()}
                                    </div>

                                    {/*Last Synced*/}
                                    <div className='lastSync-title-nlg'>
                                        {lastSyncMarkup()}
                                    </div>

                                    {/*Context Menu Dropdown Button*/}
                                    <div className={`col-auto p-0 dropdown-container action-toolbar-btn`}>
                                        <button
                                            className="btn-sm btn-secondary border-0 p-0"
                                            aria-label="note"
                                            aria-controls="popup_menu"
                                            aria-haspopup>
                                            <BsThreeDots className="normal" />
                                        </button>

                                        {/* Shift Action Toolbar buttons */}
                                        <div className='dropdown-content shadow-sm border'>
                                            <div className='d-flex-column align-self-start'>
                                                {actionToolbarButtonsMarkup(false)}
                                            </div>
                                        </div>

                                    </div>


                                    {/* Shift Action Toolbar buttons */}
                                    <div className='gap-2 action-toolbar-lg'>
                                        {actionToolbarButtonsMarkup(true)}
                                    </div>
                                </div>
                            </div>

                            <div className='pl-4 d-flex gap-2 justify-content-end align-items-center small border-bottom'>
                                {/* Rotation Action Toolbar buttons */}
                                <div className='action-toolbar-rotation-nlg'>
                                    {rotationToolbarMarkup()}
                                </div>

                                {/*Last Synced*/}
                                <div className='lastSync-title-lg'>
                                    {lastSyncMarkup()}
                                </div>
                            </div>
                        </div>

                        {/*Notes Section*/}
                        <div className="no-gutters px-3 pb-3">
                            {/*My Shift Notes - If ActiveTab == myShiftNotes AND currentShift[0] exists, use it, else, if isGracePeriod == true, return shiftSchedule.roleId*/}
                            {IsMyShiftNotesTabSelected &&
                                <MyShiftNotes roleId={currentShift ? currentShift.role.id : shiftSchedule.roleId} />
                            }

                            {/*All Role Notes*/}
                            {IsAllNotesTabSelected &&
                                <AllNotes noteTab={NoteTab.AllNotes} />
                            }

                            {/*My Rotation Notes*/}
                            {IsMyRotationNotesTabSelected &&
                                <MyRotationNotes roleId={currentShift.role.id} />
                            }

                            {/*Add Note - in fixed positon at bottom*/}
                            <div className={addNoteDialogVisibility ? 'addNotePanel' : 'd-none'}>
                                <AddNote
                                    currentShift={currentShift ? currentShift : null}
                                    previousShiftRoleId={shiftSchedule.roleId}
                                    refreshNotes={myShiftRefreshMyNotes}
                                    shiftSchedule={shiftSchedule}
                                />
                            </div>

                        </div>
                    </div>
                )
            }
            {
                showTagRoleModal &&
                <TagModal
                    roleId={''}
                    selectedCategories={[]}
                    selectedRoles={[]}
                    showAddTagModal={showTagRoleModal}
                    selectedSectionTags={[]}
                    setSelectedCategories={[]}
                    setSelectedRoles={[]}
                    toggleAddTagModal={() => setShowTagRoleModal(!showTagRoleModal)}
                    roleTagData={[]}
                    setRoleTagData={[]}
                    isEdit={false}
                    title={'tag Roles'}
                    toTagRole={true}
                    refreshTable={refreshNotes}
                    noteType={NoteType.Shift}
                />
            }
            {
                (currentShift) &&
                <>
                    <TooltipPR target=".addNoteBtn" content="add note" position="top" />
                    <button onClick={() => setAddNoteModalVisibility()}
                        className={addNoteDialogVisibility ? 'd-none' : 'btn addNoteBtn border-0 p-3'}>
                        <MdOutlineEdit size={'1.6em'} />
                    </button>
                </>
            }
        </div >
    );
}

export default MyShift;