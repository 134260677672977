import { Days } from "src/enums/Days";
import { ReportConfigFilterRecipient } from "./ReportConfigFilterRecipient";
import { ScheduleType } from "src/enums/ScheduleType";

export class Report {
  constructor() {
    this.executionTime = new Date();
    this.reportRecipients = [];
  }

  id: string;
  facilityId: string | null;
  reportName: string;
  config: ReportConfigFilterRecipient;
  dailyFrequency: Days;
  executionTime: Date
  userId: string;
  scheduleType: ScheduleType;
  reportRecipients: ReportRecipient[];
  isActive: boolean;
}

export class ReportRecipient {
  id?: string;
  reportId: string;
  email: string;
}