import { Row, Col } from 'react-bootstrap';
import Skeleton from "react-loading-skeleton";
import 'src/components/FilterPanel/FilterPanel.scss';
import { PageType } from 'src/enums/PageType';

interface FilterPanelSkeletonProps {
    pageType: PageType;
}

const FilterPanelSkeleton = (props: FilterPanelSkeletonProps) => {
    const SIZE_15 = 15, SIZE_20 = 20, SIZE_50 = 50, SIZE_60 = 60, SIZE_80 = 80, SIZE_100 = 100, SIZE_150 = 150, SIZE_200 = 200;

    return (<Col className="d-flex-column mb-2">
        <div className="filter-component pt-2">
            <Row className='no-gutters'>
                {/*Header*/}
                <div className="filter-component-header align-items-baseline mb-1">
                    <strong className="filter-component-header-title">
                        <Skeleton width={SIZE_100} height={SIZE_20} />
                    </strong>
                    <strong>
                        <Skeleton width={SIZE_100} height={SIZE_20} />
                    </strong>
                </div>
                <div className="filter-component-favorite">
                    <Skeleton width={SIZE_150} height={SIZE_20} />
                </div>
            </Row>
            <hr />
        </div>
        {/*Body*/}

        <div>
            <Row className="no-gutters mb-2 mt-2" >
                <Skeleton width={SIZE_60} />
            </Row>
            <Row className="no-gutters" data-cy="roleHeirarchyTree">
                <div className='d-flex flex-row'> <Skeleton width={SIZE_15} className='mr-1' /> <Skeleton width={SIZE_80} /></div>
                <div className='d-flex flex-row'> <Skeleton width={SIZE_15} className='ml-2 mr-1' /> <Skeleton width={SIZE_80} /></div>
                <div className='d-flex flex-row'> <Skeleton width={SIZE_15} className='ml-2 mr-1' /> <Skeleton width={SIZE_80} /></div>
                <div className='d-flex flex-row'> <Skeleton width={SIZE_15} className='mr-1' /> <Skeleton width={SIZE_80} /></div>
                <div className='d-flex flex-row'> <Skeleton width={SIZE_15} className='ml-2 mr-1' /> <Skeleton width={SIZE_80} /></div>
                <div className='d-flex flex-row'> <Skeleton width={SIZE_15} className='ml-3 mr-1' /> <Skeleton width={SIZE_80} /></div>
                <div className='d-flex flex-row'> <Skeleton width={SIZE_15} className='mr-1' /> <Skeleton width={SIZE_80} /></div>
            </Row>
        </div>
        {props.pageType == PageType.History && <div>
            <div>
                <Row className="no-gutters mb-2 mt-2" >
                    <Skeleton width={SIZE_60} />
                    <Row className="no-gutters" >
                        <Skeleton width={SIZE_80} />
                        <Skeleton width={SIZE_80} />
                    </Row>
                </Row>
            </div>
            <div>
                <Row className="no-gutters mb-2 mt-2" >
                    <Skeleton width={SIZE_100} />
                    <Row className="no-gutters" >
                        <div className='d-flex flex-row'>
                            <div className='d-flex flex-row'> <Skeleton width={SIZE_15} className='mr-1' /> <Skeleton width={SIZE_50} /></div>
                            <div className='d-flex flex-row'> <Skeleton width={SIZE_15} className='ml-2 mr-1' /> <Skeleton width={SIZE_50} /></div>
                        </div>
                    </Row>
                </Row>
            </div>
            <div>
                <Row className="no-gutters mb-2 mt-2" >
                    <Skeleton width={SIZE_100} />
                    <Row className="no-gutters" >
                        <Skeleton width={SIZE_200} height={SIZE_20} />
                    </Row>
                </Row>
            </div>
        </div>}
    </Col>);

}

export default FilterPanelSkeleton; 