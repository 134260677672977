import "./HistoryNotes.scss";
import ShiftNotes from "../ShiftNotes";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import { useTypedSelector } from 'src/hooks/useTypedselector';
import { NoteTab } from "src/enums/NoteTypes";
import RotationNotes from "../RotationNotes";

interface AllNotesComponentProps {
    isFavorite?: boolean,
    from?: Date,
    to?: Date
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HistoryNotes = (props: AllNotesComponentProps) => {
    const noteData = useTypedSelector(state => state.historyReducer.historyNotesSummary);
    const loading = useTypedSelector(state => state.historyReducer.historyNotesLoading);

    const isRotationsSelected = useTypedSelector(state => state.filterReducer.isRotationsSelected);

    return (
        <div className="all-notes-container row shadow-sm rounded no-gutters">
            <div className="col mb-2">
                <CustomSpinner loading={loading} />
                {noteData &&
                    noteData.map((singleNoteData, index) => {
                        return (
                            <div key={index} className={index >= 1 ? 'mt-2' : ''}>
                                {!isRotationsSelected && <ShiftNotes noteTab={NoteTab.HistoryNotes} noteData={singleNoteData} />}
                                {isRotationsSelected && <RotationNotes noteTab={NoteTab.HistoryRotationNotes} rotationData={singleNoteData} loading={loading} />}
                            </div>)
                    })
                }
            </div>
        </div>

    );
};

export default HistoryNotes;
