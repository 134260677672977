import { useTypedSelector } from "src/hooks/useTypedselector";
import { useEffect } from "react";
import ReportService from "src/services/api/report.service";
import { NoteType } from "src/enums/NoteTypes";
import { useMyRotationActionCreators } from "src/hooks/useActions";
import ReportRotationNotes from "./ReportRotationNotes";

// eslint-disable-next-line react/display-name 
const ReportMyRotationNotes = () => {
  const roleId = useTypedSelector(state => state.appProfileReducer.currentUserShift.shift?.role.id);
  const isGracePeriod = useTypedSelector(state => state.appProfileReducer.currentUserShift.gracePeriod.isInGracePeriod);

  const myRotationNotes = useTypedSelector(state => state.myRotationReducer.currentRotationNotes);
  const currentUserRotation = useTypedSelector(state => state.appProfileReducer.currentUserRotation.rotation);

  const { getCurrentRotationNotes } = useMyRotationActionCreators();

  const reportService: ReportService = new ReportService();

  const getRotationTitleMarkup = () => {
    if (currentUserRotation) {
      return <div className={reportService.contStDef + ` align-items-center`}>
        <h2 className="m-0">{`${currentUserRotation.role.name + '-' + currentUserRotation.role.jobClass.shortName} Rotation Turnover (Draft Report)`}</h2>
        <div>
          <span className="bold">Rotation: </span>
          <span>{`${reportService.getDateTimeStringToLocale(currentUserRotation.startDate)} - ${reportService.getDateTimeStringToLocale(currentUserRotation.endDate)}`}</span>
        </div>
        <div>
          <span className="bold">Report Date Time: </span>
          <span>{reportService.getDateTimeString(new Date())}</span>
        </div>
      </div>
    }
    else {
      return <>
        <h2>{reportService.pageTitle}</h2>
        <h4>{reportService.noRotationMessage}</h4>
      </>
    }
  }

  useEffect(() => {
    getCurrentRotationNotes(roleId, isGracePeriod);
  }, []);

  return (
    <div className={reportService.contStDef}>
      {getRotationTitleMarkup()}

      <ReportRotationNotes noteType={NoteType.Rotation} noteData={myRotationNotes} />

    </div>
  );
}

export default ReportMyRotationNotes;