export enum NoteTab {
    MyNotes = 1,
    FollowedNotes = 2,
    AllNotes = 3,
    MyRotationNotes = 4,
    SelectRoleNotes = 5,
    SelectRoleRotationNotes = 6,
    SelectRoleAllRotationNotes = 7,
    HistoryNotes = 8,
    HistoryNotesModal = 9,
    HistoryRotationNotes = 10
}

export enum NoteType {
    Shift = 1,
    Template = 2,
    Rotation = 3,
    HistoryShiftNotes = 4,
    HistoryRotationNotes = 5,
}