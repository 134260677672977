import { msalInstance } from "src/index";
import { apiScope } from "src/auth-config";
import { SilentRequest } from '@azure/msal-browser';
import * as signalR from '@microsoft/signalr';

class SignalRHubInterceptor{
    connection: signalR.HubConnection = undefined;
    private baseURL = "";

    constructor(hubName: string){
        this.baseURL = process.env.REACT_APP_API_URL;
        this.createHubConnection(hubName);
    }

    public send = (actionName: string, object:any, callBackFunction: any) =>{
        if(object === null){
            this.connection.send(actionName).then(callBackFunction());
        }else{
            this.connection.send(actionName, object).then(callBackFunction());
        }
    }

    public recieve = (subscriptionName: string, callBackFunction: any) =>{
        this.connection.on(subscriptionName, callBackFunction);
    }

    public onHubDisconnectedStatus = (callBackFunction: any)=>{
        this.connection.onclose(() => {
            callBackFunction();
        });
    }

    public onHubReConnectedStatus = (callBackFunction: any)=>{
        this.connection.onreconnected(() => {
            callBackFunction();
        });
    }

    private createHubConnection = (hubName: string) => {
        const hubRUI = this.baseURL + "/" + hubName;

        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(hubRUI, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
                accessTokenFactory: async () => {
                    return await this.acquireAccessToken();
                }

            })
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Information)
            .build();

        this.startConnection();
    }

    private startConnection = async () => {
        try {
            await this.connection.start();
            console.log("SignalR Connected.");
        } catch (err: any) {
            console.log(err);
            setTimeout(this.startConnection, 5000);
        }
    };

    private acquireAccessToken = async () => {
        const activeAccount = msalInstance.getActiveAccount();
        const accounts = msalInstance.getAllAccounts();
    
        const request: SilentRequest = {
            scopes: apiScope.scopes,
            account: activeAccount || accounts[0]
        };
    
        const authResult = await msalInstance.acquireTokenSilent(request);
        return authResult.accessToken
    };
}

export default SignalRHubInterceptor;