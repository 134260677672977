import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import { Dropdown } from 'primereact/dropdown';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';

import "./UserAccessDataTable.scss";
import { AssignRole } from 'src/models/Permission';
import { AssignState } from 'src/enums/AssignState';
import { DateTimeHelper } from 'src/helpers/DateTimeHelper';
import { Permission } from 'src/enums/Permission';
import { PaginatorCurrentPageReportOptions, PaginatorRowsPerPageDropdownOptions } from 'primereact/paginator';
import { GroupRole } from 'src/models/Role';
import UserService from "src/services/api/user.service";
import { useLocation } from "react-router-dom";
import { Toaster } from "src/services/api/toaster.service";

interface UserAccessDataTableProps {
    facilityAssigned: AssignRole[];
    setFacilityAssigned: any;
    isUserProfileSelf: boolean;
}

// eslint-disable-next-line react/display-name
const UserAccessDataTable = (props: UserAccessDataTableProps) => {

    // Services
    const userService = new UserService();

    // Get the information of another page
    const location = useLocation();
    const userProfileId = location.state;

    // Check if user has active shifts or rotations
    const doesUserHaveActiveShiftsOrRotations = async (userId, roleId) => {
        try {
            const { shifts, rotations } = await userService.getUserShiftsAndRotations(userId, roleId);
            return shifts.length > 0 || rotations.length > 0;
        } catch (error) {
            console.error('Error:', error);
            return false;
        }
    }

    // Function to remove item
    const deleteRole = async (selectedRole: GroupRole) => {
        // Verify active shifts and rotations
        const hasActiveShiftsOrRotations = await doesUserHaveActiveShiftsOrRotations(userProfileId, selectedRole.roleId);
        if (hasActiveShiftsOrRotations) {
            Toaster.showWarn("The removed role contain active shifts or rotations. Please notify the user to remove them first.");
        }
        const updateAssignedRole = props.facilityAssigned.map((assignedRole: AssignRole) => {
            if (assignedRole.groupRole.roleId === selectedRole.roleId && assignedRole.groupRole.name == selectedRole.name) {
                if (assignedRole.isPersist) {
                    return new AssignRole(
                        assignedRole.groupRole,
                        AssignState.remove,
                        assignedRole.isPersist,
                        assignedRole.startDate
                    );
                } else {
                    return null;
                }
            }
            return assignedRole;
        }).filter((role) => role != null);

        props.setFacilityAssigned(updateAssignedRole as AssignRole[]);
    }

    const permissionsToString = (value: number): string => {
        const values: string[] = [];
        while (value) {
            const bit = value & (~value + 1);
            values.push(Permission[bit]);
            value ^= bit;
        }
        return values.join(', ');
    }

    const uaColTemplateRemoveAccess = (rowData) => {
        if (props.isUserProfileSelf && Permission.Admin === (rowData.groupRole.permissions & Permission.Admin))
            return <></>;

        return (
            <button
                type="button"
                className="btn button-text ua-remove-access-link"
                onClick={() => {
                    deleteRole(rowData.groupRole);
                }}
            >
                remove role
            </button>
        );
    }

    const filteredRoles = () => {
        return props.facilityAssigned.filter(
            (facilityRole) =>
                (facilityRole.state == AssignState.add || facilityRole.state == AssignState.assigned)
        )
    }

    const emptyRecordsMsg = (
        <span className='custom-empty-message'>No role(s) added yet</span>
    )

    const paginationSection = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        'RowsPerPageDropdown': (options: PaginatorRowsPerPageDropdownOptions) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];
            return (
                <>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Items per page: </span>
                    <Dropdown
                        value={options.value}
                        options={dropdownOptions}
                        onChange={options.onChange} />
                </>
            );
        },
        'CurrentPageReport': (options: PaginatorCurrentPageReportOptions) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '60%', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords} items
                </span>
            )
        }
    };

    return (
        <DataTable
            dataKey="id"
            paginator
            value={filteredRoles()}
            rows={10}
            rowsPerPageOptions={[10, 20, 50]}
            className='custom-datatable'
            currentPageReportTemplate={`${props.facilityAssigned.length} results`}
            resizableColumns
            rowClassName={() => "rowHover"}
            paginatorPosition="bottom"
            selectionMode="single"
            paginatorTemplate={paginationSection}
            emptyMessage={emptyRecordsMsg}>

            {/*Role Name*/}
            <Column key='roleName' field='groupRole.name' header='role' sortable={true} align='left' style={{ width: '10%' }} />

            {/*Description*/}
            <Column key='description' field='groupRole.description' header='description' align='left' style={{ width: '35%' }} />

            {/*Permissions*/}
            <Column key='permissions' body={(rowData: AssignRole) => permissionsToString(rowData.groupRole.permissions)} header='permissions' align='left' style={{ width: '20%' }} />

            {/*Effective Date*/}
            <Column key='effectiveDate' body={(rowData: AssignRole) => DateTimeHelper.toHHMMString(rowData.startDate)} header='effective date' sortable={true} align='left' style={{ width: '10%' }} />

            {/*Action Link Column*/}
            <Column sortableDisabled excludeGlobalFilter align='center' body={uaColTemplateRemoveAccess} className="dGridRemoveAccessColWi" style={{ width: '10%' }} />

        </DataTable>
    );
};

export default UserAccessDataTable;