import FilterPanel from 'src/components/FilterPanel';
import { Row, Col } from 'react-bootstrap';
import { BiSearch } from "react-icons/bi";
import { useState, useRef, useEffect } from 'react';
import { useHistoryActionCreators } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypedselector';
import './History.scss';
import CustomSpinner from 'src/components/CustomSpinner/CustomSpinner';
import { PageType } from 'src/enums/PageType';
import HistoryNotes from 'src/components/HistoryNotes';
import HistoryCalendar from 'src/components/HistoryCalendar';
import { usePrevious } from 'src/hooks/usePrevious';
import { FiSliders, FiChevronUp, FiChevronDown, FiPrinter } from "react-icons/fi";
import { NoteType } from 'src/enums/NoteTypes';
import { useNavigate } from 'react-router-dom';

export const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
        isMountRef.current = false;
    }, []);
    return isMountRef.current;
};

const History = () => {
    const navigate = useNavigate();

    const [isFilterPanelHidden, toggleFilterPanelVisibility] = useState(false);
    const [searchTxt, setSearchTxt] = useState('');
    const loading = useTypedSelector(state => state.historyReducer.historyNotesLoading);
    const historyData = useTypedSelector(state => state.historyReducer);
    const { getHistoryNotes, setSelectedHistoryDateFilters, setSelectedHistoryTextFilter } = useHistoryActionCreators();
    const searchTextRef = useRef(null);
    const historyCalRef = useRef(null);

    const noteData = useTypedSelector(state => state.historyReducer.historyNotesSummary);
    const selectedDateFilter = useTypedSelector(state => state.historyReducer.selectedHistoryDates);

    const isRotationsSelected = useTypedSelector(state => state.filterReducer.isRotationsSelected);
    const isCriticalSelected = useTypedSelector(state => state.filterReducer.isCriticalSelected);
    const filters = useTypedSelector(state => state.filterReducer.allFilters);


    const prevStateValues = usePrevious({ selectedDateFilter, isRotationsSelected, filters, searchTxt });

    const maxDayRange = 90;

    useEffect(() => {
        if ((!selectedDateFilter || selectedDateFilter.length === 0) && noteData?.length > 0)
            setSelectedHistoryDateFilters([new Date(noteData[0].beginDateTime), new Date(noteData[0].endDateTime)])
    }, [noteData]);

    // Toggle Filter panel
    const onClickFilterToggleImage = () => {
        toggleFilterPanelVisibility(!isFilterPanelHidden);
    };

    const populateHistoryNotes = (searchText: string, val: any) => {
        let frmDate = '';
        let toDate = '';

        if (val?.[0]) {
            let d = new Date(val[0])
            // Temporary fix for the date locale issue
            d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
            frmDate = new Date(d).toLocaleDateString('en-US', { timeZone: 'UTC' });
        }
        if (val?.[1]) {
            let d = new Date(val[1])
            // Temporary fix for the date locale issue
            d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
            toDate = new Date(d).toLocaleDateString('en-US', { timeZone: 'UTC' });
        } else { // if end Date is null set end date as same as start date. Api is setting end date as 23:59:59
            toDate = frmDate;
        }

        //use searchTxt as a paramter to pass in the below call for endpoint
        getHistoryNotes(searchText, frmDate, toDate, isRotationsSelected, isCriticalSelected);
    }


    const refreshTable = () => {
        if (selectedDateFilter?.length === 2 && selectedDateFilter.every(x => x != null)) {
            historyCalRef.current.hide();
        }

        populateHistoryNotes(searchTxt, selectedDateFilter);
    }

    // Refresh table on page load.
    useEffect(() => {
        if(historyData?.selectedHistoryText?.length > 0){
            setSearchTxt(historyData.selectedHistoryText);
            searchTextRef.current.value = historyData.selectedHistoryText;
        }
        refreshTable();
    }, []);

    // Refreh table only when states changed.
    useEffect(() => {

        if (!prevStateValues)
            return;

        let isRefresh = false;
        let canSelectedDateFilterRefresh = false;
        let canSelectedRoleFiltersRefresh = false;
        let canSelectedShiftScheduleFiltersRefresh = false;
        let canSelectedLocationFiltersRefresh = false;
        let canSelectedCategoryTagFiltersRefresh = false;
        let canSelectedRoleTagFiltersRefresh = false;
        let canRotationsSelectedRefresh = false;
        let canSelectedSearchRefresh = false;

        // Refresh when date selection not same as previous.
        if (JSON.stringify(selectedDateFilter || []) !== JSON.stringify(prevStateValues.selectedDateFilter || [])) {
            // Do not refresh when page is refreshed first time, Dates are null 
            // and when api response prefilled with begin and end date

            let startDateTime;
            let endDateTime;

            if (noteData?.length > 0) {
                startDateTime = noteData[0].beginDateTime | noteData[0].startDate
                endDateTime = noteData[0].endDateTime | noteData[0].endDate
            }

            canSelectedDateFilterRefresh = !(!prevStateValues.selectedDateFilter
                && noteData?.length > 0
                && selectedDateFilter?.every(z => z != null)
                && new Date(startDateTime).toDateString() === new Date(selectedDateFilter[0]).toDateString()
                && new Date(endDateTime).toDateString() === new Date(selectedDateFilter[1]).toDateString()
            );
        }
        // Refresh when role filters not same as previous.
        if (JSON.stringify(searchTxt || []) !== JSON.stringify(prevStateValues.searchTxt || []))
            canSelectedSearchRefresh = true;

        // Refresh when role filters not same as previous.
        if (JSON.stringify(filters?.selectedRoleIds || []) !== JSON.stringify(prevStateValues.filters?.selectedRoleIds || []))
            canSelectedRoleFiltersRefresh = true;

        // Refresh when shift schedule filters not same as previous.
        if (JSON.stringify(filters.shiftSchedulePeriods || [])
            !== JSON.stringify(prevStateValues.filters.shiftSchedulePeriods || []))
            canSelectedShiftScheduleFiltersRefresh = true;

        // Refresh when location filters not same as previous.
        if (JSON.stringify(filters.selectedLocationIds || []) !== JSON.stringify(prevStateValues.filters.selectedLocationIds || []))
            canSelectedLocationFiltersRefresh = true;


        // Refresh when category filters not same as previous.
        if (JSON.stringify(filters.categoryTags || []) !== JSON.stringify(prevStateValues.filters.categoryTags || []))
            canSelectedCategoryTagFiltersRefresh = true;

        // Refresh when role tag filters not same as previous.
        if (JSON.stringify(filters.roleTags || []) !== JSON.stringify(prevStateValues.filters.roleTags || []))
            canSelectedRoleTagFiltersRefresh = true;

        // Refresh when rotations selected filter not same as previous.
        if (JSON.stringify(isRotationsSelected || false) !== JSON.stringify(prevStateValues.isRotationsSelected || []))
            canRotationsSelectedRefresh = true;

        // If any of the above is refreshed set refresh flag to true.
        if (canSelectedDateFilterRefresh || canRotationsSelectedRefresh || canSelectedRoleFiltersRefresh
            || canSelectedShiftScheduleFiltersRefresh || canSelectedLocationFiltersRefresh || canSelectedCategoryTagFiltersRefresh || canSelectedRoleTagFiltersRefresh || canSelectedSearchRefresh)
            isRefresh = true;

        if (isRefresh) {
            refreshTable();
        }

    }, [selectedDateFilter, isRotationsSelected, isCriticalSelected, filters, searchTxt]);


    const handleSearchClick = () => {
        setSelectedHistoryTextFilter(searchTextRef.current.value);

        setSearchTxt(searchTextRef.current.value);
        // use searchTxt to pass as parameter in api getHistoryNotes which is called in refreshTable function
    };

    const handleSearchEnterButton = (e) => {
        if (e.key === "Enter") {
            handleSearchClick();
        }
        return false;
    }

    const printReport = () => {
        if (isRotationsSelected)
            navigate(`/viewreport/HistoryRotationNotes`);
        else
            navigate(`/viewreport/HistoryShiftNotes`);
    }

    return (
        <>
            <CustomSpinner loading={loading} cssClass={'spinner-position-my-shift'} />
            <Row className='no-gutters min-height-actionbar align-items-center'>
                <Col sm={1} md={1} lg={3} className="d-flex mr-3">
                    <button className="btn btn-secondary icon border-0 p-1 filter-toggle-btn" type="button" onClick={onClickFilterToggleImage} aria-label="filter toggle"><FiSliders />
                        {isFilterPanelHidden ? <FiChevronUp /> : <FiChevronDown />}
                    </button>
                </Col>

                <Col>
                    <Row className='no-gutters'>
                        <Col className="d-flex align-items-center p-1">
                            <HistoryCalendar
                                selectedDateFilter={selectedDateFilter}
                                setSelectedDateFilter={setSelectedHistoryDateFilters}
                                maxDayRangeSelection={maxDayRange} ref={historyCalRef}
                            />
                        </Col>
                        <Col sm={12} md={5} lg={8} className="d-flex p-1">
                            <input ref={searchTextRef} type="text" className="form-control" placeholder="i'm looking for..." onKeyUp={handleSearchEnterButton} />
                            <button type="button" className="search-button px-3" onClick={handleSearchClick}><BiSearch /></button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/*Filter Panel, History Notes*/}
            <Row className="no-gutters mt-3">
                {/*Body*/}
                {/*Filter Panel*/}
                <Col className={isFilterPanelHidden ? 'd-none' : 'px-0 mr-3 bg-white shadow-sm'} sm={6} md={4} lg={3}>
                    <FilterPanel filterFromPage={PageType.History} noteType={isRotationsSelected ? NoteType.Rotation : NoteType.Shift} />
                </Col>
                {/*History Note Body*/}
                <Col className="shadow-sm px-3 bg-white">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="historyTitle pt-2">
                            history notes
                        </div>
                        <div className="historyTitle pt-2">
                            {/*Print Button*/}
                            <button
                                className="btn button-text"
                                onClick={printReport}
                                type="button"
                                disabled={loading}
                            >
                                print
                                <FiPrinter className="normal ml-1" />
                            </button>
                        </div>
                    </div>

                    {/*Notes*/}
                    <div className="mt-2">
                        <HistoryNotes isFavorite={false} from={null} to={null} />
                    </div>
                </Col>
            </Row>
        </ >
    );

}

export default History;