import { RadioButton } from 'primereact/radiobutton';
import { NoteType } from 'src/enums/NoteTypes';
import { useTypedSelector } from 'src/hooks/useTypedselector';

interface NoteSelectionTypeProps {
    isShiftEnabled?: boolean;
    isRotationEnabled?: boolean;
    noteType: NoteType;
    onNoteTypeChange?: any;
}

// eslint-disable-next-line react/display-name
const NoteSelectionType = (props: NoteSelectionTypeProps) => {
    const handleCheckTypeChange = (e) => {
        props.onNoteTypeChange(e.value);
    };

    const rotationId = useTypedSelector(state => state.appProfileReducer.currentUserRotation.rotation?.id);

    return (
        <div className="d-flex gap-2 gotham-bold color-black medium">
            {props.isShiftEnabled &&
                <div className="d-flex gap-1 align-items-center">
                    <RadioButton
                        name="noteTypeRadioBtn"
                        value={NoteType.Shift}
                        checked={props.noteType == NoteType.Shift}
                        onChange={handleCheckTypeChange} />
                    <span>shift note</span>
                </div>
            }
            {props.isRotationEnabled &&
                <div className="d-flex gap-1 align-items-center">
                    <RadioButton
                        name="noteTypeRadioBtn"
                        value={NoteType.Rotation}
                        checked={props.noteType == NoteType.Rotation}
                        onChange={handleCheckTypeChange}
                        disabled={rotationId == undefined} />
                    <span>rotation note</span>
                </div>
            }
        </div>
    )
};

export default NoteSelectionType;