import "./AllNotes.scss";
import ShiftNotes from "../ShiftNotes";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import { useTypedSelector } from 'src/hooks/useTypedselector';
import { NoteTab } from "src/enums/NoteTypes";
import { useEffect, useState } from "react";
import { useMyShiftActionCreators } from "src/hooks/useActions";
import { ShiftNote } from "../../models/NoteItem";

interface AllNotesComponentProps {
    noteTab: NoteTab
}

// eslint-disable-next-line react/display-name
const AllNotes = (props: AllNotesComponentProps) => {
    const noteTab = props.noteTab;

    const allRoleNoteData: ShiftNote[] = useTypedSelector(state => state.myShiftReducer.allRolesSummary);
    const { getAllRolesSummary, clearAllRolesSummary } = useMyShiftActionCreators();
    const loading = useTypedSelector(state => state.myShiftReducer.allRolesLoading);

    const [noteData, setNoteData] = useState<ShiftNote[]>([])

    useEffect(() => {
        getAllRolesSummary();

        return () => {
            clearAllRolesSummary();
        }
    }, []);

    useEffect(() => {
        allRoleNoteData?.length > 0 ? setNoteData(allRoleNoteData) : [];
    }, [allRoleNoteData])

    return (
        <div className="d-flex-column gap-3 rounded">
            <CustomSpinner loading={loading} />
            {/*if type is rotation, encapasulate below in another layer with tabs for select role modal*/}

            {noteData.map((singleNoteData, index) => {
                return <div key={index} className="shadow-sm">
                    <ShiftNotes noteTab={noteTab} noteData={singleNoteData} />
                </div>
            })
            }
        </div>
    );
};

export default AllNotes;