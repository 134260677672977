import { useTypedSelector } from "src/hooks/useTypedselector";
import { BiSearch } from "react-icons/bi";
import { useEffect, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from 'primereact/dropdown';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';

import "./UserAccount.scss";
import { useAppProfileActionCreators } from "src/hooks/useActions";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import { DateTimeHelper } from "src/helpers/DateTimeHelper";
import { useNavigate } from "react-router-dom";
import { PaginatorCurrentPageReportOptions, PaginatorRowsPerPageDropdownOptions } from "primereact/paginator";
import UserAccountModal from "src/components/UserAccountModal";

interface UserAccountProps {
    rootRoleId?: string;
}

// eslint-disable-next-line react/display-name
const UserAccount = (props: UserAccountProps) => {
    const navigate = useNavigate()
    const rootRoleId = props.rootRoleId;

    const refreshUserListInMin = 5; //minutes to refresh

    const { getFacilityUsers } = useAppProfileActionCreators();

    const accountUser = useTypedSelector(state => state.appProfileReducer.userAccount);

    const loading = useTypedSelector(state => state.appProfileReducer.userAccount.isLoading);
    const gridDataSet = accountUser.items || [];

    const maxTotalCount = accountUser.totalCount || 0;

    const [showUserAccountModal, setShowUserAccountModal] = useState(false);
    const [searchCriteria, setSearchCriteria] = useState('');
    const [paginationParams, setPaginationParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        pageCount: 0,
        filters: {}
    });
    
    const maxLenghtInput = 100; //input textbox limit
    const searchRef = useRef(null);
    const [timer, setTimer] = useState(0);

    const handleNavigate = (userProfileId) => {
        navigate(`/manageuseraccess/`, { state: userProfileId });
    }
    
    //call redux
    const fetchData = async () => {
        try {
          getFacilityUsers(rootRoleId, searchCriteria, paginationParams.page, paginationParams.rows);
        }
        catch (error) {
            console.log('error', error)
        }
    }

    //search event
    const onSearchClick = () => fetchData();

    const onEnterSearchChange = (event) => {
        setSearchCriteria(searchRef.current.value);
        if (event.key === "Enter") {
            onSearchClick();
        }
        return false;
    }

    //event next page
    const onPage = (event) => {
        setPaginationParams(event);
    }

    //colunms names 
    const accountUserNameColumnBody = (rowData) => <span>{rowData.firstName + ' ' + rowData.lastName}</span>

    const onFilter = (event) => {
        event['first'] = 0;
        setPaginationParams(event);
    }

    const accountRolesColumnBody = (rowData) => {
        const user = accountUser?.items.find(item => item.id == rowData.id)
        const roleName = user ? user.groupRoles.map(item => item.roleName).join(', ') : 'No roles assigned yet';
        return <span>{roleName}</span>
    }

    const accountStatusColumnBody = (rowData) => <span>{rowData.isAuthorized === true ? "Authorized" : "Not authorized"}</span>

    const accountManageAccessLinkBody = (rowData) => {
        const manageAccessLink = accountUser?.items.some(item => item.id == rowData.id)
        return manageAccessLink ? (
            <button
                type="button"
                className="btn button-text" id={rowData.id}
                onClick={() => {
                    handleNavigate(rowData.id);
                }}
            >manage access</button>
        ) : null;
    }

    /* start a timer to automatically refresh the grid content*/
    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(prevTime => prevTime - 1);
        }, 60 * 1000);

        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        if (timer <= 1) {
            setTimer(refreshUserListInMin);
            fetchData();
        }
    }, [timer]);

    //dropdown effect
    useEffect(() => {
        fetchData();
    }, [maxTotalCount, paginationParams]);

    const paginationSection = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        'RowsPerPageDropdown': (options: PaginatorRowsPerPageDropdownOptions) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];
            return (
                <>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Items per page: </span>
                    <Dropdown
                        value={options.value}
                        options={dropdownOptions}
                        onChange={options.onChange} />
                </>
            );
        },
        'CurrentPageReport': (options: PaginatorCurrentPageReportOptions) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '60%', textAlign: 'center' }}>
                    {options.first} - {options.last} of {maxTotalCount} items
                </span>
            )
        }
    };

    return (
        <>
            <div className={"user-account"}>
                <CustomSpinner loading={loading} />
                <Row className="user-account-header">
                    <Col>
                        <Row className="user-account-header-title d-flex align-items-center">
                            <Col>
                                <strong>
                                    user accounts
                                </strong>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <button type="button" className="btn btn-secondary" aria-label=""
                                    onClick={() => { setShowUserAccountModal(true) }}
                                    data-cy="">
                                    add user(s)
                                </button>
                                <UserAccountModal
                                    showModal={showUserAccountModal}
                                    toggleShowModal={() => setShowUserAccountModal(!showUserAccountModal)}
                                    refreshUsers={fetchData}
                                />
                            </Col>
                        </Row>
                        <Row>
                            {/* search bar*/}
                            <div className="col searchbartxt">
                                <input
                                    type="text"
                                    className="form-control searchCriteria"
                                    placeholder="search user by name, e-mail or role..."
                                    maxLength={maxLenghtInput}
                                    ref={searchRef}
                                    onKeyUp={onEnterSearchChange}
                                />
                            </div>
                            <div className="col-auto searchbarbtn">
                                <button
                                    type="button"
                                    className="btn btn-primary px-3 searchbtn"
                                    onClick={onSearchClick}>
                                    <BiSearch />
                                </button>
                            </div>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <DataTable
                        value={gridDataSet}
                        lazy
                        paginator
                        dataKey="id"
                        resizableColumns
                        totalRecords={maxTotalCount}
                        onPage={onPage}
                        onFilter={onFilter}
                        loading={loading}
                        first={paginationParams.first}
                        rows={paginationParams.rows}
                        filters={paginationParams.filters}
                        paginatorTemplate={paginationSection}
                        paginatorPosition="bottom"
                        selectionMode="single"
                        emptyMessage="no records found"
                        onSelectionChange={(rowData) => {
                            handleNavigate(rowData.value.id);
                        }}>
                        {/*Name Column*/}
                        <Column body={accountUserNameColumnBody} header="name" align='left' style={{ width: '15%' }} />
                        {/*E-mail Column*/}
                        <Column field="email" header="e-mail" align='left' style={{ width: '25%' }} />
                        {/*Created date Column*/}
                        <Column header="created date" body={(rowData) => DateTimeHelper.toHHMMString(rowData.created)} align='left' style={{ width: '15%' }} />
                        {/*Role Column*/}
                        <Column body={accountRolesColumnBody} header="role (s)" align='left' style={{ width: '15%' }} />
                        {/*Statis Column*/}
                        <Column body={accountStatusColumnBody} header="status" align='left' style={{ width: '15%' }} />
                        {/*manage access Column link*/}
                        <Column excludeGlobalFilter sortableDisabled body={accountManageAccessLinkBody} align="center" style={{ width: '15%' }} />
                    </DataTable>
                </Row>
            </div>
        </>
    )
}

export default UserAccount;