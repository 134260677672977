import { useState, useEffect } from "react";
import "./ShiftNotes.scss";
import { SectionTagShiftNote, ShiftNote } from "src/models/NoteItem";
import { NoteTab } from "src/enums/NoteTypes";
import { useMyShiftActionCreators } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypedselector';
import ShiftService from "src/services/api/shift.service";
import NoteCardSkeleton from "../Skeleton/NoteCardSkeleton";
import { ShiftStatus } from "src/enums/ShiftStatus";
import ReportService from "src/services/api/report.service";
import NoteCardSection from "../NoteCardSection";
import { TagType } from "src/enums/TagType";
import SectionTagConstants from "src/constants/section-tag-constants";

interface ShiftComponentProps {
    noteData: ShiftNote,
    noteTab: NoteTab,
    setChecked?: any,
    selectedNoteIds?: string[],
    canSelect?: boolean,
    loading?: boolean
}

// eslint-disable-next-line react/display-name
const ShiftNotes = (props: ShiftComponentProps) => {
    const noteData = props.noteData;
    const noteTab = props.noteTab;

    const styleDef = "d-flex justify-content-between align-items-center bold medium gotham-bold color-dark-blue cursor-pointer rounded-top bg-lighter-gray px-3 py-2";
    const errorContStyle = "d-flex-column flex-grow-1 align-items-center justify-content-center";

    const collapseStateAllNotes = useTypedSelector(state => state.myShiftReducer.collapseAllNotes);
    const collapseStateMyNotes = useTypedSelector(state => state.myShiftReducer.collapseMyNotes);
    const sectionTagsMasterList = useTypedSelector(state => state.myShiftReducer.sectionList || []);

    const [sectionTagShiftNotes, setSectionTagShiftNotes] = useState<SectionTagShiftNote[]>([]);

    const { setMyShiftCollapseFlag } = useMyShiftActionCreators();

    const defaultExpandedOption = (noteTab === NoteTab.MyNotes || noteTab === NoteTab.SelectRoleNotes || noteTab === NoteTab.HistoryNotesModal);
    const [isExpanded, setIsExpanded] = useState(defaultExpandedOption);

    const reportService: ReportService = new ReportService();

    const [localNoteData, setLocalNoteData] = useState<ShiftNote>();
    const [loading, setLoading] = useState(false);

    const [noteCount, setNoteCount] = useState(0);

    const [errorMessage, setErrorMessage] = useState<string>();

    const shiftService = new ShiftService();
    const isGracePeriod = useTypedSelector(state => state.appProfileReducer.currentUserShift.gracePeriod.isInGracePeriod);

    const getCurrentShiftNotes = async () => {
        setLoading(true);

        await shiftService.getCurrentShiftNotes(localNoteData.roleId, isGracePeriod).then((data) => {
            setLocalNoteData((state) => ({ ...state, locationNotesList: data?.locationNotesList }));
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    const filterBySectionTags = (shiftNote: ShiftNote, tagName: string) => {
        const _notesForSectionTags = structuredClone(shiftNote);
        reportService.filterShiftNoteBySectionTags(_notesForSectionTags, tagName);

        return _notesForSectionTags;
    }

    useEffect(() => {
        if (isExpanded) {
            if (noteTab === NoteTab.AllNotes) {
                setMyShiftCollapseFlag(NoteTab.AllNotes, false);

                if (!localNoteData.locationNotesList)
                    getCurrentShiftNotes();
            }
            else if (noteTab === NoteTab.MyNotes) {
                setMyShiftCollapseFlag(NoteTab.MyNotes, false);
            }
        }
    }, [isExpanded]);

    useEffect(() => {
        setLocalNoteData(noteData);
    }, [noteData]);


    useEffect(() => {
        if (noteTab === NoteTab.HistoryNotes) {
            let _cnt = 0;
            localNoteData?.locationNotesList?.map(notelist => _cnt = _cnt + notelist.notes.length);
            setNoteCount(_cnt);
        }

        if (!localNoteData || localNoteData === undefined && localNoteData.shiftStatus === ShiftStatus.UnDefined)
            setErrorMessage("No Shift/Notes found");
        else
            setErrorMessage("");

        if (localNoteData) {
            const allNotes: SectionTagShiftNote[] = [];

            sectionTagsMasterList.forEach(sectionTag => {
                const sTagNameVal = sectionTag.name.toLocaleLowerCase();

                const data: SectionTagShiftNote = {
                    isCritical: sTagNameVal == SectionTagConstants.CriticalSectionTagName.toLocaleLowerCase(),
                    shiftNote: filterBySectionTags(localNoteData, sTagNameVal),
                    tag: {
                        id: sectionTag.id,
                        name: sectionTag.name.toLocaleLowerCase(),
                        tagContent: sectionTag.tagContent,
                        tagType: TagType.Section
                    }
                }

                allNotes.push(data);
            });

            setSectionTagShiftNotes(allNotes);
        }
    }, [localNoteData]);

    /* handle notes accordian on click of collapse all notes*/
    useEffect(() => {
        if (isExpanded && (collapseStateAllNotes || collapseStateMyNotes) && (noteTab === NoteTab.AllNotes || noteTab === NoteTab.MyNotes))
            setIsExpanded(false);
    }, [collapseStateAllNotes, collapseStateMyNotes]);

    const shiftNotesErrorClassDef = () => {
        if (noteTab == NoteTab.SelectRoleNotes || noteTab == NoteTab.HistoryNotesModal)
            return errorContStyle;
        else
            return "px-4 py-2";
    }

    return (
        <div className="d-flex-column flex-grow-1">
            {/*Collapsable Header - Role Name*/}
            {(noteTab != NoteTab.SelectRoleNotes && noteTab != NoteTab.HistoryNotesModal) &&
                <div className={styleDef} aria-label={!isExpanded ? 'expand panel' : 'collapse panel'} onClick={() => setIsExpanded(!isExpanded)}>
                    <div>
                        {localNoteData &&
                            <>
                                <span>{localNoteData.roleName} - {localNoteData.jobClassShortName}</span>
                                {noteTab === NoteTab.HistoryNotes && <span className="pl-1">{`(${noteCount})`}</span>}
                            </>
                        }
                    </div>
                    <div className="shift-icon-plus d-flex align-items-center">
                        <span className={!isExpanded ? 'pi pi-plus' : 'pi pi-minus'}></span>
                    </div>
                </div>
            }

            {/*List of Note Cards*/}
            <div className="d-flex-column flex-grow-1">
                {
                    (props.loading || loading) ? <div className="p-3"><NoteCardSkeleton /></div> :
                        (isExpanded && sectionTagShiftNotes && sectionTagShiftNotes?.map((sectionData: SectionTagShiftNote, index) => {
                            const sectionExist = sectionTagShiftNotes.filter(s => s.shiftNote.locationNotesList?.length > 0 && s.tag.name == sectionData.tag.name); 
                            
                            if(sectionExist.length > 0 && sectionData.shiftNote.locationNotesList.length === 0) {
                                return <></>;
                            }

                            return <NoteCardSection key={index} noteType={props.noteTab} 
                                        sectionTagShiftNote={sectionData} selectedNoteIds={props.selectedNoteIds} 
                                        setChecked={props.setChecked} canSelect={props.canSelect} />; 
                        }))
                }

                {
                    !(props.loading || loading) && errorMessage && isExpanded && <div className={shiftNotesErrorClassDef()}>
                        {errorMessage}
                    </div>
                }
            </div>
        </div>
    );
};

export default ShiftNotes;