import { Modal } from "react-bootstrap";
import "./EditRotationDetailsModal.scss";
import RotationSection from "../RotationSection";
import { Rotation } from "src/models/Rotation";
import { useTypedSelector } from "src/hooks/useTypedselector";

interface EditRotationDetailsModalProps {
  show: boolean
  toggleShowModal: () => void;
  titleText: string;
  titleClass?: string;
  bodyClass?: string;
  footerClass?: string;
  saveRotationDetailsButtonText?: string;
  setRotation: (rotation: any) => void
  isSavingChanges: boolean
  onSaveChanges: any
}

const EditRotationDetailsModal = (props: EditRotationDetailsModalProps) => {

  const currentRotation: Rotation = useTypedSelector(state => state.appProfileReducer.currentUserRotation.rotation);

  return (
    <Modal
      show={props.show}
      onHide={props.toggleShowModal}
      backdrop="static"
      centered
      keyboard={!props.isSavingChanges}
      scrollable
    >
      <Modal.Header closeButton={!props.isSavingChanges} closeLabel='Close' className="border-bottom-0">
        <Modal.Title bsPrefix={props.titleClass}>
          {props.titleText}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body bsPrefix={props.bodyClass} >
        <RotationSection
          isEditMode={true}
          currentRotation={currentRotation}
          setRotation={props.setRotation}
          shouldDisplayVertically={true}
        />
      </Modal.Body>
      <Modal.Footer className='border-0'>
        {/*Cancel button*/}
        <button
          type="button"
          className="btn flex-fill btn-secondary"
          disabled={props.isSavingChanges}
          onClick={props.toggleShowModal}
        >
          cancel
        </button>
        {/*placeholder*/}
        <div className='px-1' />
        {/*Confirmation button*/}
        <button
          type="button"
          className={'btn flex-fill btn-primary'}
          disabled={props.isSavingChanges}
          onClick={props.onSaveChanges}
        >
          {props.saveRotationDetailsButtonText}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditRotationDetailsModal;

EditRotationDetailsModal.defaultProps = {
  titleText: 'edit rotation',
  titleClass: 'modal-title large gotham-bold',
  bodyClass: 'modal-body medium pt-0',
  footerClass: 'modal-footer border-0',
  saveRotationDetailsButtonText: 'save changes',
};
