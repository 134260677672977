/* eslint-disable max-len */

import { useEffect, useRef, useState } from "react";
import "./AddNote.scss";
import { BsPinAngle } from 'react-icons/bs';
import { BiPurchaseTag } from 'react-icons/bi';
import { MdPriorityHigh, MdHistory, MdOutlineAssignment } from 'react-icons/md';
import { Tooltip as TooltipPR } from 'primereact/tooltip';
import { FiSend, FiXCircle, FiCheck, FiChevronDown } from 'react-icons/fi';
import { Shift, ShiftSchedule } from "src/models/Shift";
import { AddRotationNote, AddShiftNote, NoteAddModelBase, NoteTemplateAddModel, SingleNote } from "src/models/NoteItem";
import NoteService from "src/services/api/note.service";
import { Toaster } from "src/services/api/toaster.service";
import { DateTimeHelper } from "src/helpers/DateTimeHelper";
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import { Menu } from 'primereact/menu';
import TagModal from "../TagModal";
import NoteItemSection from "../NoteItemSection";
import { useTypedSelector } from 'src/hooks/useTypedselector';
import AttachmentService from 'src/services/api/attachment.service';
import { Attachment } from "src/models/Attachment";
import { BlobFile } from "src/models/BlobFile";
import { RoleTag } from "src/models/Tag";
import { useMyShiftActionCreators } from "src/hooks/useActions";
import { TagType } from "src/enums/TagType";
import { TemplateType } from "src/enums/TemplateType";
import { NoteValidationType } from "src/enums/NoteValidationType";
import TagService from "src/services/api/tag.service";
import LocationSelection from "../LocationSelection";
import NoteTemplateModal from "../NoteTemplateModal";
import NoteTemplateSaveModal from "../NoteTemplateSaveModal";
import { NoteType } from "src/enums/NoteTypes";
import NoteSelectionType from "../NoteSelectionType";
import { Editor, EditorTools, EditorUtils, ProseMirror, EditorMountEvent, } from '@progress/kendo-react-editor';
import EditorCustomFormatBlock from "./editorCustomFormatBlock";
import { EditorCustomForeColor, EditorCustomBackColor } from "./editorCustomColor";
import { Rotation } from "src/models/Rotation";
import { useAppProfileActionCreators, useTemplateActionCreators } from 'src/hooks/useActions';
import { NoteAction } from "src/models/NoteAction";

import '@progress/kendo-theme-default/dist/all.css';
import rteStyleDef from './rteStyleDef';

import { BlockUI } from 'primereact/blockui';
import SignalRHubInterceptor from "src/interceptors/signalr.hub_interceptor";
import { HubConnectionState } from "@microsoft/signalr";
import { ActionType } from "src/enums/ActionType";
import GracePeriodHubConstants from 'src/constants/grace-period-hub-constants';
import { NoteTemplateType } from "src/models/NoteTemplate";
import ConfirmationModal from "../ConfirmationModal";
import SectionTagConstants from "src/constants/section-tag-constants";

interface AddNoteProps {
    currentShift: Shift,
    currentRotation?: Rotation;
    previousShiftRoleId: string,
    editNote?: SingleNote,
    shiftSchedule?: ShiftSchedule,
    refreshNotes: any,
    setNoteToEdit?: any,
    setNoteDialogDisplay?: any,
    isEditNoteIsFullScreen?: boolean
}

const AddNote = (props: AddNoteProps) => {
    const noteAddSuccessMsg = "Note added successfully";
    const noteAddErrorMsg = "Failed to save note";

    const noteUpdateSuccessMsg = "Note updated successfully";
    const noteUpdateErrorMsg = "Failed to update note";

    const invalidFileExtMsgP1 = "The following file(s) are not supported:";
    const invalidFileExtMsgP2 = "Please upload file(s) with the supported extensions:";

    const invalidFileSizeMsgP1 = "The following file(s) exceed the maximum size allowed (15MB):";

    const duplicateFileNameMsgP1 = "The following file(s) were already uploaded:";
    const duplicateFileNameMsgP2 = "Please change the name and upload again or delete previously attached file";

    const templateAppliedMsg = "Selected template applied to the note";
    const noteValueLengthExceededMsg = "You have exceeded the character limit";

    const noteTitleValueLengthExceededMsg = "You have exceeded the character limit";

    const promptModalTemplateTitle = "change note type?";
    const promptModalTemplateBodyText = "If you click yes, you will lose the changes";

    const [noteTitleValue, setNoteTitleValue] = useState<string>('');

    const [noteTextValue, setNoteTextValue] = useState('');
    const [noteTextValueLength, setNoteTextValueLength] = useState<number>(0);

    const [noteValidations, setNoteValidations] = useState<NoteValidationType[]>([]);
    const [locationValue, setLocationValue] = useState('');
    const [performedDT, setPerformedDT] = useState(null);
    const [persistentEndDT, setPersistentEndDT] = useState(null);
    const shiftSchedule = useTypedSelector(state => state.appProfileReducer.currentUserShift.shift?.shiftSchedule);
    const shiftId = useTypedSelector(state => state.appProfileReducer.currentUserShift.shift?.id);
    const rotationId = useTypedSelector(state => state.appProfileReducer.currentUserRotation.rotation?.id);
    const currentUserProfile = useTypedSelector(state => state.appProfileReducer.userProfileState.userProfile);
    const { getCurrentRotation } = useAppProfileActionCreators();

    const shiftDefaultTemplate = useTypedSelector(state => state.templateReducer.shiftDefaultTemplate);
    const rotationDefaultTemplate = useTypedSelector(state => state.templateReducer.rotationDefaultTemplate);
    const { getDefaultTemplate } = useTemplateActionCreators();

    const [showTemplateConfirmationModal, setShowTemplateConfirmationModal] = useState(false);

    const notePayload = useTypedSelector(state => state.myShiftReducer.notePayload);
    const { clearAddNoteDialogVisibility } = useMyShiftActionCreators();

    const graceMinutes = useTypedSelector(state => state.appProfileReducer.shiftSchedule.graceMinutes);
    const minPDT = new Date(shiftSchedule?.beginDateTime + 'Z'); //Min Performed Date Time, convert from UTC to local
    const maxPDT = new Date(shiftSchedule?.endDateTime + 'Z'); //Max Performed Date Time, convert from UTC to local
    maxPDT.setMinutes(maxPDT.getMinutes() + graceMinutes);
    const minPersistentDT = new Date(maxPDT);
    minPersistentDT.setDate(minPersistentDT.getDate() + 1); //Min Persistent End DT is the next day same shift period

    const [isCritical, setIsCritical] = useState(false);
    const [isPersistent, setIsPersistent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rollUpTagsMap, setRollUpTagsMap] = useState(new Map());

    const noteTextboxRef = useRef(null);
    const tagButtonRef = useRef(null);


    /*attachmentBlobList and attachmentModelList represent files that were successfully added to Attachment summary*/
    /* DTO for StorageAccount API. NoteID, Metadata, FileObj*/
    const [attachmentBlobList, setAttachmentBlobList] = useState<BlobFile[]>([]);

    /*Model for UI Attachment Obj, used when comparing previously attached files. FileName, StorageAccountReferenceName*/
    const [attachmentModelList, setAttachmentModelList] = useState<Attachment[]>([]);

    const { incrementDraftNotesCount, decrementDraftNotesCount, SetNewNoteIsDirty } = useMyShiftActionCreators();
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedSectionTags, setSelectedSectionTags] = useState<string[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [showAddTagModal, setShowAddTagModal] = useState(false);
    const [showTemplateModal, setShowTemplateModal] = useState(false);
    const [isBackDateEnabled, setIsBackDateEnabled] = useState(false);
    const [roleTagData, setRoleTagData] = useState<RoleTag[]>([]);

    const [selectedNoteType, setSelectedNoteType] = useState<NoteType>(NoteType.Shift);

    const isSelectedNoteTypeRotation = selectedNoteType == NoteType.Rotation;
    const isSelectedNoteTypeShift = selectedNoteType == NoteType.Shift;

    const service = new NoteService();
    const domParser = new DOMParser();

    const [template, setTemplate] = useState<NoteTemplateType>();

    const isGracePeriod = useTypedSelector(state => state.appProfileReducer.currentUserShift.gracePeriod.isInGracePeriod);
    const hubConnections = useTypedSelector(state => state.hubReducer.connections)
    const roleId = props.currentShift != null ? props.currentShift.role.id : props.previousShiftRoleId;

    const sectionTagsMasterList = useTypedSelector(state => state.myShiftReducer.sectionList || []);

    const maxNoteTitleLength = 100;
    const maxNoteContentLength = 20000;
    const maxFileSize = 15728640; //15 * 1024 * 1024 = 15728640 bytes 

    const locationSelectionRef = useRef(null);
    const menu = useRef(null);

    const { EditorState, EditorView } = ProseMirror;
    const { tableResizing } = EditorUtils;

    const onMount = (event: EditorMountEvent) => {
        const { plugins, doc } = event.viewProps.state;

        const iframeDocument = event.dom.ownerDocument;
        const style = iframeDocument.createElement("style");
        style.appendChild(iframeDocument.createTextNode(rteStyleDef));
        iframeDocument.head.appendChild(style);

        return new EditorView(
            { mount: event.dom },
            {
                ...event.viewProps,
                state: EditorState.create({
                    doc,
                    plugins: [...tableResizing(), ...plugins],
                }),
            }
        );
    };

    const {
        Bold,
        Italic,
        Underline,
        Indent,
        Outdent,
        OrderedList,
        UnorderedList,
        Undo,
        Redo,
        Link,
        Unlink,
        InsertTable,
        AddRowBefore,
        AddRowAfter,
        AddColumnBefore,
        AddColumnAfter,
        DeleteRow,
        DeleteColumn,
        DeleteTable,
        MergeCells,
        SplitCell,
        CleanFormatting,
    } = EditorTools;

    const {
        sanitize,
    } = EditorUtils;

    const [showNoteTemplateSaveModal, setShowNoteTemplateSaveModal] = useState(false);
    const [isNoteContentValid, setIsNoteContentValid] = useState(false);
    const roleSettings = useTypedSelector(state => state.appProfileReducer.roleSettings.roleSettingsDetail);

    const applyTemplate = (noteTemplate: NoteTemplateType) => {
        setTemplate({ ...noteTemplate });
    }

    const toggleAddTagModal = () => {
        setShowAddTagModal(!showAddTagModal)
    }

    const toggleTemplateModal = () => {
        setShowTemplateModal(!showTemplateModal);
    }

    const hideNoteTemplateSaveModal = () => {
        setShowNoteTemplateSaveModal(false);
    }

    const items = [
        {
            label: 'select a template', command: () => {
                toggleTemplateModal();
            }
        },
        {
            label: 'save note as a template', disabled: !isNoteContentValid, command: () => {
                setShowNoteTemplateSaveModal(true);
            }
        }
    ];

    const validateNoteTemplateAddModel = () => {
        const noteMetaData = [];

        // Critical
        if (isCritical)
            noteMetaData.push("Critical");

        // Persistent
        if (isPersistent)
            noteMetaData.push("Persistent");

        // Attachments
        if (attachmentBlobList?.length > 0)
            noteMetaData.push("Attachment(s)");

        // Backdate
        if (performedDT)
            noteMetaData.push("Back date");

        // Location
        if (locationValue)
            noteMetaData.push("Location");

        if (noteMetaData.length > 0) {

            let errorMessage: string = noteMetaData.join(', ');
            errorMessage += " will be ignored";

            Toaster.showWarn(errorMessage);
        }
    }

    const getNoteTemplateAddModel = () => {
        validateNoteTemplateAddModel();

        const noteTemplateAddModel: NoteTemplateAddModel = {
            /* 
            The Note data entity need shiftId because the respective table column is not null.
            In case of NoteType.Template, the shiftId isn't acknowledged during load or autoprofile mapping.
            This will be refactored soon.
            */
            shiftId: props.currentShift.id,
            roleId: props.currentShift.role.id,
            label: "",
            content: noteTextValue.trim(),
            tagNames: [...selectedSectionTags, ...selectedRoles, ...selectedCategories],
            noteType: NoteType.Template,
            isDefault: false,
            templateType: selectedNoteType == NoteType.Shift ? TemplateType.Shift : TemplateType.Rotation,
        };

        return noteTemplateAddModel;
    }

    const getRoleTagData = (roleDataId) => {
        // Only call get roletag data when roleTagData is not present.
        if (roleDataId && (!roleTagData || roleTagData.length === 0)) {
            const tagService = new TagService();
            tagService.getTagsByRoleId(roleDataId).then((res) => {
                setRoleTagData(res);
            });
        }
    }

    const focusNoteTextbox = () => {
        noteTextboxRef.current?.focus();
    }

    function getNoteValueActualLength(noteHtmlContent: string) {
        const actualContentHTMLDocument = domParser.parseFromString(noteHtmlContent, "text/html");

        return actualContentHTMLDocument.body.textContent.length;
    }

    function getNoteValueSanitizedInfo(noteHtmlContent: string) {
        const sanitizeNoteHtmlContent = sanitize(noteHtmlContent);

        const actualContentLength = getNoteValueActualLength(sanitizeNoteHtmlContent);

        return { sanitizeNoteHtmlContent, actualContentLength };
    }

    const defineValidations = (validationTypesToAdd: NoteValidationType[], validationTypesToRemove: NoteValidationType[]) => {
        const validations: NoteValidationType[] = [...noteValidations];

        validationTypesToAdd.forEach((v: NoteValidationType) => {
            if (!validations.includes(v)) {
                validations.push(v);
            }
        });

        const finalValidations: NoteValidationType[] = validations.filter(item => !validationTypesToRemove.includes(item));

        setNoteValidations(finalValidations);
    }

    const handleTitleChange = (title: string) => {

        setNoteTitleValue(title)

        const validationsToAdd: NoteValidationType[] = [];
        const validationsToRemove: NoteValidationType[] = [];

        if (title.length > maxNoteTitleLength) {
            validationsToAdd.push(NoteValidationType.NoteTitleReachedCharacterLimitExceeded)
        }
        else {
            validationsToRemove.push(NoteValidationType.NoteTitleReachedCharacterLimitExceeded)
        }

        defineValidations(validationsToAdd, validationsToRemove)
    }
    /*note content validation*/
    const handleNotesChange = (noteHtmlContent: string) => {
        const resp = getNoteValueSanitizedInfo(noteHtmlContent);

        setNoteTextValue(resp.sanitizeNoteHtmlContent);
        setNoteTextValueLength(resp.actualContentLength);

        const validationsToAdd: NoteValidationType[] = [];
        const validationsToRemove: NoteValidationType[] = [];

        resp.actualContentLength > maxNoteContentLength ? validationsToAdd.push(NoteValidationType.NoteReachedCharacterLimitExceeded) : validationsToRemove.push(NoteValidationType.NoteReachedCharacterLimitExceeded);

        if (resp.actualContentLength > 0) {
            validationsToRemove.push(NoteValidationType.NoteContentMissing)
        }

        defineValidations(validationsToAdd, validationsToRemove)
    }

    useEffect(() => {
        if (isBackDateEnabled)
            setPerformedDT(null);
    }, [isBackDateEnabled]);

    // useEffect(() => {
    //     focusNoteTextbox();
    // });

    useEffect(() => {
        const roleDataId = props.currentShift?.role?.id || props.previousShiftRoleId;
        getRoleTagData(roleDataId);
        getDefaultTemplate(roleDataId);
        incrementDraftNotesCount();

        return () => {
            decrementDraftNotesCount();
        }
    }, []);

    useEffect(() => {

        if (template && template.note.id && template.note.content && !props.editNote) {
            const defaultTemplate = selectedNoteType == NoteType.Shift ? shiftDefaultTemplate : rotationDefaultTemplate;

            if (defaultTemplate && template.note.id === defaultTemplate.note.id && template.actionType === ActionType.Initialize) {
                handleNotesChange(template.note.content);
            }

            if (defaultTemplate && template.note.id === defaultTemplate.note.id && template.actionType === ActionType.Apply) {
                handleNotesChange(noteTextValue.concat(template.note.content));
            }

            if (!defaultTemplate || (defaultTemplate && template.note.id !== defaultTemplate.note.id)) {
                handleNotesChange(noteTextValue.concat(template.note.content));
            }

            setSelectedSectionTags(template.note.tags?.filter(x => x.tagType === TagType.Section).map(x => x.name) || []);
            setSelectedCategories(template.note.tags?.filter(x => x.tagType === TagType.Category).map(x => x.name) || []);
            setSelectedRoles(template.note.tags?.filter(x => x.tagType === TagType.Role).map(x => x.name) || []);

            if (template.actionType === ActionType.Apply) {
                Toaster.showSuccess(templateAppliedMsg);
            }
        }
    }, [template]);

    useEffect(() => {
        if (notePayload && notePayload.tag) {
            const tags: string[] = [];
            tags.push(notePayload.tag.name);

            if (notePayload?.modalVisibility) {
                setSelectedNoteType(notePayload.noteType);
                setSelectedSectionTags(tags);
            }
        }
    }, [notePayload?.modalVisibility]);

    useEffect(() => {
        const val = (noteTextValueLength == 0 || noteTextValueLength > maxNoteContentLength);
        setIsNoteContentValid(!val);
    }, [noteTextValue]);

    useEffect(() => {
        let _rollUptags = [];
        const roleTags = [...roleTagData];
        if (roleTags?.length >= 0) {
            _rollUptags = roleTags.flatMap(roleTag => {
                if (roleTag.rollUpTagNames?.length > 0)
                    return roleTag.rollUpTagNames;
                else
                    return [];
            });

            // Roll up TagNames is a dictionary object which holds boolean value based on the presence of the roleTag among the selected tags
            const _rollUptagsMap = new Map();
            _rollUptags.map(t => _rollUptagsMap.set(t, false));
            setRollUpTagsMap(_rollUptagsMap);
        }
    }, [roleTagData]);

    const getFileName = (fileName: string) => {
        //file name length limit
        const fileExtesion: string = '.' + fileName.split('.').pop();
        let fileNameModified = fileName.replace(fileExtesion, "");
        fileNameModified = fileNameModified.substring(0, 15).trim();

        return `${fileNameModified}${fileExtesion}`;
    }

    useEffect(() => {
        const roleDataId = props.currentShift?.role?.id || props.previousShiftRoleId;
        getCurrentRotation(roleDataId);
    }, [rotationId]);

    useEffect(() => {
        const itemList = [...attachmentBlobList];

        //remove files form memory
        itemList.forEach((file: BlobFile) => {
            const fileExists: number = attachmentModelList.findIndex((value) => {
                return value.displayFileName == getFileName(file.file.name);
            });

            if (fileExists == -1) {
                itemList.splice(itemList.findIndex(a => a.file.name == file.file.name), 1);
            }

            setAttachmentBlobList(itemList);

        });
    }, [attachmentModelList]);

    // Condition(s) to confirm if the Note is populated to ensure navigation prompt
    useEffect(() => {
        if (!props.editNote) {
            if ( (noteTextValueLength == 0 && !isCritical && !isPersistent && attachmentModelList?.length == 0 && selectedRoles?.length == 0 && selectedSectionTags?.length == 0 && selectedCategories?.length == 0) || (selectedNoteType == NoteType.Shift && noteTextValue === shiftDefaultTemplate?.note.content ) || (selectedNoteType == NoteType.Rotation && noteTextValue === rotationDefaultTemplate?.note.content ) )
                SetNewNoteIsDirty(false);
            else {
                SetNewNoteIsDirty(true);
            }
        }
    }, [noteTextValue, locationValue, isCritical, isPersistent, attachmentModelList, selectedRoles, selectedCategories, selectedSectionTags]);

    const showError = (validationsTypes: NoteValidationType[]) => {
        return noteValidations.some((v: NoteValidationType) => validationsTypes.indexOf(v) != -1);
    }

    /*location validation*/
    const handleLocationChange = (e) => {
        setLocationValue(e.value);
        defineValidations([], [NoteValidationType.LocationNotSelected]);
    }

    /*performed validation*/
    const handlePerformedDTChange = (e) => {
        if (minPDT > e.value || e.value > maxPDT) {
            Toaster.showWarn(`Performed time must be betweeen: ${minPDT.toLocaleString()} and ${maxPDT.toLocaleString()}`);
            defineValidations([NoteValidationType.PerformedTimeOutOfBounds], [])
        }
        else
            defineValidations([], [NoteValidationType.PerformedTimeOutOfBounds]);

        setPerformedDT(() => e.value);
    }

    /*return persistentEndDT.Date + shiftSchedule.EndDT.Time*/
    const setPersistentEndTime = (persistentDate: any): Date => {
        // Date is correct but time should be set to end of Shift Schedule
        const persistentDT = new Date(persistentDate);

        persistentDT.setHours(maxPDT.getHours());
        persistentDT.setMinutes(maxPDT.getMinutes());
        persistentDT.setMilliseconds(0);

        return persistentDT;
    }

    const validateAddNoteFields = () => {
        let isValid = true;
        const fieldsMissing = [];
        const validationsToAdd: NoteValidationType[] = [];
        const messages: string[] = []

        if (noteTitleValue) {
            if (noteTitleValue.length > maxNoteTitleLength) {
                validationsToAdd.push(NoteValidationType.NoteTitleReachedCharacterLimitExceeded);
                fieldsMissing.push('fix title length');
            }
        }

        if (!noteTextValue || noteTextValueLength == 0) {
            validationsToAdd.push(NoteValidationType.NoteContentMissing);
            fieldsMissing.push('content');
        }
        else {
            if (noteTextValueLength > maxNoteContentLength) {
                validationsToAdd.push(NoteValidationType.NoteReachedCharacterLimitExceeded);
                fieldsMissing.push('fix length');
            }
        }

        if (!locationValue && !isSelectedNoteTypeRotation) {
            validationsToAdd.push(NoteValidationType.LocationNotSelected);
            fieldsMissing.push('location');
        }

        if (performedDT && (minPDT > performedDT || performedDT > maxPDT)) {
            isValid = false;
            validationsToAdd.push(NoteValidationType.PerformedTimeOutOfBounds);
            messages.push(`Performed time must be betweeen: ${minPDT.toLocaleString()} and ${maxPDT.toLocaleString()}`);
        }

        if (fieldsMissing.length > 0) {
            //isValid = isSelectedNoteTypeRotation ? true : false;
            isValid = false;
            messages.push(`Please enter missing field(s): ${fieldsMissing.join(', ')}`);
        }

        if (isValid === false) {
            defineValidations(validationsToAdd, [])
            Toaster.showErrorMultiple(messages);
        }

        return isValid;
    }

    const removeEditedAttachmentFiles = async (oldAttachments: Attachment[], newAttachments: Attachment[]) => {
        const attachmentService = new AttachmentService();

        try {
            const promises: Promise<string>[] = [];
            const newAttachmentsStr = newAttachments.filter(x => x.storageFileNameReference?.length > 0).map(x => x.storageFileNameReference);
            const removedAttachments = oldAttachments.filter(e => !newAttachmentsStr.some(y => e.storageFileNameReference.includes(y)));

            removedAttachments.forEach(async (blobFile: Attachment) => {
                promises.push(attachmentService.removeAttachmentFiles(props.editNote.id, blobFile.storageFileNameReference));
            });

            //parallel upload
            await Promise.all(promises);
        }
        catch (ex) {
            console.log(ex);
        }
    }

    const uploadAttachmentFiles = async (note: SingleNote) => {
        const attachmentService = new AttachmentService();

        try {
            const promises: Promise<string>[] = [];

            attachmentBlobList.forEach(async (blobFile: BlobFile) => {
                blobFile.noteId = note.id;
                promises.push(attachmentService.add(blobFile));
            });

            //parallel upload
            await Promise.all(promises);

            setAttachmentBlobList([]);
        }
        catch (ex) {
            console.log(ex);
        }
    }

    const dismissAddNoteModal = () => {
        clearAddNoteDialogVisibility();
    }

    const addNoteRefreshNotes = () => {
        props?.refreshNotes();
    }

    const resetNote = () => {
        setNoteTitleValue('')
        setNoteTextValue('');
        setNoteTextValueLength(0);

        locationSelectionRef.current.resetSelection();
        setIsCritical(false);
        setIsPersistent(false)
        setSelectedSectionTags([]);
        setSelectedCategories([]);
        setSelectedRoles([]);
        setAttachmentModelList([]);
        setNoteValidations([]);
        setPerformedDT(null);
        setPersistentEndDT(null);
        setIsBackDateEnabled(false);

        if (props.editNote)
            props.setNoteToEdit(null);
    }

    const loadDefaultTemplate = async () => {
        if (selectedNoteType == NoteType.Shift) {
            if (shiftDefaultTemplate != null) {
                applyTemplate(shiftDefaultTemplate);
            } else if (!props.editNote) {
                resetNote();
            }
        }

        if (selectedNoteType == NoteType.Rotation) {
            if (rotationDefaultTemplate != null) {
                applyTemplate(rotationDefaultTemplate);
            } else if (!props.editNote) {
                resetNote();
            }
        }
    }

    useEffect(() => {
        if (isSelectedNoteTypeRotation) {
            setIsPersistent(false);
            setIsBackDateEnabled(false);
            setSelectedRoles([]);
        }

        loadDefaultTemplate();
    }, [selectedNoteType]);

    useEffect(() => {
        loadDefaultTemplate();
    }, [shiftDefaultTemplate, rotationDefaultTemplate]);

    const notifyNoteModificationInGracePeriod = (noteId: string, actionType: ActionType) => {
        if (!isGracePeriod) return;

        const signalRHubInterceptor: SignalRHubInterceptor = hubConnections.get(GracePeriodHubConstants.gracePeriodHubName);

        if (signalRHubInterceptor.connection.state == HubConnectionState.Disconnected ||
            signalRHubInterceptor.connection.state == HubConnectionState.Reconnecting) {
            console.log("SignalR is disconnected");
            return;
        }

        const notificationMessage: NoteAction = new NoteAction()

        notificationMessage.noteId = noteId;
        notificationMessage.actionType = actionType;

        signalRHubInterceptor.send(GracePeriodHubConstants.gracePeriodHubMethods.noteModifiedInGracePeriod, notificationMessage, () => null);
    }


    useEffect(() => {  
        const isCriticalSectionTagSelected = selectedSectionTags?.some(x => x.toLocaleLowerCase() === SectionTagConstants.CriticalSectionTagName.toLocaleLowerCase());
        setIsCritical(isCriticalSectionTagSelected);
    }, [showAddTagModal, selectedSectionTags]);

    const sendNote = () => {

        if (loading) {
            return;
        }

        Toaster.clearAllToastMessages();

        if (!validateAddNoteFields())
            return;

        if (selectedSectionTags?.length == 0) {
            selectedSectionTags.push(SectionTagConstants.OtherSectionTagName);
        }

        const request: NoteAddModelBase = isSelectedNoteTypeShift ? new AddShiftNote({
            shiftId,
            content: noteTextValue.trim(),
            tagNames: [...selectedSectionTags, ...selectedRoles, ...selectedCategories],
            locationId: locationValue,
            copiedFromId: null,
            isCritical,
            isPersistent,
            performed: performedDT ? new Date(performedDT) : new Date(),
            persistentEndDateTime: isPersistent && persistentEndDT ? setPersistentEndTime(persistentEndDT) : null,
            id: props.editNote?.id
        }) : new AddRotationNote({
            title: noteTitleValue.trim(),
            rotationId: rotationId,
            shiftId,
            content: noteTextValue.trim(),
            tagNames: [...selectedSectionTags, ...selectedRoles, ...selectedCategories],
            copiedFromId: null,
            isCritical,
            id: props.editNote?.id
        })

        setLoading(true);

        // update
        if (request.id) {
            service.updateNote(request).then(async () => {
                // Remove files which were removed.
                await removeEditedAttachmentFiles(props.editNote.attachments, attachmentModelList);

                // Attaches new files
                await uploadAttachmentFiles(props.editNote);
                resetNote();
                Toaster.showSuccess(noteUpdateSuccessMsg);
                addNoteRefreshNotes();

                if (request.noteType == NoteType.Shift) {
                    notifyNoteModificationInGracePeriod(request.id, ActionType.Edit);
                }

            }).catch((error) => {
                console.error('Update note failed with error: ', error?.message);
                Toaster.showError(noteUpdateErrorMsg);
            }).finally(() => {
                setLoading(false);
            })
        }
        // add
        else {
            service.addNote(request).then(async (note: SingleNote) => {
                await uploadAttachmentFiles(note);
                resetNote();
                Toaster.showSuccess(noteAddSuccessMsg);
                dismissAddNoteModal();
                addNoteRefreshNotes();

                if (request.noteType == NoteType.Shift) {
                    notifyNoteModificationInGracePeriod(note.id, ActionType.Create);
                }

                loadDefaultTemplate();
            }).catch((error) => {
                console.error('Add note failed with error: ', error?.message);
                Toaster.showError(noteAddErrorMsg);
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    /* Validate file extension, size, duplicate names
     * Show toast for invalid files
     * return list of valid files
     */
    const fileValidation = (event) => {
        const extensionFileSupported: string[] = ["rtf", "txt", "pdf", "doc", "docx", "csv", "xls", "xlsx", "png", "jpg", "jpeg", "gif", "mp4", "mov", "mjpeg", "mpeg", "mpeg4", "mpeg2", "wmv", "avi"]
        const validationInvalidFileExtension = [];
        const validationInvalidFileSize = [];
        const validationDuplicateFileName = [];
        const validFiles = [];

        for (let i = 0; i < event.files.length; i++) {
            const file = event.files[i]; //Newly selected files
            const fileExtesion: string = file.name.split('.').pop();

            //File extenstion validation
            if (!extensionFileSupported.includes(fileExtesion.toLocaleLowerCase())) {
                validationInvalidFileExtension.push(file.name);
                continue;
            }

            //File size validation
            if (file.size > maxFileSize) {
                validationInvalidFileSize.push(file.name);
                continue;
            }

            //Duplicate Validation
            //Check if new selected files matches previously attached files
            //OR another file in the new selected list
            if (
                attachmentModelList.some(attachment => { return attachment.displayFileName == file.name }) ||
                validFiles.some(validFile => { return validFile.name == file.name })
            ) {
                validationDuplicateFileName.push(file.name);
                continue;
            }

            //Passed validation
            validFiles.push(event.files[i]);
        }

        if (validationInvalidFileExtension.length > 0) {
            Toaster.showError(`${invalidFileExtMsgP1} ${validationInvalidFileExtension.join(', ')}. 
            ${invalidFileExtMsgP2} ${extensionFileSupported.join(', ')}`);
        }

        if (validationInvalidFileSize.length > 0) {
            Toaster.showError(`${invalidFileSizeMsgP1} ${validationInvalidFileSize.join(', ')}`);
        }

        if (validationDuplicateFileName.length > 0) {
            Toaster.showError(`${duplicateFileNameMsgP1} ${validationDuplicateFileName.join(', ')}. 
            ${duplicateFileNameMsgP2}`);
        }

        return validFiles;
    }

    /*Validate selected files and add them to list of existing attachmentModelList*/
    const onSelectAttachmentFiles = async (event: any) => {
        const attachmentList: Attachment[] = [];
        const attachmentFileList: BlobFile[] = [];

        /*Show toast for invalid files, return list of valid files*/
        const validFiles = fileValidation(event);

        for (let i = 0; i < validFiles.length; i++) {
            const fileName: string = validFiles[i].name;

            //file
            const blobFile = new BlobFile();
            blobFile.file = validFiles[i];

            attachmentFileList.push(blobFile);
            setAttachmentBlobList([...attachmentBlobList, ...attachmentFileList]);

            //file name length limit
            const fileNameModified: string = getFileName(fileName);

            //attachment records
            const attachment = new Attachment();
            attachment.displayFileName = fileNameModified;
            attachmentList.push(attachment);
        }

        setAttachmentModelList([...attachmentModelList, ...attachmentList]);

        //clean prime react file upload
        event.options.clear();
    }

    const onRollUpSelection = (checked: boolean) => {
        //Add or Remove Section Tag named "Critical"
        if (checked) {
            const criticalSectionTag = sectionTagsMasterList.find(tag => tag.name.toLocaleLowerCase() === SectionTagConstants.CriticalSectionTagName.toLocaleLowerCase());
            if (criticalSectionTag) {
                setSelectedSectionTags([criticalSectionTag.name]);
            }
        }
        else {
            const otherSectionTag = sectionTagsMasterList.find(tag => tag.name.toLocaleLowerCase() === SectionTagConstants.OtherSectionTagName.toLocaleLowerCase());
            if (otherSectionTag) {
                setSelectedSectionTags([otherSectionTag.name]);
            }
        }


        if (rollUpTagsMap && isSelectedNoteTypeShift) {
            const _selectedRoles = [...selectedRoles];

            if (checked) {
                rollUpTagsMap.forEach((value: boolean, k: string) => {
                    // Set the rollup TagName's value to true when the tagName is not present in the selected tags list
                    // By doing so, during toggle back, we can remove the tags that are only applied or having value set to true
                    if (!selectedRoles.includes(k)) {
                        _selectedRoles.push(k);
                        rollUpTagsMap.set(k, true);
                    }
                })
            }
            else {
                rollUpTagsMap.forEach((value: boolean, k: string) => {
                    const tagIndex = _selectedRoles.indexOf(k);

                    // Remove tags form the selected tags list only when the TagName's is true
                    if (value && tagIndex != -1) {
                        _selectedRoles.splice(tagIndex, 1);
                        rollUpTagsMap.set(k, false);
                    }
                })
            }

            setRollUpTagsMap(new Map(rollUpTagsMap));
            setSelectedRoles(_selectedRoles);
        }

        setIsCritical(checked);
    }

    useEffect(() => {
        if (props.editNote) {
            const persistentEndDateTimeTemp = DateTimeHelper.isMAXDateTime(props.editNote.persistentEndDateTime) || !props.editNote.isPersistent
                ? null
                : new Date(props.editNote.persistentEndDateTime)

            setNoteTitleValue(props.editNote.title)
            handleNotesChange(props.editNote.content);
            setLocationValue(props.editNote.locationId);
            setPerformedDT(new Date(props.editNote.performedUTC + 'Z')); //props.performed is in UTC, converting it to JS DT
            setAttachmentModelList(props.editNote.attachments);
            setSelectedSectionTags(props.editNote.tags?.filter(x => x.tagType === TagType.Section).map(x => x.name) || []);
            setSelectedCategories(props.editNote.tags?.filter(x => x.tagType === TagType.Category).map(x => x.name) || []);
            setSelectedRoles(props.editNote.tags?.filter(x => x.tagType === TagType.Role).map(x => x.name) || []);
            setIsCritical(props.editNote.isCritical);
            setIsPersistent(props.editNote.isPersistent);
            setPersistentEndDT(persistentEndDateTimeTemp);
            setSelectedNoteType(props.editNote.noteType);
        }
    }, [props.editNote]);

    const hideTemplateConfirmationModal = () => {
        setShowTemplateConfirmationModal(false);
    }

    const setChangeNoteTemplate = (noteType: NoteType) => {
        const newNoteType = noteType === NoteType.Shift ? NoteType.Rotation : NoteType.Shift
        setSelectedNoteType(newNoteType);
    }

    const onNoteTypeChange = (noteType: NoteType) => {
        if (noteType === NoteType.Rotation && shiftDefaultTemplate && noteTextValue !== shiftDefaultTemplate.note.content) {
            setShowTemplateConfirmationModal(true);
        } else if (noteType === NoteType.Shift && rotationDefaultTemplate && noteTextValue !== rotationDefaultTemplate.note.content) {
            setShowTemplateConfirmationModal(true);
        } else {
            setSelectedNoteType(noteType);
        }
    }

    const constraints: React.CSSProperties = {
        maxHeight: window.innerHeight - 180,
        minHeight: window.innerHeight - 180,
    };
    const resize: React.CSSProperties = {
        resize: 'both'
    };

    {/*Add notes by selecting location, Persistant, Priority options*/ }
    return (
        <BlockUI blocked={loading} containerClassName="d-flex-column flex-grow-1" template={<i className="pi pi-spin pi-spinner" style={{ fontSize: '3rem' }}></i>}>
            {/*Add Note Header*/}
            <div className="d-flex">
                <h6 className={`d-flex flex-grow-1 align-items-center text-left m-0 p-0 add-note-title ${showError([NoteValidationType.NoteReachedCharacterLimitExceeded, NoteValidationType.NoteContentMissing]) ? 'error' : ''}`}>
                    {props.editNote ? 'edit note' : 'add note'}
                    {showError([NoteValidationType.NoteReachedCharacterLimitExceeded]) ? <span className="pl-4 error-message">{noteValueLengthExceededMsg}</span> : null}
                </h6>
                <h6 className={`d-flex flex-grow-1 align-items-center text-left m-0 p-0 ${showError([NoteValidationType.NoteTitleReachedCharacterLimitExceeded]) ? 'error' : ''}`}>
                    {showError([NoteValidationType.NoteTitleReachedCharacterLimitExceeded]) ? <span className="pl-4 error-message">{noteTitleValueLengthExceededMsg}</span> : null}
                </h6>
                <div className={props.editNote ? 'd-none' : 'd-flex'}>
                    <button className="btn button-text" onClick={() => {
                        dismissAddNoteModal();
                    }}
                        type="button">
                        hide
                        <FiChevronDown className="normal ml-1" />
                    </button>
                </div>
            </div>

            {selectedNoteType === NoteType.Rotation &&
                <div className="d-flex mb-2 border">
                    <InputText value={noteTitleValue}
                        placeholder="Enter note title"
                        className={`d-flex flex-grow-1 notes-inputbox rounded-0 ${showError([NoteValidationType.NoteTitleReachedCharacterLimitExceeded]) ? 'error-border' : 'border-bottom'}`}
                        onChange={(e) => handleTitleChange(e.target.value)} />
                </div>}

            <div className="d-flex flex-grow-1">
                {/*Add Note Body*/}
                <Editor
                    onMount={onMount}
                    resizable={props.isEditNoteIsFullScreen}
                    style={props.isEditNoteIsFullScreen ? { height: 360, ...constraints, ...resize } : {}}
                    tools={[
                        [Bold, Italic, Underline],
                        [EditorCustomForeColor, EditorCustomBackColor],
                        [CleanFormatting],
                        [EditorCustomFormatBlock],
                        [Undo, Redo], [Link, Unlink],
                        [OrderedList, UnorderedList, Indent, Outdent],
                        [InsertTable],
                        [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
                        [DeleteRow, DeleteColumn, DeleteTable],
                        [MergeCells, SplitCell],
                    ]}
                    value={sanitize(noteTextValue)}
                    onChange={(event) => handleNotesChange(event.html)}
                    className={`d-flex flex-grow-1 notes-inputbox rounded-0 ${showError([NoteValidationType.NoteReachedCharacterLimitExceeded, NoteValidationType.NoteContentMissing]) ? 'error-border' : 'border-bottom'}`}
                    ref={noteTextboxRef}
                />
            </div>

            {/*Tags Summary, Attachment summary section*/}
            {(attachmentModelList.length > 0 || selectedSectionTags.length > 0 || selectedRoles.length > 0 || selectedCategories.length > 0) &&
                <div className="border border-top-0 p-1">
                    <NoteItemSection
                        noteId={''}
                        selectedSection={selectedSectionTags}
                        selectedCategory={selectedCategories}
                        selectedRoles={selectedRoles}
                        setSelectedSectionTags={setSelectedSectionTags}
                        setSelectedCategories={setSelectedCategories}
                        setSelectedRoles={setSelectedRoles}
                        selectedAttachments={attachmentModelList}
                        setSelectedAttachments={setAttachmentModelList}
                        selectedReadOnlyRoles={[]}
                        editMode={true}
                        loading={loading}
                    />
                </div>
            }

            {/*Add Note Footer (Bottom Buttons)*/}
            <div className="d-flex flex-wrap justify-content-between align-items-center no-gutters border border-top-0 p-1">
                <div className="d-flex flex-wrap gap-1 flex-grow-1">
                    {/*Critical flag*/}
                    <TooltipPR target=".ttIsCriticalBtn" content="note will be marked as important and will roll up" />
                    <button
                        className={`${isCritical ? "btn-danger" : "btn-secondary"} ttIsCriticalBtn btn icon border-0 p-1`}
                        onClick={() => onRollUpSelection(!isCritical)}
                        type="button"
                        disabled={loading}
                        aria-label="note will be marked as important and will roll up">
                        <MdPriorityHigh className="extraLarge" />
                    </button>

                    {/*Tag flag*/}
                    <TooltipPR target=".tagsModalBtn" content="add section, category and role tags" position="top" />
                    <button
                        className={`${selectedSectionTags.length + selectedCategories.length + selectedRoles.length > 0 ? 'btn-secondary-selected' : ''} btn btn-secondary icon border-0 p-1 tagsModalBtn`}
                        onClick={() => { toggleAddTagModal(); tagButtonRef.current.blur() }}
                        type="button"
                        disabled={loading}
                        ref={tagButtonRef}
                        aria-label="add category and role tags">
                        <BiPurchaseTag className="extraLarge" />
                    </button>

                    {/*Persistent flag*/}
                    <div className="d-flex">
                        <TooltipPR target=".pinNoteBtn" content="pin note, carry over from shift to shift" position="top" />
                        <button
                            className={`${isPersistent ? 'btn-secondary-selected' : ''} btn btn-secondary icon border-0 p-1 pinNoteBtn`}
                            onClick={() => setIsPersistent(!isPersistent)}
                            type="button"
                            disabled={loading || isSelectedNoteTypeRotation}
                            aria-label="pin note, carry over from shift to shift">
                            <BsPinAngle className="extraLarge" />
                        </button>

                        {/*Persistent Time Textbox*/}
                        {isPersistent && <div className="col-auto px-1">
                            <Calendar className="px-1"
                                id="persistentBoxDT"
                                value={persistentEndDT}
                                //only enabling future date to be selected
                                minDate={minPersistentDT}
                                placeholder={"indefinitely"}
                                onChange={(e) => setPersistentEndDT(e.value)}
                                inputClassName={'py-1'}
                                disabled={isSelectedNoteTypeRotation}
                                ariaLabelledBy="persistent input textbox"
                            />
                        </div>
                        }
                    </div>

                    {/*Attachment section*/}
                    <TooltipPR target=".p-fileupload.p-fileupload-basic.p-component" content="attach file" position="top" />
                    <FileUpload
                        name="FileUpload"
                        multiple
                        customUpload
                        uploadHandler={onSelectAttachmentFiles}//Validate and add to Attachment Summary
                        mode="basic"
                        disabled={loading}
                        accept=".rtf, .txt, .pdf, .doc, .docx, .csv, .xls, .xlsx, .png, .jpg, .jpeg, .gif, .mp4, .m4v, .mov, .mjpeg, .mpeg, .mpeg4, .mpeg2, .wmv, .avi"
                        auto
                        chooseOptions={
                            {
                                // middle layer span
                                className: `${attachmentModelList.length > 0 ? 'btn-secondary-selected' : ''} rounded-0 btn-secondary icon border-0 p-1`,
                                iconOnly: true,
                                // inner most span
                                icon: 'pi pi-paperclip mr-0 btn-attachment',
                            }
                        }
                    />

                    {/*Performed time button*/}
                    <div className="d-flex">
                        <TooltipPR target=".backdateNoteBtn" content="adjust timestamp" position="top" />
                        <button
                            className={`${isBackDateEnabled ? 'btn-secondary-selected' : ''} btn btn-secondary icon border-0 p-1 backdateNoteBtn`}
                            onClick={() => setIsBackDateEnabled(!isBackDateEnabled)}
                            type="button"
                            disabled={loading || isSelectedNoteTypeRotation}
                            aria-label="performed button">
                            <MdHistory className="extraLarge" />
                        </button>

                        {/*Performed Time Textbox*/}
                        {isBackDateEnabled &&
                            <div className="col-auto px-1">
                                <TooltipPR target="#performedDT" content="modify when the task or observation was performed" position="top" />
                                <Calendar id="performedBoxDT"
                                    value={performedDT}
                                    onChange={(e) => handlePerformedDTChange(e)}
                                    timeOnly
                                    hourFormat="12"
                                    locale="en"
                                    stepMinute={5}
                                    minDate={new Date(shiftSchedule?.beginDateTime + 'Z')}
                                    maxDate={new Date(shiftSchedule?.endDateTime + 'Z')}
                                    ariaLabelledBy="performed input textbox"
                                    className={`${isBackDateEnabled ? `${showError([NoteValidationType.PerformedTimeOutOfBounds]) ? 'error-border' : ''}` : 'd-none'} px-1`}
                                    inputClassName={`py-1 ${showError([NoteValidationType.PerformedTimeOutOfBounds]) ? 'error' : ''}`}
                                    placeholder='hh:mm AM/PM'
                                    disabled={isSelectedNoteTypeRotation}
                                />
                            </div>}
                    </div>

                    {/*Template Menu*/}
                    {!props.editNote &&
                        <div>
                            <Menu model={items}
                                popup
                                ref={menu}
                                id="popup_menu"
                                className="note-template-menu" />
                            <TooltipPR target=".templatesBtn" content="templates" position="top" />
                            <button
                                className="btn btn-secondary icon border-0 p-1 templatesBtn"
                                onClick={(event) => menu.current.toggle(event)}
                                type="button"
                                disabled={loading}
                                aria-label="templates button"
                                aria-controls="popup_menu"
                                aria-haspopup>
                                <MdOutlineAssignment className="extraLarge" />
                            </button>

                            {showNoteTemplateSaveModal &&
                                <NoteTemplateSaveModal
                                    show={showNoteTemplateSaveModal}
                                    onHide={hideNoteTemplateSaveModal}
                                    noteTemplateAddModel={getNoteTemplateAddModel()}
                                    userProfile={currentUserProfile}
                                />
                            }

                        </div>
                    }

                    {/*Rotation Note Button*/}
                    {/*TODO use "MdOutlineAutorenew" for the icon*/}
                </div>

                <div className="d-flex flex-wrap gap-2 flex-grow-1 justify-content-end align-items-center">
                    {/*Radio Button*/}
                    {props.editNote == null &&
                        <NoteSelectionType
                            isShiftEnabled={roleSettings?.isShiftEnabled}
                            isRotationEnabled={roleSettings?.isRotationEnabled}
                            noteType={selectedNoteType}
                            onNoteTypeChange={onNoteTypeChange} />
                    }

                    {/*Location Dropdown*/}
                    <LocationSelection ref={locationSelectionRef}
                        labelClass={`mb-0 pr-2 input-control-label ${showError([NoteValidationType.LocationNotSelected]) ? 'error' : ''}`}
                        dropdownDivClass="dropdown-select-box"
                        dropDownClass={`${showError([NoteValidationType.LocationNotSelected]) ? 'error-border' : ''}`}
                        handleLocationChange={handleLocationChange}
                        locationValue={locationValue}
                        roleId={roleId}
                        loading={loading}
                        isDisable={!isSelectedNoteTypeShift}
                    />

                    {/*Publish notes*/}
                    {/* add note publish button */}
                    {!props.editNote &&
                        <>
                            <TooltipPR target=".publishNoteBtn" content="publish note" position="top" />
                            <button
                                className={`btn btn-secondary icon border-0 p-1 publishNoteBtn`}
                                onClick={sendNote}
                                type="submit"
                                disabled={loading}
                                aria-label="publish note">
                                <FiSend className="extraLarge" />
                            </button>
                        </>
                    }

                    {/* edit note save & cancel buttons */}
                    {props.editNote &&
                        <>
                            {/*Cancel note*/}
                            <TooltipPR target=".cancelEditNoteBtn" content="cancel edit note" position="top" />
                            <button
                                className={`btn btn-secondary icon border-0 p-1 cancelEditNoteBtn`}
                                onClick={resetNote}
                                type="reset"
                                disabled={loading}
                                aria-label="cancel edit note">
                                <FiXCircle className="extraLarge" />
                            </button>

                            {/*Save note*/}
                            <TooltipPR target=".saveNoteBtn" content="save note" position="top" />
                            <button
                                className={`btn btn-secondary icon border-0 p-1 saveNoteBtn`}
                                onClick={sendNote}
                                type="submit"
                                disabled={loading}
                                aria-label="save note">
                                <FiCheck className="extraLarge" />
                            </button>
                        </>
                    }

                    <ConfirmationModal
                        showConfirmationModal={showTemplateConfirmationModal}
                        toggleConfirmationModal={() => hideTemplateConfirmationModal()}
                        objIdList={null}
                        onConfirm={() => setChangeNoteTemplate(selectedNoteType)}
                        titleText={promptModalTemplateTitle}
                        titleClass='modal-title large gotham-bold'
                        bodyText={promptModalTemplateBodyText}
                        bodyClass='modal-body medium pt-0'
                        footerClass='modal-footer border-0'
                        confirmationButtonText='Ok' />
                </div>
            </div>

            {/*Add Tag Modal*/}
            {
                showAddTagModal &&
                <TagModal roleId={roleId}
                    selectedSectionTags={selectedSectionTags}
                    selectedCategories={selectedCategories}
                    selectedRoles={selectedRoles}
                    showAddTagModal={showAddTagModal}
                    setSelectedSectionTags={setSelectedSectionTags}
                    setSelectedCategories={setSelectedCategories}
                    setSelectedRoles={setSelectedRoles}
                    toggleAddTagModal={() => toggleAddTagModal()}
                    roleTagData={roleTagData}
                    setRoleTagData={setRoleTagData}
                    isEdit={props.editNote ? true : false}
                    title={props.editNote ? 'Edit tags' : 'Add tags'}
                    noteType={selectedNoteType}
                />
            }

            { /*Select Template Modal*/}
            {
                showTemplateModal &&
                <NoteTemplateModal
                    showModal={showTemplateModal}
                    toggleShowModal={toggleTemplateModal}
                    roleId={roleId}
                    noteTemplateList={[]}
                    noteContent={noteTextValue}
                    applyTemplate={applyTemplate}
                    userProfile={currentUserProfile}
                    templateType={selectedNoteType == NoteType.Shift ? TemplateType.Shift : TemplateType.Rotation}
                />
            }
        </BlockUI>
    )
};

export default AddNote;
