import "./ProfileImage.scss";
import { useState, useEffect } from "react";
import { useSelectRoleActionCreators } from "src/hooks/useActions";
import { useTypedSelector } from "src/hooks/useTypedselector";
import { UserProfile } from "src/models/AppProfile";

interface ProfileImageProps {
  userProfile: UserProfile
  isProfileHeader: boolean
}

const ProfileImage = (props: ProfileImageProps) => {
  const currentUser = props.userProfile;
  const isProfileHeader = props.isProfileHeader;
  const profileImage = useTypedSelector(
    (state) => state.selectRoleReducer.profileImages.find(p => p.oId == currentUser?.oId)
  );

  const { addProfileImage: addUserProfileImage } = useSelectRoleActionCreators();

  const [initials, setInitials] = useState<string>("");
  const [userProfImg, setUserProfImg] = useState<string>(null);

  useEffect(() => {
    let initial = "";

    if (currentUser?.firstName && currentUser?.firstName.length >= 1) {
      initial = currentUser.firstName.slice(0, 1);
      if (currentUser?.lastName && currentUser?.lastName.length >= 1)
        initial = initial + currentUser.lastName.slice(0, 1);
    }

    setInitials(initial);

    if (currentUser?.oId) {
      addUserProfileImage(currentUser.oId);
    }
  }, [currentUser?.oId]);

  useEffect(() => {
    if (profileImage) {
      setUserProfImg(profileImage.image);
    }
  }, [profileImage]);

  return (
    <>
      {userProfImg ? (
        <img className={isProfileHeader ? "user-image-profile rounded-circle" : "user-image-note rounded-circle"} src={userProfImg} alt="user-pic" />
      ) : (
        <div className={isProfileHeader ? "user-initials-profile" : "user-initials-note"} >{initials}</div>
      )}
    </>
  );
};

export default ProfileImage;