
import { APIService } from './api.service';
import { AppProfile } from 'src/models/AppProfile';

export default class ConfigService extends APIService {
    private url = '/config';
   
    async getRoleSettings(): Promise<AppProfile> {
        const response = await this.api.get(`${this.url}/app-profile`);
        return response.data;
    }

}