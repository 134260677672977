import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    selectRoleActionCreators,
    myShiftActionCreators,
    historyActionCreators,
    filterActionCreators,
    appProfileActionCreators,
    myRotationActionCreators,
    hubCreators,
    notificationActionCreators,
    templateActionCreators
} from '../store';

export const useSelectRoleActionCreators = () => {
    const dispatch = useDispatch();
    return bindActionCreators(selectRoleActionCreators, dispatch);
};

export const useMyShiftActionCreators = () => {
    const dispatch = useDispatch();
    return bindActionCreators(myShiftActionCreators, dispatch);
};

export const useHistoryActionCreators = () => {
    const dispatch = useDispatch();
    return bindActionCreators(historyActionCreators, dispatch);
};

export const useFilterActionCreators = () => {
    const dispatch = useDispatch();
    return bindActionCreators(filterActionCreators, dispatch);
};

export const useAppProfileActionCreators = () => {
    const dispatch = useDispatch();
    return bindActionCreators(appProfileActionCreators, dispatch);
};

export const useMyRotationActionCreators = () => {
    const dispatch = useDispatch();
    return bindActionCreators(myRotationActionCreators, dispatch);
};

export const useHubActionCreators = () => {
    const dispatch = useDispatch();
    return bindActionCreators(hubCreators, dispatch);
};

export const useNotificationActionCreators = () => {
    const dispatch = useDispatch();
    return bindActionCreators(notificationActionCreators, dispatch);
}

export const useTemplateActionCreators = () => {
    const dispatch = useDispatch();
    return bindActionCreators(templateActionCreators, dispatch);
}