import { ShiftNote } from "src/models/NoteItem";
import { useTypedSelector } from "src/hooks/useTypedselector";
import { useEffect } from "react";
import ReportService from "src/services/api/report.service";
import { NoteType } from "src/enums/NoteTypes";
import { useMyShiftActionCreators } from "src/hooks/useActions";
import ReportShiftNotes from "./ReportShiftNotes";

// eslint-disable-next-line react/display-name
const ReportMyShiftNotes = () => {
  const myPublishedNote = useTypedSelector(state => state.myShiftReducer.currentShiftNotes);
  const followedNotes = useTypedSelector(state => state.myShiftReducer.followedNotes);
  const roleId = useTypedSelector(state => state.appProfileReducer.currentUserShift.shift?.role.id);
  const isGracePeriod = useTypedSelector(state => state.appProfileReducer.currentUserShift.gracePeriod.isInGracePeriod);
  const currentUserShift = useTypedSelector(state => state.appProfileReducer.currentUserShift);

  const { getCurrentShiftNotes, getFollowedNotes } = useMyShiftActionCreators();

  const reportService: ReportService = new ReportService();

  useEffect(() => {
    getCurrentShiftNotes(roleId, isGracePeriod);
    getFollowedNotes(roleId);
  }, []);

  const getShiftTitleMarkup = () => {
    if (currentUserShift.ifExists || isGracePeriod) {
      return <div className={reportService.contStDef + ` align-items-center`}>
        <h2 className="m-0">{`${currentUserShift.shift.role.name + '-' + currentUserShift.shift.role.jobClass.shortName} ${reportService.pageTitle}`}</h2>
        <div>
          <span className="bold">Shift: </span>
          <span>{`${reportService.getDateTimeStringToLocale(currentUserShift.shift.shiftSchedule.beginDateTime)} - ${reportService.getDateTimeStringToLocale(currentUserShift.shift.shiftSchedule.endDateTime)}`}</span>
        </div>
        <div>
          <span className="bold">Report Date Time: </span>
          <span>{reportService.getDateTimeString(new Date())}</span>
        </div>
      </div>
    }
    else {
      return <>
        <h2>{reportService.pageTitle}</h2>
        <h4>{reportService.noShiftMessage}</h4>
      </>
    }
  }

  const getHeaderContent = (historyNote: ShiftNote) => {
    return "".concat(historyNote?.roleName, '-', historyNote?.jobClassShortName);
  }

  const getHeaderContentFollowedNotes = (historyNote: ShiftNote) => {
    return "Followed Notes ".concat(historyNote?.roleName, '-', historyNote?.jobClassShortName);
  }

  return (
    <div className={reportService.contStDef}>
      {getShiftTitleMarkup()}

      <ReportShiftNotes noteType={NoteType.Shift} noteData={myPublishedNote} showHeader={true} headerContent={getHeaderContent(myPublishedNote)} />

      {followedNotes?.map((followedNote: ShiftNote, index) => {
        return <ReportShiftNotes key={index} noteType={NoteType.Shift} noteData={followedNote} showHeader={true} headerContent={getHeaderContentFollowedNotes(followedNote)} />
      })
      }
    </div>
  );
}

export default ReportMyShiftNotes;