/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import CrossWhite from 'src/assets/images/cross-white.svg';
import CrossBlue from 'src/assets/images/cross-blue.svg';
import "./NoteItemSection.scss";
import { useEffect, useState } from 'react';
import { Tag } from 'src/models/Tag';
import { TagType } from "src/enums/TagType";
import { NoteTab } from "src/enums/NoteTypes";
import { Attachment } from 'src/models/Attachment';
import { BsPaperclip } from 'react-icons/bs';

interface NoteItemSectionProps {
    noteId: string,
    selectedSection: string[],
    selectedCategory: string[],
    selectedRoles: string[],
    selectedOriginalRoles?: string[],
    setSelectedSectionTags: any,
    setSelectedCategories: any,
    setSelectedRoles: any,
    selectedAttachments: any,
    setSelectedAttachments: any,
    editMode: boolean,
    loading: boolean,
    selectedReadOnlyRoles?: string[],
    noteTab?: NoteTab,
    updateSelectedTags?: any,
    isPrintMode?: boolean
}

// eslint-disable-next-line react/display-name
const NoteItemSection = (props: NoteItemSectionProps) => {
    const [noteItemSection, setNoteItemSection] = useState<any[]>([]);

    /* Tags */
    const removeSectionTagItem = (item: string) => {
        if (props.loading)
            return; // Do nothing if Add Note is loading (publishing)
        const itemList = [...props.selectedSection];
        itemList.splice(itemList.indexOf(item), 1);
        props.setSelectedSectionTags(itemList);

        if (props.updateSelectedTags) // for filterprofile to update tags
            props.updateSelectedTags(itemList, props.selectedRoles, props.selectedCategory); // Nico Fernandez
    }

    const removeCategoryItem = (item: string) => {
        if (props.loading)
            return; // Do nothing if Add Note is loading (publishing)
        const itemList = [...props.selectedCategory];
        itemList.splice(itemList.indexOf(item), 1);
        props.setSelectedCategories(itemList);

        if (props.updateSelectedTags) // for filterprofile to update tags
            props.updateSelectedTags(props.selectedSection, props.selectedRoles, itemList); //Nico Fernandez
    }

    const removeRoleItem = (item: string) => {
        if (props.loading)
            return; // Do nothing if Add Note is loading (publishing)
        const itemList = [...props.selectedRoles];
        const readOnlyRoles = props.selectedReadOnlyRoles ? props.selectedReadOnlyRoles : [];
        if (readOnlyRoles.length > 0 && readOnlyRoles.includes(item)) return;
        itemList.splice(itemList.indexOf(item), 1);
        props.setSelectedRoles(itemList);

        if (props.updateSelectedTags)
            props.updateSelectedTags(props.selectedSection, itemList, props.selectedCategory); //Nico Fernandez
    }

    /* Attachments */
    const removeAttachmentItem = (item: string) => {
        if (props.loading)
            return; // Do nothing if Add Note is loading (publishing)
        const itemList = [...props.selectedAttachments];
        itemList.splice(itemList.findIndex(a => a.storageFileNameReference == item), 1);
        props.setSelectedAttachments(itemList);
    }

    /* Both */
    const clearAll = () => {
        if (props.loading)
            return; // Do nothing if Add Note is loading (publishing)

        if (props.selectedSection.length > 0) {
            props.setSelectedSectionTags([]);
        }

        if (props.selectedCategory.length > 0) {
            props.setSelectedCategories([]);
        }
        const selRoles: string[] = [...props.selectedReadOnlyRoles || []];

        // if original roles are undefined use selectedRoles so that it is compatible with AddNote & filterPanel.
        if (!props.selectedOriginalRoles) {
            selRoles.concat(props.selectedRoles || []);
        }
        props.setSelectedRoles(Array.from(new Set(selRoles))); // removes duplicates.

        if (props.updateSelectedTags)
            props.updateSelectedTags([], [], []);

        if (props.selectedAttachments.length > 0) {
            props.setSelectedAttachments([]);
        }
    }

    const loadTagsAndAttachments = () => {
        const itemList: any[] = [];

        props.selectedSection?.forEach(item => {
            const tag = new Tag();
            tag.name = item;
            tag.tagType = TagType.Section;

            itemList.push(tag);
        });

        props.selectedCategory?.forEach(item => {
            const tag = new Tag();
            tag.name = item;
            tag.tagType = TagType.Category;

            itemList.push(tag);
        });

        props.selectedRoles?.forEach(item => {
            const tag = new Tag();
            tag.name = item;
            tag.tagType = TagType.Role;

            itemList.push(tag);
        });

        props.selectedAttachments?.forEach((item) => {
            const attachment = new Attachment();
            attachment.displayFileName = item.displayFileName;
            attachment.storageFileNameReference = item.storageFileNameReference;
            attachment.downloadURI = item.downloadURI;

            itemList.push(attachment);
        });

        setNoteItemSection(itemList);
    }

    useEffect(() => {
        loadTagsAndAttachments();
    }, [props])

    return (
        /* Edit mode and AddNote: Tags and Attachment Summary Section*/
        <div className="noteItemFace">
            <div className="d-flex-column gap-2 color-white">
                {/* Tags */}
                {props.editMode &&
                    <>
                        {/*Section Tags*/}
                        <div className='d-flex flex-wrap gap-2'>
                            {(noteItemSection.filter(item => item instanceof Tag && item.tagType === TagType.Section)).map((sectionTag: Tag, i) =>
                                <span key={i} className="bg-green gotham-narrow-book rounded p-1">{sectionTag.name}
                                    {<img src={CrossWhite} className="cursor-pointer pl-2 pb-1" onClick={() => removeSectionTagItem(sectionTag.name)} />}
                                </span>)}
                        </div>

                        {/*Category Tags*/}
                        <div className='d-flex flex-wrap gap-2'>
                            {(noteItemSection.filter(item => item instanceof Tag && item.tagType === TagType.Category)).map((category: Tag, i) =>
                                <span key={i} className="bg-blue gotham-narrow-book rounded p-1">{category.name}
                                    {<img src={CrossWhite} className="cursor-pointer pl-2 pb-1" onClick={() => removeCategoryItem(category.name)} />}
                                </span>)}
                        </div>

                        {/*Role Tags*/}
                        <div className='d-flex flex-wrap gap-2'>
                            {(noteItemSection.filter(item => item instanceof Tag && item.tagType === TagType.Role)).map((role: Tag, i) =>
                                <span key={i} className="bg-green gotham-narrow-book rounded p-1">{role.name}
                                    {!props.selectedReadOnlyRoles?.includes(role.name) &&
                                        <img src={CrossWhite} className="cursor-pointer pl-2 pb-1" onClick={() => removeRoleItem(role.name)} />}</span>)}
                        </div>
                    </>
                }

                {/*Attachments*/}
                {
                    !props.isPrintMode &&
                    <div className='d-flex flex-wrap gap-2'>
                        {/*Edit mode and AddNote Attachments Summary*/}
                        {(noteItemSection.filter(item => item instanceof Attachment && props.editMode)).map((attachment: Attachment, i) =>
                            <div key={i} className="d-flex gap-1 align-items-center color-dark-gray">
                                <BsPaperclip />
                                <span>{attachment.displayFileName}</span>
                                <img src={CrossBlue} className="cursor-pointer" onClick={() => removeAttachmentItem(attachment.storageFileNameReference)} />
                            </div>)}

                        {/*Clear all*/}
                        {((noteItemSection.filter(item => item instanceof Tag && item.tagType === TagType.Category).length > 0
                            || noteItemSection.filter(item => item instanceof Tag && item.tagType === TagType.Section).length > 0
                            || noteItemSection.filter(item => item instanceof Tag && item.tagType === TagType.Role).length > 0
                            || noteItemSection.filter(item => item instanceof Attachment).length > 0) && props.editMode) &&
                            <button
                                className="btn button-text p-1 d-inline-block"
                                onClick={() => clearAll()}
                                type="reset">
                                clear all
                            </button>
                        }
                    </div>
                }
            </div>
        </div>
    );
};

export default NoteItemSection;