import "./ProfileIcon.scss";
import ProfileImage from "../ProfileImage";
import ProfileImageShkeleton from "../Skeleton/ProfileImageShkeleton";
import { UserProfile } from "src/models/AppProfile";

interface ProfileIconProps {
    userProfile: UserProfile;
    isLoading: boolean;
}

const ProfileIcon = (props: ProfileIconProps) => {
    return <div className="profile-icon">
        {props.isLoading ? <ProfileImageShkeleton />
            : <div className="dropdown2 float-end">
                {/*User Profile Button Icon*/}
                <button className="dropbtn border-0">
                    <ProfileImage userProfile={props.userProfile} isProfileHeader={true} />
                </button>

                {/*User Profile Dropdown Content*/}
                <div className="row no-gutters align-items-center dropdown-content px-5 py-4">
                    {/*left profile picture*/}
                    <div className="col-auto pr-3 user-profile">
                        <ProfileImage userProfile={props.userProfile} isProfileHeader={true} />
                    </div>
                    <div className="col-auto text-left">
                        {/*right top name*/}
                        <div className="gotham-bold">
                            {props.userProfile?.firstName + ' ' + props.userProfile?.lastName}
                        </div>
                        {/*right bottom email*/}
                        <div className="gotham-narrow-book medium">
                            {props.userProfile?.email}
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>;
}

export default ProfileIcon;