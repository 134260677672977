import { useState, useEffect } from "react";
import { SectionTagShiftNote, ShiftNote } from "src/models/NoteItem";
import { NoteType } from "src/enums/NoteTypes";
import NoteCardSkeleton from "../Skeleton/NoteCardSkeleton";
import { ShiftStatus } from "src/enums/ShiftStatus";
import ReportService from "src/services/api/report.service";
import { TagType } from "src/enums/TagType";
import ReportNoteCardSection from "./ReportNoteCardSection";
import { useTypedSelector } from "src/hooks/useTypedselector";
import SectionTagConstants from "src/constants/section-tag-constants";

interface ReportShiftComponentProps {
  noteData: ShiftNote,
  noteType: NoteType,
  loading?: boolean,
  showHeader?: boolean,
  headerContent?: string,
}

// eslint-disable-next-line react/display-name
const ReportShiftNotes = (props: ReportShiftComponentProps) => {
  const noteType = props.noteType;

  const sectionTagsMasterList = useTypedSelector(state => state.myShiftReducer.sectionList || []);

  const styleDef = "d-flex justify-content-between align-items-center bold medium gotham-bold color-dark-blue cursor-pointer rounded-top bg-lighter-gray px-3 py-2";
  const errorContStyle = "d-flex-column flex-grow-1 align-items-center justify-content-center";

  const [sectionTagShiftNotes, setSectionTagShiftNotes] = useState<SectionTagShiftNote[]>([]);

  const reportService: ReportService = new ReportService();

  const [localNoteData, setLocalNoteData] = useState<ShiftNote>();

  const [noteCount, setNoteCount] = useState(0);

  const [errorMessage, setErrorMessage] = useState<string>();

  const filterBySectionTags = (shiftNote: ShiftNote, tagName: string) => {
    const _notesForSectionTags = structuredClone(shiftNote);
    reportService.filterShiftNoteBySectionTags(_notesForSectionTags, tagName);

    return _notesForSectionTags;
  }

  useEffect(() => {
    setLocalNoteData(props.noteData);
  }, [props.noteData]);


  useEffect(() => {
    if (noteType === NoteType.HistoryShiftNotes) {
      let _cnt = 0;
      localNoteData?.locationNotesList?.map(notelist => _cnt = _cnt + notelist.notes.length);
      setNoteCount(_cnt);
    }

    if (!localNoteData || localNoteData === undefined && localNoteData.shiftStatus === ShiftStatus.UnDefined)
      setErrorMessage("No Shift/Notes found");
    else if (localNoteData.locationNotesList == null || localNoteData.locationNotesList.length === 0)
      setErrorMessage("No Notes found");
    else
      setErrorMessage("");

    if (localNoteData) {
      const allNotes: SectionTagShiftNote[] = [];

      sectionTagsMasterList.forEach(sectionTag => {
        const sTagNameVal = sectionTag.name.toLocaleLowerCase();

        const data: SectionTagShiftNote = {
          isCritical: sTagNameVal == SectionTagConstants.CriticalSectionTagName.toLocaleLowerCase(),
          shiftNote: filterBySectionTags(localNoteData, sTagNameVal),
          tag: {
            id: sectionTag.id,
            name: sectionTag.name.toLocaleLowerCase(),
            tagContent: sectionTag.tagContent,
            tagType: TagType.Section
          }
        }

        allNotes.push(data);
      });

      setSectionTagShiftNotes(allNotes);
    }
  }, [localNoteData]);


  return (
    <div className="d-flex-column flex-grow-1 bg-lightest-gray">
      {
        props.showHeader && <div className={styleDef}>
          <>
            <span>{props.headerContent}
              {noteType === NoteType.HistoryShiftNotes && <span className="pl-1">{`(${noteCount})`}</span>}
            </span>
          </>
        </div>
      }

      {/*List of Note Cards*/}
      <div className="d-flex-column flex-grow-1 p-3">
        {
          (props.loading) ? <div className="p-3"><NoteCardSkeleton /></div> :
            (sectionTagShiftNotes && sectionTagShiftNotes?.map((sectionData: SectionTagShiftNote, index) => {
              return (sectionData.shiftNote.locationNotesList?.length > 0) ?
                <ReportNoteCardSection key={index} noteType={noteType} sectionTagShiftNote={sectionData} /> :
                <></>
            }))
        }

        {
          !(props.loading) && errorMessage && <div className={errorContStyle}>
            {errorMessage}
          </div>
        }
      </div>
    </div>
  );
};

export default ReportShiftNotes;