/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import "./TagModal.scss";
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { RoleLocation } from "src/models/Role";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import NoteItemSection from '../NoteItemSection';
import { Alert, Modal } from 'react-bootstrap';
import { useFilterActionCreators, useMyShiftActionCreators, useAppProfileActionCreators } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypedselector';
import { RoleTag } from 'src/models/Tag';
import TagService from 'src/services/api/tag.service';
import { PageType } from 'src/enums/PageType';
import NoteService from 'src/services/api/note.service';
import { Toaster } from 'src/services/api/toaster.service';
import { NoteType } from 'src/enums/NoteTypes';

interface TagModalProps {
    roleId: string;
    selectedSectionTags: string[];
    selectedCategories: string[];
    selectedRoles: string[];
    showAddTagModal: boolean;
    setSelectedSectionTags?: any;
    setSelectedCategories?: any;
    setSelectedRoles?: any;
    updateTags?: any;
    toggleAddTagModal: any;
    roleTagData: RoleTag[];
    setRoleTagData: any;
    isEdit: boolean;
    title: string;
    toTagRole?: boolean;
    refreshTable?: any;
    noteType: NoteType;
}

// eslint-disable-next-line react/display-name
const TagModal = (props: TagModalProps) => {
    const toTagRole = props.toTagRole ? props.toTagRole : false;
    const categoryList = useTypedSelector(state => state.myShiftReducer.categoryList || []);
    const sectionList = useTypedSelector(state => state.myShiftReducer.sectionList || []);

    const roleSettings = useTypedSelector(state => state.appProfileReducer.roleSettings.roleSettingsDetail);
    const categoryLoading = useTypedSelector(state => state.myShiftReducer.categoryLoading);
    const tagRoleNoteDetails = useTypedSelector(state => state.myShiftReducer.tagRoleNoteDetails);
    const { getCategoryList, getSectionList } = useMyShiftActionCreators();
    const { getRoleSettings } = useAppProfileActionCreators();

    const { getAllFilters } = useFilterActionCreators();
    const filters = useTypedSelector(state => state.filterReducer.allFilters);

    const [disable, setDisabled] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [locationDropdownList, setLocationDropdownList] = useState<RoleLocation[]>([]);
    const [allRoleDataList, setAllRoleDataList] = useState<RoleTag[]>(props.roleTagData);
    const [locationDropDownValue, setLocationDropDownValue] = useState<string>('');
    const [selectedCategories, setSelectedCategoriesForModal] = useState<string[]>(props.selectedCategories);
    const [selectedSectionTags, setSelectedSectionTagsForModal] = useState<string[]>(props.selectedSectionTags);
    const [selectedRoles, setSelectedRolesForModal] = useState<string[]>(props.selectedRoles);
    const [selectedRolesReadOnly, setSelectedRolesReadOnly] = useState<string[]>([]);
    const [selectedOriginalRoles, setSelectedOriginalRoles] = useState<string[]>([]);

    const [roleTagList, setRoleTagList] = useState<string[]>([])
    const [rollUptags, setRollUpTags] = useState<string[]>([]);
    const [roleLoading, setRoleLoading] = useState(false);
    const tagService = new TagService();
    const noteService = new NoteService();

    const getRoleTagData = (data: RoleTag[]) => {
        const roleTags = [...data]
        let _totalRoles = 0;
        let _rollUptags = [];
        let _roleList = [];
        let _roleListSelected: string[] = [];
        let _roleListSelectedReadOnly: string[] = [];
        let _roleLocationList: string[] = [];
        if (roleTags.length > 0) {
            roleTags.forEach(role => _totalRoles = _totalRoles + role.tagNames.length);
            _roleLocationList = roleTags.map(role => { return role.roleName });
            _roleList = roleTags[0].tagNames.map(roleTag => { return roleTag });
            _roleListSelected = roleTags[0].selectedTagNames ? roleTags[0].selectedTagNames.map(roleTag => { return roleTag }) : [];
            _roleListSelectedReadOnly = roleTags[0].readonlyTagNames ? roleTags[0].readonlyTagNames.map(roleTag => { return roleTag }) : [];
            _rollUptags = roleTags.flatMap(roleTag => {
                if (roleTag.rollUpTagNames?.length > 0)
                    return roleTag.rollUpTagNames;
                else
                    return [];
            });

            const newReadOnlyArr = _roleListSelectedReadOnly.map(readonly => readonly.split(' ').join(''));

            const newListRoleReadOnly = _roleList.filter(function (role) {
                return newReadOnlyArr.indexOf(role) != -1
            })


            const newSelectedRoles = _roleListSelected.concat(newListRoleReadOnly).concat(props.selectedRoles);

            setAllRoleDataList(roleTags)
            setRoleTagList(_roleList)
            setRollUpTags(_rollUptags);
            setSelectedRolesForModal(newSelectedRoles);
            setSelectedRolesReadOnly(newListRoleReadOnly);
            setSelectedOriginalRoles(newSelectedRoles);

            const temp_list = _roleLocationList.map((role, i) => {
                return {
                    name: role,
                    id: i + ''
                }
            });
            setLocationDropdownList(temp_list);

            if (temp_list?.length >= 1) {
                setLocationDropDownValue(temp_list[0].name);
            }
        }


    };

    useEffect(() => {
        if (!toTagRole) {
            setSelectedCategoriesForModal(props.selectedCategories);
            setSelectedRolesForModal(props.selectedRoles);
            setErrorMessage(null);
        }
    }, [props, props.showAddTagModal])


    /* to check  current state with store selected categories and role to enable/disable apply button*/
    useEffect(() => {
        const selectedSectionTagsModal = [...selectedSectionTags].sort();
        const selectedCategoriesModal = [...selectedCategories].sort();

        const selectedSectionTagsStore = [...props.selectedSectionTags].sort();
        const selectedCategoriesStore = [...props.selectedCategories].sort();
        const selectedRoleModal = [...selectedRoles].sort();
        const selectedRoleStore = [...selectedOriginalRoles].sort();
        //compare internal state for selection from central store 

        const isSectionTagsNotChanged = (selectedSectionTagsModal.length == selectedSectionTagsStore.length) && selectedSectionTagsModal.every(function (element, index) {
            return element === selectedSectionTagsStore[index]
        });

        const isCategoriesNotChanged = (selectedCategoriesModal.length == selectedCategoriesStore.length) && selectedCategoriesModal.every(function (element, index) {
            return element === selectedCategoriesStore[index]
        });
        const isRolesNotChanged = (selectedRoleModal.length == selectedRoleStore.length) && selectedRoleModal.every(function (element, index) {
            return element === selectedRoleStore[index]
        });

        (isSectionTagsNotChanged && isCategoriesNotChanged && isRolesNotChanged) ? setDisabled(true) : setDisabled(false);
    }, [selectedSectionTags, selectedCategories, selectedRoles, selectedOriginalRoles, props.showAddTagModal]);

    useEffect(() => {
        if (!toTagRole) {
            getCategoryList();
            getSectionList();
            if (!roleSettings)
                getRoleSettings();

            if (props.roleId?.length > 0 && !toTagRole) {
                setRoleLoading(true);
                tagService.getTagsByRoleId(props.roleId).then((res) => {
                    props.setRoleTagData(res);
                    setRoleLoading(false);
                    getRoleTagData(res);

                });
            }

        }
        else {
            setRoleLoading(true);
            tagService.getTagsByRoleIdNoteId(tagRoleNoteDetails.shiftSchedule.roleId, tagRoleNoteDetails.id).then((res) => {
                setRoleLoading(false);
                getRoleTagData(res);

            }).catch((error) => {
                setRoleLoading(false);
                console.error(error);
            })
        }
        if (!filters || !filters.filterProfileRoleModels || filters.filterProfileRoleModels.length === 0) {
            getAllFilters(PageType.SelectRolePage)
        }

    }, [props.roleId]);

    const onCategoryChange = (e: { value: any, checked: boolean }) => {
        const _selectedCategories = [...selectedCategories];
        setErrorMessage(null);
        if (e.checked) {
            _selectedCategories.push(e.value);
            if (roleSettings?.categoryTagSelectionLimit > 0 && _selectedCategories.length > roleSettings.categoryTagSelectionLimit) {
                setErrorMessage(`You can only select a maximum of ${roleSettings.categoryTagSelectionLimit} category tags.`);
                return;
            }
        }
        else {
            for (let i = 0; i < _selectedCategories.length; i++) {
                const selectedCategory = _selectedCategories[i];

                if (selectedCategory === e.value) {
                    _selectedCategories.splice(i, 1);
                    break;
                }
            }
        }

        setSelectedCategoriesForModal(_selectedCategories);
    }

    const onSectionChange = (e: { value: any, checked: boolean }) => {
        const _selectedSectionTags = [...selectedSectionTags];
        setErrorMessage(null);
        if (e.checked) {
            _selectedSectionTags.push(e.value);
            if (roleSettings?.sectionTagSelectionLimit > 0 && _selectedSectionTags.length > roleSettings.sectionTagSelectionLimit) {
                setErrorMessage(`You can only select a maximum of ${roleSettings.sectionTagSelectionLimit} section tags.`);
                return;
            }
        }
        else {
            for (let i = 0; i < _selectedSectionTags.length; i++) {
                const selectedSectionTag = _selectedSectionTags[i];

                if (selectedSectionTag === e.value) {
                    _selectedSectionTags.splice(i, 1);
                    break;
                }
            }
        }

        setSelectedSectionTagsForModal(_selectedSectionTags);
    }

    const onAllSelection = (e: { value: any, checked: boolean }) => {
        let _selectedRoles = [...selectedRoles];

        if (e.checked) {
            _selectedRoles = _selectedRoles.concat(roleTagList.map(roleTag => { return roleTag }));
        }
        else {
            _selectedRoles = _selectedRoles.filter(r => selectedRolesReadOnly.includes(r) || selectedOriginalRoles.includes(r) || !roleTagList.includes(r));
        }
        setSelectedRolesForModal(Array.from(new Set(_selectedRoles))); // removes duplicates.;
    }

    const onRollUpSelection = (e: { value: any, checked: boolean }) => {
        let _selectedRoles = [...selectedRoles];
        console.log('roll up sel', _selectedRoles, selectedOriginalRoles, selectedRolesReadOnly, rollUptags);
        if (rollUptags) {
            if (e.checked) {
                _selectedRoles = _selectedRoles.concat(rollUptags.map(roleTag => { return roleTag }));
            }
            else {
                _selectedRoles = _selectedRoles.filter(r => selectedRolesReadOnly.includes(r) || selectedOriginalRoles.includes(r));
            }

            setSelectedRolesForModal(_selectedRoles);
        }
    }

    const onRoleChange = (e: { value: any, checked: boolean }) => {
        const _selectedRoles = [...selectedRoles];

        if (e.checked) {
            _selectedRoles.push(e.value);
        }
        else {
            for (let i = 0; i < _selectedRoles.length; i++) {
                const selectedCategory = _selectedRoles[i];

                if (selectedCategory === e.value) {
                    _selectedRoles.splice(i, 1);
                    break;
                }
            }
        }

        setSelectedRolesForModal(_selectedRoles);
    }

    const handleLocationChange = (e) => {
        const _roleTagData = [...allRoleDataList]
        let _newRoleList = [];
        setLocationDropDownValue(e.value);
        _newRoleList = _roleTagData.flatMap(locatedRole => {
            if (locatedRole.roleName === e.value && locatedRole.tagNames)
                return locatedRole.tagNames;
            else
                return [];
        });
        setRoleTagList(_newRoleList);
    }

    const applyAll = () => {

        if (!toTagRole) {
            let isSectionTagsChanged = false;
            let isRolesChanged = false;
            let isCategoriesChanged = false;
            // Refresh when role filters not same as previous.
            if (JSON.stringify(selectedRoles || []) !== JSON.stringify(props.selectedRoles || [])) {
                if (props.setSelectedRoles)
                    props.setSelectedRoles([...selectedRoles]);
                isRolesChanged = true;
            }
            // Refresh when category filters not same as previous.
            if (JSON.stringify(selectedSectionTags || []) !== JSON.stringify(props.selectedSectionTags || [])) {
                if (props.setSelectedSectionTags)
                    props.setSelectedSectionTags([...selectedSectionTags]);
                isSectionTagsChanged = true;
            }

            if (JSON.stringify(selectedCategories || []) !== JSON.stringify(props.selectedCategories || [])) {
                if (props.setSelectedCategories)
                    props.setSelectedCategories([...selectedCategories]);
                isCategoriesChanged = true;
            }

            if (props.updateTags && (isSectionTagsChanged || isCategoriesChanged || isRolesChanged))
                props.updateTags(selectedSectionTags, selectedRoles, selectedCategories);

            props.toggleAddTagModal();
        }
        else {
            const noteTagDetails = {
                shiftRoleId: tagRoleNoteDetails.shiftSchedule.roleId,
                noteId: tagRoleNoteDetails.id,
                tagNames: [...selectedRoles]
            }
            noteService.updateTagRoleDetails(noteTagDetails).then(() => {
                Toaster.showSuccess('Tagged role successfully');
                props.refreshTable();
            }).catch((error) => {
                Toaster.showError('Tagging role failed');
                console.error(error);
            }).finally(() => {
                props.toggleAddTagModal();
            });
        }
    }

    const checkRoleDisabled = (role) => {
        if (selectedRolesReadOnly?.some((item) => item == role))
            return true;
        else if (props.isEdit)
            return false;
        else if (selectedOriginalRoles?.some((item) => item == role))
            return true;
    }

    const showCategoryTags = () => {
        return !toTagRole && categoryList?.length > 0;
    }

    const showSectionTags = () => {
        return !toTagRole && sectionList?.length > 0;
    }

    return (
        <Modal
            className="dialog2 tag-modal"
            show={props.showAddTagModal}
            onHide={props.toggleAddTagModal}
            centered>
            <Modal.Header closeButton className="border-bottom-0">
                <Modal.Title>
                    <h4 className="mb-0 text-body">{props.title}</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='overflow-auto'>
                <>
                    {errorMessage &&
                        <Alert variant='danger'>{errorMessage}</Alert>
                    }
                    {/* load custom spinner untill the  role tag list and category tag list is loaded */}
                    <CustomSpinner loading={categoryLoading || roleLoading} />
                    {/*section tags div*/}
                    {showSectionTags() &&
                        <div>
                            <h4 className='mb-0 text-left tagheading'>Section Tags</h4>
                            <p className='tag-message'>Please select section tags from the list below</p>
                            <div className='row overflow-auto'>
                                {sectionList.map((sTag, i) =>
                                    <div className="col-6 field-checkbox d-flex align-items-center" key={i}>
                                        <Checkbox inputId={sTag.id} name="sTag" value={sTag.name} onChange={onSectionChange} checked={selectedSectionTags?.some((item) => item == sTag.name)} />
                                        <label className='label-Text pl-2' htmlFor={sTag.id} >{sTag.name}</label>
                                    </div>)
                                }
                            </div>
                        </div>
                    }



                    {/*category tags div*/}
                    {showCategoryTags() &&
                        <div>
                            <h4 className='mb-0 text-left tagheading'>Category Tags</h4>
                            <p className='tag-message'>Please select category tags from the list below</p>
                            <div className='row overflow-auto'>
                                {categoryList.map((category, i) =>
                                    <div className="col-6 field-checkbox d-flex align-items-center" key={i}>
                                        <Checkbox inputId={category.id} name="category" value={category.name} onChange={onCategoryChange} checked={selectedCategories.some((item) => item == category.name)} />
                                        <label className='label-Text pl-2' htmlFor={category.id} >{category.name}</label>
                                    </div>)
                                }
                            </div>
                        </div>
                    }
                    {/*role tags div*/}
                    {(props.noteType == NoteType.Shift || props.noteType == NoteType.Template) &&
                        <div className={`mt-2 ${showCategoryTags() ? 'border-top' : ''}`}>
                            <h4 className='mb-0 text-left tagheading'>Role Tags</h4>
                            <p className='tag-message'>Please select the options below to tag roles</p>

                            {/* Only show drop down when more than 1 hierarchy level */}
                            {(props.title === 'Manage tags' || (filters?.filterProfileRoleModels[0]?.children?.length > 1 && allRoleDataList.length > 1 && locationDropdownList.length >= 1)) && <div id='ddlAreas'>
                                {locationDropdownList.length &&
                                    <>
                                        <label className='label-Text mb-1 w-100'>area</label>
                                        <Dropdown value={locationDropDownValue} className='w-75 mb-2 rounded-0 custom-area-dropdown' optionLabel="name" optionValue="name" options={locationDropdownList} placeholder="Select Area" onChange={(e) => handleLocationChange(e)}
                                        />
                                    </>
                                }
                            </div>}
                            <div className="row">
                                <div className="col-6 field-checkbox align-items-center d-flex">
                                    <Checkbox inputId={'all'} name="role" value='all' onChange={onAllSelection} checked={roleTagList.every(rt => selectedRoles.includes(rt))} />
                                    <label className='label-Text pl-2' htmlFor={'all'}>All</label>
                                </div>

                                {rollUptags?.length > 0 &&
                                    <div className="col-6 field-checkbox d-flex align-items-center">
                                        <Checkbox inputId={'rollUp'} name="rollUp" value='rollUp' onChange={onRollUpSelection} checked={rollUptags.length > 0 && selectedRoles.length > 0 && rollUptags.every(rollupTag => selectedRoles.includes(rollupTag))} />
                                        <label className='label-Text pl-2' htmlFor={'rollUp'}  >Roll Up</label>
                                    </div>
                                }
                            </div>

                            <div className='row'>
                                {roleTagList.map((role, j) =>
                                    <div className="col-4 field-checkbox d-flex align-items-center" key={j}>
                                        <Checkbox
                                            inputId={role}
                                            name="category"
                                            value={role}
                                            onChange={onRoleChange}
                                            checked={selectedRoles?.some((item) => item == role)}
                                            disabled={checkRoleDisabled(role)}
                                        />
                                        <label className='label-Text pl-2' htmlFor={role} >{role}</label>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                    {/*Tag list section to display tags selected*/}
                    <NoteItemSection
                        noteId=''
                        selectedSection={selectedSectionTags}
                        selectedCategory={selectedCategories}
                        selectedRoles={selectedRoles}
                        selectedOriginalRoles={selectedOriginalRoles}
                        selectedReadOnlyRoles={selectedRolesReadOnly}
                        setSelectedSectionTags={setSelectedSectionTagsForModal}
                        setSelectedCategories={setSelectedCategoriesForModal}
                        setSelectedRoles={setSelectedRolesForModal}
                        selectedAttachments={[]}
                        setSelectedAttachments={null}
                        editMode={true}
                        loading={categoryLoading && roleLoading}
                    />
                </>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="reset"
                    className="btn btn-secondary flex-fill"
                    onClick={props.toggleAddTagModal}>
                    cancel
                </button>
                <button
                    type="submit"
                    disabled={disable}
                    className="btn btn-primary flex-fill"
                    onClick={() => { applyAll(); }}>
                    apply
                </button>
            </Modal.Footer>
        </Modal >
    );
};

export default TagModal;