import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { NoteType } from "src/enums/NoteTypes";
import "./ViewReport.scss";
import ReportHistoryShiftNotes from "src/components/ReportPageComponents/ReportHistoryShiftNotes";
import ReportHistoryRotationNotes from "src/components/ReportPageComponents/ReportHistoryRotationNotes";
import ReportMyShiftNotes from "src/components/ReportPageComponents/ReportMyShiftNotes";
import ReportMyRotationNotes from "src/components/ReportPageComponents/ReportMyRotationNotes";

const ViewReport = () => {
    const { reportType } = useParams();

    const [reportTypeEnum, setReportTypeEnum] = useState<NoteType | null>(null);

    useEffect(() => {
        setReportTypeEnum(NoteType[reportType]);
    }, [])

    return (
        <div className="bg-white p-3">
            {/* Shift Notes */}
            {
                reportTypeEnum === NoteType.Shift && <ReportMyShiftNotes />
            }

            {/* Rotation Notes */}
            {
                reportTypeEnum === NoteType.Rotation && <ReportMyRotationNotes />
            }

            {/* History Shift Notes */}
            {
                reportTypeEnum === NoteType.HistoryShiftNotes && <ReportHistoryShiftNotes />
            }

            {/* History Rotation Notes */}
            {
                reportTypeEnum === NoteType.HistoryRotationNotes && <ReportHistoryRotationNotes />
            }
        </div>
    )
}

export default ViewReport;