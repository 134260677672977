import { TagType } from "src/enums/TagType";

export class Tag {
    id: string;
    name: string;
    tagContent: string
    tagType: TagType;
}

export class RoleTag {
    roleName: string;
    tagNames: string[];
    rollUpTagNames: string[];
    readonlyTagNames?: string[];
    selectedTagNames?: string[];
}

export class TagFilters {
    categoryTags: string[];
    roleTags: string[];
    sectionTags: string[];
}