import { NoteType } from "src/enums/NoteTypes";
import { useTypedSelector } from "src/hooks/useTypedselector";
import ReportService from "src/services/api/report.service";
import ReportShiftNotes from "./ReportShiftNotes";
import { ShiftNote } from "src/models/NoteItem";
import { useEffect, useState } from "react";
import { RoleFilterItem } from "src/models/FilterItem";
import TreeHelper from "../FilterPanel/TreeHelper";
import { FilterDataType } from "src/enums/TagType";

// eslint-disable-next-line react/display-name
const ReportHistoryShiftNotes = () => {
  const historyData = useTypedSelector(state => state.historyReducer);
  const filterData = useTypedSelector(state => state.filterReducer);

  const [roleFilterItems, setRoleFilterItems] = useState<RoleFilterItem[]>([]);

  const reportService: ReportService = new ReportService();

  const getHistoryShiftNotesTitleMarkup = () => {
    if (historyData.historyNotesSummary && historyData.historyNotesSummary?.length > 0) {
      return <div className="d-flex-column gap-3 align-items-center">
        <h2 className="m-0">{reportService.getHistoryPageTitle(false)}</h2>

        <div className="container-fluid row">
          <div className="col d-flex flex-row-reverse">
            <div className="d-flex-column gap-2">
              <div>
                <span className="bold">Role(s): </span>
                <span>{reportService.getSelectedRolesTitle(roleFilterItems)}</span>
              </div>
              <div>
                <span className="bold">Category Tag(s): </span>
                <span>{reportService.getFilterDataMarkup(filterData, historyData, FilterDataType.CategoryTag)}</span>
              </div>
              <div>
                <span className="bold">Role Tag(s): </span>
                <span>{reportService.getFilterDataMarkup(filterData, historyData, FilterDataType.RoleTag)}</span>
              </div>
              <div>
                <span className="bold">Period(s): </span>
                <span>{reportService.getFilterDataMarkup(filterData, historyData, FilterDataType.ShiftSchedulePeriod)}</span>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="d-flex-column gap-2">
              <div>
                <span className="bold">Location(s): </span>
                <span>{reportService.getFilterDataMarkup(filterData, historyData, FilterDataType.Location)}</span>
              </div>
              <div>
                <span className="bold">Date(s): </span>
                <span>{reportService.getFilterDataMarkup(filterData, historyData, FilterDataType.DateRange)}</span>
              </div>
              <div>
                <span className="bold">Text: </span>
                {/* TODO: to add and get from Redux */}
                <span>{reportService.getFilterDataMarkup(filterData, historyData, FilterDataType.Text)}</span>
              </div>
              <div>
                <span className="bold">Report Date Time: </span>
                <span>{reportService.getDateTimeString(new Date())}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    else {
      return <>
        <h2>{reportService.pageTitle}</h2>
        <h4>{reportService.noShiftMessage}</h4>
      </>
    }
  }

  const getHeaderContent = (historyNote: ShiftNote) => {
    return "".concat(historyNote?.roleName, '-', historyNote?.jobClassShortName);
  }

  useEffect(() => {
    setRoleFilterItems(TreeHelper.getSelectedItemsIdNames(filterData?.allFilters?.selectedRoleIds, filterData.role.filters));
  }, [])

  return (
    <div className={reportService.contStDef}>
      {getHistoryShiftNotesTitleMarkup()}

      {
        historyData.historyNotesSummary.map((historyNote: ShiftNote, index) => {
          return (historyNote.locationNotesList?.length > 0) ?
            <ReportShiftNotes key={'hsn-' + index} noteType={NoteType.HistoryShiftNotes} noteData={historyNote} showHeader={true} headerContent={getHeaderContent(historyNote)} /> :
            <></>
        })
      }
    </div >
  );
}

export default ReportHistoryShiftNotes;