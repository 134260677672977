import { ActionType } from "../action-types";
import { NotificationAction } from "../actions/notificationActions";
import { Notification } from "src/models/Notification";

export class NotificationState {
    notifications: Notification[];
    notificationApiErr: string;
    notificationLoading: boolean;
}

const initialState = {
    notifications: [],
    notificationApiErr: '',
    notificationLoading: false
};

export const notificationReducer = (
     state: NotificationState = initialState, 
     action: NotificationAction) : NotificationState => {
        switch (action.type) {
            case ActionType.GET_USER_NOTIFICATIONS:
                return { ...state, notificationLoading: true };
            case ActionType.GET_USER_NOTIFICATIONS_SUCCESS:
                return { ...state, notifications: action.payload, notificationLoading: false };
            case ActionType.GET_USER_NOTIFICATIONS_FAILURE:
                return { ...state, notificationApiErr: action.payload, notificationLoading: false };
            case ActionType.SET_READED_NOTIFICATION:
                return { ...state, notifications: state.notifications.map((notification: Notification) => {
                    if(notification.id === action.payload.id) {
                        return { ...notification, readed: true };
                    }
                    return notification;
                })};
            default:
                return state;
        }
     };