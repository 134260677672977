import { APIService } from './api.service';

export default class RolePermissionService extends APIService {
    private url = '/role-permissions';

    async getRolePermission (rootRoleId: string): Promise<any> { 
        const url = `${this.url}/roles?rootRoleId=${rootRoleId}`;            
        const response = await this.api.get(url);

        return response.data;
    }

}