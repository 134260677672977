import { ScheduleAddModel, ScheduleDisableModel } from "src/models/Schedule";
import { APIService } from "./api.service";

export default class ScheduleService extends APIService {
    private url = '/schedules';

    async addSchedule(request: ScheduleAddModel): Promise<void> {
        const response = await this.api.post(`${this.url}`, request);
        return response.data;
    }

    async disableSchedule(request: ScheduleDisableModel): Promise<void> {
        const response = await this.api.put(`${this.url}/disable`, request);
        return response.data;
    }
}