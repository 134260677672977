import React from "react";
import "./NoteCard.scss";
import { SingleNote } from "src/models/NoteItem";
import NoteItemCard from "../NoteItemCard";
import { NoteTab } from "src/enums/NoteTypes";
import TitleCard from "../ReportPageComponents/TitleCard";

interface NoteCardProps {
    notesToRender: SingleNote[],
    noteType: NoteTab,
    locationName?: string,
    roleJobClassName?: string,
    setChecked?: any,
    selectedNoteIds?: string[],
    canSelect?: boolean,
}

const NoteCard: React.FC<NoteCardProps> = (props: NoteCardProps) => {
    const contTitleBaseStDef = "d-flex-column bold medium";
    const contTitleStDef = contTitleBaseStDef + " px-3 py-2";

    const contStDef = "d-flex-column gap-3";
    const noteItemCardContStDef = "p-3 bg-white";

    const contStyle = () => {
        if (props.noteType == NoteTab.SelectRoleNotes || props.noteType == NoteTab.SelectRoleRotationNotes || props.noteType == NoteTab.SelectRoleAllRotationNotes)
            return "px-3 pt-2";
        else
            return "px-3 pt-2";
    }

    return (<div className={contStyle()}>
        {
            (props.noteType != NoteTab.MyRotationNotes && props.noteType != NoteTab.SelectRoleRotationNotes && props.locationName) ?
                <TitleCard titleStDef={contTitleStDef} content={props.locationName} />
                : <></>
        }
        <div className={contStDef}>
            {props.notesToRender && props.notesToRender.length > 0 &&
                props.notesToRender.map((note: SingleNote, index: number) => {
                    return <div className={noteItemCardContStDef} key={'r-nic-' + index}>
                        <NoteItemCard noteTab={props.noteType} note={note} roleJobClassName={props.roleJobClassName} selectedNoteIds={props.selectedNoteIds} setChecked={props.setChecked} canSelect={props.canSelect} />
                    </div>
                })}
        </div>
    </div >)
}

export default NoteCard