import Skeleton from "react-loading-skeleton";
import NoteItemCardSkeleton from "./NoteItemCardSkeleton";

const NoteCardSkeleton = () => {
    const SIZE_100 = 100;
    const noteItemCardCount = 3;

    return (
        <div className="d-flex-column">
            <Skeleton width={SIZE_100} />

            {[...Array(noteItemCardCount)].map((x, i) =>
                <div key={i} className="my-2">
                    <NoteItemCardSkeleton />
                </div>
            )}

        </div>)
}

export default NoteCardSkeleton