import { useEffect, useState } from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import { Checkbox } from 'primereact/checkbox';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Toaster } from "src/services/api/toaster.service";
import ShiftNotes from "../ShiftNotes";
import { RotationNote, ShiftNote, ShiftSchedulePeriod } from "src/models/NoteItem";
import { ShiftStatus } from 'src/enums/ShiftStatus';
import ScheduleService from 'src/services/api/schedule.service';
import ShiftService from 'src/services/api/shift.service';
import { useAppProfileActionCreators, useMyShiftActionCreators, useSelectRoleActionCreators } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypedselector';
import "./SelectRoleModal.scss";
import { useNavigate } from 'react-router-dom';
import RotationSection from '../RotationSection';
import { ScheduleAddModel, ScheduleDisableModel } from 'src/models/Schedule';
import { FiRefreshCw } from 'react-icons/fi';
import { SelectRoleTab } from 'src/enums/SelectRoleTab'
import { NoteTab } from 'src/enums/NoteTypes';
import RotationService from 'src/services/api/rotations.service';
import RotationNotes from '../RotationNotes';
import { NewRotation, Rotation } from 'src/models/Rotation';
import { Shift } from 'src/models/Shift';
import { AppProfile } from 'src/models/AppProfile';
import { AddScheduleAction } from 'src/enums/AddScheduleAction';
import { RoNoteHeaderProps } from 'src/models/Rotation';
import RotationHeader from './RotationHeader/RotationHeader';
import SignalRHubInterceptor from 'src/interceptors/signalr.hub_interceptor';
import GracePeriodHubConstants from 'src/constants/grace-period-hub-constants';

interface SelectRoleModalProps {
    show: boolean;
    onHide: any;
    roleId: string;
    title: string;
}

const SelectRoleModal = ((props: SelectRoleModalProps) => {
    let rotationService = null;
    let shiftService = null;
    let scheduleService = null;
    const MAX_SHIFT_NUMBER = 2, INTERVAL_7_DAYS = 7, INTERVAL_14_DAYS = 14, INTERVAL_28_DAYS = 28;

    const navigationHistory = useNavigate();
    const { setShowAddShiftToast } = useSelectRoleActionCreators();
    const { setCurrentShift, getCurrentShift, setShiftId, setTimeLeftForShiftEnd, getCurrentRotation } = useAppProfileActionCreators();
    const { clearCurrentShiftNotes, clearFollowedNotes } = useMyShiftActionCreators();
    const currentShift: Shift = useTypedSelector(state => state.appProfileReducer.currentUserShift.shift);
    const currentRotation: Rotation = useTypedSelector(state => state.appProfileReducer.currentUserRotation.rotation);
    const hasCurrentShift: boolean = useTypedSelector(state => state.appProfileReducer.currentUserShift.ifExists);
    const isGracePeriod: boolean = useTypedSelector(state => state.appProfileReducer.currentUserShift.gracePeriod.isInGracePeriod);
    const roleSettings: AppProfile = useTypedSelector(state => state.appProfileReducer.roleSettings.roleSettingsDetail);
    const [prevShiftNotes, setPrevShiftNotes] = useState<ShiftNote>(null);
    const [loading, setLoading] = useState<boolean>(false);
    // Set shiftNumber to 2 i.e., previous shift (1 represents current shift)
    const [shiftNumber, setShiftNumber] = useState<number>(MAX_SHIFT_NUMBER);
    const [newRotation, setNewRotation] = useState<NewRotation>(new NewRotation());

    const hubConnections = useTypedSelector(state => state.hubReducer.connections)

    const rotationNotesIntervalOptions = [
        { name: `previous ${INTERVAL_7_DAYS} days`, val: INTERVAL_7_DAYS },
        { name: `previous ${INTERVAL_14_DAYS} days`, val: INTERVAL_14_DAYS },
        { name: `previous ${INTERVAL_28_DAYS} days`, val: INTERVAL_28_DAYS },
    ];

    const headerContStyle = "d-flex flex-wrap justify-content-between align-items-center justify-content-center";

    const shiftPropsDefault = { status: "N/A", statusClassName: "", lastUpdatedDateTime: "N/A", userNameText: "N/A", userNameTooltip: "", rotationNotesIntervalOptions: rotationNotesIntervalOptions };

    const [prevShiftProperties, setPrevShiftProperties] = useState(shiftPropsDefault);

    const rotationNotesPropsDefault: RoNoteHeaderProps = { rotationStartDate: "N/A", rotationEndDate: "N/A", lastUpdatedDateTime: "N/A", userNamesText: "N/A", userNameTooltip: "" };
    const [rotationNotesProperties, setRotationNotesProperties] = useState<RoNoteHeaderProps>(rotationNotesPropsDefault);

    // TODO: setallRotationNotesProperties is not impl. - the lastUpdatedDateTime, userNamesText, userNameTooltip needs to per Role, so this requires a change in the API - confirmation from Product Owner.
    const [allRotationNotesProperties, setallRotationNotesProperties] = useState<RoNoteHeaderProps>(rotationNotesPropsDefault);

    const [lastSyncedShiftNotes, setLastSyncShiftNotes] = useState<string>();
    const [lastSyncedRotationNotes, setLastSyncedRotationNotes] = useState<string>();
    const [lastSyncedAllRotationNotes, setLastSyncedAllRotationNotes] = useState<string>();

    const [isLatestShift, setIsLatestShift] = useState(shiftNumber == MAX_SHIFT_NUMBER);
    const [selectedNoteIds, setSelectedNoteIds] = useState<string[]>([]);

    const [totalNoteIds, setTotalNoteIds] = useState<string[]>([]);

    const [activeTab, setActiveTab] = useState(SelectRoleTab.ShiftNotes);

    const [selectedInterval, setSelectedInterval] = useState(INTERVAL_7_DAYS);

    const [rotationNotes, setRotationNotes] = useState<RotationNote>();
    const [rotationNotesGroup, setRotationNotesGroup] = useState<RotationNote[]>();

    const getSelectRoleNotes = async (roleId: string, shiftNo: number) => {
        setLoading(true);

        if (!shiftService)
            shiftService = new ShiftService();

        await shiftService.getSelectRoleNotes(roleId, shiftNo).then((data) => {
            setPrevShiftNotes(data);
            setTotalNoteIds(data?.locationNotesList?.flatMap(x => x.notes)?.map(x => x.id) || []);

            setLastSyncShiftNotes(new Date().toLocaleString());
        }).catch((error) => {
            console.error(error);
        }).finally(() => setLoading(false));
    };

    const getRotationNotes = async (roleId: string, interval: number) => {
        setLoading(true);

        if (!rotationService)
            rotationService = new RotationService();

        await rotationService.getRotationNotes(roleId, interval).then((data) => {
            if (data && data.length >= 1){
                setRotationNotes(data[0]);
            }
            else{
                setRotationNotes(null);
            }

            setLastSyncedRotationNotes(new Date().toLocaleString());
        }).catch((error) => {
            console.error(error);
        }).finally(() => setLoading(false));
    };

    const getRotationNotesByRoleId = async (roleId: string, interval: number) => {
        setLoading(true);

        if (!rotationService)
            rotationService = new RotationService();

        await rotationService.getRotationNotesByRoleId(roleId, interval).then((data: RotationNote[]) => {
            if (data)
                setRotationNotesGroup(data);
            else
                setRotationNotesGroup(null);

            setLastSyncedAllRotationNotes(new Date().toLocaleString());
        }).catch((error) => {
            console.error(error);
        }).finally(() => setLoading(false));
    };

    const refreshModalData = async () => {
        if (props.roleId) {
            if (activeTab == SelectRoleTab.ShiftNotes) {
                getSelectRoleNotes(props.roleId, shiftNumber);
            }
            else if (activeTab == SelectRoleTab.RotationNotes) {
                getRotationNotes(props.roleId, selectedInterval);
            }
            else if (activeTab == SelectRoleTab.AllRotationNotes) {
                getRotationNotesByRoleId(props.roleId, selectedInterval);
            }
        }
    };

    const canCopy = () => {
        if (!prevShiftNotes || shiftNumber !== 2)
            return false;

        //either I am accepting a new role and do not have an active shift
        if (!currentShift)
            return true;

        // or I am copying from the same role as my active shift
        const currentShiftRoleJobClassName = currentShift.role.name + '-' + currentShift.role.jobClass.shortName;
        const noteRoleJobClassName = prevShiftNotes.roleName + '-' + prevShiftNotes.jobClassShortName;
        return currentShiftRoleJobClassName === noteRoleJobClassName;
    }

    const refreshShiftProperties = () => {
        if (prevShiftNotes) {
            const lastUpdatedDateTime = prevShiftNotes.shiftLastUpdatedDateTime !== '' ? new Date(prevShiftNotes.shiftLastUpdatedDateTime).toLocaleString() : shiftPropsDefault.lastUpdatedDateTime;
            const status = ShiftStatus[prevShiftNotes.shiftStatus] != '0' ? ShiftStatus[prevShiftNotes.shiftStatus].toLocaleLowerCase() : shiftPropsDefault.status;

            let statusClassName = shiftPropsDefault.statusClassName;

            switch (prevShiftNotes.shiftStatus) {
                case ShiftStatus.Unassigned:
                    statusClassName = 'bi-exclamation-triangle mr-1';
                    break;
                case ShiftStatus.Assigned:
                    if (prevShiftNotes.onShiftUserNames?.length > 1)
                        statusClassName = 'bi-people-fill mr-1';
                    else
                        statusClassName = 'bi-person-fill mr-1';
                    break;
            }

            let columnData = shiftPropsDefault.userNameText;
            let columnDataTooltip = shiftPropsDefault.userNameTooltip;

            if (prevShiftNotes.onShiftUserNames && prevShiftNotes.onShiftUserNames.length > 0) {
                columnData = prevShiftNotes.onShiftUserNames[0];

                if (prevShiftNotes.onShiftUserNames.length > 1) {
                    columnData += `, +${prevShiftNotes.onShiftUserNames?.length - 1}`;
                    columnDataTooltip = prevShiftNotes.onShiftUserNames?.join('\n');
                }
            }

            setPrevShiftProperties(prev => ({
                ...prev,
                lastUpdatedDateTime: lastUpdatedDateTime,
                status: status,
                statusClassName: statusClassName,
                userNameText: columnData,
                userNameTooltip: columnDataTooltip
            }));
        }
        else {
            setPrevShiftProperties(shiftPropsDefault);
        }
    }

    const refreshRotationNotesProperties = () => {
        if (rotationNotes) {
            let rotationStartDate: string, rotationEndDate: string;

            if (rotationNotes?.rotationStartDate)
                rotationStartDate = new Date(rotationNotes?.rotationStartDate).toLocaleDateString();
            if (rotationNotes?.rotationEndDate)
                rotationEndDate = new Date(rotationNotes?.rotationEndDate).toLocaleDateString();

            setRotationNotesProperties(prev => ({
                ...prev,
                rotationStartDate: rotationStartDate,
                rotationEndDate: rotationEndDate,
            }));
        }
        else {
            setRotationNotesProperties(rotationNotesPropsDefault);
        }
    }

    const moveToNextShift = () => {
        if (activeTab == SelectRoleTab.ShiftNotes && shiftNumber > MAX_SHIFT_NUMBER) {
            setShiftNumber(shiftNumber - 1);
            setSelectedNoteIds([]);
        }
    };

    const moveToPrevShift = () => {
        if (activeTab == SelectRoleTab.ShiftNotes) {
            setShiftNumber(shiftNumber + 1);
            setSelectedNoteIds([]);
        }
    }

    useEffect(() => {
        getCurrentRotation(props.roleId);
    }, []);

    useEffect(() => {
        setIsLatestShift(shiftNumber == MAX_SHIFT_NUMBER);

        if (hasCurrentShift) {
            setShiftId(currentShift?.id)
        }
    }, [shiftNumber, currentShift]);

    useEffect(() => {
        refreshShiftProperties();
    }, [prevShiftNotes, isGracePeriod]);

    useEffect(() => {
        refreshRotationNotesProperties();
    }, [rotationNotes]);

    useEffect(() => {
        refreshModalData();
    }, [activeTab, selectedInterval, shiftNumber, currentShift, props.roleId]);

    const onShowSelectRoleModal = () => {
        setActiveTab(SelectRoleTab.ShiftNotes);
        setShiftNumber(MAX_SHIFT_NUMBER);
    }

    const dismissModal = () => {
        setPrevShiftNotes(null);
        props.onHide();
    }

    const onHideSelectRoleModal = () => {
        dismissModal();
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key == "ArrowLeft" || e.key == "ArrowDown") {
            moveToPrevShift();
        }
        else if (e.key == "ArrowRight" || e.key == "ArrowUp") {
            moveToNextShift();
        }
    }

    const joinGracePeriodNotificationGroup = () => {
        const signalRHubInterceptor : SignalRHubInterceptor = hubConnections.get(GracePeriodHubConstants.gracePeriodHubName);          
        if(signalRHubInterceptor !== undefined){
            signalRHubInterceptor.send(GracePeriodHubConstants.gracePeriodHubMethods.joinGroup, null, ()=>null);
        }
    }

    /*function to accept a shift*/
    const acceptShift = (action: AddScheduleAction) => {
        setLoading(true);

        if (!scheduleService)
            scheduleService = new ScheduleService();

        let addShift = null;
        let addRotation = null;

        if (action == AddScheduleAction.ShiftAndRotation || action == AddScheduleAction.Shift) {
            addShift = {
                roleId: props.roleId,
                noteIds: selectedNoteIds
            };
        }

        if ((action == AddScheduleAction.ShiftAndRotation || action == AddScheduleAction.Rotation)
            && !currentRotation && newRotation != null && newRotation.isStartingRotation == true) {

            if (!newRotation.isValid) {
                setLoading(false);
                return;
            }
            newRotation.rotation.roleId = props.roleId;
            addRotation = newRotation.rotation;

            addRotation.startDate = new Date(Date.UTC(addRotation.startDate.getFullYear(), addRotation.startDate.getMonth(), addRotation.startDate.getDate()));
            addRotation.endDate = new Date(Date.UTC(addRotation.endDate.getFullYear(), addRotation.endDate.getMonth(), addRotation.endDate.getDate()));
        }

        const request: ScheduleAddModel = {
            shift: addShift,
            rotation: addRotation
        };

        scheduleService.addSchedule(request).then(res => {
            setTimeLeftForShiftEnd(0);
            dismissModal();
            joinGracePeriodNotificationGroup();

            navigationHistory('/myshift'); //redirect to myshift page
            setShowAddShiftToast(true);
        }).catch((error) => {
            console.error('accept shift failed', error?.message);
            Toaster.showError('Failed to accept shift.');
        }).finally(() => {
            setLoading(false);
        })
    }

    /*function to unaccept a shift*/
    const unAcceptShift = () => {
        setLoading(true);

        if (!scheduleService)
            scheduleService = new ScheduleService();

        const request: ScheduleDisableModel = {
            shiftId: currentShift?.id,
            rotationId: null
        };

        scheduleService.disableSchedule(request).then(() => {
            setCurrentShift(null);

            clearCurrentShiftNotes();
            clearFollowedNotes();


            Toaster.showSuccess('Shift unaccepted successfully.');
        }).catch((error) => {
            console.error('unaccept shift failed', error?.message);
            Toaster.showError('Failed to unaccept shift.');
        }).finally(() => {
            setLoading(false);
            dismissModal();
        });
    }

    const copySelectedNotes = async (noteIds: string[]) => {
        setLoading(true);

        if (!shiftService)
            shiftService = new ShiftService();

        const request = {
            id: currentShift?.id,
            noteIds: noteIds
        }

        await shiftService.updateShift(request).then(res => {
            Toaster.showSuccess('Note(s) copied successfully.');
            dismissModal();
            getCurrentShift();
            navigationHistory('/myshift'); //redirect to myshift page
        }).catch((error) => {
            console.error(error);
            Toaster.showError('Failed to copy notes.');
        }).finally(() => {
            setLoading(false);
        })
    }

    const setChecked = (isChecked: boolean, noteId: string) => {
        if (isChecked) {
            if (!selectedNoteIds.some(x => x === noteId))
                setSelectedNoteIds([...selectedNoteIds, noteId]);
        } else {
            setSelectedNoteIds(selectedNoteIds.filter(x => x !== noteId));
        }
    }

    const selectAll = (isChecked: boolean) => {
        if (isChecked)
            setSelectedNoteIds(totalNoteIds);
        else
            setSelectedNoteIds([]);
    }

    const selectRoleModalLastSynced = () => {
        switch (activeTab) {
            case SelectRoleTab.ShiftNotes:
                return <span>{lastSyncedShiftNotes}</span>
            case SelectRoleTab.RotationNotes:
                return <span>{lastSyncedRotationNotes}</span>
            case SelectRoleTab.AllRotationNotes:
                return <span>{lastSyncedAllRotationNotes}</span>
        }
    }

    const getAcceptButtonLabel = (): string => {
        if (selectedNoteIds?.length > 0) {
            if (!currentRotation && newRotation?.isStartingRotation) {
                return 'accept shift, rotation & copy';
            }

            return 'accept shift & copy';
        }


        if (!currentRotation && newRotation?.isStartingRotation)
            return 'accept shift & rotation';

        return 'accept shift';
    }

    return (
        <div onKeyDown={onKeyDown} tabIndex={-1}>
            <Modal className='selectRoleModal dialog1'
                show={props.show}
                onShow={() => onShowSelectRoleModal()}
                onHide={() => onHideSelectRoleModal()}
                centered>
                {/*Modal.Header*/}
                <Modal.Header closeButton className='px-4'>
                    <h4 className='m-0'>{props.title}</h4>
                </Modal.Header>

                {/*Modal.Body*/}
                {/* Shift and Notes are found */}
                <Modal.Body className='d-flex-column flex-grow-1 px-4 pt-4 pb-0 bg-lightest-gray'>
                    {/* Tabs */}
                    <div className={`${headerContStyle}`}>
                        <div className="d-flex align-items-baseline tabs">
                            {/*ShiftNotes Tab*/}
                            <div className={`px-4 pb-3 action-bar-page-tab ${activeTab === SelectRoleTab.ShiftNotes ? " active-link " : " "}`}
                                onClick={() => { setActiveTab(SelectRoleTab.ShiftNotes); }}>
                                {"shift notes"}
                            </div>

                            {/*RotationNotes Tab*/}
                            {roleSettings?.isRotationEnabled &&
                                <div className={`px-4 pb-3 action-bar-page-tab ${activeTab === SelectRoleTab.RotationNotes ? " active-link " : " "}`}
                                    onClick={() => { setActiveTab(SelectRoleTab.RotationNotes); }}>
                                    {"rotation notes"}
                                </div>
                            }

                            {/*AllRotationNotes Tab*/}
                            {roleSettings?.isRotationEnabled &&
                                <div className={`px-4 pb-3 action-bar-page-tab ${activeTab === SelectRoleTab.AllRotationNotes ? " active-link " : " "}`}
                                    onClick={() => { setActiveTab(SelectRoleTab.AllRotationNotes); }}>
                                    {"all rotation notes"}
                                </div>
                            }
                        </div>

                        <div className="d-flex color-dark-gray justify-content-end align-items-center small">
                            {/*Last Synced*/}
                            <div className='pr-2'>
                                last synced: {selectRoleModalLastSynced()}
                            </div>

                            {/*Refresh Button*/}
                            <div className="pl-2">
                                <button className="btn button-text" onClick={refreshModalData} disabled={loading}>
                                    refresh
                                    <FiRefreshCw className="normal ml-1" />
                                </button>
                            </div>
                        </div>
                    </div>

                    {/*Tab Content*/}
                    <div className="d-flex-column flex-grow-1 gap-3 p-4 modalStyle">
                        {/*MyShiftNotes*/}
                        {activeTab === SelectRoleTab.ShiftNotes &&
                            <>
                                <div className={headerContStyle}>
                                    {/* ShiftStatus */}
                                    <div className='mr-auto'>
                                        <span className='large title'>{new Date(prevShiftNotes?.shiftScheduleDate).toLocaleDateString()}, {ShiftSchedulePeriod[prevShiftNotes?.shiftSchedulePeriod]}</span>
                                    </div>

                                    <div className="d-flex gap-3">
                                        <OverlayTrigger overlay={
                                            <Tooltip id='selectAllNotesTooltip' className='modal-tooltip'>
                                                previous shift
                                            </Tooltip>
                                        }>
                                            <button className="btn button-text" onClick={moveToPrevShift} disabled={loading}>
                                                {"<  previous shift"}
                                            </button>
                                        </OverlayTrigger>

                                        <OverlayTrigger overlay={
                                            <Tooltip id='selectAllNotesTooltip' className='modal-tooltip'>
                                                next shift
                                            </Tooltip>
                                        }>
                                            <button className="btn button-text" onClick={moveToNextShift} disabled={loading || isLatestShift}>
                                                {"next shift >"}
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                </div>

                                <div className="d-flex gap-3 flex-wrap align-items-center justify-content-center border-bottom">
                                    <div className='d-flex-column'>
                                        <strong>status</strong>
                                        <label>
                                            <i className={prevShiftProperties.statusClassName}></i>
                                            {prevShiftProperties.status}
                                        </label>
                                    </div>
                                    <div className='d-flex-column'>
                                        <strong>last updated</strong>
                                        <label>
                                            {prevShiftProperties.lastUpdatedDateTime}
                                        </label>
                                    </div>
                                    <div className='d-flex-column'>
                                        <strong>on-shift user</strong>
                                        <label title={prevShiftProperties.userNameTooltip}>
                                            {prevShiftProperties.userNameText}
                                        </label>
                                    </div>

                                    <div className='ml-auto'>
                                        {prevShiftNotes?.locationNotesList?.length > 0 &&
                                            <OverlayTrigger
                                                overlay={
                                                    <Tooltip id='selectAllNotesTooltip' className='modal-tooltip'>
                                                        select all notes
                                                    </Tooltip>
                                                }>
                                                <div className='d-flex justify-content-end align-items-center text-end justify-content-center'>
                                                    <Checkbox inputId="copyAll"
                                                        disabled={!canCopy()}
                                                        checked={selectedNoteIds.length > 0 && selectedNoteIds.length === totalNoteIds.length}
                                                        onChange={e => selectAll(e.checked)} />
                                                    <label className={`label-Text  note-sm-hide ${!canCopy() ? 'disabled' : ''}`} htmlFor="copyAll" id="copyAllLabel">
                                                        copy all
                                                    </label>
                                                </div>
                                            </OverlayTrigger>
                                        }
                                    </div>
                                </div>

                                <ShiftNotes loading={loading} noteTab={NoteTab.SelectRoleNotes} noteData={prevShiftNotes} selectedNoteIds={selectedNoteIds} canSelect={canCopy()} setChecked={setChecked} />
                            </>
                        }

                        {/*RotationNotes*/}
                        {activeTab === SelectRoleTab.RotationNotes &&
                            <>
                                <RotationHeader roNoteHeaderProps={rotationNotesProperties} selectedInterval={selectedInterval}
                                    setSelectedInterval={setSelectedInterval} intervalOptions={shiftPropsDefault.rotationNotesIntervalOptions} loading={loading} />
                                <RotationNotes noteTab={NoteTab.SelectRoleRotationNotes} rotationData={rotationNotes} loading={loading} />
                            </>
                        }

                        {/*MyRotationNotes*/}
                        {activeTab === SelectRoleTab.AllRotationNotes &&
                            <>
                                <RotationHeader roNoteHeaderProps={allRotationNotesProperties} selectedInterval={selectedInterval}
                                    setSelectedInterval={setSelectedInterval} intervalOptions={shiftPropsDefault.rotationNotesIntervalOptions} loading={loading} />

                                {
                                    loading || (rotationNotesGroup && rotationNotesGroup.length == 0) ? <RotationNotes noteTab={NoteTab.SelectRoleAllRotationNotes} rotationData={undefined} loading={loading} /> : (
                                        rotationNotesGroup?.map((rItem: RotationNote, index) => {
                                            return <RotationNotes key={index} noteTab={NoteTab.SelectRoleAllRotationNotes} rotationData={rItem} loading={loading} />
                                        }))
                                }
                            </>
                        }
                    </div>
                </Modal.Body>

                {/*Modal.Footer*/}
                <Modal.Footer className='p-3'>
                    <Container fluid>
                        <Row className='d-flex justify-content-between no-gutters'>

                            <Col className='flex-row'>
                                {/*Rotation Selection*/}
                                {roleSettings?.isRotationEnabled &&
                                    <RotationSection
                                        currentRotation={currentRotation}
                                        setRotation={setNewRotation}
                                        shouldDisplayVertically={false}
                                    />
                                }
                            </Col>

                            {/*Submit Buttons (accept, copy, unassign*/}
                            <Col className='d-flex align-items-end col-auto'>
                                {
                                    currentShift && !isGracePeriod && props.roleId === currentShift.role.id ?
                                        (<button className="btn btn-primary" disabled={!isLatestShift || loading} onClick={() => unAcceptShift()}>
                                            unaccept
                                        </button>
                                        )
                                        :
                                        (<button className="btn btn-primary" disabled={!isLatestShift || loading || (
                                            hasCurrentShift
                                            && !isGracePeriod
                                            && props.roleId !== currentShift.role.id)
                                        }
                                            onClick={() => acceptShift(newRotation?.isStartingRotation ? AddScheduleAction.ShiftAndRotation : AddScheduleAction.Shift)}>
                                            {getAcceptButtonLabel()}
                                        </button>)
                                }
                                {
                                    currentShift && !isGracePeriod && props.roleId === currentShift.role.id && roleSettings?.isRotationEnabled && !currentRotation &&
                                    (<button className="ml-1 btn btn-secondary btn-higth" disabled={!isLatestShift || loading || !newRotation?.isStartingRotation} onClick={() => acceptShift(AddScheduleAction.Rotation)}>
                                        rotation
                                    </button>)
                                }
                                {canCopy() && currentShift && !isGracePeriod &&
                                    (<button className="ml-1 btn btn-primary"
                                        disabled={totalNoteIds.length === 0 || selectedNoteIds.length === 0 || loading}
                                        onClick={() => copySelectedNotes(selectedNoteIds)}>
                                        copy selected
                                    </button>
                                    )
                                }
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
        </div>
    );
});

export default SelectRoleModal;