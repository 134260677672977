import { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import Filters from 'src/services/api/filter.service';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';

import "./RoleDataTable.scss";
import { SelectRole } from '../../models/SelectRole';
import { ShiftStatus } from 'src/enums/ShiftStatus';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { useSelectRoleActionCreators } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypedselector';
import SelectRoleModal from '../SelectRoleModal/SelectRoleModal';
import { DateTimeHelper } from 'src/helpers/DateTimeHelper';
import { BiCheckCircle } from "react-icons/bi";

// eslint-disable-next-line react/display-name
const RoleDataTable = () => {
    const toolTip_Assigned = "Assigned";
    const toolTip_UnAssigned = "Unassigned";
    const emptyRecordsMsg = "no records found";

    const [selectRoleModalVisibility, setSelectRoleModalVisibility] = useState(false);
    const { setRoleData } = useSelectRoleActionCreators();
    const roleData = useTypedSelector(state => state.selectRoleReducer.roleData);
    const loading = useTypedSelector(state => state.selectRoleReducer.loading);
    const currentShift = useTypedSelector(state => state.appProfileReducer.currentUserShift.shift);

    const filterService = new Filters();

    const [selectedRoleData, setSelectedRoleData] = useState({ id: null, name: null });

    const [first, setFirst] = useState(0);

    const showSelectRoleModal = () => {
        setSelectRoleModalVisibility(true);
    }

    const hideSelectRoleModal = () => {
        setSelectRoleModalVisibility(false);
    }

    const toggleRoleFavorite = async (roleId: string, isFavorite: boolean) => {

        return isFavorite
            ? await filterService.deleteFavourites(1, roleId)
            : await filterService.addFavourites(1, roleId)
    };

    const toggleRolestatus = async (rowDataItem: SelectRole, isFavorite: boolean) => {
        await toggleRoleFavorite(rowDataItem.roleId, isFavorite)
            .then(() => {
                const roleDataCopy = roleData.slice();
                roleDataCopy.find(r => r.roleId == rowDataItem.roleId).isFavorite = !isFavorite;
                setRoleData(roleDataCopy);
            }).catch(e => {
                throw e;
            });
    };

    const roleFavoriteColumnBodyTemplate = (rowData: SelectRole) => {
        return <i className={rowData.isFavorite ? "h5 bi bi-star-fill textColor" : "h5 bi bi-star textColor"}
            onClick={(event) => { event.stopPropagation(); toggleRolestatus(rowData, rowData.isFavorite) }}>
        </i>;
    }

    const onShiftUserNameColumnBodyTemplate = (rowData: SelectRole) => {
        const hasMultiPeopleAssigned = rowData.otherShiftUserNames?.length > 1;
        let columnData: string;
        let toolTipText: string;

        if (hasMultiPeopleAssigned) {
            columnData = rowData.otherShiftUserNames[0];
            columnData += `, +${rowData.otherShiftUserNames?.length - 1}`;
            toolTipText = rowData.otherShiftUserNames?.join('\n');
        }
        else {
            if (rowData.otherShiftUserNames)
                columnData = rowData.otherShiftUserNames[0];
        }

        return <div title={toolTipText}>{columnData}</div>;
    }

    const roleStatusColumnBodyTemplate = (rowData: SelectRole) => {
        let icon = 'h5 bi';
        let tooltip = "";

        switch (rowData.shiftsStatus) {
            case ShiftStatus.Unassigned:
                icon += " bi-person textColor";
                tooltip = toolTip_UnAssigned;
                break;

            case ShiftStatus.Assigned:
                {
                    icon += rowData.otherShiftUserNames?.length > 1
                        ? ' bi-people-fill textColor'
                        : ' bi-person-fill textColor';
                }
                tooltip = toolTip_Assigned;
                break;
        }

        return <i className={icon} title={tooltip} />;
    }

    const intializeModalProps = (selectRole: SelectRole) => {
        setSelectedRoleData({ id: selectRole.roleId, name: selectRole.roleName });
    }

    const selectRoleBodyTemplate = (rowData: SelectRole) => {
        return (
            <button
                type="button"
                className="btn button-text select-role-link"
                onClick={() => {
                    intializeModalProps(rowData);
                }}>
                select
            </button>
        );
    }

    const assignedShiftCheckedTemplate = (rowData: SelectRole) => {
        return currentShift?.role.id === rowData.roleId ? (
            <OverlayTrigger
                overlay={
                    <Tooltip id={rowData.roleId} className="tooltip">
                        my assigned shift
                    </Tooltip>
                }
            >
                <span>< BiCheckCircle />  </span>
            </OverlayTrigger>
        ) : null;
    }

    const onRowClick = (event) => {
        intializeModalProps(event.data);
    };

    useEffect(() => {
        if (selectedRoleData?.id)
            showSelectRoleModal();
    }, [selectedRoleData]);

    useEffect(() => {
        if (!selectRoleModalVisibility)
            setSelectedRoleData(null);
    }, [selectRoleModalVisibility]);

    return (
        <>
            <DataTable
                dataKey="id"
                value={roleData}
                paginator
                rows={10}
                first={first}
                loading={loading}
                onPage={(e) => setFirst(e.first)}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{totalRecords} results"
                resizableColumns
                rowClassName={() => "rowHover"}
                paginatorLeft
                selectionMode="single"
                onRowClick={onRowClick}
                emptyMessage={emptyRecordsMsg}>
                {/*Favorites Column*/}
                <Column body={roleFavoriteColumnBodyTemplate} align='center' className="datGridIconColumnWidth" />

                {/*Role Name Column*/}
                <Column key='roleName' field='roleName' header='role name' sortable={true} />

                {/*Hold the Shift Username*/}
                <Column header="on-shift user" body={onShiftUserNameColumnBodyTemplate} />

                {/*Hold the lastUpdated datetime*/}
                <Column header="last updated" body={(rowData: SelectRole) => DateTimeHelper.toHHMMString(rowData.shiftsLastUpdatedDateTime)} className="dataGridLastUpdColumnGrid" />

                {/*Hold the Shift's Status*/}
                <Column header="status" body={roleStatusColumnBodyTemplate} align='center' className="datGridIconColumnWidth" />

                {/*Select Link Column*/}
                <Column sortableDisabled excludeGlobalFilter align='center' body={selectRoleBodyTemplate} className="datGridSelectColumnWidth"></Column>

                {/*Assigned Shift Role checked  */}
                <Column sortableDisabled excludeGlobalFilter body={assignedShiftCheckedTemplate} align='center' className="datGridAssignedShiftWidth h5 textColor"></Column>
            </DataTable>

            {selectedRoleData && selectedRoleData.id &&
                <SelectRoleModal show={selectRoleModalVisibility} onHide={hideSelectRoleModal} roleId={selectedRoleData.id} title={selectedRoleData.name} />
            }
        </>
    );
};

export default RoleDataTable;
