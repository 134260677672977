import { Dispatch } from "react";
import { ActionType } from "../action-types";
import { HubAction } from "../actions/hubActions";

export const addHubConnection = (payload: string) => {
    return (dispatch: Dispatch<HubAction>) => {
        dispatch({
            type: ActionType.ADD_HUB_CONNECTION,
            payload
        });
    }
}

export const RemoveHubConnection = (payload: string) => {
    return (dispatch: Dispatch<HubAction>) => {
        dispatch({
            type: ActionType.REMOVE_HUB_CONNECTION,
            payload
        });
    }
}