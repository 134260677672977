import "./ReportModal.scss"
import { Modal } from 'react-bootstrap';
import { Toaster } from "src/services/api/toaster.service";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { Chips } from "primereact/chips";
import { useEffect, useState } from "react";
import { ReportScheduleFrequency } from "src/models/ReportConfig";
import { useTypedSelector } from "src/hooks/useTypedselector";
import { Days } from "src/enums/Days";
import ReportService from "src/services/api/report.service";
import { Report } from "src/models/Report";
import { ReportConfigFilterRecipient } from "src/models/ReportConfigFilterRecipient";
import { Calendar } from 'primereact/calendar';
import { RoleTagFilter, ShiftScheduleFilter, TagFilter } from "src/models/FilterProfileHistory";
import TreeHelper from "../FilterPanel/TreeHelper";
import { FilterConfigHistory } from "src/models/FilterConfigHistory";
import { ShiftSchedulePeriod } from "src/models/NoteItem";
import { ReportValidationType } from "src/enums/ReportValidationType";
import { ValidationHelper } from "src/helpers/ValidationHelper";
import CustomSpinner from "../CustomSpinner/CustomSpinner";

interface ReportModalProps {
  showModal: boolean;
  toggleShowModal: () => void;
  report: Report;
  updateReports?: () => void;
}

const ReportModal = (props: ReportModalProps) => {
  const titleText = props.report?.id == undefined ? 'create email report' : 'edit email report';
  const buttonSave = 'save report'
  const successSaveReport = `Report saved successfully`
  const errorSaveReport = `Something went wrong`
  const lengthNameReportValue = 128

  const roleSettings = useTypedSelector(state => state.appProfileReducer.roleSettings.roleSettingsDetail);

  const [reportValidations, setReportValidations] = useState<ReportValidationType[]>([]);
  const [scheduleFrequencyOptions, setScheduleFrequencyOptions] = useState<ReportScheduleFrequency[]>([]);
  const [loading, setLoading] = useState(false);
  const [reportEdit, setReportEdit] = useState<Report>(props.report || {} as Report);

  const selectedRoleIds = useTypedSelector(state => state.filterReducer.allFilters.selectedRoleIds);
  const filterProfileRoleModels = useTypedSelector(state => state.filterReducer.allFilters.filterProfileRoleModels);
  const shiftSchedulePeriods: ShiftScheduleFilter[] = useTypedSelector(state => state.filterReducer.allFilters.shiftSchedulePeriods);
  const selectedLocationIds: string[] = useTypedSelector(state => state.filterReducer.allFilters.selectedLocationIds);
  const sectionTags: TagFilter[] = useTypedSelector(state => state.filterReducer.allFilters.sectionTags);
  const categoryTags: TagFilter[] = useTypedSelector(state => state.filterReducer.allFilters.categoryTags);
  const roleTags: RoleTagFilter[] = useTypedSelector(state => state.filterReducer.allFilters.roleTags);
  const locations = useTypedSelector(state => state.filterReducer.allFilters.locations);

  const currentUserProfile = useTypedSelector(state => state.appProfileReducer.userProfileState.userProfile);
  const reportService = new ReportService();

  const getScheduledFrequencyOptions = (): ReportScheduleFrequency[] => {
    return scheduleFrequencyOptions.filter(o => o.scheduleType == props.report?.scheduleType);
  }

  const isDayFrequencySelected = (day: Days): boolean => {
    return (reportEdit?.dailyFrequency & day) === day;
  }

  const addValidation = (validationType: ReportValidationType) => {
    if (!reportValidations.includes(validationType)) {
      setReportValidations([...reportValidations, validationType]);
    }
  }

  const removeValidation = (validationType: ReportValidationType) => {
    if (reportValidations.includes(validationType)) {
      setReportValidations(reportValidations.filter(v => v !== validationType));
    }
  }

  const checkValidations = (validationsTypes: ReportValidationType[]): boolean => {
    return reportValidations.some((v: ReportValidationType) => validationsTypes.indexOf(v) != -1);
  }

  const validateAllFields = (reportEntity: Report): boolean => {
    let isValid = true;

    if (!reportEntity?.reportName) {
      addValidation(ReportValidationType.ReportNameMissing);
      isValid = false;
    } else {
      removeValidation(ReportValidationType.ReportNameMissing);
    }

    if (!reportEntity?.config?.schedule || reportEntity?.config?.schedule == 0) {
      addValidation(ReportValidationType.ScheduleFrequencyNotSelected);
      isValid = false;
    } else {
      removeValidation(ReportValidationType.ScheduleFrequencyNotSelected);
    }

    if (!reportEntity?.dailyFrequency) {
      addValidation(ReportValidationType.ScheduleDayNotSelected);
      isValid = false;
    } else {
      removeValidation(ReportValidationType.ScheduleDayNotSelected);
    }

    if (!reportEntity?.executionTime) {
      addValidation(ReportValidationType.ExecutionTimeNotDefined);
      isValid = false;
    } else {
      removeValidation(ReportValidationType.ExecutionTimeNotDefined);
    }

    if (reportEntity?.reportRecipients?.length == 0) {
      addValidation(ReportValidationType.ReportRecipientNotDefined);
      isValid = false;
    } else {
      removeValidation(ReportValidationType.ReportRecipientNotDefined);
    }

    return isValid;
  }

  const handleReportNameChange = ({ name, value }) => {
    if (value.length >= lengthNameReportValue) {
      addValidation(ReportValidationType.ReportNameReachedCharacterLimit);
      return;
    }

    if (value.length == 0) {
      addValidation(ReportValidationType.ReportNameMissing);
    } else {
      removeValidation(ReportValidationType.ReportNameMissing);
    }

    removeValidation(ReportValidationType.ReportNameReachedCharacterLimit);

    setReportEdit((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleReportScheduleDropdownChange = (e) => {
    const { value } = e.target;

    removeValidation(ReportValidationType.ScheduleFrequencyNotSelected);

    setReportEdit(prev => ({
      ...prev,
      config: {
        ...prev.config,
        schedule: value
      }
    }));
  };
  const handleEmailChipsChange = (e: { value: unknown[] }) => {
    const emails = e.value.map(email => (typeof email === 'string' ? email.replace(/\s/g, '') : ''));

    const uniqueEmails = Array.from(new Set(emails));

    const validEmails = uniqueEmails.filter(email => {
      if (typeof email === 'string') {
        return ValidationHelper.validateEmail(email);
      }
      return false;
    });

    const invalidEmails = uniqueEmails.filter(email => {
      if (typeof email === 'string') {
        return !ValidationHelper.validateEmail(email);
      }
      return true;
    });

    if (invalidEmails.length > 0) {
      addValidation(ReportValidationType.InvalidRecipiendEmails);
    } else {
      removeValidation(ReportValidationType.InvalidRecipiendEmails);
    }

    if (validEmails.length > 0) {
      removeValidation(ReportValidationType.ReportRecipientNotDefined);
    } else {
      addValidation(ReportValidationType.ReportRecipientNotDefined);
    }

    setReportEdit(prev => ({
      ...prev,
      reportRecipients: validEmails.map(email => ({ reportId: reportEdit.id, email }))
    }));
  };

  const handleDayFrequencyChange = (day: Days, isChecked: boolean) => {
    setReportEdit(prev => {
      const newDailyFrequency = isChecked
        ? prev.dailyFrequency | day
        : prev.dailyFrequency & ~day;

      if (newDailyFrequency >= 1) {
        removeValidation(ReportValidationType.ScheduleDayNotSelected);
      } else {
        addValidation(ReportValidationType.ScheduleDayNotSelected);
      }

      return {
        ...prev,
        dailyFrequency: newDailyFrequency
      }
    })
  };

  const handleExecutionDTChange = (e) => {
    if (!e.value) return;

    const excecutionTimeDate = new Date(e.value as Date);
    excecutionTimeDate.setSeconds(0);
    excecutionTimeDate.setMilliseconds(0);

    setReportEdit((prev) => ({
      ...prev,
      executionTime: excecutionTimeDate,
    }));
  }

  const formatDayFrequencyLabel = (day: number): string => {
    return Days[day].substring(0, 3).toUpperCase();
  }

  const populateReportFilterConfig = (): ReportConfigFilterRecipient => {
    const reportFitlerConfig: ReportConfigFilterRecipient = {
      filters: {
        roles: [],
        roleTags: [],
        locations: [],
        selectedRoleIds: selectedRoleIds?.length > 0 ? selectedRoleIds : [],
        selectedLocationIds: selectedLocationIds?.length > 0 ? selectedLocationIds : [],
        selectedSectionTagIds: sectionTags?.length > 0 ? sectionTags.filter(t => t.isSelected).map(s => (s.id)) : [],
        selectedCategoryTagIds: categoryTags?.length > 0 ? categoryTags.filter(t => t.isSelected).map(s => (s.id)) : [],
        selectedRoleTagIds: roleTags?.length > 0 ? roleTags.flatMap(rt => rt.tags.filter(t => t.isSelected).map(t => t.id)) : [],
        selectedShiftSchedulePeriods: shiftSchedulePeriods?.length > 0 ? shiftSchedulePeriods.filter(sh => sh.isSelected).map(s => s.id) : [],
      },
      schedule: reportEdit?.config?.schedule,
    } as ReportConfigFilterRecipient;

    return reportFitlerConfig;
  }

  const saveReport = async () => {
    setLoading(true);

    const reportEntity: Report = {
      id: props.report?.id,
      facilityId: props.report?.facilityId,
      reportName: reportEdit?.reportName,
      config: props.report?.id ? reportEdit?.config : populateReportFilterConfig(),
      executionTime: reportEdit.executionTime ? reportEdit.executionTime : new Date(),
      dailyFrequency: reportEdit?.dailyFrequency,
      scheduleType: reportEdit?.scheduleType,
      userId: currentUserProfile?.id,
      reportRecipients: reportEdit.reportRecipients.map(rec => ({ reportId: reportEdit.id, email: rec.email })),
      isActive: true
    }

    setReportEdit(reportEntity);

    if (!validateAllFields(reportEntity)) {
      setLoading(false);
      return;
    }

    let result: boolean;
    try {
      if (!props.report.id) {
        const response = await reportService.addReport(reportEntity);
        result = response.id?.length > 0
      } else {
        result = await reportService.updateReport(reportEntity);
      }

      if (result) {
        Toaster.showSuccess(successSaveReport)
        if (props.updateReports) {
          props.updateReports();
        }
        props.toggleShowModal();
      } else {
        Toaster.showError(errorSaveReport);
      }

      setLoading(false);

    } catch (error: any) {
      // Handle error is done in AxiosInterceptor
      setLoading(false);
    }


  };

  const reportModalSutitle = (): string => {
    let subtitle = `You'll receive a recurring email report containing the turnover notes filtered by: `
    const filters = [];

    const reportConfigFilter: ReportConfigFilterRecipient = reportEdit?.id ? reportEdit?.config : populateReportFilterConfig();
    const reportFilters: FilterConfigHistory = reportConfigFilter?.filters;

    if (reportFilters.selectedRoleIds.length > 0) {
      let roleNames = '';
      if (reportFilters.roles?.length == 0) {
        const roles = TreeHelper.convertToTreeItems(filterProfileRoleModels);
        const seledtedRoles = TreeHelper.getSelectedItemsIdNames(reportFilters.selectedRoleIds, roles);

        roleNames = seledtedRoles.map(s => (s.name)).join(', ');
      } else {
        roleNames = reportFilters?.roles?.map(s => (s.name)).join(', ');
      }

      filters.push('Roles: ' + roleNames);
    } else {
      filters.push('Roles: All');
    }

    if (reportFilters.selectedSectionTagIds?.length > 0 || reportFilters.selectedCategoryTagIds?.length > 0 || reportFilters.selectedRoleTagIds?.length > 0) {
      let tagNames = '';
      if (reportFilters.roleTags?.length == 0) {
        const selectedSectionTags = sectionTags ? sectionTags.filter(t => t.isSelected).map(s => s.name) : [];
        const selectedCategoryTags = categoryTags ? categoryTags.filter(t => t.isSelected).map(s => s.name) : [];
        const selectedRoleTags = roleTags ? roleTags.flatMap(rt => rt.tags.filter(t => t.isSelected).map(t => t.name)) : [];

        tagNames = [...selectedSectionTags, ...selectedCategoryTags, ...selectedRoleTags].join(', ');
      } else {
        tagNames = reportFilters?.roleTags?.map(s => s.name).join(', ');
      }

      filters.push('Tags: ' + tagNames)
    } else {
      filters.push('Tags: All');
    }

    if (reportFilters.selectedShiftSchedulePeriods?.length > 0) {
      const selectedShiftSchedulePeriods = reportFilters.selectedShiftSchedulePeriods.map(sh => ShiftSchedulePeriod[sh]);

      const shiftSchedulePeriodNames = selectedShiftSchedulePeriods.length == 0 || selectedShiftSchedulePeriods.length == 2 ? 'All'
        : selectedShiftSchedulePeriods.join(', ');

      filters.push('Shift Schedule: ' + shiftSchedulePeriodNames);
    } else {
      filters.push('Shift Schedule: All');
    }

    if (reportFilters.selectedLocationIds?.length > 0) {
      let locationNames = '';
      if (reportFilters.locations?.length == 0) {
        locationNames = locations.filter(l => selectedLocationIds.includes(l.id)).map(l => l.name).join(', ');
      } else {
        locationNames = reportFilters?.locations?.map(l => l.name).join(', ');
      }

      filters.push('Locations: ' + locationNames);
    } else {
      filters.push('Locations: All');
    }

    subtitle += filters.join(', ');

    return subtitle;
  }

  useEffect(() => {
    if (roleSettings && roleSettings.reportConfig && roleSettings.reportConfig.reportScheduleFrequency && scheduleFrequencyOptions.length === 0) {
      setScheduleFrequencyOptions(roleSettings?.reportConfig.reportScheduleFrequency)
    }
  }, [roleSettings, scheduleFrequencyOptions])
  useEffect(() => {
    setReportEdit(props.report);

    return () => {
      setReportEdit({} as Report);
      setReportValidations([]);
    };
  }, [props.report])

  useEffect(() => {
    if (reportEdit?.isActive) {
      validateAllFields(reportEdit);
    }
  }, [reportValidations]);

  return (
    <Modal
      show={props.showModal}
      onHide={props.toggleShowModal}
      centered
      keyboard
      scrollable
    >
      <Modal.Header closeButton closeLabel='Close' className="border-bottom-0">
        <Modal.Title bsPrefix="modal-title large gotham-bold">
          {titleText}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body pt-0 pb-4">
        <CustomSpinner loading={loading} cssClass={'spinner-position-report-modal'} />
        <p className="input-control-label">
          {reportModalSutitle()}
        </p>
        <div className="form-group">
          <label htmlFor="txtReportName" className="input-control-label">report name</label>
          <p className="input-control-label">this will be the subject of your email report</p>
          <InputText
            id="txtReportName"
            className={checkValidations([ReportValidationType.ReportNameMissing, ReportValidationType.ReportNameReachedCharacterLimit]) ? 'p-invalid form-control error-border' : 'form-control'}
            name="reportName"
            value={reportEdit?.reportName}
            onChange={(e) => handleReportNameChange({ name: 'reportName', value: e.target.value })}
            placeholder="Enter report name"
            maxLength={128}
          />
          <div>
            {checkValidations([ReportValidationType.ReportNameMissing]) && <small className="p-error">* required report name</small>}
            {checkValidations([ReportValidationType.ReportNameReachedCharacterLimit]) && <small className="p-error">* Max length is 128</small>}
          </div>

        </div>

        <div className="form-group flex-column">
          <label htmlFor="dpdwnReportScheduleData" className="input-control-label">report data</label>
          <Dropdown
            id="dpdwnReportScheduleData"
            placeholder="Select an option"
            className={'mb-2 rounded-0 custom-area-dropdown ' + (checkValidations([ReportValidationType.ScheduleFrequencyNotSelected]) ? 'p-invalid error-border' : '')}
            name="reportEdit.config.schedule"
            options={getScheduledFrequencyOptions()}
            value={reportEdit?.config?.schedule}
            optionLabel="value"
            optionValue="id"
            onChange={handleReportScheduleDropdownChange}
          />
          <div>
            {checkValidations([ReportValidationType.ScheduleFrequencyNotSelected]) && <small className="p-error">* required schedule data frenquency</small>}
          </div>
        </div>

        <div className="form-group">
          <label className="input-control-label">days</label>
          <div className="checkbox-container d-flex gap-3 align-items-center wrap">
            {Object.keys(Days)
              .filter(key => !isNaN(Number(Days[key as any])))
              .map(key => Number(Days[key as any]))
              .filter(value => value !== Days.None)
              .map((day) => (
                <div className="checkbox-button-wrapper" key={day}>
                  <input
                    type="checkbox"
                    id={`day-${day}`}
                    checked={isDayFrequencySelected(day as Days)}
                    onChange={(e) => handleDayFrequencyChange(day as Days, e.target.checked)}
                  />
                  <label htmlFor={`day-${day}`}>{formatDayFrequencyLabel(day)}</label>
                </div>
              ))
            }
          </div>
          <div>
            {checkValidations([ReportValidationType.ScheduleDayNotSelected]) && <small className="p-error">* required day selection</small>}
          </div>
        </div>
        <div className="form-group flex-column">
          <label className="input-control-label">execution time</label>
          <Calendar id="executionTimeBoxDT"
            value={new Date(reportEdit?.executionTime)}
            onChange={(e) => handleExecutionDTChange(e)}
            timeOnly
            hourFormat="12"
            stepMinute={1}
            ariaLabelledBy="execution-time input textbox"
            baseZIndex={2000}
            placeholder='hh:mm AM/PM'
          />
        </div>

        <div className="form-group">
          <label className="input-control-label">additional recipients</label>
          <p className="input-control-label">use commas to separate multiple email addresses</p>
          <Chips
            className={'form-control ' + (checkValidations([ReportValidationType.ReportRecipientNotDefined]) ? 'error-border' : '')}
            value={reportEdit?.reportRecipients?.map(recipient => recipient.email)}
            onChange={handleEmailChipsChange}
            placeholder={"email(s) separated by comma"}
            allowDuplicate={false}
            separator="," />
          <div>
            {checkValidations([ReportValidationType.ReportRecipientNotDefined]) && <small className="p-error">* required recipient list</small>}
          </div>
          <div>
            {checkValidations([ReportValidationType.InvalidRecipiendEmails]) && <small className="p-error">* invalid recipient email</small>}
          </div>
        </div>

      </Modal.Body>
      <Modal.Footer className='border-0'>
        {/*Cancel button*/}
        <button
          type="button"
          className="btn flex-fill btn-secondary"
          onClick={props.toggleShowModal}
        >
          cancel
        </button>

        <div className='px-1' />
        {/*Confirmation button*/}
        <button
          type="button"
          className={'btn flex-fill btn-primary'}
          onClick={() => saveReport()}
        >
          {buttonSave}
        </button>

      </Modal.Footer>
    </Modal>
  )
};

export default ReportModal;

ReportModal.defaultProps = {
  titleClass: 'modal-title large gotham-bold',
  bodyClass: 'modal-body medium pt-0',
  bodyText: '',
  footerClass: 'modal-footer border-0',
  confirmationButtonText: 'save report',
};