import { Dispatch } from 'redux';
import { ActionType } from '../action-types';
import { SelectRoleAction } from '../actions/selectRoleActions';
import SelectRoleService from 'src/services/api/select-role.service';
import MS_Graph_Service from '../../services/api/ms-graph-service'
import { ProfileImage } from "../../models/ProfileImage";
import { SelectRole } from 'src/models/SelectRole';

const selectRoleService = new SelectRoleService();
const ms_graph_service = new MS_Graph_Service();

export const toggleSelectRoleModal = () => {
    return (dispatch: Dispatch<SelectRoleAction>) => {
        dispatch({
            type: ActionType.SELECT_ROLE_MODAL_TOGGLE_VISIBILITY
        });
    }
}

export const setRoleData = (roles: SelectRole[]) => {
    return (dispatch: Dispatch<SelectRoleAction>) => {
        dispatch({
            type: ActionType.SET_ROLE_DATA,
            payload: roles
        });
    }
}

export const setIsFavoriteToggle = (isFavorite: boolean) => {
    return (dispatch: Dispatch<SelectRoleAction>) => {
        dispatch({
            type: ActionType.SET_IS_FAVOURITE_TOGGLE,
            payload: isFavorite
        });
    }
}

export const setIsFilterPanelEnabled = (isFilterPanelEnabled: boolean) => {
    return (dispatch: Dispatch<SelectRoleAction>) => {
        dispatch({
            type: ActionType.SET_IS_FILTER_PANEL_ENABLED,
            payload: isFilterPanelEnabled
        });
    }
}

export const setShowAddShiftToast = (value: boolean) => {
    return (dispatch: Dispatch<SelectRoleAction>) => {
        dispatch({
            type: ActionType.SET_SHOW_ADD_SHIFT_TOAST,
            payload: value
        });
    }
}

export const getRoleData = (isFavorite: boolean, abortSignal: AbortSignal) => {
    return async (dispatch: Dispatch<SelectRoleAction>) => {
        dispatch({
            type: ActionType.GET_ROLE_DATA,
        });
        try {
            const roleData = await selectRoleService.getByFilter(isFavorite, abortSignal);
            dispatch({
                type: ActionType.GET_ROLE_DATA_SUCCESS,
                payload: roleData,
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            dispatch({
                type: ActionType.GET_ROLE_DATA_FAILURE,
                payload: err.message,
            });
        }
    };
};

export const updateProfileImage = (oId: string) => {
    return async (dispatch: Dispatch<SelectRoleAction>) => {
        try {
            const getImage = async (obId: string) => {
                return ms_graph_service.getUserProfileImage(obId);
            };

            const imgObj: ProfileImage = {
                oId: oId,
                image: null,
            };

            await getImage(oId).then((data) => {
                imgObj.image = URL.createObjectURL(data);
            });

            dispatch({
                type: ActionType.UPDATE_PROFILE_IMAGE,
                payload: imgObj,
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            console.log("ac.error: " + err);
        }
    };
};

export const addProfileImage = (oId: string) => {
    return async (dispatch: Dispatch<SelectRoleAction>, getState) => {
        try {
            const profileImages = getState().selectRoleReducer.profileImages;

            const pIndex = profileImages.findIndex((p) => p.oId == oId);

            if (pIndex >= 0) {
                return;
            } else {
                const imgObj: ProfileImage = {
                    oId: oId,
                    image: null,
                };

                dispatch({
                    type: ActionType.ADD_PROFILE_IMAGE,
                    payload: imgObj,
                });

                const pImageData = await ms_graph_service.getUserProfileImage(oId);
                imgObj.image = URL.createObjectURL(pImageData);

                dispatch({
                    type: ActionType.UPDATE_PROFILE_IMAGE,
                    payload: imgObj,
                });
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) { }
    };
};