import { NoteAddModelBase, SingleNote } from 'src/models/NoteItem';
import { APIService } from './api.service';
import { NoteType } from 'src/enums/NoteTypes';

export default class NoteService extends APIService {
    private url = '/notes';

    async addNote(request: NoteAddModelBase): Promise<SingleNote> {
        const response = await this.api.post(`${this.url}`, request);
        return response.data;
    }

    async updateNote(request: NoteAddModelBase): Promise<boolean> {
        const response = await this.api.put(`${this.url}`, request);
        return response.data;
    }

    async dismissItem(shiftId: string, noteId: string): Promise<void> {
        await this.api.delete(
            `${this.url}/dismiss-followed-note/${shiftId}/${noteId}`
        );
    }

    async deleteNote(noteId: string, noteType: NoteType): Promise<void> {
        await this.api.delete(`${this.url}/?id=${noteId}&noteType=${noteType.valueOf()}`);
    }

    async updateTagRoleDetails(request): Promise<boolean> {
        const response = await this.api.put(`${this.url}/tags-to-note`, request);
        return response.data;
    }

    async archivePersistentNotes(noteId: string, isArchived: boolean): Promise<boolean> {
        const response = await this.api.put(`${this.url}/archive-persistent-note/${noteId}/${isArchived}`);
        return response.data;
    }

    async copyNote(request): Promise<boolean> {
        const response = await this.api.post(`${this.url}/copy-note`, request);
        return response.data;
    }

}