import React, { useState, useEffect, useRef } from "react";
import { BiSearch } from "react-icons/bi";
import { Row, Col } from 'react-bootstrap';
import { Toaster } from "src/services/api/toaster.service";
import ReportSelection from "src/components/ReportSelection";
import ReportService from "src/services/api/report.service";
import "./Report.scss";
import { Report as ReportModel } from "src/models/Report";
import CustomSpinner from "src/components/CustomSpinner/CustomSpinner";
import ReportCard from "src/components/ReportCard";
import { RiSearchLine } from "react-icons/ri";

const Report: React.FC = () => {
    const ref = useRef(null);

    const service = new ReportService();
    const [loading, setLoading] = useState(false);
    const retrievalErrorMessage = 'Failed to retrieve reports';

    const [allReports, setAllReports] = useState<ReportModel[]>([]);
    const [reports, setReports] = useState<ReportModel[]>([]);

    const retrieveTable = () => {
        setLoading(true);
        service.getAllReportsByCurrentUser().then(async (response) => {
            setAllReports(response)
            setReports(response)
        }).catch((error) => {
            console.error('Failed to retrieve reports: ', error?.message);
            Toaster.showError(retrievalErrorMessage);
        }).finally(() => {
            setLoading(false);
        })
    }

    // Retrieve table on page load.
    useEffect(() => {
        retrieveTable();
    }, []);

    const handleClick = () => {
        const filteredReports = allReports.filter(report => report.reportName.toLowerCase().includes(ref.current.value.toLowerCase()));
        setReports(filteredReports)
    };

    const handleEnterButton = (e) => {
        if (e.key === "Enter") {
            handleClick();
        }
        return false;
    }

    return (
        <div className='report'>
            <div className='no-gutters min-height-actionbar align-items-center'>
                <strong className="report-component-header-title">
                    reports
                </strong>
            </div>
            <div className="d-flex-column bg-white shadow-sm p-3">
                <Row className="no-gutters">
                    <Col className="d-flex align-items-center search-input-box my-1">
                        <input ref={ref} type="text" className="form-control search-input" placeholder="i'm looking for..." onKeyUp={handleEnterButton} />
                    </Col>
                    <Col xs={12} md="auto" className="d-flex my-1">
                        <ReportSelection
                            dropdownParentClass="dropdown-select-container"
                            dropdownChildClass="dropdown-select-box"
                            dropdownClass="dropdown-select"
                        />
                        <button type="button" className="search-button px-3" onClick={handleClick}><BiSearch /></button>
                    </Col>
                </Row>
                <Row>
                    <CustomSpinner loading={loading} cssClass={'spinner-position'} />

                    {reports.map((report, index) => {
                        return (
                            <Col key={index} sm={12} md={6} className="mb-6">
                                <div className="p-3">
                                </div>
                                <ReportCard
                                    report={report}
                                    severity={""}
                                    updateReports={retrieveTable}
                                />
                            </Col>
                        )
                    })}

                    {reports.length === 0 && !loading && (
                        <div>
                            <div className="center-content mt-5">
                                <RiSearchLine style={{ fontSize: '2.5em', marginBottom: '10px' }} />
                            </div>
                            <div>
                                <div className="text-container">
                                    <p className="bold-text"> no available reports. </p>
                                    <p className="description-text">to create new reports access the history page and define new reports or define a different search critial.</p>
                                </div>
                            </div>
                        </div>
                    )
                    }
                </Row>
            </div>
        </div>
    )
}

export default Report;