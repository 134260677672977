import { AccessType } from 'src/enums/AccessType';
import { APIService } from './api.service';
import { UserFacility, UserProfile } from 'src/models/AppProfile';
import { AssignRole } from 'src/models/Permission';
import { AddFacilityUsersResponse } from 'src/models/AddFacilityUsersResponse';

export default class UserService extends APIService {
    private url = '/users';

    async getUserProfile(): Promise<UserProfile> {
        const response = await this.api.get(`${this.url}/profile`);
        return response.data;
    }

    async validateAuthorizedUser(): Promise<AccessType> {
        const response = await this.api.post(`${this.url}/authorization`);
        return response.data.accessType;
    }

    async getFacilityUsers (rootRoleId: string, searchCriteria?: string, 
                            currentPage?: number, pageSize?:number): Promise<any> { 
        let url = `${this.url}?rootRoleId=${rootRoleId}`;            
        
        if (searchCriteria) {
            url += `&searchCriteria=${searchCriteria}`;
        }

        if (currentPage) {
            currentPage += 1; //value of currentPage in frontEnd start in 0; we need 1 to use the paginate
            url += `&currentPage=${currentPage}`;
        }

        if (pageSize) {
            url += `&pageSize=${pageSize}`;
        }
        
        const response = await this.api.get(url);
        return response.data;
    }

    async changeCurrentUserFacility (facilityId : string): Promise<boolean> {
        const url = `${this.url}/facility/${facilityId}`;            
        const response = await this.api.put(url);
        return response.data;
    }

    async getFacilityPermission (userId: string, facilityId:string): Promise<AssignRole[]> { 
        const url = `${this.url}/${userId}/facility/${facilityId}/permissions`;            
        const response = await this.api.get(url);
        return response.data;
    }

    async setFacilityPermission (userId: string, facilityId:string, requestBody: any): Promise<any> { 
        const url = `${this.url}/${userId}/facility/${facilityId}/permissions`;            
        try {
            const response = await this.api.put(url, requestBody);
            return response.data;            
        } catch (error) {
            console.error('error', error)
            throw error;
        }
    }

    async getUserShiftsAndRotations (userId: string, roleId:string): Promise<any> { 
        const url = `${this.url}/${userId}/roles/${roleId}/shifts-and-rotations`;            
        try {
            const response = await this.api.get(url);
            return response.data;            
        } catch (error) {
            console.error('error', error)
            throw error;
        }
    }

    async createFacilityUsers(emails: string[]) : Promise<AddFacilityUsersResponse> {
      const response = await this.api.post(`${this.url}/addFacilityUsers`, emails)
      return response.data
    }
}