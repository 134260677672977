import { AssignState } from "src/enums/AssignState";
import { GroupRole } from "./Role";

export class AssignRole {
	groupRole : GroupRole; 
	state: AssignState;
	isPersist: boolean;
	startDate: string;

	constructor(groupRole: GroupRole, state: AssignState, isPersist: boolean
		, startDate : string) 
	{
		this.groupRole = groupRole;
		this.state = state;
		this.isPersist = isPersist;
		this.startDate = startDate;
	}
}