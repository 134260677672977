import './RotationSection.scss'
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import { Row, Stack } from 'react-bootstrap';
import { DateTimeHelper } from 'src/helpers/DateTimeHelper';
import { useTypedSelector } from 'src/hooks/useTypedselector';
import { NewRotation, EditRotation, Rotation } from 'src/models/Rotation';

interface RotationSectionComponentProps {
    currentRotation: Rotation,
    setRotation: any,
    shouldDisplayVertically?: boolean
    isEditMode?: boolean
}

const RotationSection = ((props: RotationSectionComponentProps) => {
    const [hasStarted, setHasStarted] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Date | Date[] | string | undefined | null>('');
    const [endDate, setEndDate] = useState<Date | Date[] | string | undefined | null>('');
    const [backToBack, setBackToBack] = useState<string>('select');
    const [maxStartDate, setMaxStartDate] = useState<Date | null>(null)
    const [minEndDate, setMinEndDate] = useState<Date | null>(null)

    const myRotationNotes = useTypedSelector(state => state.myRotationReducer.currentRotationNotes);
    const myRotationNotesApiError = useTypedSelector(state => state.myRotationReducer.currentRotationNotesApiErr);

    const isStartDateEmpty = () => {
        return startDate == undefined || startDate.toLocaleString()?.length == 0;
    };

    const isEndDateEmpty = () => {
        return endDate == undefined || endDate.toLocaleString()?.length == 0;
    };

    const isBackToBackEmpty = () => {
        return backToBack == undefined || backToBack?.length == 0;
    }

    const isStartDateGretherThenEndDate = () => {
        if (isStartDateEmpty() && isEndDateEmpty()) return false;
        return (startDate > endDate)
    }

    const isValid = (): boolean => {
        return !isStartDateEmpty() && !isEndDateEmpty() && !isBackToBackEmpty() && !isStartDateGretherThenEndDate(); //!isStartDateGretherThenEndDate();
    }

    useEffect(() => {
        if (!props.isEditMode) {
            const newRotation = new NewRotation();

            newRotation.isStartingRotation = hasStarted;
            newRotation.rotation.startDate = startDate as Date;
            newRotation.rotation.endDate = endDate as Date;
            newRotation.rotation.backToBackEmail = '';
            newRotation.isValid = isValid();

            props.setRotation(newRotation);
        }
        else {
            const editRotation = new EditRotation();

            editRotation.rotation.id = props.currentRotation.id
            editRotation.rotation.startDate = startDate as Date;
            editRotation.rotation.endDate = endDate as Date;
            editRotation.rotation.backToBackEmail = '';
            editRotation.isValid = isValid();

            props.setRotation(editRotation);
        }
    }, [hasStarted, startDate, endDate, props.isEditMode])

    useEffect(() => {
        if (props.isEditMode && props.currentRotation) {
            setStartDate(new Date(props.currentRotation.startDate))
            setEndDate(new Date(props.currentRotation.endDate))
        }
    }, [props.currentRotation])

    useEffect(() => {
        if (props.isEditMode && myRotationNotes && myRotationNotes.rotationNotes && myRotationNotes.rotationNotes.length) {
            setMaxStartDate(new Date(Math.min(...myRotationNotes.rotationNotes[0].notes.map(x => {
                return new Date(x.lastUpdatedDateTime).getTime()
            }))))
            setMinEndDate(new Date(Math.max(...myRotationNotes.rotationNotes[0].notes.map(x => {
                return new Date(x.lastUpdatedDateTime).getTime()
            }))))
        }
        else {
            setMaxStartDate(new Date())
            setMinEndDate(startDate ? startDate as Date : null)
        }
    }, [props.isEditMode, myRotationNotes, myRotationNotesApiError, startDate])

    const StartDateCol = () => (
        <div className={(props.shouldDisplayVertically ? '' : 'col-auto')}>
            <Stack>
                <label className={classNames({ 'p-error': isStartDateEmpty() || isStartDateGretherThenEndDate() })}>rotation start date*</label>
                <Calendar value={startDate} baseZIndex={2000}
                    onChange={(e) => setStartDate(e.value)}
                    className={classNames({ 'p-invalid': isStartDateEmpty() || isStartDateGretherThenEndDate() })}
                    showIcon inputClassName="py-1"
                    placeholder="select"
                    maxDate={maxStartDate}
                />
                {/* disabled={!!(props.isEditMode && (myRotationNotesApiError || (myRotationNotes && myRotationNotes.rotationNotes.length)))} */}
                {isStartDateEmpty() && <label className='p-error'>this field is required.</label>}
                {isStartDateGretherThenEndDate() && <label className='p-error'>the date range is invalid.</label>}
            </Stack>
        </div>
    )

    const EndDateCol = () => (
        <div className={(props.shouldDisplayVertically ? '' : 'col-auto')}>
            <Stack>
                <label className={classNames({ 'p-error': isEndDateEmpty() || isStartDateGretherThenEndDate() })}>rotation end date*</label>
                <Calendar value={endDate}
                    baseZIndex={2000}
                    onChange={(e) => setEndDate(e.value)}
                    className={classNames({ 'p-invalid': isEndDateEmpty() || isStartDateGretherThenEndDate() })}
                    showIcon inputClassName="py-1" placeholder="select"
                    minDate={minEndDate}
                />
                {isEndDateEmpty() && <label className='p-error'>this field is required.</label>}
            </Stack>
        </div>
    )

    const BackToBackCol = () => (
        <div className={(props.shouldDisplayVertically ? '' : 'col-auto')}>
            <Stack>
                <label className={classNames({ 'p-error': isBackToBackEmpty() })}>back-to-back*</label>
                <div className='b2b-select-box'>
                    <Dropdown optionLabel="name"
                        placeholder="select"
                        onChange={(e) => setBackToBack(e.value)}
                        className={classNames({ 'p-invalid': isBackToBackEmpty() })}
                        disabled
                    />
                </div>
                {isBackToBackEmpty() && <label className='p-error'>this field is required.</label>}
            </Stack>
        </div>
    )

    return (<div className='rotation-section'>
        {
            (!props.currentRotation || props.isEditMode) && <Row className='flex-row'>
                {!props.isEditMode && <Row className='align-items-center mt-2 mb-2'>
                    <div className='align-items-center col-auto'>
                        <Checkbox checked={hasStarted} onChange={(e) => setHasStarted(!hasStarted)} />
                        <strong>I’m starting my rotation</strong>
                    </div>
                </Row>
                }
                {(hasStarted || props.isEditMode) && <Row className='rotationDetail d-flex mb-2'>
                    {(props.shouldDisplayVertically ? <div className='flex-row'><StartDateCol /></div> : <StartDateCol />)}
                    {(props.shouldDisplayVertically ? <div className='flex-row'><EndDateCol /></div> : <EndDateCol />)}
                    {/* TODO: We will enable B2B when we have a clear definition of requirements  */}
                    {/* {(props.shouldDisplayVertically ? <div className='flex-row'><BackToBackCol /></div> : <BackToBackCol />)} */}
                </Row>}
            </Row>
        }
        {
            (props.currentRotation && !props.isEditMode) && <Row>
                <div className='col-auto'>
                    <strong>current rotation: </strong><label>{DateTimeHelper.toString(props.currentRotation.startDate)} - {DateTimeHelper.toString(props.currentRotation.endDate)}</label>
                </div>

            </Row>
        }

    </div>);
});

export default RotationSection;
