import { useEffect, useState } from "react";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import "./MyRotationNotes.scss";
import RotationNotes from '../RotationNotes';
import { NoteTab } from 'src/enums/NoteTypes'
import { useMyRotationActionCreators } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypedselector';
import { MdOutlineInfo } from "react-icons/md";

interface MyRotationNotesProps {
    roleId: string;
}

// eslint-disable-next-line react/display-name
const MyRotationNotes = (props: MyRotationNotesProps) => {
    const myRotationNotes = useTypedSelector(state => state.myRotationReducer.currentRotationNotes);
    const myRotationNotesLoading = useTypedSelector(state => state.myRotationReducer.currentRotationNotesLoading);
    const isGracePeriod = useTypedSelector(state => state.appProfileReducer.currentUserShift.gracePeriod.isInGracePeriod);

    const collapseAll = useTypedSelector(state => state.myRotationReducer.collapseAllNotes);
    const collapsedFromMyNotes = useTypedSelector(state => state.myRotationReducer.collapseMyNotes);
    const previousRotationNoteData = useTypedSelector(state => state.myRotationReducer.previousNotesRotation);
    const previousRotationNoteDataLoading = useTypedSelector(state => state.myRotationReducer.previousNotesRotationLoading);

    const { getCurrentRotationNotes, clearCurrentRotationNotes, getPreviousNotesRotation } = useMyRotationActionCreators();
    const [collapsedFollowedNotes, setCollapsedFollowedNotes] = useState(true);

    const styleDef = "d-flex justify-content-between align-items-center bold medium gotham-bold color-dark-blue cursor-pointer rounded-top bg-lighter-gray px-3 py-2";


    useEffect(() => {
        getCurrentRotationNotes(props.roleId, isGracePeriod);
        getPreviousNotesRotation(props.roleId)

        return (() => {
            clearCurrentRotationNotes();
        });
    }, []);

    const handleFollowedNotes = async (bool: boolean) => {
        setCollapsedFollowedNotes(bool)
    };

    return (
        <div className="my-rotation-notes xshadow-sm rounded-bottom d-flex-column gap-2">
            <div className="bg-highlight-blue p-3 mb-3">
                <MdOutlineInfo className="mr-2" />
                These are the notes you flagged as <i>rotation notes</i>.
                They will be included in the handover report after the end of your rotation.
            </div>

            <div className="d-flex-column shadow-sm rounded-bottom">
                <RotationNotes noteTab={NoteTab.MyRotationNotes} rotationData={myRotationNotes} loading={myRotationNotesLoading} />
            </div>

            {/*Followed Notes*/}
            <div className="d-flex-column shadow-sm rounded-bottom">
                <div className={styleDef} aria-label={collapsedFollowedNotes ? 'collapse panel' : 'expand panel'}
                    onClick={() => handleFollowedNotes(!collapsedFollowedNotes)}>
                    <div>
                        Previous Rotation Notes
                    </div>

                    <div className="shift-icon-plus d-flex align-items-center">
                        <span className={collapsedFollowedNotes ? 'pi pi-plus' : 'pi pi-minus'}></span>
                    </div>
                </div>

                <div className="bg-lightest-gray">
                    {!collapsedFollowedNotes && previousRotationNoteData && previousRotationNoteData?.length > 0 &&
                        <div className="d-flex-column gap-3 p-4">
                            {
                                previousRotationNoteData.map((previousNote, index) => {
                                    return <RotationNotes key={index} noteTab={NoteTab.MyRotationNotes} rotationData={previousNote} loading={previousRotationNoteDataLoading} />
                                })
                            }
                        </div>
                    }
                    {!collapsedFollowedNotes && (!previousRotationNoteData || (previousRotationNoteData && previousRotationNoteData?.length === 0)) &&
                        <div className="normal px-4 py-2">
                            You have no previous rotation notes.
                        </div>
                    }
                </div>
            </div>
        </div>
    )
};

export default MyRotationNotes;
