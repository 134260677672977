import { ActionType } from '../action-types';
import { MyRotationAction } from '../actions/myRotationActions';
import { RotationNote, SingleNote } from 'src/models/NoteItem';
import { NoteType } from 'src/enums/NoteTypes';

export interface MyRotationState {
    currentRotationNotesLoading: boolean,
    currentRotationNotes: RotationNote,
    currentRotationNotesApiErr: string,
    currentRotationNotesLastSynced: string,

    allRolesSummary: RotationNote[],
    allRolesLoading: boolean,
    allRolesSummaryApiErr: string,

    tagRoleNoteDetails: SingleNote,

    collapseAllNotes: boolean,
    collapseMyNotes: boolean,
    previousNotesRotation: RotationNote[],
    previousNotesRotationLoading: boolean,
    previousNotesRotationApiErr: string,
}

const initialState = {
    currentRotationNotesLoading: false,
    currentRotationNotes: null,
    currentRotationNotesApiErr: '',
    currentRotationNotesLastSynced: '',

    allRolesLoading: false,
    allRolesSummary: [],
    allRolesSummaryApiErr: '',

    tagRoleNoteDetails: {
        title: '',
        content: '',
        id: '',
        sourceNoteId: '',
        isCritical: false,
        isPersistent: false,
        persistentBeginDateTime: new Date(),
        persistentEndDateTime: new Date(),
        lastUpdatedDateTime: new Date(),
        locationId: '',
        createdDateTime: new Date(),
        userProfile: {
            id: '',
            oId: '',
            firstName: '',
            lastName: '',
            email: '',
            groupRoles: null,
            created: new Date(),
            isAuthorized: false,
            userFacilities: null,
        },
        tags: [],
        attachments: [],
        canEdit: false,
        canTag: false,
        performed: new Date(),
        performedUTC: new Date(),
        noteType: NoteType.Rotation,
        rotationId: ''
    },

    collapseAllNotes: false,
    collapseMyNotes: false,
    previousNotesRotation: [],
    previousNotesRotationLoading: false,
    previousNotesRotationApiErr: '',
};

export const myRotationReducer = (
    state: MyRotationState = initialState,
    action: MyRotationAction
): MyRotationState => {
    switch (action.type) {
        case ActionType.GET_CURRENT_ROTATION_NOTES:
            return { ...state, currentRotationNotesLoading: true };
        case ActionType.GET_CURRENT_ROTATION_NOTES_SUCCESS:
            return { ...state, currentRotationNotesLoading: false, currentRotationNotesLastSynced: new Date().toLocaleString(), currentRotationNotes: action.payload };
        case ActionType.GET_CURRENT_ROTATION_NOTES_FAILURE:
            return { ...state, currentRotationNotesLoading: false, currentRotationNotesApiErr: action.payload, currentRotationNotes: null };
        case ActionType.CLEAR_CURRENT_ROTATION_NOTES:
            return { ...state, currentRotationNotes: null };
        case ActionType.GET_ALL_ROLES_SUMMARY:
            return { ...state, allRolesLoading: true };
        case ActionType.GET_ALL_ROLES_SUMMARY_SUCCESS:
            return { ...state, allRolesLoading: false, allRolesSummary: action.payload };
        case ActionType.GET_ALL_ROLES_SUMMARY_FAILURE:
            return { ...state, allRolesLoading: false, allRolesSummaryApiErr: action.payload, allRolesSummary: [] };
        case ActionType.SET_ALL_NOTES_COLLAPSE_ROTATION:
            return { ...state, collapseAllNotes: action.payload }
        case ActionType.SET_MY_NOTES_COLLAPSE_ALL_NOTES_ROTATION:
            return { ...state, collapseMyNotes: action.payload }
        case ActionType.GET_PREVIOUS_NOTES_ROTATION:
              return { ...state, previousNotesRotationLoading: true }
        case ActionType.GET_PREVIOUS_NOTES_ROTATION_SUCCESS:
            return { ...state, previousNotesRotationLoading: false, previousNotesRotation: action.payload }
        case ActionType.GET_PREVIOUS_NOTES_ROTATION_ERROR:
            return { ...state, previousNotesRotationLoading: false, previousNotesRotationApiErr: action.payload, previousNotesRotation: null }
        case ActionType.SET_PREVIOUS_NOTES_ROTATION_CLEAR:
            return { ...state, previousNotesRotation: null }

        default:
            return state;
    }
};
