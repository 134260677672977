import { TreeCheckboxSelectionKeys, TreeExpandedKeysType, TreeSelectionKeys } from "primereact/tree";
import { RoleFilterItem } from "src/models/FilterItem";
import TreeNode from "src/models/TreeNode";

class TreeNodeHelper {
    fitleredTreeItemsByPermissions(filters: RoleFilterItem[]): TreeNode[] {
        const convertedTree = this.convertToTreeItems(filters);

        if (convertedTree.length > 0) {
            const filteredTree: TreeNode[] = [];
            filteredTree.push(this.filterTreeByPermission(convertedTree[0]));
            return filteredTree;
        }

        return convertedTree;
    }

    convertToTreeItems(filters: RoleFilterItem[]): TreeNode[] {
        const treeItems: TreeNode[] = filters.map(i => <TreeNode>{
            label: i.name,
            key: i.id,
            children: this.convertToTreeItems(i.children),
            userHasAccess: i.userHasAccess
        });

        return treeItems;
    }

    convertToSelectedKeys(items: RoleFilterItem[], keys: string[], selectedNodes: TreeCheckboxSelectionKeys = {}): TreeCheckboxSelectionKeys {
        for (const item of items) {
            const isChecked = keys.some(i => i === item.id);
            const isPartialChecked = this.hasSelectedChildren(item.children, keys);

            if (isChecked || isPartialChecked) {
                selectedNodes[item.id] = {
                    checked: isChecked,
                    partialChecked: (!isChecked) && isPartialChecked
                };
            }

            this.convertToSelectedKeys(item.children, keys, selectedNodes);
        }

        return selectedNodes;
    }

    private hasSelectedChildren(items: RoleFilterItem[], keys: string[]): boolean {
        for (const item of items) {
            if (keys.some(i => i === item.id) || this.hasSelectedChildren(item.children, keys)) {
                return true;
            }
        }

        return false;
    }

    convertToExpandedKeys(keys: TreeCheckboxSelectionKeys): TreeExpandedKeysType {
        const expandedNodes: TreeExpandedKeysType = {};

        for (const [key,] of Object.entries(keys)) {
            expandedNodes[key] = true;
        }

        return expandedNodes;
    }

    getTreeNodeName(id: string, treeNodes: TreeNode[]): string {
        let name: string;

        for (let index = 0; index < treeNodes.length; index++) {

            if (treeNodes[index].key === id) {
                name = treeNodes[index].label;
                break;
            }
            name = this.getTreeNodeName(id, treeNodes[index].children);

            if (name)
                break;
        }

        return name;
    }

    getSelectedItemsIdNames(ids: string[], treeNodes: TreeNode[]) {
        const items: RoleFilterItem[] = [];

        ids.forEach((id) => {
            items.push(<RoleFilterItem>{
                id: id,
                name: this.getTreeNodeName(id, treeNodes),
            });
        });

        // console.log('items: ' + JSON.stringify(items));

        return items;
    }

    getSelectedItems(selectedNodes: TreeSelectionKeys): RoleFilterItem[] {
        const items: RoleFilterItem[] = [];

        if (selectedNodes == null) {
            return items;
        }

        for (const [key, value] of Object.entries(selectedNodes)) {
            if (value.partialChecked) {
                continue;
            }

            items.push(<RoleFilterItem>{
                id: key,
            });
        }

        return items;
    }

    getAllIdsFromLevel(items: RoleFilterItem[], currentDepth: number, maxDepth: number): string[] {
        let ids: string[] = [];
        items.forEach(item => {
            ids.push(item.id);
            if(currentDepth == maxDepth) return; 
            if (item.children.length > 0) {
                ids = ids.concat(this.getAllIdsFromLevel(item.children, currentDepth + 1, maxDepth));
            }
        });
        return ids;
    }

    filterTreeByPermission(root: TreeNode): TreeNode | null {
        if (!root) {
            return null;
        }

        const filteredChildren: TreeNode[] = [];

        for (const child of root.children) {
            const filteredChild = this.filterTreeByPermission(child);
            if (filteredChild) {
                filteredChildren.push(filteredChild);
            }
        }

        if (root.userHasAccess || filteredChildren.length > 0) {
            return <TreeNode>{
                label: root.label,
                key: root.key,
                children: filteredChildren,
                userHasAccess: root.userHasAccess
            }
        }

        return null;
    }
}

const TreeHelper = new TreeNodeHelper();
export default TreeHelper;
