import { Permission } from "src/enums/Permission";
import { UserProfile } from "src/models/AppProfile";

export class PermissionHelper {    
    static hasPermission(user: UserProfile, permission: Permission): boolean {
        for (let index = 0; index <  user.groupRoles.length; index++) {
            const role =  user.groupRoles[index];
            
            if(permission === (role.permissions & permission)){
                return true;   
            }    
        }

        return false;
    }

}