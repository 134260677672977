import { NoteTemplate } from 'src/models/NoteTemplate';
import { SingleNote } from 'src/models/NoteItem';
import { APIService } from './api.service';

export default class NoteTemplateService extends APIService {
    private url = '/note-templates';

    async getTemplateByRoleId(roleId: string): Promise<NoteTemplate[]> {
        const response = await this.api.get(`${this.url}/roleId/${roleId}`);
        return response.data;
    }

    async getTemplate(noteTemplateId: string): Promise<SingleNote> {
        const response = await this.api.get(`${this.url}`, {
            params: {noteTemplateId: noteTemplateId }
        });

        return response.data;
    }

    async getDefaultByRoleId(roleId: string): Promise<NoteTemplate[]> {
        const response = await this.api.get(`${this.url}/default/${roleId}`);
        return response.data;
    }

    async setTemplateAsDefault(roleId: string, noteTemplateId: string): Promise<boolean> {
        const response = await this.api.put(`${this.url}/default/${roleId}/${noteTemplateId}`);
        return response.data;
    }
}