import { useState } from "react";
import { Modal } from 'react-bootstrap';
import LocationSelection from "../LocationSelection/LocationSelection";

interface LocationSelectionModalProps {
    show: boolean;
    toggle: any;
    onConfirm: any;
    titleText: string;
    roleId: string;
    titleClass?: string;
    bodyClass?: string;
    footerClass?: string;
    confirmationButtonText?: string;
}

const LocationSelectionModal = (props: LocationSelectionModalProps) => {

    const [locationValue, setLocationValue] = useState('');
    const [showError, setShowError] = useState(null);

    const handleLocationChange = (e) => {
        setLocationValue(e.value);
        setShowError(null);
    }

    /*generic method that passes in parent props to parent method onConfirm*/
    const onConfirm = () => {
        if (!locationValue || locationValue.length === 0) {
            setShowError('LocationNotSelected');
        }
        else {
            props.onConfirm(locationValue)
            props.toggle();
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={props.toggle}
            backdrop="static"
            centered
            keyboard
            scrollable
        >
            <Modal.Header closeButton closeLabel='Close' className="border-bottom-0">
                <Modal.Title bsPrefix={props.titleClass}>
                    {props.titleText}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body bsPrefix={props.bodyClass}>
                {/*Location Dropdown*/}
                <LocationSelection
                    dropDownClass={`w-100 ${showError ? 'error-border' : ''}`}
                    dropdownDivClass=" dropdown-select-box"
                    handleLocationChange={handleLocationChange}
                    labelClass={`pr-2 input-control-label ${showError ? 'error' : ''}`}
                    locationValue={locationValue}
                    roleId={props.roleId}
                />
            </Modal.Body>
            <Modal.Footer className='border-0'>
                {/*Cancel button*/}
                <button
                    type="button"
                    className="btn flex-fill btn-secondary"
                    onClick={props.toggle}
                >
                    cancel
                </button>
                {/*placeholder*/}
                <div className='px-1' />
                {/*Confirmation button*/}
                <button
                    type="button"
                    className={'btn flex-fill btn-primary'}
                    disabled={!locationValue || locationValue.length === 0}
                    onClick={onConfirm}
                >
                    {props.confirmationButtonText}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default LocationSelectionModal;

/*functional component default props*/
LocationSelectionModal.defaultProps = {
    titleClass: 'modal-title large gotham-bold',
    bodyClass: 'modal-body medium pt-0',
    footerClass: 'modal-footer border-0',
    confirmationButtonText: 'confirm',
    titleText: 'Please select a location',
};