import { useEffect, useState } from "react";
import "./RotationNotes.scss";
import { RotationNote, RotationNotesData, SectionTagRotationNote } from "src/models/NoteItem";
import { NoteTab } from 'src/enums/NoteTypes'
import { useAppProfileActionCreators, useMyRotationActionCreators } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypedselector';
import NoteCardSkeleton from "../Skeleton/NoteCardSkeleton";
import ReportService from "src/services/api/report.service";
import NoteCardSection from "../NoteCardSection";
import { TagType } from "src/enums/TagType";
import SectionTagConstants from "src/constants/section-tag-constants";
import { Rotation } from "src/models/Rotation";

interface RotationNotesProps {
    rotationData: RotationNote,
    noteTab: NoteTab,
    loading?: boolean
}

// eslint-disable-next-line react/display-name
const RotationNotes = (props: RotationNotesProps) => {
    const styleDef = "d-flex justify-content-between align-items-center bold medium gotham-bold color-dark-blue cursor-pointer rounded-top bg-lighter-gray px-3 py-2";
    const errorContStyle = "d-flex-column flex-grow-1 align-items-center justify-content-center";

    const rotationData = props.rotationData;
    const noteTab = props.noteTab;

    const roleId = useTypedSelector(state => state.appProfileReducer.currentUserShift.shift?.role.id);

    const defaultExpandedOption = (noteTab === NoteTab.MyNotes || noteTab === NoteTab.SelectRoleNotes
        || noteTab === NoteTab.HistoryNotesModal || noteTab == NoteTab.MyRotationNotes
        || noteTab === NoteTab.SelectRoleRotationNotes || noteTab === NoteTab.SelectRoleAllRotationNotes);

    const [isExpanded, setIsExpanded] = useState(defaultExpandedOption);

    const { getCurrentRotationNotes } = useMyRotationActionCreators();

    const isGracePeriod = useTypedSelector(state => state.appProfileReducer.currentUserShift.gracePeriod.isInGracePeriod);
    const sectionTagsMasterList = useTypedSelector(state => state.myShiftReducer.sectionList || []);
    const currentRotation: Rotation = useTypedSelector(state => state.appProfileReducer.currentUserRotation.rotation);

    const [sectionTagShiftNotes, setSectionTagShiftNotes] = useState<SectionTagRotationNote[]>([]);

    const [errorMessage, setErrorMessage] = useState<string>();
    const [noteCount, setNoteCount] = useState(0);

    const reportService: ReportService = new ReportService();

    const filterBySectionTags = (rotationNote: RotationNote, tagName: string) => {
        const _notesForSectionTags = structuredClone(rotationNote);
        reportService.filterRotationNoteBySectionTags(_notesForSectionTags, tagName);

        return _notesForSectionTags;
    }


    useEffect(() => {
        if (rotationData) {
            const allNotes: SectionTagRotationNote[] = [];

            sectionTagsMasterList.forEach(sectionTag => {
                const sTagNameVal = sectionTag.name.toLocaleLowerCase();

                const data: SectionTagRotationNote = {
                    isCritical: sTagNameVal == SectionTagConstants.CriticalSectionTagName.toLocaleLowerCase(),
                    rotationNote: filterBySectionTags(rotationData, sTagNameVal),
                    tag: {
                        id: sectionTag.id,
                        name: sectionTag.name.toLocaleLowerCase(),
                        tagContent: sectionTag.tagContent,
                        tagType: TagType.Section
                    }
                }

                allNotes.push(data);
            });


            setSectionTagShiftNotes(allNotes);
        }

        if (props.noteTab === NoteTab.HistoryRotationNotes) {
            let _cnt = 0;
            rotationData?.rotationNotes?.map(notelist => _cnt = _cnt + notelist.notes.length);
            setNoteCount(_cnt);
        }

        if (!currentRotation && noteTab == NoteTab.MyRotationNotes)
            setErrorMessage("No Rotation found");
        else {
            setErrorMessage("");
        }
    }, [rotationData]);

    useEffect(() => {
        if (props.noteTab != NoteTab.SelectRoleRotationNotes && props.noteTab != NoteTab.SelectRoleAllRotationNotes && props.noteTab != NoteTab.HistoryRotationNotes)
            getCurrentRotationNotes(roleId, isGracePeriod);
    }, []);

    const rotNotesErrorClassDef = () => {
        if (props.noteTab == NoteTab.SelectRoleRotationNotes || props.noteTab == NoteTab.SelectRoleAllRotationNotes)
            return errorContStyle;
        else
            return "shadow-sm px-4 py-2";
    }

    return (<div className="d-flex-column flex-grow-1">
        {(props.noteTab === NoteTab.MyRotationNotes || props.noteTab === NoteTab.HistoryRotationNotes || props.noteTab === NoteTab.SelectRoleAllRotationNotes) &&
            <div className={styleDef}
                aria-label={!isExpanded ? 'expand panel' : 'collapse panel'}
                onClick={() => setIsExpanded(!isExpanded)}>
                <div className="col p-0">
                    {rotationData &&
                        <div>
                            <span>{rotationData.roleName} - {rotationData.jobClassShortName}</span>
                            {props.noteTab === NoteTab.HistoryRotationNotes && <span className="pl-1">{`(${noteCount})`}</span>}
                        </div>

                    }
                </div>
                <div className="col-auto p-0 shift-icon-plus">
                    <span className={!isExpanded ? 'pi pi-plus' : 'pi pi-minus'}></span>
                </div>
            </div>
        }

        <div className="d-flex-column flex-grow-1 bg-lightest-gray">
            {
                (props.loading)  ? <div className="p-3"><NoteCardSkeleton /></div> :
                    (isExpanded && sectionTagShiftNotes && sectionTagShiftNotes?.map((sectionData: SectionTagRotationNote, index) => {
                      const sectionExist = sectionTagShiftNotes.filter(s => s.rotationNote.rotationNotes?.length > 0 && s.tag.name == sectionData.tag.name); 

                      if(sectionExist.length > 0 && sectionData.rotationNote.rotationNotes.length === 0) {
                        return <></>;
                      }

                      return (currentRotation || noteTab != NoteTab.MyRotationNotes)  && <NoteCardSection key={index} noteType={props.noteTab} 
                                sectionTagRotationNote={sectionData} 
                                locationName={`${rotationData.roleName}-${rotationData.jobClassShortName}`}  />;
                  }))
            }

            {
                !(props.loading) &&  !currentRotation && errorMessage && isExpanded && <div className={rotNotesErrorClassDef()}>
                    {errorMessage}
                </div>
            }
        </div>
    </div>
    );
};

export default RotationNotes;