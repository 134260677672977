import { Spinner } from "react-bootstrap";
import "./CustomSpinner.scss";

// You can pass your custom css class from parent component and add class in your parent component .scss File.
const CustomSpinner = ({ loading, cssClass = 'spinner-position' }) => {

    return (
        <>
            {loading &&
                <div className="custom-spinner">
                    <Spinner className={"card-spinner " + cssClass}
                        animation="border" role="status" variant="secondary">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            }
        </>
    );
}

export default CustomSpinner;
