import { RotationNote } from 'src/models/NoteItem';
import { APIService } from './api.service';
import { Rotation } from 'src/models/Rotation';

export default class RotationService extends APIService {

    private url = '/rotations'

    async getCurrentRotationNotes(roleId: string, isGracePeriod: boolean): Promise<RotationNote> {
        const response = await this.api.get(`${this.url}/current-user-rotation-notes`, {
            params: { roleId, isGracePeriod }
        });
        return response.data;
    }

    async getCurrentRotation(roleId: string): Promise<Rotation> {
        const response = await this.api.get(`${this.url}/${roleId}`)
        return response.data;
    }

    async getRotationNotesByRotationId(rotationId: string): Promise<RotationNote> {
        const response = await this.api.get(`${this.url}/rotation-notes/${rotationId}`)
        return response.data;
    }

    async getRotationNotes(roleId: string, inLastDays: number): Promise<RotationNote[]> {
        const response = await this.api.get(`${this.url}/rotation-notes/completed`, {
            params: { roleId, inLastDays }
        });

        return response.data;
    }

    async getRotationNotesByRoleId(startingRoleId: string, inLastDays: number): Promise<RotationNote[]> {
        const response = await this.api.get(`${this.url}/rotation-notes/hierarchy/completed`, {
            params: { startingRoleId, inLastDays }
        });

        return response.data;
    }

    async updateRotation(rotationToUpdate): Promise<any> {
        const response = await this.api.put(`${this.url}/`, rotationToUpdate);

        return response.data;
    }

    async getHistoryNotes(searchText: string, from: string, to: string): Promise<RotationNote[]> {
        let url = `${this.url}/history-notes?searchText=${searchText}`;
        if (from) {
            url += `&from=${from}`;
        }
        if (to) {
            url += `&to=${to}`;
        }
        const response = await this.api.get(url);
        return response.data;
    }
}
