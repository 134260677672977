import { TemplateAction } from "../actions/templateActions";
import { ActionType } from "../action-types";
import { NoteTemplateType } from "src/models/NoteTemplate";

export class TemplateState {
    notificationApiErr: string;
    shiftDefaultTemplate: NoteTemplateType;
    rotationDefaultTemplate: NoteTemplateType;
}

const initialState: TemplateState = {
    notificationApiErr: '',
    shiftDefaultTemplate: null,
    rotationDefaultTemplate: null
}

export const templateReducer = (
        state: TemplateState = initialState,
        action: TemplateAction
    ) : TemplateState => {
        switch(action.type){
            case ActionType.GET_DEFAULT_TEMPLATE:
                return {...state};
            case ActionType.GET_DEFAULT_TEMPLATE_SUCCESS:
                return {...state, shiftDefaultTemplate: action.payload.shiftDefaultTemplate, rotationDefaultTemplate: action.payload.rotationDefaultTemplate};
            case ActionType.GET_DEFAULT_TEMPLATE_FAILURE:
                return {...state, notificationApiErr: action.payload}
            default:
                return state;
        }
    }