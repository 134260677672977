import { useEffect, useRef, useState } from "react";
import { Modal } from 'react-bootstrap';
import { Checkbox } from 'primereact/checkbox';
import { NoteTemplateAddModel } from "src/models/NoteItem";
import NoteService from "src/services/api/note.service";
import { Toaster } from "src/services/api/toaster.service";
import { UserProfile } from "src/models/AppProfile";
import { PermissionHelper } from "src/helpers/PermissionHelper";
import { Permission } from "src/enums/Permission";
import { useTemplateActionCreators } from "src/hooks/useActions";
import { TemplateType } from "src/enums/TemplateType";

interface NoteTemplateSaveModalProps {
    show: boolean;
    onHide: () => void;
    noteTemplateAddModel: NoteTemplateAddModel;
    userProfile: UserProfile;
}

const NoteTemplateSaveModal = (props: NoteTemplateSaveModalProps) => {
    const modalTitle = "template name";
    const modalSubTitle = "Choose a name for your new template note";
    const errMsgNoName = "Please enter a name for the template";
    const errMsgInvalidLength = "template name must be between 3 and 100 characters";
    const tmplNameMinLength = 3, tmplNameMaxLength = 100;

    const [loading, setLoading] = useState(false);
    const inputReference = useRef(null);

    const [errorMessage, setErrorMessage] = useState("");

    const [templateName, setTemplateName] = useState('');
    const [isTemplateNameValid, setIsTemplateNameValid] = useState(false);

    const [isDefault, setIsDefault] = useState(false);

    const noteService = new NoteService();

    const { getDefaultTemplate} = useTemplateActionCreators();

    useEffect(() => {
        inputReference.current.focus();
    }, [])

    useEffect(() => {
        if (templateName) {
            const val = templateName.trim();

            if (val) {
                if (val.length >= tmplNameMinLength && val.length <= tmplNameMaxLength) {
                    setIsTemplateNameValid(true);
                    setErrorMessage("");
                }
                else
                    setErrorMessage(errMsgInvalidLength);
            }
        }
        else {
            setErrorMessage("");
            setIsTemplateNameValid(false);
        }

    }, [templateName]);

    const handleTemplateNameValueChange = (val: string) => {
        setTemplateName(val);
    }

    const onConfirm = () => {
        const templateNameVal = templateName?.trim();

        if (templateNameVal?.length > 0) {
            const noteTemplateAddModel: NoteTemplateAddModel = props.noteTemplateAddModel;
            noteTemplateAddModel.label = templateName;
            noteTemplateAddModel.isDefault = isDefault;

            setLoading(true);

            noteService.addNote(noteTemplateAddModel).then(async () => {
                Toaster.showSuccess("You successfully created a template note.");
                setErrorMessage("");
                props.onHide();

                if(noteTemplateAddModel.isDefault)
                    getDefaultTemplate(noteTemplateAddModel.roleId);
                
            }).catch((error) => {
                /*
                clear toasts triggered by the interceptor as the error info
                is shown within the component validation
                */
                Toaster.clearAllToastMessages();
                setErrorMessage(error.response?.data?.message);
            }).finally(() => {
                setLoading(false);
            });
        }
        else {
            setErrorMessage(errMsgNoName);
        }
    }

    return (
        <Modal
            className="dialog2"
            show={props.show}
            onHide={props.onHide}
            backdrop="static"
            centered
            keyboard
            scrollable>
            <Modal.Header closeButton={!loading} closeLabel='Close' className="border-bottom-0">
                <Modal.Title bsPrefix="modal-title large gotham-bold">
                    {props.noteTemplateAddModel.templateType === TemplateType.Shift ? 'shift': 'rotation'} {modalTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body py-0">
                <label htmlFor="divNoteTempSaveModal" className="dialog-sub-title">{modalSubTitle}</label>

                <div id="divNoteTempSaveModal" className="d-flex-column">
                    <label htmlFor="txtTemplateName" className="input-control-label">name</label>
                    <input type="text" id="txtTemplateName" ref={inputReference} className="form-control"
                        readOnly={loading ? true : false}
                        onChange={(event) => handleTemplateNameValueChange(event.target.value)} />

                    {/*Stablish if a Template is default*/}
                    {PermissionHelper.hasPermission(props.userProfile, Permission.Template) &&
                        <div className="d-flex align-items-center" style={{marginTop: '20px'}}>
                            <Checkbox inputId="copyAll"
                                checked={isDefault}
                                onChange={(event) => setIsDefault(event.target.checked)} />
                            <label htmlFor="chkIsDefault">set as default</label>
                        </div>
                    }


                    <div className="error">{errorMessage}&nbsp;</div>
                </div>
            </Modal.Body>
            <Modal.Footer className='border-0 '>
                {/*Cancel button*/}
                <button
                    type="button"
                    className="btn flex-fill btn-secondary"
                    disabled={loading}
                    onClick={props.onHide}>
                    cancel
                </button>

                {/*placeholder*/}
                <div className='px-1' />

                {/*Confirmation button*/}
                <button
                    type="button"
                    className={'btn flex-fill btn-primary'}
                    disabled={!isTemplateNameValid || loading}
                    onClick={onConfirm}>
                    save
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default NoteTemplateSaveModal;