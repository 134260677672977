import "./ConfirmationModal.scss";
import { Modal } from 'react-bootstrap';

interface ConfirmationModalProps {
    showConfirmationModal: boolean;
    toggleConfirmationModal: any;
    objIdList?: string[];
    onConfirm: any;
    titleText: string;
    titleClass?: string;
    bodyText?: string;
    bodyClass?: string;
    footerClass?: string;
    confirmationButtonText?: string;
}

const ConfirmationModal = (props: ConfirmationModalProps) => {

    /*generic method that passes in parent props to parent method onConfirm*/
    const onConfirm = () => {
        if(!props.objIdList){
            props.onConfirm()
            props.toggleConfirmationModal();
            return;
        }

        if(props.onConfirm.length != props.objIdList.length){
            console.error("The amount of IDs must be the same that the amount of parameters");
            return;
        }

        props.onConfirm(...props.objIdList);
        props.toggleConfirmationModal();
    }

    return (
        <Modal
            show={props.showConfirmationModal}
            onHide={props.toggleConfirmationModal}
            backdrop="static"
            centered
            keyboard
            scrollable
        >
            <Modal.Header closeButton closeLabel='Close' className="border-bottom-0">
                <Modal.Title bsPrefix={props.titleClass}>
                    {props.titleText}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body bsPrefix={props.bodyClass} >
                <span>{props.bodyText}</span>
            </Modal.Body>
            <Modal.Footer className='border-0'>
                {/*Cancel button*/}
                <button
                    type="button"
                    className="btn flex-fill btn-secondary"
                    onClick={props.toggleConfirmationModal}
                >
                    cancel
                </button>
                {/*placeholder*/}
                <div className='px-1' />
                {/*Confirmation button*/}
                <button
                    type="button"
                    className={'btn flex-fill btn-primary'}
                    onClick={onConfirm}
                >
                    {props.confirmationButtonText}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;

/*functional component default props*/
ConfirmationModal.defaultProps = {
    titleClass: 'modal-title large gotham-bold',
    bodyClass: 'modal-body medium pt-0',
    bodyText: '',
    footerClass: 'modal-footer border-0',
    confirmationButtonText: 'confirm',
};