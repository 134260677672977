import { NoteType } from "src/enums/NoteTypes";
import { RotationNote } from "src/models/NoteItem";
import { useTypedSelector } from "src/hooks/useTypedselector";
import { useEffect, useState } from "react";
import ReportService from "src/services/api/report.service";
import { FilterDataType } from "src/enums/TagType";
import { RoleFilterItem } from "src/models/FilterItem";
import TreeHelper from "../FilterPanel/TreeHelper";
import ReportRotationNotes from "./ReportRotationNotes";

// eslint-disable-next-line react/display-name
const ReportHistoryRotationNotes = () => {
    const historyData = useTypedSelector(state => state.historyReducer);
    const filterData = useTypedSelector(state => state.filterReducer);

    const [roleFilterItems, setRoleFilterItems] = useState<RoleFilterItem[]>([]);

    const reportService: ReportService = new ReportService();

    const getHistoryRotationNotesTitleMarkup = () => {
        if (historyData.historyNotesSummary && historyData.historyNotesSummary?.length > 0) {
            return <div className="d-flex-column gap-3 align-items-center">
                <h2 className="m-0">{reportService.getHistoryPageTitle(true)}</h2>

                <div className="container-fluid row">
                    <div className="col d-flex flex-row-reverse">
                        <div className="d-flex-column gap-2">
                            <div>
                                <span className="bold">Role(s): </span>
                                <span>{reportService.getSelectedRolesTitle(roleFilterItems)}</span>
                            </div>
                            <div>
                                <span className="bold">Category Tag(s): </span>
                                <span>{reportService.getFilterDataMarkup(filterData, historyData, FilterDataType.CategoryTag)}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex-column gap-2">
                            <div>
                                <span className="bold">Date(s): </span>
                                <span>{reportService.getFilterDataMarkup(filterData, historyData, FilterDataType.DateRange)}</span>
                            </div>
                            <div>
                                <span className="bold">Text: </span>
                                <span>{reportService.getFilterDataMarkup(filterData, historyData, FilterDataType.Text)}</span>
                            </div>
                            <div>
                                <span className="bold">Report Date Time: </span>
                                <span>{reportService.getDateTimeString(new Date())}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        else {
            return <>
                <h2>{reportService.pageTitle}</h2>
                <h4>{reportService.noShiftMessage}</h4>
            </>
        }
    }

    const getHeaderContent = (historyNote: RotationNote) => {
        return "".concat(historyNote.roleName, '-', historyNote.jobClassShortName);
    }

    useEffect(() => {
        setRoleFilterItems(TreeHelper.getSelectedItemsIdNames(filterData?.allFilters?.selectedRoleIds, filterData.role.filters));
    }, [])

    return (
        <div className={reportService.contStDef}>
            {getHistoryRotationNotesTitleMarkup()}

            {
                historyData.historyNotesSummary.map((historyNote: RotationNote, index) => {
                    return (historyNote.rotationNotes?.length > 0) ?
                        <ReportRotationNotes key={'hrn-' + index} noteType={NoteType.HistoryRotationNotes} noteData={historyNote} showHeader={true} headerContent={getHeaderContent(historyNote)} /> :
                        <></>
                })
            }
        </div>
    );
}

export default ReportHistoryRotationNotes;