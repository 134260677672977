import { EditorTools, EditorToolsSettings } from '@progress/kendo-react-editor';

const fontSizeToolSettings: EditorToolsSettings.FormatBlockDropDownListSettings = {
  ...EditorToolsSettings.formatBlock,
  items: [
    {
      "text": "Heading 1",
      "value": "h1",
      "style": {
        "display": "block",
        "fontSize": "1.25em",
      }
    },
    {
      "text": "Heading 2",
      "value": "h2",
      "style": {
        "display": "block",
        "fontSize": "1.125em",
      }
    },
    {
      "text": "Paragraph",
      "value": "p",
      "style": {
        "display": "block",
        "fontSize": "1em",
        "marginLeft": 0,
      }
    }
  ]
};

const CustomFormatBlock = EditorTools.createFormatBlockDropDownList(fontSizeToolSettings);

const EditorCustomFormatBlock = props => <CustomFormatBlock {...props} />;

export default EditorCustomFormatBlock;