import { Link } from "react-router-dom";
import "./NoShift.scss";
import noShift from 'src/assets/images/checklist.png';

const NoShift = () => {
    return (
        <div className="no-shift d-flex-column flex-grow-1 justify-content-center align-items-center">
            <img src={noShift} />
            <label className='no-shift-label'>Select a role to continue</label>
            <label className='no-shift-label-link'>Please visit the
                <Link to="/selectrole" className='m-1 btn-link'><u>Select Role</u></Link>
                page to start your shift.</label>
        </div>
    );
}

export default NoShift;
