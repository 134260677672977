import './Header.scss';
import { Col, Nav, Row } from 'react-bootstrap';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import ChevronLogo from 'src/assets/images/chevronLogo.jpg';
import { Toaster } from 'src/services/api/toaster.service';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { useTypedSelector } from 'src/hooks/useTypedselector';
import { CustomNavLink } from 'src/models/Header';
import ProfileIcon from '../ProfileIcon';
import NotificationIcon from '../NotificationIcon';
import SettingsIcon from '../SettingsIcon';
import FacilityDropdown from '../FacilityDropdown';

interface HeaderDetails {
    title: string;
    links: CustomNavLink[];
}

const Header = (props: HeaderDetails) => {
    const [loading, setLoading] = useState(true);
    Toaster.init(useRef());
    const locationChange = useLocation();
    const currentUserProfile = useTypedSelector(state => state.appProfileReducer.userProfileState.userProfile);
    const navigate = useNavigate();

    // Listens for the route change and removes errors from the toast compoenent
    useEffect(() => {
        Toaster.clearAllToastMessages();
    }, [locationChange]);


    useEffect(() => {
        setLoading(false);
    }, [currentUserProfile]);

    const handleHomePageNavigation = () => {
        navigate('/selectrole');
    }

    return (
        <div className='headerDiv'>
            <header className='header-detail'>
                <Row className="header no-gutters align-items-center">
                    <Col xs={3}>
                    </Col>
                    <Col className="logo-container">
                        <img className="header__logo" src={ChevronLogo} alt="logo"  onClick={handleHomePageNavigation}/>
                        <h4 className="header__title">{props.title}</h4>
                    </Col>
                    <Col xs={2} sm={1}>
                    </Col>
                    <Col xs={1} sm={2} className="header__userInfo">

                        {/*Facility Selection Dropdown*/}
                        {currentUserProfile.userFacilities.length > 1 && <FacilityDropdown userProfile={currentUserProfile} isLoading={loading} />}

                        {/*Notification Button Icon*/}
                        <NotificationIcon userProfile={currentUserProfile} isLoading={loading} />

                        {/*Settings Button Icon*/}
                        <SettingsIcon userProfile={currentUserProfile} isLoading={loading} />

                        {/*User Profile Button Icon*/}
                        <ProfileIcon userProfile={currentUserProfile} isLoading={loading} />
                    </Col>
                </Row>
            </header>
            <nav id="site-specific-nav">
                <Nav justify activeKey={locationChange.pathname} data-cy="primaryNavBar">
                    {props.links.filter(link => link.enabled).map((link: CustomNavLink, index) => {
                        return <Nav.Item key={index}>
                            <Nav.Link
                                key={index}
                                bsPrefix={link.bsPrefix}
                                eventKey={link.eventKey}
                                as={Link}
                                to={link.to}
                                data-cy={link.dataCy}
                            >
                                {link.name}
                            </Nav.Link>
                        </Nav.Item>;
                    })}
                </Nav>
            </nav>
            <Toast ref={Toaster.instance} position='top-center' />
        </div>
    );
};

export default Header;
