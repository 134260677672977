import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Toaster } from "src/services/api/toaster.service";
import { Chips } from 'primereact/chips';
import { ValidationHelper } from 'src/helpers/ValidationHelper';
import UserService from "src/services/api/user.service";

interface UserAccountModalProps {
  showModal: boolean;
  toggleShowModal: () => void;
  refreshUsers: () => void;
}

const UserAccountModal = (props: UserAccountModalProps) => {
  const userService = new UserService();

  const [hasError, setHasError] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);

  const handleCloseModal = (): void => {
    setEmails([]);
    props.toggleShowModal();
  }

  const handleAddEmails = (e: any): void => {
    if (!e.value) {
      Toaster.showError("No email value provided");
      return;
    }

    const validEmails: string[] = [];
    const invalidEmails: string[] = [];
    e.value.forEach(email => {
      email = email.replace(/\s/g, '');
      if (ValidationHelper.validateEmail(email)) {
        validEmails.push(email);
      } else {
        invalidEmails.push(email);
        Toaster.showError("Invalid email: " + email);
      }
    });
  
    if (validEmails.length > 0) {
      setEmails(validEmails);
    }
    
    setHasError(validEmails.length === 0 && invalidEmails.length > 0 ? true : false);
  }

  const createUsersAccount = async () => {
    if (hasError) {
      setHasError(false);
      return;
    }

    if (emails.length === 0 && !hasError) {
      Toaster.showError("Email(s) cannot be blank.");
      return;
    }
    
    const response = await userService.createFacilityUsers(emails)

    if (response.successMail.length > 0) {
      Toaster.showSuccess(`Added emails: ${response.successMail.join(', ')}`);
    }

    if (response.errorMail.length > 0) {
      setTimeout(() =>
        Toaster.showError(`The following mails weren't added: ${response.errorMail.join(', ')}`), 500);
    }
    
    handleCloseModal();

    await props.refreshUsers();
  }

  return (
    <Modal
      show={props.showModal}
      onHide={handleCloseModal}
      centered
      keyboard
      scrollable
    >
      <Modal.Header closeButton closeLabel='Close' className="border-bottom-0">
        <Modal.Title bsPrefix="modal-title large gotham-bold">
          add user(s)
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body pt-0 pb-4">
        <Chips style={{display: 'unset'}}
               value={emails} placeholder={"email(s) separated by comma"}
               allowDuplicate={false}
               addOnBlur={true}
               onChange={handleAddEmails} separator=","
               />
      </Modal.Body>
      <Modal.Footer className='border-0'>
        <button
          type="button"
          className="btn flex-fill btn-secondary"
          onClick={handleCloseModal}
        >
          cancel
        </button>
        <div className='px-1' />
        <button
          type="button"
          className={'btn flex-fill btn-primary'}
          onClick={() => createUsersAccount() }
          >
          create
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default UserAccountModal;