import "./RotationHeader.scss";
import { Dropdown } from 'primereact/dropdown';
import { RoNoteHeaderProps } from "src/models/Rotation";
import RotationHeaderSkeleton from "src/components/Skeleton/RotationHeaderSkeleton";

interface RotationHeaderProps {
    roNoteHeaderProps: RoNoteHeaderProps;
    selectedInterval: number;
    setSelectedInterval: any;
    intervalOptions: any;
    loading: boolean;
}

const RotationHeader = (props: RotationHeaderProps) => {
    return (props.loading ? <RotationHeaderSkeleton /> : <>
        <div className="d-flex flex-wrap justify-content-between align-items-center justify-content-center">
            {/* Rotation BeginDate, EndDate */}
            <div className='mr-auto'>
                <span className='large title'>{props.roNoteHeaderProps.rotationStartDate} - {props.roNoteHeaderProps.rotationEndDate}</span>
            </div>

            {/* Interval Options */}
            <div className="dropdown-select-box">
                <Dropdown optionLabel="name" optionValue="val" value={props.selectedInterval} options={props.intervalOptions} onChange={(e) => props.setSelectedInterval(e.value)} disabled={props.loading} />
            </div>
        </div>

        <div className="d-flex gap-3 flex-wrap pt-3 align-items-center border-bottom">
            {/* LastUpdated */}
            <div className='d-flex-column'>
                <strong>last updated</strong>
                <label>
                    {props.roNoteHeaderProps.lastUpdatedDateTime}
                </label>
            </div>

            {/* On-Rotation User(s) */}
            <div className='d-flex-column'>
                <strong>on-rotation user(s)</strong>
                <label title={props.roNoteHeaderProps.userNameTooltip}>
                    {props.roNoteHeaderProps.userNamesText}
                </label>
            </div>
        </div>
    </>
    );
}

export default RotationHeader;
