import React, { useState, useEffect, useRef } from "react";
import { ShiftSchedulePeriod, SingleNote } from "src/models/NoteItem";
import "./NoteItemCard.scss";
import { MdBlock, MdOutlineInfo } from 'react-icons/md';
import { BsPinAngle, BsThreeDots, BsPerson } from 'react-icons/bs';
import { IoMdCopy } from 'react-icons/io';
import { NoteTab, NoteType } from "src/enums/NoteTypes";
import { NoteMetaDataType, TagType } from "src/enums/TagType";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import { Toaster } from "src/services/api/toaster.service";
import NoteService from "src/services/api/note.service";
import { Attachment } from "src/models/Attachment";
import NoteItemSection from '../NoteItemSection';
import { useTypedSelector } from 'src/hooks/useTypedselector';
import { DateTimeHelper } from "src/helpers/DateTimeHelper";
import { useMyRotationActionCreators, useMyShiftActionCreators } from 'src/hooks/useActions';
import AddNote from "../AddNote";
import ConfirmationModal from "src/components/ConfirmationModal";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import LocationSelectionModal from "../LocationSelectionModal/LocationSelectionModal";
import { EditorUtils } from "@progress/kendo-react-editor";
import HistoryModal from "../HistoryModal";
import { ShiftTab } from "src/enums/ShiftTab";
import NoteMetaDropDown from "../NoteMetaDataDropDown/NoteMetaDataDropDown";
import SignalRHubInterceptor from "src/interceptors/signalr.hub_interceptor";
import { HubConnectionState } from "@microsoft/signalr";
import { ActionType } from "src/enums/ActionType";
import GracePeriodHubConstants from 'src/constants/grace-period-hub-constants';
import { NoteAction } from "src/models/NoteAction";

interface NoteItemCardProps {
    note: SingleNote,
    noteTab: NoteTab,
    roleJobClassName?: string,
    setChecked?: any,
    selectedNoteIds?: string[],
    canSelect?: boolean
    isPrintMode?: boolean
}

const NoteItemCard: React.FC<NoteItemCardProps> = (props) => {
    const note: SingleNote = props.note;
    const noteTab: NoteTab = props.noteTab;
    const [loading, setLoading] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState<string>();

    const noteService = new NoteService();
    const archiveButtonRef = useRef(null);
    const dialogRef = useRef<any>(null);

    const [selectedSectionTagsProps, setSelectedSectionTagsProps] = useState<string[]>([]);
    const [selectedCategoriesProps, setSelectedCategoriesProps] = useState<string[]>([]);
    const [selectedRolesProps, setSelectedRolesProps] = useState<string[]>([]);
    const [selectedAttachmentsProps, setSelectedAttachmentsProps] = useState<Attachment[]>([]);
    const [editNote, setEditNote] = useState<SingleNote>(null);
    const [isExpanded, setIsExpanded] = useState(true);

    const [isEditNoteIsFullScreen, setEditNoteIsFullScreen] = useState<boolean>(false);

    const activeShiftTab = useTypedSelector(state => state.myShiftReducer.activeShiftTab);
    const currentShift = useTypedSelector(state => state.appProfileReducer.currentUserShift.shift);
    const shiftSchedule = useTypedSelector(state => state.appProfileReducer.shiftSchedule.shiftScheduleDetail);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showLocationSelectionModal, setShowLocationSelectionModal] = useState(false);
    const [historyModalVisibility, setHistoryModalVisibility] = useState(false);

    const roleId = useTypedSelector(state => state.appProfileReducer.currentUserShift.shift?.role.id);
    const isGracePeriod = useTypedSelector(state => state.appProfileReducer.currentUserShift.gracePeriod.isInGracePeriod);
    const gracePeriod = useTypedSelector(state => state.appProfileReducer.currentUserShift.gracePeriod);
    const showTagRoleModal = useTypedSelector(state => state.myShiftReducer.showTagRoleModal);
    const currentUserProfile = useTypedSelector(state => state.appProfileReducer.userProfileState.userProfile);
    const currentShiftNotesLoading = useTypedSelector(state => state.myShiftReducer.currentShiftNotesLoading);
    const hubConnections = useTypedSelector(state => state.hubReducer.connections);
    const { getAllRolesSummary, getCurrentShiftNotes, getFollowedNotes, setShowTagRoleModal, setTagRoleModalList } = useMyShiftActionCreators();
    const { getCurrentRotationNotes } = useMyRotationActionCreators();

    const {
        sanitize,
    } = EditorUtils;

    const notifyNoteModificationInGracePeriod = (noteId: string, actionType: ActionType) => {
        if (!isGracePeriod) return;

        const signalRHubInterceptor: SignalRHubInterceptor = hubConnections.get(GracePeriodHubConstants.gracePeriodHubName);

        if (signalRHubInterceptor.connection.state == HubConnectionState.Disconnected ||
            signalRHubInterceptor.connection.state == HubConnectionState.Reconnecting) {
            console.log("SignalR is disconnected");
            return;
        }

        const notificationMessage: NoteAction = new NoteAction()

        notificationMessage.noteId = noteId;
        notificationMessage.actionType = actionType;

        signalRHubInterceptor.send(GracePeriodHubConstants.gracePeriodHubMethods.noteModifiedInGracePeriod, notificationMessage, () => null);
    }

    const returnCategoryRoleAndAttachmentProps = () => {
        const _selectedSectionTags = note.tags.filter(el => el.tagType == TagType.Section).map(el => el.name);
        const _selectedCategories = note.tags.filter(el => el.tagType == TagType.Category).map(el => el.name);
        const _selectedRoleProps = note.tags.filter(el => el.tagType == TagType.Role).map(el => el.name);
        const _selectedAttachmentProps = note.attachments;

        setSelectedSectionTagsProps(_selectedSectionTags);
        setSelectedRolesProps(_selectedRoleProps);
        setSelectedCategoriesProps(_selectedCategories);
        setSelectedAttachmentsProps(_selectedAttachmentProps);
    }

    useEffect(() => {
        returnCategoryRoleAndAttachmentProps();

        setDeleteMessage(`Deleting a note will permanently remove it from your ${NoteType[props?.note?.noteType].toLowerCase()} notes.`);
    }, [props.note]);

    const nicRefreshMyNotes = () => {
        if (activeShiftTab === ShiftTab.MyShiftNotes) {
            getCurrentShiftNotes(roleId, isGracePeriod);
        }
        else if (activeShiftTab === ShiftTab.MyRotationNotes) {
            getCurrentRotationNotes(roleId, isGracePeriod);
        }
    }

    const nicRefreshFollowedNotes = () => {
        if (activeShiftTab === ShiftTab.MyShiftNotes) {
            getFollowedNotes(roleId);
        }
    }

    //function to delete a note and it's children obj
    const deleteNote = async () => {
        setLoading(true);

        if (note.noteType == NoteType.Shift) {
            notifyNoteModificationInGracePeriod(note.id, ActionType.Delete);
        }

        await noteService.deleteNote(note.id, note.noteType).then(() => {
            setShowConfirmationModal(false);
            Toaster.showSuccess('Note deleted successfully.');
            nicRefreshMyNotes();
        }).catch((error) => {
            console.error(error);
            Toaster.showError('Failed to delete note.');
        }).finally(() => setLoading(false));
    }

    const toggleConfirmationModal = () => {
        setShowConfirmationModal(!showConfirmationModal)
    }

    const toggleLocationSelectionModal = () => {
        setShowLocationSelectionModal(!showLocationSelectionModal)
    }

    const showHistoryModal = () => {
        setHistoryModalVisibility(true);
    }

    const hideHistoryModal = () => {
        setHistoryModalVisibility(false);
    }

    //function to edit a note
    const setNoteToEdit = (sNote: SingleNote, isFullScreen: boolean) => {
        setEditNote(sNote);
        setEditNoteIsFullScreen(isFullScreen);
    }

    const archiveNote = async (archiveNoteDetailsId: string, isArchived: boolean) => {
        archiveButtonRef.current.blur();

        setLoading(true);

        await noteService.archivePersistentNotes(archiveNoteDetailsId, isArchived).then(() => {
            if (isArchived) {
                Toaster.showSuccess('Note archived successfully.');
            } else {
                Toaster.showSuccess('Note unarchived successfully.');
            }
            getCurrentShiftNotes(roleId, isGracePeriod)
        }).catch((error) => {
            console.error(error);
            Toaster.showError('Failed to archive note.');
        }).finally(() => setLoading(false));
    }

    //function to copy Notes
    const copyNote = async (locationId: string = null) => {
        const request = {
            sourceNoteId: note.id,
            // Passed location from LocationSelectionModal when copied note from different role hierarchy or copied note rolename
            roleId: locationId,
            currentShiftId: currentShift.id,
            noteType: (noteTab === 4 ? NoteType.Rotation : NoteType.Shift).valueOf()
        }

        setLoading(true);

        await noteService.copyNote(request).then(res => {
            if (res) {
                Toaster.showSuccess('Note copied successfully.');
                if (noteTab === NoteTab.AllNotes)
                    getAllRolesSummary();
                else  // updates followed notes and my shift notes.
                    nicRefreshMyNotes();
            } else {
                setShowLocationSelectionModal(true);
            }
        }).catch((error) => {
            console.error(error);
            Toaster.showError('Failed to copy note.');
        }).finally(() => {
            setLoading(false);
        })
    }

    //function to tag a role
    const tagRole = async (noteId: string, editItemNote: SingleNote) => {
        setTagRoleModalList(editItemNote);
        setShowTagRoleModal(!showTagRoleModal);
    }

    const dismissItem = async (id: string) => {
        setLoading(true);

        await noteService.dismissItem(currentShift.id, id).then(() => {
            Toaster.showSuccess('Note dismissed successfully.');
            nicRefreshFollowedNotes();
        }).catch((error) => {
            console.error(error);
            Toaster.showError('Failed to dismiss note.');
        }).finally(() => setLoading(false));
    }

    const toolTipsClassName = () => {
        return (noteTab === NoteTab.SelectRoleNotes || noteTab === NoteTab.SelectRoleRotationNotes || noteTab === NoteTab.SelectRoleAllRotationNotes || noteTab === NoteTab.HistoryNotesModal) ? 'modal-tooltip' : "tooltip";
    }

    const isMyNote = note.userProfile.email === currentUserProfile.email;

    const hideContextMenuBtn = () => {
        /* hide context menu button for large screen 
         * when it is my note, not persistent and not my note,
         * or when it is my All notes and not my note
         * or when it is a followed notes
         * because buttons are already in the action bar
         */
        if ((noteTab === NoteTab.MyNotes && !note.isPersistent && !isMyNote)
            || (noteTab === NoteTab.AllNotes && !isMyNote)
            || noteTab === NoteTab.FollowedNotes
        ) {
            return "note-lg-hide"
        }

        /* it is always empty if it is my note, in AllNotes section, 
         * regardless of screen size because,
         * can't tag role or copy because is my note and
         * persistent, archive, and unarchive options are hidden because type is AllNotes
         */
        if (noteTab === NoteTab.AllNotes && isMyNote)
            return "d-none"
    }

    const showPersistentUntilLabel = (persistentEndDateTime: Date | string) => {
        return DateTimeHelper.isMAXDateTime(persistentEndDateTime)
            ? 'indefinitely'
            : DateTimeHelper.toHHMMString(note.persistentEndDateTime)
    }

    const showPersistentArchiveBackgroud = () => {
        return note.isArchived && noteTab === NoteTab.MyNotes ? 'bg-gray' : '';
    }

    const isARotationNoteAndHasTitle = () => {
        return note.noteType == NoteType.Rotation && note.title?.length > 0;
    }
    return (
        <>
            <div className={`note-card-section d-flex-column gap-2 note-item-container ${showPersistentArchiveBackgroud()}`}>
                {!editNote &&
                    <>
                        {isARotationNoteAndHasTitle() &&
                            <div className={`d-flex gap-2 gotham-bold medium p-2 justify-content-between rounded-top ${'bg-lighter-blue'} `}>

                                <div className='d-flex flex-wrap gap-2 justify-content-between'>
                                    <span>{note.title}</span>
                                </div>
                                <div className="shift-icon-plus d-flex align-items-center">
                                    <span className={`cursor-pointer ${!isExpanded ? 'pi pi-plus' : 'pi pi-minus'}`} onClick={() => {
                                        if (props.isPrintMode) return;
                                        setIsExpanded(!isExpanded);
                                    }
                                    }></span>
                                </div>

                            </div>
                        }

                        {(isExpanded) && <div className={`${isARotationNoteAndHasTitle() ? 'pr-2 pl-2' : ''}`}>
                            <CustomSpinner loading={loading} />
                            {/* tags, author, highlights, context-menu */}
                            <div className="d-flex flex-wrap justify-content-between">
                                <div className="d-flex flex-wrap gap-2 align-items-center medium gotham-narrow-bold">
                                    {/* NoteCard Author */}
                                    <div>{note.userProfile.firstName} {note.userProfile.lastName}</div>

                                    {
                                        // NoteCard Info Icon and tooltip
                                        !props.isPrintMode &&
                                        <OverlayTrigger rootClose trigger={['hover', 'focus', 'click']} overlay={
                                            <Tooltip id={`tooltip-${note.id}`} className={toolTipsClassName()}>
                                                <span className='d-block font-weight-bold'>Note ID:</span>
                                                {note.id}
                                                {note.sourceNoteId?.length > 0 &&
                                                    <>
                                                        <span className='d-block font-weight-bold'>Copy from ID:</span>
                                                        {note.sourceNoteId}
                                                    </>
                                                }
                                                <span className='d-block font-weight-bold'>Created:</span>
                                                {DateTimeHelper.toHHMMString(note.createdDateTime)}
                                                <span className='d-block font-weight-bold'>Last Modified:</span>
                                                {DateTimeHelper.toHHMMString(note.lastUpdatedDateTime)}
                                                <span className='d-block font-weight-bold'>Performed:</span>
                                                {DateTimeHelper.toHHMMString(note.performed)}
                                            </Tooltip>
                                        }>
                                            <button
                                                className="btn-sm btn-secondary border-0 p-0"
                                                type="button"
                                                disabled={loading}
                                                aria-label="more info about note">
                                                <MdOutlineInfo className="small" />
                                            </button>
                                        </OverlayTrigger>
                                    }

                                    {/*Critical Icon*/}
                                    {
                                        note.isCritical && <span className="medium color-medium-red">critical</span>
                                    }

                                    {
                                        // Category tags  TODO - "category-span" class name from noteitemcard.scss
                                        selectedCategoriesProps?.map((catTag, i) =>
                                            <span key={i} className="small color-white bg-blue gotham-narrow-book rounded p-1 d-inline-block">{catTag}</span>)
                                    }

                                    <div className="color-dark-gray gotham-narrow-book">
                                        {
                                            props.isPrintMode &&
                                            <span>{DateTimeHelper.toHHMMString(note.createdDateTime)}</span>
                                        }

                                        {/* NoteCard Last Modified and Shift Schedule Name */}
                                        {(noteTab === NoteTab.HistoryNotes && note.shiftSchedulePeriod > 0) &&
                                            <span>{ShiftSchedulePeriod[note.shiftSchedulePeriod]}</span>
                                        }

                                        {
                                            (noteTab === NoteTab.MyNotes || noteTab === NoteTab.AllNotes) &&
                                            (DateTimeHelper.toHHMMSSString(note.createdDateTime) !== DateTimeHelper.toHHMMSSString(note.lastUpdatedDateTime)) &&
                                            <span className="italic">(edited)</span>
                                        }
                                    </div>
                                </div>
                                {/* highlights, context-menu */}
                                <div className="d-flex justify-content-end gap-2 align-items-center justify-content-center gotham-narrow-bold">
                                    {/*Persistent Icon and Tooltip*/}
                                    {props.isPrintMode != true && note.isPersistent &&
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip id={`tooltip-${note.id}`} className={toolTipsClassName()}>
                                                    <span className='d-block font-weight-bold'>persistent since:</span>
                                                    {DateTimeHelper.toHHMMString(note.persistentBeginDateTime)}
                                                    <span className='d-block font-weight-bold'>persistent until:</span>
                                                    {showPersistentUntilLabel(note.persistentEndDateTime)}
                                                </Tooltip>
                                            }
                                            trigger={['hover', 'focus', 'click']}>
                                            <span><BsPinAngle className="large color-blue" /></span>
                                        </OverlayTrigger>
                                    }

                                    {/*Section, Role Tags Icon*/}
                                    {props.isPrintMode != true && selectedRolesProps?.length > 0 &&
                                        <NoteMetaDropDown noteTab={props.noteTab} items={selectedRolesProps} noteMetaDataType={NoteMetaDataType.RoleTag} />
                                    }

                                    {/*Attachments Icon*/}
                                    {props.isPrintMode != true && note.attachments?.length > 0 &&
                                        <NoteMetaDropDown noteTab={props.noteTab} items={note.attachments} noteMetaDataType={NoteMetaDataType.Attachment} />
                                    }

                                    {/*Action Button "copy note"*/}
                                    {(noteTab === NoteTab.FollowedNotes || noteTab === NoteTab.AllNotes || noteTab === NoteTab.MyRotationNotes)
                                        && !isMyNote && currentShift &&
                                        <button
                                            className="btn-sm btn-secondary border-0 note-sm-hide note-md-hide note-lg-show"
                                            onClick={() => copyNote()}
                                            aria-label="copy note">
                                            copy note
                                            <IoMdCopy className="normal ml-1" />
                                        </button>
                                    }

                                    {/*Action Button "dismiss note"*/}
                                    {noteTab == NoteTab.FollowedNotes &&
                                        <button
                                            className="btn-sm btn-secondary border-0 note-sm-hide note-md-hide note-lg-show"
                                            onClick={() => dismissItem(note.id)}
                                            aria-label="dismiss note">
                                            dismiss note
                                            <MdBlock className="normal ml-1" />
                                        </button>
                                    }

                                    {/*Action Button "tag role"*/}
                                    {(noteTab === NoteTab.MyNotes || noteTab === NoteTab.AllNotes || noteTab === NoteTab.FollowedNotes)
                                        && !isMyNote &&
                                        <button
                                            className="btn-sm btn-secondary border-0 note-sm-hide note-md-hide note-lg-show"
                                            onClick={() => tagRole(note.id, note)}
                                            aria-label="tag note"
                                            disabled={note.isArchived || isGracePeriod}>
                                            tag role
                                            <BsPerson className="normal ml-1" />
                                        </button>
                                    }

                                    {/*Action Button "Shift View" (Role Name)*/}
                                    {(noteTab === NoteTab.HistoryNotes || noteTab === NoteTab.HistoryRotationNotes) &&
                                        <button
                                            className=" btn button-text"
                                            onClick={() => {
                                                showHistoryModal();
                                            }}
                                            aria-label="shift view">
                                            {props.roleJobClassName}
                                        </button>
                                    }

                                    {/*Copy Note selection checkbox
                                    * Hide copy when canSelect is undefined else enabled/disabled.
                                    */}
                                    {(props.canSelect !== undefined && !props.isPrintMode) &&
                                        <OverlayTrigger
                                            overlay={
                                                <Tooltip id={`selecttooltip-${note.id}`} className={toolTipsClassName()}>
                                                    copy to my shift
                                                </Tooltip>
                                            }>
                                            <div className="col-auto mt-1 d-flex align-items-center">
                                                <Checkbox inputId={'copyNote-' + note.id}
                                                    disabled={!props.canSelect}
                                                    checked={props.selectedNoteIds?.some(sn => sn === note.id)} onChange={e => props.setChecked(e.checked, note.id)} />
                                                <label className={`label-Text note-sm-hide ${!props.canSelect ? 'disabled' : ''}`}
                                                    htmlFor={'copyNote-' + note.id}
                                                    id={'copyNoteSelectLabel-' + note.id}>copy</label>
                                            </div>
                                        </OverlayTrigger>
                                    }

                                    {/*Action Button "Context Menu"*/}
                                    {(noteTab === NoteTab.AllNotes || noteTab === NoteTab.MyNotes || noteTab === NoteTab.MyRotationNotes || noteTab === NoteTab.FollowedNotes) &&
                                        /*Context Menu Dropodown Container*/
                                        <div className={`col-auto dropdown-container p-0 ${hideContextMenuBtn() || ''}`}>
                                            {/*Context Menu Dropdown Button*/}
                                            <button
                                                className="btn-sm btn-secondary border-0 p-0"
                                                aria-label="note"
                                                aria-controls="popup_menu"
                                                aria-haspopup>
                                                <BsThreeDots className="normal" />
                                            </button>

                                            {/*Context Menu Options, Dropdown Content*/}
                                            <div className="dropdown-content shadow-sm border">
                                                {/*Context Menu Option: "edit"*/}
                                                {(noteTab === NoteTab.MyNotes || noteTab === NoteTab.MyRotationNotes) && (note.canEdit || isGracePeriod) &&
                                                    <>
                                                        <button
                                                            className="btn-sm btn-secondary border-0 w-100 text-left d-block"
                                                            onClick={() => setNoteToEdit(note, false)}
                                                            aria-label="edit note">
                                                            edit in line
                                                        </button>

                                                        <button
                                                            className="btn-sm btn-secondary border-0 w-100 text-left d-block"
                                                            onClick={() => setNoteToEdit(note, true)}
                                                            aria-label="edit note">
                                                            edit in full-screen
                                                        </button>
                                                    </>
                                                }

                                                {/*Context Menu Option: "delete"*/}
                                                {(noteTab === NoteTab.MyNotes || noteTab === NoteTab.MyRotationNotes) && (note.canEdit || isGracePeriod) &&
                                                    <button
                                                        className="btn-sm btn-secondary border-0 w-100 text-left d-block"
                                                        onClick={() => setShowConfirmationModal(true)}
                                                        aria-label="delete note">
                                                        delete
                                                    </button>
                                                }

                                                {/*Context Menu Option: "archive/unarchive"*/}
                                                {noteTab === NoteTab.MyNotes && !note.canEdit && (note.isPersistent || note.isArchived) &&
                                                    <button
                                                        className="btn-sm btn-secondary border-0 w-100 text-left"
                                                        disabled={isGracePeriod || loading || currentShiftNotesLoading}
                                                        ref={archiveButtonRef}
                                                        onClick={() => archiveNote(note.id, !note.isArchived)}
                                                        aria-label="archive note">
                                                        {note.isArchived ? 'unarchive' : 'archive'}
                                                    </button>
                                                }

                                                {/*Context Menu Option: "follow"*/}
                                                {/*Disabled functionality for MVP*/}
                                                {/*{noteType == NoteTypes.AllNotes && !isMyNote &&
                                                    <button className="btn-sm btn-secondary border-0 w-100 text-left note-sm-show note-md-hide note-lg-hide"
                                                        onClick={() => followNotes(note.id, note)}
                                                    >
                                                        follow
                                                    </button>
                                                */}

                                                {/*Context Menu Option: "copy notes"*/}
                                                {(noteTab === NoteTab.FollowedNotes || noteTab === NoteTab.AllNotes)
                                                    && !isMyNote &&
                                                    <button
                                                        className="btn-sm btn-secondary border-0 w-100 text-left note-sm-show note-md-show note-lg-hide"
                                                        onClick={() => copyNote()}
                                                        aria-label="copy to my shift">
                                                        copy note
                                                        <IoMdCopy className="normal ml-1" />
                                                    </button>
                                                }

                                                {/*Context Menu Option: "dismiss note*/}
                                                {noteTab === NoteTab.FollowedNotes &&
                                                    <button
                                                        className="btn-sm btn-secondary border-0 w-100 text-left note-sm-show note-md-show note-lg-hide"
                                                        disabled={gracePeriod.minLeftForShiftEndWithGraceMin < 0}
                                                        onClick={() => dismissItem(note.id)}
                                                        aria-label="dismis note">
                                                        dismiss note
                                                        <MdBlock className="normal ml-1" />
                                                    </button>
                                                }

                                                {/*Context Menu Option: "tag role"*/}
                                                {(noteTab === NoteTab.MyNotes || noteTab === NoteTab.AllNotes || noteTab === NoteTab.FollowedNotes)
                                                    && !isMyNote &&
                                                    <button
                                                        className="btn-sm btn-secondary border-0 w-100 text-left note-sm-show note-md-show note-lg-hide"
                                                        disabled={isGracePeriod || note.isArchived}
                                                        onClick={() => tagRole(note.id, note)}
                                                        aria-label="tag note">
                                                        tag role
                                                        <BsPerson className="normal ml-1" />
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {
                                        ((noteTab === NoteTab.HistoryNotes || noteTab === NoteTab.HistoryNotesModal) && props.note.noteType == NoteType.Shift && historyModalVisibility) &&
                                        <HistoryModal show={historyModalVisibility} onHide={hideHistoryModal} noteType={NoteType.Shift} roleId={note.shiftSchedule.roleId} scheduleId={note.shiftSchedule.id} />
                                    }

                                    {
                                        (noteTab === NoteTab.HistoryRotationNotes && props.note.noteType == NoteType.Rotation && historyModalVisibility) &&
                                        <HistoryModal show={historyModalVisibility} onHide={hideHistoryModal} noteType={NoteType.Rotation} rotationId={note.rotationId} />
                                    }
                                </div>
                            </div>

                            {/* NoteContent */}
                            <div className="d-flex">
                                {/*Notecard Row2 (note.content)*/}
                                <div className="noteContent resetStyle" dangerouslySetInnerHTML={{ __html: sanitize(note.content) }}></div>
                            </div>

                            {/* Attachment (PrintMode - Report) */}
                            {
                                props.isPrintMode && note.attachments?.length > 0 &&
                                <div>
                                    <hr className="m-0 opacity-100" />
                                    <div className="d-flex gap-2 mt-1 medium color-dark-gray gotham-narrow-book">
                                        {
                                            selectedAttachmentsProps.map((att, index) => {
                                                return <span key={`nic-att-${index}`}>
                                                    {att.displayFileName} |
                                                </span>
                                            })
                                        }
                                    </div>
                                </div>
                            }


                            {/*Tags Summary and Attachments Summary*/}
                            {
                                editNote && (selectedSectionTagsProps?.length > 0 || selectedCategoriesProps?.length > 0 || selectedRolesProps?.length > 0 || selectedAttachmentsProps?.length > 0) &&
                                <NoteItemSection
                                    noteId={note.id}
                                    selectedSection={selectedSectionTagsProps}
                                    selectedCategory={selectedCategoriesProps}
                                    selectedRoles={selectedRolesProps}
                                    setSelectedSectionTags={setSelectedSectionTagsProps}
                                    setSelectedCategories={setSelectedCategoriesProps}
                                    setSelectedRoles={setSelectedRolesProps}
                                    selectedAttachments={selectedAttachmentsProps}
                                    setSelectedAttachments={setSelectedAttachmentsProps}
                                    editMode={false}
                                    loading={loading}
                                    noteTab={props.noteTab}
                                    isPrintMode={props.isPrintMode}
                                />
                            }
                        </div>
                        }
                    </>
                }
                {editNote && (
                    isEditNoteIsFullScreen ?
                        (<Dialog
                            visible={isEditNoteIsFullScreen}
                            maximized={true}
                            modal
                            style={{ width: '100vw', height: '100vh' }}
                            onHide={() => {
                                setNoteToEdit(null, false)
                                setEditNoteIsFullScreen(false)
                            }}

                            ref={dialogRef}
                        >
                            <AddNote
                                currentShift={currentShift ? currentShift : null}
                                previousShiftRoleId={shiftSchedule.roleId}
                                refreshNotes={nicRefreshMyNotes}
                                editNote={editNote}
                                setNoteToEdit={setNoteToEdit}
                                isEditNoteIsFullScreen={isEditNoteIsFullScreen}
                            />
                        </Dialog>)
                        :
                        (<AddNote
                            currentShift={currentShift ? currentShift : null}
                            previousShiftRoleId={shiftSchedule.roleId}
                            refreshNotes={nicRefreshMyNotes}
                            editNote={editNote}
                            setNoteToEdit={setNoteToEdit}
                        />)
                )
                }
            </div >
            {/*Delete Note Modal*/}
            <ConfirmationModal
                showConfirmationModal={showConfirmationModal}
                toggleConfirmationModal={() => toggleConfirmationModal()}
                onConfirm={deleteNote}
                titleText='You are about to delete a note'
                titleClass='modal-title large gotham-bold'
                bodyText={deleteMessage}
                bodyClass='modal-body medium pt-0'
                footerClass='modal-footer border-0'
                confirmationButtonText='delete'
            />
            {/*Location selection Modal for copy note when different hierarchy*/}
            {
                showLocationSelectionModal &&
                <LocationSelectionModal
                    show={showLocationSelectionModal}
                    onConfirm={(locId: string) => {
                        copyNote(locId);
                    }}
                    roleId={currentShift.role.id}
                    toggle={toggleLocationSelectionModal}
                />
            }
        </>
    )
};

NoteItemCard.defaultProps = {
    isPrintMode: false
}

export default NoteItemCard;